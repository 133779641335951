import React, { useState } from 'react'

import { Checkbox, FormControlLabel } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

import Separator from '../../../../utils/Separator/Separator'

import {
    LeanToSideWrapper,
    LeanToSideTitle,
    LeanToSideTitleLabel,
    LeanToSideFormContent,
    BuiltSizeScrollbarWrapper
} from './LeanToSide.css'

import InputSelect from '../../parts/InputSelect/InputSelect';



const LeanToSide = ({ onLeanVisbilityChange, onLeanWidthChange, onLeanHeightChange, onLeanLengthChange, onLeanPitchChange, title, options, indexes, leanChecked }) => {


    const [isChecked, setIsChecked] = useState(leanChecked);

    const handleLeftLeanToCheckboxChange = () => {
        setIsChecked(!isChecked)
        onLeanVisbilityChange(isChecked);
    }
    return (
    
        <LeanToSideWrapper>
            <LeanToSideTitle>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isChecked}
                            onChange={handleLeftLeanToCheckboxChange}
                            name="double-leg-baserail"
                            color="primary"
                        />
                    }
                    style={{ color: '#222' }}
                    label={`${title} Lean-To`}
                />
                <LeanToSideTitleLabel>Click to Add Lean-To</LeanToSideTitleLabel>
            </LeanToSideTitle>

            <Separator />
            <LeanToSideFormContent checked={isChecked}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <InputSelect 
                         onChange={onLeanWidthChange} 
                        label="Width"
                          options={options[0]} inputName={title} selectedOptionIndex={indexes[0] > -1 ? indexes[0] : 10} 
                         />
                    </Grid>
                    <Grid item xs={6}>
                        <InputSelect 
                         onChange={onLeanLengthChange} 
                        label="Length" 
                         options={options[1]} inputName={title} selectedOptionIndex={indexes[2] > -1 ? indexes[2] : 0}
                         />
                    </Grid>
                    <Grid item xs={12}>
                        <InputSelect 
                         onChange={onLeanHeightChange} 
                        label="Height" 
                         options={options[2]} inputName={title} selectedOptionIndex={indexes[1] > -1 ? indexes[1] : 0}
                         />
                    </Grid>
                    <Grid item xs={12}>
                        <InputSelect 
                         onChange={onLeanPitchChange}
                         label="Lean-To Roof Pitch" 
                          options={options[3]} inputName={title} selectedOptionIndex={indexes[3] > -1 ? indexes[3] : 0} 
                         />
                    </Grid>
                    <Grid item xs={12}>
                        <InputSelect label="Wind/Snow" 
                         options={options[4]} inputName={title} 
                        />
                    </Grid>
                </Grid>
            </LeanToSideFormContent>

            <Separator />
        </LeanToSideWrapper>
    )
}

export default LeanToSide
