const buildingTypesData = [
  {
    id: 1,
    productName: "Standard Carports",
    productImage: "./img/buildingTypes/carports.png",
    productImageDescription: "image",
    width: "12’-24’",
    height: "6’-16’",
    length: "21’-81’",
    customizationData: {
      mainStructure: {
        width: "12",
        height: "9",
        length: "21",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
  {
    id: 2,
    productName: "Triple Wide Carports",
    productImage: "./img/buildingTypes/TripleWideCarports.png",
    productImageDescription: "image",
    width: "26’-30’",
    height: "6’-16’",
    length: "21’-101’",
    customizationData: {
      mainStructure: {
        width: "26",
        height: "9",
        length: "26",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: [],
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
  {
    id: 3,
    productName: "Standard Garages",
    productImage: "./img/buildingTypes/Garages.png",
    productImageDescription: "image",
    width: "12’-24’",
    height: "6’-16’",
    length: "21’-81’",
    customizationData: {
      mainStructure: {
        width: "20",
        height: "10",
        length: "21",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "enclosed",
          rightStyle: "enclosed",
          frontStyle: "enclosed",
          backStyle: "enclosed",
        },
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
  {
    id: 4,
    productName: "Triple Wide Garages",
    productImage: "./img/buildingTypes/TripleWideGarages.png",
    productImageDescription: "image",
    width: "26’-30’",
    height: "6’-16’",
    length: "21’-101’",
    customizationData: {
      mainStructure: {
        width: "26",
        height: "10",
        length: "21",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "enclosed",
          rightStyle: "enclosed",
          frontStyle: "enclosed",
          backStyle: "enclosed",
        },
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
  {
    id: 5,
    productName: "Utility Carports",
    productImage: "./img/buildingTypes/UtilityBuildings.png",
    productImageDescription: "image",
    width: "12’-24’",
    height: "6’-16’",
    length: "21’-81’",
    customizationData: {
      mainStructure: {
        width: "20",
        height: "9",
        length: "36",
        roofPitch: "3/12",
        isStorageOn: true,
        storageLength: "5",
        wallStyle: "open",
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        addDoors: [],
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
  {
    id: 6,
    productName: "Commercial Buildings",
    productImage: "./img/buildingTypes/CommercialBuildings.png",
    productImageDescription: "image",
    width: "32’-60’",
    height: "8’-20’",
    length: "20’-103’",
    customizationData: {
      mainStructure: {
        width: "32",
        height: "9",
        length: "31",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
  {
    id: 7,
    productName: "Commercial Buildings",
    productImage: "./img/buildingTypes/CommercialBuildings_.png",
    productImageDescription: "image",
    width: "62’-70’",
    height: "8’-20’",
    length: "21’-101’",
    customizationData: {
      mainStructure: {
        width: "32",
        height: "12",
        length: "41",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
  {
    id: 8,
    productName: "Standard Barn",
    productImage: "./img/buildingTypes/StandardBarns.png",
    productImageDescription: "image",
    width: "12’-24’",
    height: "6’-16’",
    length: "21’-81’",
    customizationData: {
      mainStructure: {
        width: "20",
        height: "10",
        length: "26",
        roofPitch: "3/12",
        isStorageOn: true,
        storageLength: "5",
        wallStyle: "enclosed",
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        walls: {
          leftStyle: "enclosed",
          rightStyle: "enclosed",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        walls: {
          leftStyle: "enclosed",
          rightStyle: "enclosed",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
  {
    id: 9,
    productName: "Raised Center Barn",
    productImage: "./img/buildingTypes/RaisedCenterBarns.png",
    productImageDescription: "image",
    width: "12’-24’",
    height: "6’-16’",
    length: "21’-81’",
    customizationData: {
      mainStructure: {
        width: "20",
        height: "12",
        length: "26",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "enclosed",
          rightStyle: "enclosed",
          frontStyle: "enclosed",
          backStyle: "enclosed",
        },
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "17",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "enclosed",
          rightStyle: "enclosed",
          frontStyle: "enclosed",
          backStyle: "enclosed",
        },
        addDoors: [],
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "17",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "enclosed",
          rightStyle: "enclosed",
          frontStyle: "enclosed",
          backStyle: "enclosed",
        },
        addDoors: [],
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
  {
    id: 10,
    productName: "Straight Roof Barn",
    productImage: "./img/buildingTypes/StraightRoofBarns.png",
    productImageDescription: "image",
    width: "12’-24’",
    height: "6’-16’",
    length: "21’-81’",
    customizationData: {
      mainStructure: {
        width: "20",
        height: "10",
        length: "26",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "enclosed",
          rightStyle: "enclosed",
          frontStyle: "enclosed",
          backStyle: "enclosed",
        },
        addDoors: [],
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        walls: {
          leftStyle: "enclosed",
          rightStyle: "enclosed",
          frontStyle: "enclosed",
          backStyle: "enclosed",
        },
        addDoors: [],
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
  {
    id: 11,
    productName: "Garage With LeanTo ",
    productImage: "./img/buildingTypes/GaragewithLeanto.png",
    productImageDescription: "image",
    width: "12’-24’",
    height: "6’-16’",
    length: "21’-81’",
    customizationData: {
      mainStructure: {
        width: "20",
        height: "10",
        length: "31",
        roofPitch: "3/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "enclosed",
        addDoors: [], //possible options : [addGarageDoor, addWalkInDoor, addWindow, addFramedOpening, addFramedWalkInDoor, addFramedWindow]
      },
      leftLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      rightLeanTo: {
        isOn: false,
        width: "16",
        height: "20",
        length: "21",
        roofPitch: "2/12",
        isStorageOn: false,
        storageLength: "5",
        wallStyle: "open",
        walls: {
          leftStyle: "open",
          rightStyle: "open",
          frontStyle: "open",
          backStyle: "open",
        },
        addDoors: [],
      },
      materials: {
        // list of color names in Forms/parts/colorPicker/ColorPickerData.json
        wallMaterialValue: "#6f2026",
        roofMaterialValue: "#6f2026",
        trimMaterialValue: "#FFFFFF",
        wainscotMaterialValue: "#6f2026",
      },
    },
  },
];

export default buildingTypesData;
