import styled from "styled-components/macro";

export const BuildMenuWrapper = styled.div`
	display: flex;
	z-index: 4;
	height: 92%;
	margin-top: auto;
	width: fit-content;
	max-width: 460px;
	position: absolute;
	top: 74px;
	left: 0;
	backdrop-filter: blur(30px) brightness(1.15);
	background-color: hsla(0, 0%, 100%, 0.25);
	transitionDelay: 1s;
	transform: ${(props) =>
    props.isFullView ? "translateX(-100%)" : "translateX(0)"};
	transition: 0.3s ease-in-out;
	
	.grid
	 {  //display:inline;
		//background-color:#000;
		width: 100%;
		
	 }

	.tab-content {
		> .tab-pane {
			display: none;
		}
		> .active {
			display: block;
		}
	}
	.nav
	{
		display:inline;
	}
	.A
	{
		margin-top:170px;
		background-color:black;
	}
	.nav-item
	{
		//background-color:#000;
		display:inline-block;
		// height:80px;
	}

	.nav-link {
		display: flex;
		flex-direction: column;
		text-align: center;
		font-size: 11px;
		color: #222;
		padding: 10px 0;
		align-items: center;
		text-transform: uppercase;
		text-decoration: none;
		border-bottom: 1px solid rgba(35, 35, 35, 0.1);
		border-right: 1px solid rgba(35, 35, 35, 0.1);
		transition: all 0.2s ease;
		position: relative;
		border-radius: 0;
		height: 80px;
		

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 6px;
			height: 100%;
			background: #222;
			z-index: 5;
			opacity: 0;
			visibility: hidden;
		}

		&:focus {
			outline: none;
		}

		svg {
			margin-bottom: 10px;
		}

		span {
			font-weight: bold;
			user-select: none;
		}

		&.active {
			&::before {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	@media (max-width: 991px) {
		position: static;
        width: 100%;
		background-color: #fff;
    }
    > .active {
      display: block;
    }
  }
  .nav {
    display: inline;
  }
  .A {
    margin-top: 170px;
    background-color: black;
  }
  .nav-item {
    //background-color:#000;
    display: inline-block;
    // height:80px;
  }

  .nav-link {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 11px;
    color: #222;
    padding: 10px 0;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 1px solid rgba(35, 35, 35, 0.1);
    border-right: 1px solid rgba(35, 35, 35, 0.1);
    transition: all 0.2s ease;
    position: relative;
    border-radius: 0;
    height: 80px;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 6px;
      height: 100%;
      background: #222;
      z-index: 5;
      opacity: 0;
      visibility: hidden;
    }

    &:focus {
      outline: none;
    }

    svg {
      margin-bottom: 10px;
    }

    span {
      font-weight: bold;
      user-select: none;
    }

    &.active {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media (max-width: 991px) {
    position: static;
    width: 100%;
    background-color: #fff;
  }
`;

export const BuildMenuNav = styled.div`
  padding: 20px 25px 20px 15px;
  cursor: pointer;
`;

export const BuildMenuNavNextBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222;
  color: #fff;
  border-radius: 52px;
  padding: 0.35rem 5px 0.35rem 1.25rem;
`;

export const BuildMenuNavNextBtnText = styled.span`
  display: block;
`;

export const BuildMenuNavNextBtnTextSmall = styled.span`
  display: block;
  margin-top: 2px;
  font-size: 11px;
`;

export const BuildMenuNavNextBtnIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: #000;
  border-radius: 100%;
`;

export const BuildMenuNavFooter = styled.div`
  padding: 0px 25px 20px 15px;
  cursor: pointer;
  display: flex;
  height: 10%;
`;

export const BuildMenuNavPrintBtn = styled.div`
  display: flex;
  //justify-content: space-between;
  align-items: center;
  background-color: #fff;
  // color: #fff;
  border-radius: 52px;
  border: 2px solid #000000;
  padding: 0.45rem 5px 0.35rem 1.25rem;
  margin-top: 10px;
  margin-right: 25px;
  width: 50%;
`;

export const BuildMenuNavPrintBtnIcon = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  height: 40px;
  width: 40px;

  //background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./img/icon/Print.png");
  background-size: 20px 20px;
`;

export const BuildMenuNavPrintBtnText = styled.span`
  display: block;
  color: #000;
`;

export const BuildMenuNavPrintBtnTextSmall = styled.span`
  display: block;
  margin-top: 2px;
  font-size: 11px;
  color: #fff;
`;
export const BuildMenuNavSaveBtn = styled.div`
  display: flex;
  //justify-content: space-between;
  align-items: center;
  background-color: #fff;
  //color: #fff;
  border-radius: 52px;
  border: 2px solid #000000;

  padding: 0.45rem 5px 0.35rem 1.25rem;
  margin-top: 10px;
  width: 50%;
`;
export const BuildMenuNavSaveBtnIcon = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  height: 40px;
  width: 40px;
  //background: #fff;
  //border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./img/icon/Save.png");
  background-size: 20px 20px;
`;
export const BuildMenuNavSaveBtnText = styled.span`
  display: block;
  color: #000;
  //padding-left: 35px;
`;

export const BuildMenuNavSaveBtnTextSmall = styled.span`
  display: block;
  margin-top: 2px;
  font-size: 11px;
  color: #fff;
`;
