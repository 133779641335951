import React, { useContext, useRef, useEffect, useState } from "react";

import { LeanToWrapper, BuiltSizeScrollbarWrapper } from "./LeanTo.css";
import { Plane, Axis, Space, Vector3 } from "@babylonjs/core";
import LeanToSide from "./LeanToSide/LeanToSide";
import { GlobalState } from "../../../App";
import {
  regenerateFrames,
  mainEndsWallClipPlanesChange,
  mainSidesWallClipPlanesChange,
  switchMorphLevel,
  recreatePlanes,
  createMeasurementsGUI,
  handlePriceValue,
  deleteDoorsAndWindows,
} from "../../../utils/functions";

import buildingTypesData from "../../BuildingType/BuildingTypes.json";
import WallsLeft from "../Walls/WallsLeft/WallsLeft";
import WallsRight from "../Walls/WallsRight/WallsRight";

var pitchRT = "";
var pitchLT = "";

const LeanTo = () => {
  //console.log('lean to triggered')
  const GlobalStore = useContext(GlobalState);
  //console.log(GlobalStore.LTFrontWall, GlobalStore.LTBackWall, GlobalStore.LTLeftWall)
  const leanOptions = [];

  const leanToWidth = [];
  for (let i = 6; i <= 16; i++) {
    leanToWidth.push({
      value: i.toString(),
      label: i.toString(),
      price: i * 15,
    });
  }

  const leanToLength = [];
  for (let i = 21; i < 22; i++) {
    leanToLength.push({
      value: i.toString(),
      label: i.toString(),
      price: i * 15,
    });
  }

  const leanToHeight = [{ value: "20", label: "20", price: 6 * 15 }];
  for (let i = 10; i <= GlobalStore.leanWallHeightConstraint; i++) {
    leanToHeight.push({
      value: i.toString(),
      label: i.toString(),
      price: i * 15,
    });
  }

  const leanToRoofPitch = [
    { value: "1/12", label: "1/12" },
    { value: "3/12", label: "3/12" },
    { value: "5/12", label: "5/12" },
  ];

  const leanToWindSnow = [
    {
      value: "Certified 140 MPH + 30 PSF",
      label: "Certified 140 MPH + 30 PSF",
    },
  ];
  leanOptions.push(
    leanToWidth,
    leanToLength,
    leanToHeight,
    leanToRoofPitch,
    leanToWindSnow
  );

  const scene = GlobalStore.sceneGlobalRef.current;

  var XScaleLT = [];
  var YScaleLT = [];
  var ZScaleLT = [];

  var XScaleSizesRT = [];
  var YScaleSizesRT = [];
  var ZScaleSizesRT = [];

  var XScaleRT = [];
  var YScaleRT = [];
  var ZScaleRT = [];

  var XScaleSizesLT = [];
  var YScaleSizesLT = [];
  var ZScaleSizesLT = [];
  var LeftLeanMeshes = [];
  var RightLeanMeshes = [];

  // const leftLeanParent = scene.getMeshByName("Lean_Left_Helper");
  // const rightLeanParent = scene.getMeshByName("Lean_Right_Helper");
  // const mainParent_L = scene.getMeshByName("Main_Helper_L");
  // const mainParent_R = scene.getMeshByName("Main_Helper_R");

  //create and define current indexes of the dropdown menus
  const widthIndexLeft = useRef(null);
  const heightIndexLeft = useRef(null);
  const lengthIndexLeft = useRef(null);
  const roofPitchIndexLeft = useRef(null);

  const widthIndexRight = useRef(null);
  const heightIndexRight = useRef(null);
  const lengthIndexRight = useRef(null);
  const roofPitchIndexRight = useRef(null);

  let leftIndexes = useRef([]);
  let rightIndexes = useRef([]);

  const presetDataLeft = GlobalStore.buildingType.customizationData.leftLeanTo;
  const presetDataRight =
    GlobalStore.buildingType.customizationData.rightLeanTo;

  widthIndexLeft.current = leanToWidth.findIndex(
    (x) => x.value === presetDataLeft.width
  );
  heightIndexLeft.current = leanToHeight.findIndex(
    (x) => x.value === presetDataLeft.height
  );
  lengthIndexLeft.current = leanToLength.findIndex(
    (x) => x.value === presetDataLeft.length
  );
  roofPitchIndexLeft.current = leanToRoofPitch.findIndex(
    (x) => x.value === presetDataLeft.roofPitch
  );

  widthIndexRight.current = leanToWidth.findIndex(
    (x) => x.value === presetDataRight.width
  );
  heightIndexRight.current = leanToHeight.findIndex(
    (x) => x.value === presetDataRight.height
  );

  lengthIndexRight.current = leanToLength.findIndex(
    (x) => x.value === presetDataRight.length
  );
  roofPitchIndexRight.current = leanToRoofPitch.findIndex(
    (x) => x.value === presetDataRight.roofPitch
  );

  useEffect(() => {
    handleLeftLeanChange(
      GlobalStore.isLeftLeanActive.current[GlobalStore.buildingType.id] ==
        "true"
        ? false
        : true
    );
    handleRightLeanChange(
      GlobalStore.isRightLeanActive.current[GlobalStore.buildingType.id] ==
        "true"
        ? false
        : true
    );

    handleLeanWidthChange(presetDataLeft.width, "Left", true);
    handleLeanWidthChange(presetDataRight.width, "Right", true);

    /*  handleRightLeanChange(!presetDataRight.isOn)
         handleLeftLeanChange(!presetDataLeft.isOn)
         */

    //     handleLeanHeightChange(presetDataLeft.height, "Left", true)
    //     handleLeanPitchChange(presetDataLeft.roofPitch, "Left", true)
    //     handleLeanLengthChange(presetDataLeft.length, "Left", true)

    //     handleLeanHeightChange(presetDataRight.height, "Right", true)
    //     handleLeanPitchChange(presetDataRight.roofPitch, "Right", true)
    //     handleLeanLengthChange(presetDataRight.length, "Right", true)

    //     leftIndexes.current.push(widthIndexLeft.current, heightIndexLeft.current, lengthIndexLeft.current, roofPitchIndexLeft.current)
    //rightIndexes.current.push(widthIndexRight.current, heightIndexRight.current, lengthIndexRight.current, roofPitchIndexRight.current)

    //     recreatePlanes("leftLean", mainParent_L, "dimensionHelper_L", GlobalStore, ["front_L", "back_L", "left_L"], scene);
    //     recreatePlanes("rightLean", mainParent_R, "dimensionHelper_R", GlobalStore, ["front_R", "back_R", "right_R"], scene);
    //     createMeasurementsGUI(GlobalStore, scene, "global");
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  pitchLT =
    GlobalStore.MeshMiddleLTPitch.current[GlobalStore.buildingType.id] == 0
      ? "Down"
      : GlobalStore.MeshMiddleLTPitch.current[GlobalStore.buildingType.id] == 1
      ? "Normal"
      : "UP";
  pitchRT =
    GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] == 0
      ? "Down"
      : GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] == 1
      ? "Normal"
      : "UP";

  var m = GlobalStore.MeshLT.current[1].getChildren();

  LeftLeanMeshes.push(GlobalStore.MeshLT.current[1]);
  var x = GlobalStore.MeshLT.current[1].scaling.x;
  var y = GlobalStore.MeshLT.current[1].scaling.y;
  var z = GlobalStore.MeshLT.current[1].scaling.z;
  XScaleLT.push(x);
  YScaleLT.push(y);
  ZScaleLT.push(z);

  ZScaleRT.push(z);

  for (var i = 0; i < m.length; i++) {
    //if(GlobalStore.buildingType.id!=8)
    {
      if (!m[i].name.includes("Storage") && m[i].name.includes("Type1_LT")) {
        {
          if (m[i].name.includes(pitchLT)) {
            if (
              m[i].name.includes("Wainscot") ||
              m[i].name.includes("walls_")
            ) {
              m[i].isVisible =
                GlobalStore.leftWallStyle === "open" ? false : true;
            } else {
              m[i].isVisible = true;
            }
          } else if (
            !(
              m[i].name.includes("Down") ||
              m[i].name.includes("Normal") ||
              m[i].name.includes("UP")
            )
          ) {
            m[i].isVisible = true;
          }
          if (
            m[i].name.includes("left_inner_walls") ||
            m[i].name.includes("left_walls")
          ) {
            m[i].isVisible = GlobalStore.LTLeftWall === "open" ? false : true;
          }

          if (m[i].name.includes("front")) {
            m[i].isVisible = GlobalStore.LTFrontWall === "open" ? false : true;
          }

          if (
            m[i].name.includes("back_inner_walls") ||
            m[i].name.includes("back_walls")
          ) {
            m[i].isVisible = GlobalStore.LTBackWall === "open" ? false : true;
          }

          presetDataLeft.isOn = true;
        }
      }
      if (m[i].name.includes("Storage") && m[i].name.includes("LT")) {
        if (!m[i].name.includes(pitchLT)) {
          m.isVisible = false;
        }
      }
    }
  }

  var m = GlobalStore.MeshRT.current[1].getChildren();

  RightLeanMeshes.push(GlobalStore.MeshRT.current[1]);
  var x = GlobalStore.MeshRT.current[1].scaling.x;
  var y = GlobalStore.MeshRT.current[1].scaling.y;
  var z = GlobalStore.MeshRT.current[1].scaling.z;
  XScaleRT.push(x);
  YScaleRT.push(y);
  ZScaleRT.push(z);

  for (var i = 0; i < m.length; i++) {
    //if(GlobalStore.buildingType.id!=8)
    {
      if (!m[i].name.includes("Storage") && m[i].name.includes("Type1_RT")) {
        if (m[i].name.includes(pitchRT)) {
          if (m[i].name.includes("Wainscot") || m[i].name.includes("walls_")) {
            m[i].isVisible =
              GlobalStore.rightWallStyle === "open" ? false : true;
          } else {
            m[i].isVisible = true;
          }
        } else if (
          !(
            m[i].name.includes("Down") ||
            m[i].name.includes("Normal") ||
            m[i].name.includes("UP")
          )
        ) {
          m[i].isVisible = true;
        }
        if (
          m[i].name.includes("right_inner_walls") ||
          m[i].name.includes("right_walls")
        ) {
          m[i].isVisible = GlobalStore.RTRightWall === "open" ? false : true;
        }
        if (m[i].name.includes("front")) {
          //WallsLeft.handleEndsFront();
          m[i].isVisible = GlobalStore.RTFrontWall === "open" ? false : true;
        }
        if (
          m[i].name.includes("back_inner_walls") ||
          m[i].name.includes("back_walls")
        ) {
          //WallsLeft.handleEndsBack();
          m[i].isVisible = GlobalStore.RTBackWall === "open" ? false : true;
        }

        presetDataRight.isOn = true;
      }
      if (m[i].name.includes("Storage") && m[i].name.includes("RT")) {
        if (!m[i].name.includes(pitchRT)) {
          m[i].isVisible = false;
        }
      }
    }
    // else
    // {
    //     if((!m[i].name.includes("Storage")) &&m[i].name.includes("Type8_RT"))
    //     {
    //     // if(GlobalStore.buildingType.id==4||GlobalStore.buildingType.id>5)

    //             if(m[i].name.includes(pitchRT))
    //             {
    //                 if((m[i].name.includes("walls_")|| m[i].name.includes("Wainscot")))
    //                 {

    //                     m[i].isVisible=true;
    //                 }

    //                 else
    //                 {
    //                     m[i].isVisible=true;
    //                 }

    //             }
    //             else if(!(m[i].name.includes("Down")||m[i].name.includes("Normal")||m[i].name.includes("UP"))){ m[i].isVisible=true;}
    //             presetDataRight.isOn=true;

    //     }
    // }
  }
  {
    //         else
    //         {
    // /*             if(!(scene.meshes[i].name.includes("Storage") )&&scene.meshes[i].name.includes("Type8_LT"))
    //             {
    //                         //else if( GlobalStore.isLeftLeanActive.current[GlobalStore.buildingType.id] =="true" || GlobalStore.isRightLeanActive.current[GlobalStore.buildingType.id] =="true" )
    //             // m.isVisible=false;
    //                 //if(GlobalStore.buildingType.id==4||GlobalStore.buildingType.id>5 && GlobalStore.buildingType.id!=11)
    //                 if( GlobalStore.isLeftLeanActive.current[GlobalStore.buildingType.id] =="true"  )
    //                 {
    //                     if(scene.meshes[i].name.includes(pitchLT))
    //                     {
    //                         scene.meshes[i].isVisible=true;
    //                     }
    //                     else if(!(scene.meshes[i].name.includes("Down")||scene.meshes[i].name.includes("Normal")||scene.meshes[i].name.includes("UP"))){ scene.meshes[i].isVisible=true;}
    //                     presetDataLeft.isOn=true;
    //                 }
    //                 else
    //                 scene.meshes[i].isVisible=false;
    //                 scene.meshes[i].position= GlobalStore.MeshLTPos.current[GlobalStore.buildingType.id];
    //                 scene.meshes[i].scaling= GlobalStore.MeshLTScale.current[GlobalStore.buildingType.id];
    //                 LeftLeanMeshes.push(scene.meshes[i]);
    //                 var  x =scene.meshes[i].scaling.x;
    //                 var y=scene.meshes[i].scaling.y;
    //                 var z=scene.meshes[i].scaling.z;
    //                 XScaleLT.push(x);
    //                 YScaleLT.push(y);
    //                 ZScaleLT.push(z);
    //             }
    //             if((!scene.meshes[i].name.includes("Storage")) &&scene.meshes[i].name.includes("Type8_RT"))
    //             {
    //                // if(GlobalStore.buildingType.id==4||GlobalStore.buildingType.id>5)
    //                if(  GlobalStore.isRightLeanActive.current[GlobalStore.buildingType.id] =="true" )
    //                 {
    //                     if(scene.meshes[i].name.includes(pitchRT))
    //                     {
    //                         scene.meshes[i].isVisible=true;
    //                     }
    //                     else if(!(scene.meshes[i].name.includes("Down")||scene.meshes[i].name.includes("Normal")||scene.meshes[i].name.includes("UP"))){ scene.meshes[i].isVisible=true;}
    //                     presetDataRight.isOn=true;
    //                 }
    //                 else
    //                 scene.meshes[i].isVisible=false;
    //                 scene.meshes[i].position= GlobalStore.MeshLTPos.current[GlobalStore.buildingType.id];
    //                 scene.meshes[i].scaling= GlobalStore.MeshLTScale.current[GlobalStore.buildingType.id];
    //                 RightLeanMeshes.push(scene.meshes[i]);
    //                 var  x =scene.meshes[i].scaling.x;
    //                 var y=scene.meshes[i].scaling.y;
    //                 var z=scene.meshes[i].scaling.z;
    //                     XScaleRT.push(x);
    //                     YScaleRT.push(y);
    //                     ZScaleRT.push(z);
    //             } */
    //         }
  }

  GlobalStore.MeshLT.current[1].setEnabled(
    GlobalStore.isLeftLeanActive.current[GlobalStore.buildingType.id] == "true"
      ? true
      : false
  );
  GlobalStore.MeshRT.current[1].setEnabled(
    GlobalStore.isRightLeanActive.current[GlobalStore.buildingType.id] == "true"
      ? true
      : false
  );

  presetDataLeft.isOn =
    GlobalStore.isLeftLeanActive.current[GlobalStore.buildingType.id] == "true"
      ? true
      : false;
  presetDataRight.isOn =
    GlobalStore.isRightLeanActive.current[GlobalStore.buildingType.id] == "true"
      ? true
      : false;

  if (widthIndexLeft.current != 0) {
    //console.error("if");

    for (var i = 0; i < widthIndexLeft.current; i++) {
      ZScaleSizesLT.push(ZScaleLT[0] - (widthIndexLeft.current - i) / 250);
    }
    ZScaleSizesLT.push(ZScaleLT[0]);
    for (var i = widthIndexLeft.current + 1; i <= leanToWidth.length; i++) {
      ZScaleSizesLT.push(ZScaleLT[0] + i / 750);
    }
  } else {
    console.error("else");
    ZScaleSizesLT.push(ZScaleLT[0]);

    for (var i = 1; i <= leanToWidth.length; i++) {
      ZScaleSizesLT.push(ZScaleLT[0] + i / 100);
    }
  }

  if (widthIndexRight.current != 0) {
    for (var i = 0; i < widthIndexRight.current; i++) {
      ZScaleSizesRT.push(ZScaleRT[0] - (widthIndexRight.current - i) / 250);
    }
    ZScaleSizesRT.push(ZScaleRT[0]);
    for (var i = widthIndexRight.current + 1; i <= leanToWidth.length; i++) {
      ZScaleSizesRT.push(ZScaleRT[0] + i / 750);
    }
  } else {
    ZScaleSizesRT.push(ZScaleRT[0]);

    for (var i = 1; i <= leanToWidth.length; i++) {
      ZScaleSizesRT.push(ZScaleRT[0] + i / 100);
    }
  }

  if (lengthIndexLeft.current != 0) {
    for (var i = 0; i < lengthIndexLeft.current; i++) {
      XScaleSizesLT.push(XScaleLT[0] - (lengthIndexLeft.current - i) / 250);
    }
    XScaleSizesLT.push(XScaleLT[0]);
    for (var i = lengthIndexLeft.current + 1; i <= leanToLength.length; i++) {
      XScaleSizesLT.push(XScaleLT[0] + i / 750);
    }
  } else {
    XScaleSizesLT.push(XScaleLT[0]);

    for (var i = 1; i <= leanToLength.length; i++) {
      XScaleSizesLT.push(XScaleLT[0] + i / 100);
    }
  }

  if (lengthIndexRight.current != 0) {
    for (var i = 0; i < lengthIndexRight.current; i++) {
      XScaleSizesRT.push(XScaleRT[0] - (lengthIndexRight.current - i) / 250);
    }
    XScaleSizesRT.push(XScaleRT[0]);
    for (var i = lengthIndexRight.current + 1; i <= leanToLength.length; i++) {
      XScaleSizesRT.push(XScaleRT[0] + i / 750);
    }
  } else {
    XScaleSizesRT.push(XScaleRT[0]);

    for (var i = 1; i <= leanToLength.length; i++) {
      XScaleSizesRT.push(XScaleRT[0] + i / 100);
    }
  }

  if (GlobalStore.buildingType.id != 8) {
    /*  GlobalStore.MeshLT.current[1].position= GlobalStore.MeshLTPos.current[GlobalStore.buildingType.id];
            GlobalStore.MeshLT.current[1].scaling= GlobalStore.MeshLTScale.current[GlobalStore.buildingType.id];
            GlobalStore.MeshRT.current[1].position= GlobalStore.MeshLTPos.current[GlobalStore.buildingType.id];
            GlobalStore.MeshRT.current[1].scaling= GlobalStore.MeshLTScale.current[GlobalStore.buildingType.id]; */

    GlobalStore.MeshLT.current[1].scaling = new Vector3(
      1,
      GlobalStore.leanLHeight / 20,
      1
    ); //og/pa
    GlobalStore.MeshRT.current[1].scaling = new Vector3(
      1,
      GlobalStore.leanRHeight / 20,
      1
    ); //og/pa

    let mesh_left_lean = GlobalStore.MeshLT.current[1].getChildren();
    for (let i = 0; i < mesh_left_lean.length; i++) {
      if (mesh_left_lean[i].name.includes("Wains")) {
        mesh_left_lean[i].scaling.y = 20 / GlobalStore.leanLHeight;
      }
    }

    let mesh_right_lean = GlobalStore.MeshRT.current[1].getChildren();
    for (let i = 0; i < mesh_right_lean.length; i++) {
      if (mesh_right_lean[i].name.includes("Wains")) {
        mesh_right_lean[i].scaling.y = 20 / GlobalStore.leanRHeight;
      }
    }

    GlobalStore.MeshLT.current[1].parent = GlobalStore.MeshMain.current[1];
    GlobalStore.MeshRT.current[1].parent = GlobalStore.MeshMain.current[1];
  } else {
    GlobalStore.MeshLT.current[1].parent = GlobalStore.MeshMain.current[8];
    var C = GlobalStore.MeshLTScale.current[GlobalStore.buildingType.id];
    var A = GlobalStore.MeshLT.current[1].parent.scaling;

    GlobalStore.MeshLT.current[1].scaling = new Vector3(
      2.571,
      1.143,
      1.189
    ); //og/pa

    
    // GlobalStore.MeshLT.current[1].position= GlobalStore.MeshLTPos.current[GlobalStore.buildingType.id];

    GlobalStore.MeshRT.current[1].parent = GlobalStore.MeshMain.current[8];
    var C = GlobalStore.MeshLTScale.current[GlobalStore.buildingType.id];
    var A = GlobalStore.MeshLT.current[1].parent.scaling;
    GlobalStore.MeshRT.current[1].scaling = new Vector3(
      2.571,
      1.143,
      1.189
    ); //og/pa
    //  GlobalStore.MeshRT.current[1].position= GlobalStore.MeshRTPos.current[GlobalStore.buildingType.id];

    // GlobalStore.MeshLT.current[1].scaling= GlobalStore.MeshLTScale.current[GlobalStore.buildingType.id];
    /* 
            GlobalStore.MeshRT.current[1].position= GlobalStore.MeshLTPos.current[GlobalStore.buildingType.id];
            GlobalStore.MeshRT.current[1].scaling= GlobalStore.MeshLTScale.current[GlobalStore.buildingType.id]; */
    //GlobalStore.MeshLT.current[1].parent=GlobalStore.MeshMain.current[8];
  }

  const handleLeftLeanChange = (isChecked) => {
    switch (!isChecked) {
      case true:
        GlobalStore.isLeftLeanActive.current[GlobalStore.buildingType.id] =
          "true";
        GlobalStore.MeshLT.current[1].setEnabled(true);

        // _LT_door";
        for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("_LT_door")) {
            scene.meshes[i].isVisible = true;
            //("handleLeftLeanChange" + scene.meshes[i].name);
          }
        }

        break;
      case false:
        GlobalStore.isLeftLeanActive.current[GlobalStore.buildingType.id] =
          "false";
        GlobalStore.MeshLT.current[1].setEnabled(false);
        for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("_LT_door"))
            scene.meshes[i].isVisible = false;
        }
        //this code section deletes the doors in the leanTo's. This deletes all entries. The next then updates the arrays
        let objectsToRemove = [
          ...GlobalStore.LTFrontWallObjects,
          ...GlobalStore.LTFrontStorageWallObjects,
          ...GlobalStore.LTBackWallObjects,
          ...GlobalStore.LTLeftWallObjects,
          ...GlobalStore.LTLeftStorageWallObjects,
        ];

        for (let i = 0; i < objectsToRemove.length; i++) {
          objectsToRemove[i].object.dispose();
        }
        GlobalStore.setLTFrontWallObjects([]);
        GlobalStore.setLTFrontStorageWallObjects([]);
        GlobalStore.setLTBackWallObjects([]);
        GlobalStore.setLTLeftWallObjects([]);
        GlobalStore.setLTLeftStorageWallObjects([]);

        break;

      default:
        break;
    }
  };
  const handleRightLeanChange = (isChecked) => {
    //     const measurementGUI = GlobalStore.currentDimensionElements.current;
    //     rightLeanParent.getChildMeshes().map(rightChild => !rightChild.name.includes("dimensionHelper_R") && !rightChild.name.includes("Profile") && !rightChild.name.includes("Ridge") && !rightChild.name.includes("storage_leanR_backWall") ? rightChild.isVisible = !isChecked : null);
    switch (!isChecked) {
      case true:
        GlobalStore.isRightLeanActive.current[GlobalStore.buildingType.id] =
          "true";
        GlobalStore.MeshRT.current[1].setEnabled(true);
        for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("_RT_door")) {
            scene.meshes[i].isVisible = true;
            //console.log("handleLeftLeanChange" + scene.meshes[i].name);
            console.error("eeeee111");
          }
        }
        break;
      case false:
        GlobalStore.isRightLeanActive.current[GlobalStore.buildingType.id] =
          "false";
        GlobalStore.MeshRT.current[1].setEnabled(false);
        for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("_RT_door")) {
            scene.meshes[i].isVisible = false;
            console.error("rrrreeeee111");
          }
        }
        //handle door deletions
        let objectsToRemove = [
          ...GlobalStore.RTFrontWallObjects,
          ...GlobalStore.RTFrontStorageWallObjects,
          ...GlobalStore.RTBackWallObjects,
          ...GlobalStore.RTRightWallObjects,
          ...GlobalStore.RTRightStorageWallObjects,
        ];
        for (let i = 0; i < objectsToRemove.length; i++) {
          objectsToRemove[i].object.dispose();
        }
        GlobalStore.setRTFrontWallObjects([]);
        GlobalStore.setRTFrontStorageWallObjects([]);
        GlobalStore.setRTBackWallObjects([]);
        GlobalStore.setRTRightWallObjects([]);
        GlobalStore.setRTRightStorageWallObjects([]);
        break;
      default:
        break;
    }
    //     //check if measurement is on and if left lean is on and show/hide measurement gui accordingly
    //     if (GlobalStore.isMeasure) {
    //         measurementGUI.map(mesh => {
    //             if (mesh.name.indexOf("leanR") > -1 && !isChecked) {
    //                 mesh.isVisible = true;
    //             }
    //             else if (mesh.name.indexOf("leanR") > -1 && isChecked) {
    //                 mesh.isVisible = false;
    //             }
    //             return 0
    //         })
    //     }
    //     else {
    //         measurementGUI.map(mesh => {
    //             if (mesh.name.indexOf("leanR") > -1) {
    //                 mesh.isVisible = false;
    //             }
    //             return 0
    //         })
    //     }
  };
  const handleLeanWidthChange = (targetValue, inputName, init) => {
    switch (inputName) {
      case "Left":
        GlobalStore.setLeanLWidth(targetValue);

        for (var i = 0; i < LeftLeanMeshes.length; i++) {
          LeftLeanMeshes[i].scaling = new Vector3(
            LeftLeanMeshes[i].scaling.x,
            LeftLeanMeshes[i].scaling.y,
            ZScaleSizesLT[targetValue - 6]
          );

          LeftLeanMeshes[i].position = new Vector3(
            LeftLeanMeshes[i].position.x,
            LeftLeanMeshes[i].position.y,
            (16 - targetValue) * 1.3
          );
          // console.error(LeftLeanMeshes[i].position.z)
        }

        break;

      case "Right":
        GlobalStore.setLeanRWidth(targetValue);
        for (var i = 0; i < RightLeanMeshes.length; i++) {
          RightLeanMeshes[i].scaling = new Vector3(
            RightLeanMeshes[i].scaling.x,
            RightLeanMeshes[i].scaling.y,
            ZScaleSizesRT[targetValue - 6]
          );
          RightLeanMeshes[i].position = new Vector3(
            RightLeanMeshes[i].position.x,
            RightLeanMeshes[i].position.y,
            -(16 - targetValue) * 1.3
          );
        }

        break;

      default:
        break;
    }

    //     handlePriceValue(targetValue, currentPrice, leanToWidth, GlobalStore);
  };
  const handleLeanHeightChange = (targetValue, inputName, init) => {
    switch (inputName) {
      case "Left":
        GlobalStore.setLeanLHeight(targetValue);
        break;
      case "Right":
        GlobalStore.setLeanRHeight(targetValue);
        break;
      default:
        break;
    }
  };
  const handleLeanLengthChange = (targetValue, inputName, init) => {
    return;

    switch (inputName) {
      case "Left":
        GlobalStore.setLeanLLength(targetValue);
        for (var i = 0; i < LeftLeanMeshes.length; i++) {
          LeftLeanMeshes[i].scaling = new Vector3(
            XScaleSizesLT[targetValue - 21],
            LeftLeanMeshes[i].scaling.y,
            LeftLeanMeshes[i].scaling.z
          );
        }

        break;
      case "Right":
        GlobalStore.setLeanRLength(targetValue);
        for (var i = 0; i < RightLeanMeshes.length; i++) {
          RightLeanMeshes[i].scaling = new Vector3(
            XScaleSizesRT[targetValue - 21],
            RightLeanMeshes[i].scaling.x,
            RightLeanMeshes[i].scaling.z
          );
        }

        break;

      default:
        break;
    }
  };
  const handleLeanPitchChange = (targetValue, inputName, init) => {
    switch (inputName) {
      case "Left":
        GlobalStore.setLeanLRoofPitch(targetValue);

        for (var i = 0; i < leanToRoofPitch.length; i++) {
          if (leanToRoofPitch[i].value.includes(targetValue)) {
            GlobalStore.MeshMiddleLTPitch.current[GlobalStore.buildingType.id] =
              i;
            pitchLT =
              GlobalStore.MeshMiddleLTPitch.current[
                GlobalStore.buildingType.id
              ] == 0
                ? "Down"
                : GlobalStore.MeshMiddleLTPitch.current[
                    GlobalStore.buildingType.id
                  ] == 1
                ? "Normal"
                : "UP";
            // console.error(targetValue +" "+i+" "+pitchMain);
            //break;
          }
        }

        for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("Type1_LT")) {
            if (scene.meshes[i].name.includes(pitchLT)) {
              scene.meshes[i].isVisible = true;
            } else if (
              scene.meshes[i].name.includes("Dow") ||
              scene.meshes[i].name.includes("Nor") ||
              scene.meshes[i].name.includes("UP")
            ) {
              //console.error( scene.meshes[i].name +"#####"+pitchLT+"#####");
              scene.meshes[i].isVisible = false;
            }
          }
        }

        break;
      case "Right":
        GlobalStore.setLeanRRoofPitch(targetValue);

        for (var i = 0; i < leanToRoofPitch.length; i++) {
          if (leanToRoofPitch[i].value.includes(targetValue)) {
            GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] =
              i;
            pitchRT =
              GlobalStore.MeshMiddleRTPitch.current[
                GlobalStore.buildingType.id
              ] == 0
                ? "Down"
                : GlobalStore.MeshMiddleRTPitch.current[
                    GlobalStore.buildingType.id
                  ] == 1
                ? "Normal"
                : "UP";
            // console.error(targetValue +" "+i+" "+pitchMain);
            //break;
          }
        }

        for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("Type1_RT")) {
            if (scene.meshes[i].name.includes(pitchRT)) {
              scene.meshes[i].isVisible = true;
            } else if (
              scene.meshes[i].name.includes("Down") ||
              scene.meshes[i].name.includes("Normal") ||
              scene.meshes[i].name.includes("UP")
            ) {
              //console.error( scene.meshes[i].name +"#####"+pitchLT+"#####");
              scene.meshes[i].isVisible = false;
            }
          }
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    //your code goes here
    return () => {
      console.error("clean lean");
    };
  }, []);
  return (
    <BuiltSizeScrollbarWrapper>
      <LeanToWrapper>
        <LeanToSide
          onLeanVisbilityChange={handleLeftLeanChange}
          onLeanWidthChange={handleLeanWidthChange}
          onLeanHeightChange={handleLeanHeightChange}
          onLeanLengthChange={handleLeanLengthChange}
          onLeanPitchChange={handleLeanPitchChange}
          title="Left"
          options={leanOptions}
          indexes={leftIndexes}
          leanChecked={presetDataLeft.isOn}
        />
        <LeanToSide
          onLeanVisbilityChange={handleRightLeanChange}
          onLeanWidthChange={handleLeanWidthChange}
          onLeanHeightChange={handleLeanHeightChange}
          onLeanLengthChange={handleLeanLengthChange}
          onLeanPitchChange={handleLeanPitchChange}
          title="Right"
          options={leanOptions}
          indexes={rightIndexes}
          leanChecked={presetDataRight.isOn}
        />
      </LeanToWrapper>
    </BuiltSizeScrollbarWrapper>
  );
};

export default LeanTo;
