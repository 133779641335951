import React, { useContext, useState, useRef, useEffect } from "react";
import { BuiltSizeWrapper, BuiltSizeScrollbarWrapper } from "./BuiltSize.css";
import SectionTitle from "../../SectionTitle/SectionTitle";
import { TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import Separator from "../../../utils/Separator/Separator";
import { getBoundingBox } from "../../../utils/functions";

//import Scrollbar from "react-scrollbars-custom";

import InputBoxes from "../parts/InputBoxes/InputBoxes";
import InputSelect from "../parts/InputSelect/InputSelect";

import Grid from "@material-ui/core/Grid";

import { Plane, Space, Axis, Vector3, Scalar } from "@babylonjs/core";
import {
  regenerateFrames,
  recreatePlanes,
  createMeasurementsGUI,
  switchMorphLevel,
  mainSidesWallClipPlanesChange,
  mainEndsWallClipPlanesChange,
  handlePriceValue,
} from "../../../utils/functions";

import { GlobalState } from "../../../App";

import roofData from "../parts/InputBoxes/roofData";
import buildingTypesData from "../../BuildingType/BuildingTypes.json";
import { object } from "yup";

const roofPitchSelectOptions = [
  { value: "3/12", label: "3/12" },
  { value: "4/12", label: "4/12" },
  { value: "5/12", label: "5/12" },
  { value: "6/12", label: "6/12" },
];

const widthSelectOptions = [];
for (let i = 5; i < 61; i++) {
  widthSelectOptions.push({
    value: i.toString(),
    label: i.toString(),
    price: i * 10,
  });
}

const lengthSelectOptions = [];
for (let i = 10; i <= 101; i++) {
  lengthSelectOptions.push({
    value: i.toString(),
    label: i.toString(),
    price: i * 10,
  });
}

const heightSelectOptions = [
  { value: "6", label: "6", price: 500 },
  { value: "7", label: "7", price: 525 },
  { value: "8", label: "8", price: 550 },
  { value: "9", label: "9", price: 575 },
  { value: "10", label: "10", price: 600 },
  { value: "11", label: "11", price: 625 },
  { value: "12", label: "12", price: 650 },
  { value: "13", label: "13", price: 675 },
  { value: "14", label: "14", price: 700 },
  { value: "15", label: "15", price: 725 },
  { value: "16", label: "16", price: 750 },
  { value: "17", label: "17", price: 775 },
  { value: "18", label: "18", price: 800 },
  { value: "19", label: "19", price: 825 },
  { value: "20", label: "20", price: 850 },
];

const windSnowSelectOptions = [
  { value: "Select", label: "Select" },
  { value: "140mph", label: "140 MPH + 30 PSF Certified" },
];

var ActiveMeshList = [];
var XScale = [];
var YScale = [];
var ZScale = [];

var XScaleSizes = [];
var YScaleSizes = [];
var ZScaleSizes = [];
var pitchMain = "";

var IsSideCrossBracing = false,
  IsEndCrossBracing = false;

const BuiltSize = () => {
  console.log(
    "rendering the buildsize which always doesn't work like it should be"
  );
  ActiveMeshList = [];
  XScale = [];
  YScale = [];
  ZScale = [];
  XScaleSizes = [];
  YScaleSizes = [];
  ZScaleSizes = [];

  const [doubleLegBaserail, setDoubleLegBaserail] = useState(false);
  const [upgradeGauge, setUpgradeGauge] = useState(false);

  const [IsSideCrossBracing, SetIsSideCrossBracing] = useState(false);
  const [IsEndCrossBracing, SetIsEndCrossBracing] = useState(false);

  const GlobalStore = useContext(GlobalState);
  const scene = GlobalStore.sceneGlobalRef.current;

  //take the intial position and scaling from GlobalStor.MeshPos.current
  const Pos = GlobalStore.MeshPos.current[GlobalStore.buildingType.id];
  const Sca = GlobalStore.MeshScale.current[GlobalStore.buildingType.id];

  const roofPitchIndex = useRef(null);
  const heightIndex = useRef(null);
  const widthIndex = useRef(null);
  const lengthIndex = useRef(null);

  //GlobalStore.MeshMain.current[1].showBoundingBox = true;

  // //get current buildingType data
  const presetData = GlobalStore.buildingType.customizationData.mainStructure;
  // //find the data indexes inside the selectOptions
  widthIndex.current = widthSelectOptions.findIndex(
    (x) => x.value === presetData.width
  );
  heightIndex.current = heightSelectOptions.findIndex(
    (x) => x.value === presetData.height
  );
  lengthIndex.current = lengthSelectOptions.findIndex(
    (x) => x.value === presetData.length
  );
  roofPitchIndex.current = roofPitchSelectOptions.findIndex(
    (x) => x.value === presetData.roofPitch
  );

  pitchMain =
    GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] == 0
      ? "Down"
      : GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] ==
        1
      ? "Normal"
      : "UP";

  for (var i = 0; i < scene.meshes.length; i++) {
    if (
      scene.meshes[i].name.includes("Type") &&
      !scene.meshes[i].name.includes("Type" + GlobalStore.buildingType.id)
    ) {
      scene.meshes[i].isVisible = false;
    }
    if (GlobalStore.buildingType.id != 8) {
      if (
        scene.meshes[i].name.includes("Storage") &&
        !scene.meshes[i].name.includes("Type8")
      ) {
        if (scene.meshes[i].name.includes("LT")) {
          scene.meshes[i].isVisible = GlobalStore.isStorageLTOn;
        } else if (scene.meshes[i].name.includes("RT")) {
          scene.meshes[i].isVisible = GlobalStore.isStorageRTOn;
        } else if (scene.meshes[i].name.includes(pitchMain)) {
          scene.meshes[i].isVisible = GlobalStore.isStorageOn;
          console.log("GlobalStore.isStorageOn ", GlobalStore.isStorageOn);
        }
      }
    } else {
      if (scene.meshes[i].name.includes("Storage")) {
        if (scene.meshes[i].name.includes("LT")) {
          scene.meshes[i].isVisible = GlobalStore.isStorageLTOn;
          console.log("GlobalStore.isStorageLT ", GlobalStore.isStorageLTOn);
        } else if (scene.meshes[i].name.includes("RT")) {
          scene.meshes[i].isVisible = GlobalStore.isStorageRTOn;
          console.log("GlobalStore.isStorageRTOn ", GlobalStore.isStorageRTOn);
        }
      } else {
        if (scene.meshes[i].name.includes(pitchMain)) {
          scene.meshes[i].isVisible = GlobalStore.isStorageOn;
          console.log("GlobalStore.isStorageOn ", GlobalStore.isStorageOn);
        }
      }
    }
  }

  if (GlobalStore.buildingType.id != 8) {
    ActiveMeshList.push(GlobalStore.MeshMain.current[1]);
    //if(Scale.length<=0)
    var x = GlobalStore.MeshScale.current[1].x;
    var y = GlobalStore.MeshScale.current[1].y;
    var z = GlobalStore.MeshScale.current[1].z;

    //push the initial scales to the array
    if (GlobalStore.StorageMeshMain.current[1].name.includes(pitchMain)) {
      GlobalStore.StorageMeshMain.current[1].isVisible =
        GlobalStore.isStorageOn;
    }
    else {
      GlobalStore.StorageMeshMain.current[1].isVisible=false;
    }
    GlobalStore.StorageMeshMain.current[1].getChildren().forEach((child) => {
      if (child.name.includes(pitchMain)) {
        child.isVisible = GlobalStore.isStorageOn;
      }
      else {
        child.isVisible=false;
      }
    });
    XScale.push(x);
    YScale.push(y);
    ZScale.push(z);

    GlobalStore.MeshMain.current[1].getChildren().forEach((m) => {
      if (
        !(
          m.name.includes("LT") ||
          m.name.includes("RT") ||
          m.name.includes("Storage")
        ) &&
        m.name.includes("_Type1_")
      ) {
        if (m.name.includes("SIDE")) m.isVisible = IsSideCrossBracing;
        else if (m.name.includes("END")) m.isVisible = IsEndCrossBracing;
        else if (m.name.includes(pitchMain)) {
          m.isVisible = true; //this may be used to get the functionality implemented
        } else if (
          !(
            m.name.includes("Down") ||
            m.name.includes("Normal") ||
            m.name.includes("UP")
          )
        ) {
          m.isVisible = true;
        }

        if (m.name.includes("walls_") || m.name.includes("Wains")) {
          if (
            m.name.includes("LT") &&
            GlobalStore.IsWallLTOpen.current[GlobalStore.buildingType.id]
          ) {
            m.isVisible = false;
            m.isPickable = false;
          } else if (
            m.name.includes("RT") &&
            GlobalStore.IsWallRTOpen.current[GlobalStore.buildingType.id]
          ) {
            m.isVisible = false;
            m.isPickable = false;
          } else if (
            GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id]
          ) {
            //this could also be used to implement the
            m.isVisible = false;
            m.isPickable = false;
          }
        }
      } else if (m.name.includes("LT") || m.name.includes("RT")) {
        //console.log("I found an LT or RT here");
        //console.log(m.name);
        if (m.name.includes("Storage")) {
          //console.log("LT_Storage found");
        }
        if (m.name.includes("Storage")) {
          //console.log("RT_Storage Found");
        } else m.isVisible = false;
      } else if (
        (m.name.includes("_Type0_") || !m.name.includes("_Type1_")) &&
        m.name.includes("_Type")
      ) {
        if (m.name.includes("_Window"));

        m.isVisible = false;
      }
    });
  } else {
    ActiveMeshList.push(GlobalStore.MeshMain.current[8]);
    //if(Scale.length<=0)

    if (GlobalStore.StorageMeshMain.current[8].name.includes(pitchMain)) {
      GlobalStore.StorageMeshMain.current[8].isVisible =
        GlobalStore.isStorageOn;
    }
    GlobalStore.StorageMeshMain.current[8].getChildren().forEach((child) => {
      if (child.name.includes(pitchMain)) {
        child.isVisible = GlobalStore.isStorageOn;
      }
    });
    var x = GlobalStore.MeshScale.current[8].x;
    var y = GlobalStore.MeshScale.current[8].y;
    var z = GlobalStore.MeshScale.current[8].z;
    XScale.push(x);
    YScale.push(y);
    ZScale.push(z);

    GlobalStore.MeshMain.current[8].getChildren().forEach((m) => {
      if (
        !(
          m.name.includes("LT") ||
          m.name.includes("RT") ||
          m.name.includes("Storage")
        ) &&
        m.name.includes("_Type8_")
      ) {
        if (m.name.includes("SIDE")) m.isVisible = IsSideCrossBracing;
        else if (m.name.includes("END")) m.isVisible = IsEndCrossBracing;
        else if (m.name.includes(pitchMain)) {
          m.isVisible = true;
        } else if (
          !(
            m.name.includes("Down") ||
            m.name.includes("Normal") ||
            m.name.includes("UP")
          )
        ) {
          m.isVisible = true;
        }

        if (m.name.includes("walls_") || m.name.includes("Wainscot")) {
          if (
            m.name.includes("LT") &&
            GlobalStore.IsWallLTOpen.current[GlobalStore.buildingType.id]
          ) {
            m.isVisible = false;
            m.isPickable = false;
          } else if (
            m.name.includes("LT") &&
            GlobalStore.IsWallRTOpen.current[GlobalStore.buildingType.id]
          ) {
            m.isVisible = false;
            m.isPickable = false;
          } else if (
            GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id]
          ) {
            m.isVisible = false;
            m.isPickable = false;
          }
        }
      } else if (m.name.includes("LT") || m.name.includes("RT")) {
        if (m.name.includes("LT_Storage"));
        if (m.name.includes("RT_Storage"));
        else m.isVisible = false;
      } else if (
        (m.name.includes("_Type0_") || !m.name.includes("_Type1_")) &&
        m.name.includes("_Type")
      ) {
        if (m.name.includes("_Window"));
        m.isVisible = false;
      }
    });
  }

  if (!GlobalStore.isBuiltSizeInit.current) {
    if (GlobalStore.buildingType.id != 8) {
      GlobalStore.MeshMain.current[1].position = Pos;
      GlobalStore.MeshMain.current[1].scaling = Sca;
      GlobalStore.isBuiltSizeInit.current = true;
      GlobalStore.MeshMain.current[1].setEnabled(true);
      GlobalStore.MeshMain.current[8].setEnabled(false);
    } else {
      GlobalStore.MeshMain.current[8].position = Pos;
      GlobalStore.MeshMain.current[8].scaling = Sca;
      GlobalStore.isBuiltSizeInit.current = true;
      GlobalStore.MeshMain.current[8].setEnabled(true);
      GlobalStore.MeshMain.current[1].setEnabled(false);
    }
  }

  if (lengthIndex.current != 0) {
    for (var i = 0; i < lengthIndex.current; i++) {
      XScaleSizes.push(XScale[0] - (lengthIndex.current - i) / 250);
    }
    XScaleSizes.push(XScale[0]);
    for (
      var i = lengthIndex.current + 1;
      i <= lengthSelectOptions.length;
      i++
    ) {
      XScaleSizes.push(XScale[0] + i / 750);
    }
  } else {
    XScaleSizes.push(XScale[0]);

    for (var i = 1; i <= lengthSelectOptions.length; i++) {
      XScaleSizes.push(XScale[0] + i / 100);
    }
  }
  if (heightIndex.current != 0) {
    for (var i = 0; i < heightIndex.current; i++) {
      // console.error(heightIndex.current+"~~~~~~~~~~~~"+heightSelectOptions.length+"~~~~"+i);

      YScaleSizes.push(YScale[0] - (heightIndex.current - i) / 250);
    }
    YScaleSizes.push(YScale[0]);
    for (
      var i = heightIndex.current + 1;
      i <= heightSelectOptions.length;
      i++
    ) {
      YScaleSizes.push(YScale[0] + i / 750);
    }
  } else {
    YScaleSizes.push(YScale[0]);

    for (var i = 1; i <= heightSelectOptions.length; i++) {
      //console.error(heightIndex.current+"~~~~~~~~~~~~"+heightSelectOptions.length+"~~~~i"+i);

      YScaleSizes.push(YScale[0] + i / 100);
    }
  }
  if (widthIndex.current != 0) {
    //console.error("```````````````````````````````````````````````````");

    for (var i = 0; i < widthIndex.current; i++) {
      ZScaleSizes.push(ZScale[0] - (widthIndex.current - i) / 250);
    }
    ZScaleSizes.push(ZScale[0]);
    for (var i = widthIndex.current + 1; i <= widthSelectOptions.length; i++) {
      ZScaleSizes.push(ZScale[0] + i / 750);
    }
  } else {
    ZScaleSizes.push(ZScale[0]);

    for (var i = 1; i <= widthSelectOptions.length; i++) {
      //console.error("~~~~~~~~~~~~"+ZScale[0]+(i)/100+"~~~~");

      //	console.error(widthIndex.current+"~~~~~~~~~~~~"+widthSelectOptions.length+"~~~~i"+i);

      ZScaleSizes.push(ZScale[0] + i / 100);
    }
  }

  const handleDoubleLegBasetrailChange = () => {
    // 	setDoubleLegBaserail(!doubleLegBaserail);
  };

  const handleUpgradeGaugeChange = () => {
    setUpgradeGauge(!upgradeGauge);
    GlobalStore.setUpgradeGauge(!GlobalStore.upgradeGauge);
  };

  const handleWindSnowChange = () => {
    GlobalStore.setWindSnowChange(!GlobalStore.windSnowChange);
  };

  const handleRoofPanels = (index) => {
    console.error(index);
    switch (index) {
      case 0:
        scene.meshes.forEach((m) => {
          if (m.name.includes("Garage__roof")) {
            m.material.bumpTexture = GlobalStore.normalMapWallTex.current;
            // m.material.bumpTexture.wAng=2;
          }
        });
        break;
      case 1:
        scene.meshes.forEach((m) => {
          if (m.name.includes("Garage__roof")) {
            m.material.bumpTexture = GlobalStore.normalMapWallTex90.current;
            //  m.material.bumpTexture.wAng=2;
          }
        });

        break;
      default:
        break;
    }
  };

  const handleRoofPitch = (targetValue, inputName, init) => {
    //alert(targetValue);
    GlobalStore.setMainRoofPitch(targetValue);
    // if storage is on change storage pitch
    for (var i = 0; i < roofPitchSelectOptions.length; i++) {
      if (roofPitchSelectOptions[i].value.includes(targetValue)) {
        GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] =
          i;
        pitchMain =
          GlobalStore.MeshMiddleMainPitch.current[
            GlobalStore.buildingType.id
          ] == 0
            ? "Down"
            : GlobalStore.MeshMiddleMainPitch.current[
                GlobalStore.buildingType.id
              ] == 1
            ? "Normal"
            : "UP";
        //break;
      }
    }
    if (GlobalStore.buildingType.id != 8) {
      //if mesh is not a barn
      GlobalStore.MeshMain.current[1].getChildren().forEach((m) => {
        if (
          !(
            m.name.includes("LT") ||
            m.name.includes("RT") ||
            m.name.includes("Storage")
          ) &&
          m.name.includes("_Type1_")
        ) {
          if (m.name.includes("SIDE")) m.isVisible = IsSideCrossBracing;
          else if (m.name.includes("END")) m.isVisible = IsEndCrossBracing;
          else if (m.name.includes(pitchMain)) {
            m.isVisible = true;
          } else if (
            m.name.includes("Down") ||
            m.name.includes("Normal") ||
            m.name.includes("UP")
          ) {
            m.isVisible = false;
          }

          if (m.name.includes("walls_") || m.name.includes("Wainscot")) {
            //this might have been added when doors were not supported
            /*(
              GlobalStore.buildingType.id == 1 ||
              GlobalStore.buildingType.id == 2 ||
              GlobalStore.buildingType.id == 10)
          ) */ m.isVisible = false;
            m.isPickable = false;
          }
        } else if (
          (m.name.includes("_Type0_") || !m.name.includes("_Type1_")) &&
          m.name.includes("_Type")
        ) {
          if (m.name.includes("LT_Storage"))
            console.error("11111----" + m.name);

          if (m.name.includes("Door")) {
          } else m.isVisible = false;
        } else if (m.name.includes("LT") || m.name.includes("RT")) {
          //if(m.name.includes("LT_Storage"))console.error(m.name);
          //else
          m.isVisible = false;
          if (m.name.includes("LT_Storage"))
            console.error("22222----" + m.name);
        }
        //else
        //console.error("name-"+m.name);
        //else
        else if (m.name.includes("_Window")) m.isVisible = true;
      });
    } else {
      GlobalStore.MeshMain.current[1].getChildren().forEach((m) => {
        if (
          !(
            m.name.includes("LT") ||
            m.name.includes("RT") ||
            m.name.includes("Storage")
          ) &&
          m.name.includes("_Type1_")
        ) {
          if (m.name.includes("SIDE")) m.isVisible = IsSideCrossBracing;
          else if (m.name.includes("END")) m.isVisible = IsEndCrossBracing;
          else if (m.name.includes(pitchMain)) {
            m.isVisible = true;
          } else if (
            m.name.includes("Down") ||
            m.name.includes("Normal") ||
            m.name.includes("UP")
          ) {
            m.isVisible = false;
          }

          if (
            (m.name.includes("walls_") || m.name.includes("Wainscot")) &&
            (GlobalStore.buildingType.id == 1 ||
              GlobalStore.buildingType.id == 2 ||
              GlobalStore.buildingType.id == 10)
          ) {
            m.isVisible = false;
            m.isPickable = false;
          }
        } else if (
          (m.name.includes("_Type0_") || !m.name.includes("_Type1_")) &&
          m.name.includes("_Type")
        ) {
          if (m.name.includes("LT_Storage"))
            console.error("11111----" + m.name);

          if (m.name.includes("Door")) {
          } else m.isVisible = false;
        } else if (m.name.includes("LT") || m.name.includes("RT")) {
          //if(m.name.includes("LT_Storage"))console.error(m.name);
          //else
          m.isVisible = false;
          if (m.name.includes("LT_Storage"))
            console.error("22222----" + m.name);
        }
        //else
        //console.error("name-"+m.name);
        //else
        else if (m.name.includes("_Window")) m.isVisible = true;
      });
    }
  };

  const handleSizeWidth = (targetValue, inputName, init) => {
    for (var i = 0; i < ActiveMeshList.length; i++) {
      //GlobalStore.setMainWidth(targetValue);
      if (GlobalStore.buildingType.id != 8) {
        GlobalStore.MeshMain.current[1].scaling = new Vector3(
          ActiveMeshList[i].scaling.x,
          ActiveMeshList[i].scaling.y,
          ZScaleSizes[targetValue - 5]
        );
      } else {
        GlobalStore.MeshMain.current[8].scaling = new Vector3(
          ActiveMeshList[i].scaling.x,
          ActiveMeshList[i].scaling.y,
          ZScaleSizes[targetValue - 5]
        );
      }

      //change the scale
      GlobalStore.MeshScale.current[GlobalStore.buildingType.id].z =
        ZScaleSizes[targetValue - 5];
    }
    //GlobalStore.setMainWidth(targetValue);

    setTimeout(() => {
      let bounds = getBoundingBox(GlobalStore.mainCartportBoundingBox.current);
      

      GlobalStore.leftWallObjects.forEach((object)=>{
        object.object.position.z = bounds.maxWorld.z;
      })
      GlobalStore.leftStorageWallObjects.forEach((object)=>{
        object.object.position.z = bounds.maxWorld.z;
      })

      GlobalStore.rightWallObjects.forEach((object)=>{
        object.object.position.z = bounds.minWorld.z;
      })

      GlobalStore.rightStorageWallObjects.forEach((object)=>{
        object.object.position.z = bounds.minWorld.z;
      })

      let leftBounds =getBoundingBox(GlobalStore.LTBoundingBox.current);
      let rightBounds = getBoundingBox(GlobalStore.RTBoundingBox.current);

      GlobalStore.LTLeftWallObjects.forEach((object)=>{
        object.object.position.z = leftBounds.maxWorld.z;
      })

      GlobalStore.RTRightWallObjects.forEach((object)=>{
        object.object.position.z = rightBounds.minWorld.z;
      })

      GlobalStore.LTLeftStorageWallObjects.forEach((object)=>{
        object.object.position.z = leftBounds.maxWorld.z;
      })

      GlobalStore.RTRightStorageWallObjects.forEach((object)=>{
        object.object.position.z = rightBounds.minWorld.z;
      })

      let objectsFront = [
        ...GlobalStore.frontCenterWallObjects,
        ...GlobalStore.frontCenterStorageWallObjects
      ];

      let objectsLTFront=[
        ...GlobalStore.LTFrontWallObjects,
        ...GlobalStore.LTFrontStorageWallObjects
      ]

      let objectsRTFront=[
        ...GlobalStore.RTFrontWallObjects,
        ...GlobalStore.RTFrontStorageWallObjects
      ]

      console.log(objectsFront);

     
        objectsFront.forEach((object) => {
          object.object.position.z= (bounds.minWorld.z+bounds.maxWorld.z)/2;
          object.pointer.validateDrag=(
            targetPosition
          ) => {
          
            targetPosition.z = Scalar.Clamp(
              targetPosition.z,
              bounds.minWorld.z+object.limit,
              bounds.maxWorld.z-object.limit
            );
            targetPosition.y = Scalar.Clamp(
              targetPosition.y,
              13,
              object.heightOffset
            );
            return true;
          };
        })
        objectsLTFront.forEach((object) => {
          object.object.position.z= (leftBounds.minWorld.z+leftBounds.maxWorld.z)/2;
          object.pointer.validateDrag=(
            targetPosition
          ) => {
          
            targetPosition.z = Scalar.Clamp(
              targetPosition.z,
              leftBounds.minWorld.z+object.limit,
              leftBounds.maxWorld.z-object.limit
            );
            targetPosition.y = Scalar.Clamp(
              targetPosition.y,
              13,
              object.heightOffset
            );
            return true;
          };
        })

        objectsRTFront.forEach((object) => {
          object.object.position.z= (rightBounds.minWorld.z+rightBounds.maxWorld.z)/2;
          object.pointer.validateDrag=(
            targetPosition
          ) => {
          
            targetPosition.z = Scalar.Clamp(
              targetPosition.z,
              rightBounds.minWorld.z+object.limit,
              rightBounds.maxWorld.z-object.limit
            );
            targetPosition.y = Scalar.Clamp(
              targetPosition.y,
              13,
              object.heightOffset
            );
            return true;
          };
        })

      
     
      let objectsBack = [
        ...GlobalStore.backCenterWallObjects
       
       ];

      let objectsLTBack=[
        ...GlobalStore.LTBackWallObjects
      ]

      let objectsRTBack=[
        ...GlobalStore.RTBackWallObjects
      ]


      
      objectsBack.forEach((object) => {
        object.object.position.z= (bounds.minWorld.z+bounds.maxWorld.z)/2;
        object.pointer.validateDrag=(
          targetPosition
        ) => {
          targetPosition.z = Scalar.Clamp(
            targetPosition.z,
            bounds.minWorld.z+object.limit,
            bounds.maxWorld.z-object.limit
          );
          targetPosition.y = Scalar.Clamp(
            targetPosition.y,
            13,
            object.heightOffset
          );
          return true;
        }
      })

      objectsLTBack.forEach((object) => {
        object.object.position.z= (leftBounds.minWorld.z+leftBounds.maxWorld.z)/2;
        object.pointer.validateDrag=(
          targetPosition
        ) => {
          targetPosition.z = Scalar.Clamp(
            targetPosition.z,
            leftBounds.minWorld.z+object.limit,
            leftBounds.maxWorld.z-object.limit
          );
          targetPosition.y = Scalar.Clamp(
            targetPosition.y,
            13,
            object.heightOffset
          );
          return true;
        }
      })

      objectsRTBack.forEach((object) => {
        object.object.position.z= (rightBounds.minWorld.z+rightBounds.maxWorld.z)/2;
        object.pointer.validateDrag=(
          targetPosition
        ) => {
          targetPosition.z = Scalar.Clamp(
            targetPosition.z,
            rightBounds.minWorld.z+object.limit,
            rightBounds.maxWorld.z-object.limit
          );
          targetPosition.y = Scalar.Clamp(
            targetPosition.y,
            13
            ,
            object.heightOffset
          );
          return true;
        }
      })
    }, 1);
  };

  async function handleSizeLength(targetValue, inputName, init) {
    //console.error(lengthIndex.current+"``"+ presetData.length);

    //GlobalStore.setMainLength(targetValue);
    for (var i = 0; i < ActiveMeshList.length; i++) {
      //ActiveMeshList[i].scaling =
      if (GlobalStore.buildingType.id != 8) {
        GlobalStore.MeshMain.current[1].scaling = new Vector3(
          XScaleSizes[targetValue - 10],
          ActiveMeshList[i].scaling.y,
          ActiveMeshList[i].scaling.z
        );
      } else {
        GlobalStore.MeshMain.current[8].scaling = new Vector3(
          XScaleSizes[targetValue - 10],
          ActiveMeshList[i].scaling.y,
          ActiveMeshList[i].scaling.z
        );
      }

      if (GlobalStore.buildingType.id != 8) {
        GlobalStore.MeshScale.current[GlobalStore.buildingType.id].x =
          XScaleSizes[targetValue - 10];
      }
    }

    ////set parents
    // dimensionHelper_M.setParent(mainParent_R);
    // mainFrameBase_R.setParent(mainParent_R);
    // mainFrameBase_L.setParent(mainParent_L);
    // mainStorage_R.setParent(null)
    // mainStorage_L.setParent(null)
    // //scale the mesh length
    // mainParent_R.scaling.x = targetValue / 21;
    // mainParent_L.scaling.x = targetValue / 21;
    // //calculate clip planes
    // mainParent_R.computeWorldMatrix(true);
    // const storagePlaneDistance = mainParent_R.getBoundingInfo().boundingBox.minimumWorld.x;
    // GlobalStore.mainLengthFromCenter.current = storagePlaneDistance;
    // //set parent
    // mainStorage_R.setParent(mainParent_R);
    // mainStorage_L.setParent(mainParent_L);
    // //set position of storage (default position)
    // mainStorage_R.setAbsolutePosition(storagePlaneDistance, mainStorage_R.getAbsolutePosition().y, mainStorage_R.getAbsolutePosition().z)
    // mainStorage_L.setAbsolutePosition(storagePlaneDistance, mainStorage_L.getAbsolutePosition().y, mainStorage_L.getAbsolutePosition().z)
    // //translate storage depending on the selected value
    // if (GlobalStore.storageMain) {
    // 	mainStorage_R.translate(Axis.Y, GlobalStore.mainStorageTranslation.current / 5 * 2.4, Space.LOCAL);
    // 	mainStorage_L.translate(Axis.Y, GlobalStore.mainStorageTranslation.current / 5 * 2.4, Space.LOCAL);

    setTimeout(() => {
      let bounds = getBoundingBox(GlobalStore.mainCartportBoundingBox.current);

      let objectsFront = [
        ...GlobalStore.frontCenterWallObjects,
        ...GlobalStore.LTFrontWallObjects,
        ...GlobalStore.RTFrontWallObjects,
      ];
      console.log(objectsFront);
      objectsFront.forEach((object) => {
        object.object.position.x = bounds.maxWorld.x;
      });

      let centerStorageBounds = getBoundingBox(GlobalStore.mainCartportStorageBoundingBox.current);
      let LTStorageBounds= getBoundingBox(GlobalStore.LTStorageBoundingBox.current);
      let RTStorageBounds = getBoundingBox(GlobalStore.RTStorageBoundingBox.current);

      //let storageCenterBounds= getBoundingBox(GlobalStore.mainCartportStorageBoundingBox.current);

      //let storageLeftBounds = getBoundingBox(GlobalState.LTStorageBoundingBox.current);
      //let storageRightBounds = getBoundingBox(GlobalStore.RTStorageBoundingBox.current);

      GlobalStore.frontCenterStorageWallObjects.forEach((object) => {
        object.object.position.x = centerStorageBounds.maxWorld.x;
      })

      GlobalStore.LTFrontStorageWallObjects.forEach((object) => {
        object.object.position.x = LTStorageBounds.maxWorld.x;
      })

      GlobalStore.RTFrontStorageWallObjects.forEach((object) => {
        object.object.position.x = RTStorageBounds.maxWorld.x;
      })



      let objectsSideFull=[
        ...GlobalStore.LTLeftWallObjects, 
        ...GlobalStore.RTRightWallObjects,
      ...GlobalStore.leftWallObjects,
    ...GlobalStore.rightWallObjects,
  ]

//remap the objects to the center of the new positions
  let newObjectPosition = (bounds.maxWorld.x + bounds.minWorld.x)/2;
  objectsSideFull.forEach((object) => {
    object.object.position.x=  newObjectPosition
    object.pointer.validateDrag=(
      targetPosition
    ) => {
      targetPosition.x = Scalar.Clamp(
        targetPosition.x,
        bounds.minWorld.x+object.limit,
        bounds.maxWorld.x-object.limit
      );
      targetPosition.y = Scalar.Clamp(
        targetPosition.y,
        13,
        object.heightOffset
      );
      return true;
    }
  })

      let objectsBack = [
        ...GlobalStore.backCenterWallObjects,
        ...GlobalStore.LTBackWallObjects,
        ...GlobalStore.RTBackWallObjects,
      ];

      objectsBack.forEach((object) => {
        object.object.position.x = bounds.minWorld.x;
      });
    }, 10);
  }

  const handleSizeHeight = (targetValue, inputName, init) => {
    console.log("height change");
    //GlobalStore.setMainHeight(targetValue);
    for (var i = 0; i < ActiveMeshList.length; i++) {
      //using short gun surgery to copy height

      if (GlobalStore.buildingType.id != 8) {
        GlobalStore.MeshMain.current[1].scaling = new Vector3(
          ActiveMeshList[i].scaling.x,
          YScaleSizes[targetValue - 6],
          ActiveMeshList[i].scaling.z
        );
      } else {
        GlobalStore.MeshMain.current[8].scaling = new Vector3(
          ActiveMeshList[i].scaling.x,
          YScaleSizes[targetValue - 6],
          ActiveMeshList[i].scaling.z
        );
      }

      GlobalStore.MeshScale.current[GlobalStore.buildingType.id].y =
        YScaleSizes[targetValue - 6];
    }

    // 	//main structure wainscot meshes
    // 	const wainscots = [wainscot_B_L, wainscot_F_L, wainscot_S_L, wainscot_B_R, wainscot_F_R, wainscot_S_R];
    // 	//set parents to null, but define parents that will be reparented later
    // 	wainscots.map(wainscot => wainscot.setParent(null))

    // 	dimensionHelper_M.setParent(mainParent_R);
    // 	//set parents
    // 	mainFrameBase_R.setParent(null);
    // 	mainFrameBase_L.setParent(null);
    // 	leftSideWallStorage.setParent(mainParent_R);
    // 	rightSideWallStorage.setParent(mainParent_R);
    // 	//set parents height
    // 	mainParent_R.position.y = GlobalStore.main_R_startPosY.current + (targetValue / 9 - 1) * 3.5;
    // 	mainParent_L.position.y = GlobalStore.main_L_startPosY.current + (targetValue / 9 - 1) * 3.5;
    // 	leftSideWallStorage.setParent(leftStorage);
    // 	rightSideWallStorage.setParent(rightStorage);
    // 	//set wainscot parents

    // 	wainscot_B_L.setParent(mainBackWalls_L)
    // 	wainscot_F_L.setParent(mainFrontWalls_L)
    // 	wainscot_S_L.setParent(mainSideWalls_L)
    // 	wainscot_B_R.setParent(mainBackWalls_R)
    // 	wainscot_F_R.setParent(mainFrontWalls_R)
    // 	wainscot_S_R.setParent(mainSideWalls_R)

    // 	//clear measurements GUI elements
    // 	GlobalStore.currentDimensionElements.current.map(mesh => mesh.dispose());
    // 	GlobalStore.currentDimensionElements.current = [];

    // 	//calculate bounds
    // 	wallHeightReference.computeWorldMatrix(true);
    // 	const groundHeightY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y;
    // 	const wallHeightY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
    // 	//set clip planes
    // 	mainEndsWallClipPlanesChange(
    // 		GlobalStore.selectedEndFront,
    // 		[wallHeightY, groundHeightY],
    // 		[mainFrontWalls_R, mainFrontWalls_L],
    // 		scene
    // 	);
    // 	mainEndsWallClipPlanesChange(
    // 		GlobalStore.selectedEndBack,
    // 		[wallHeightY, groundHeightY],
    // 		[mainBackWalls_R, mainBackWalls_L],
    // 		scene
    // 	);
    // 	mainSidesWallClipPlanesChange(
    // 		GlobalStore.selectedSidesLeft,
    // 		[wallHeightY, groundHeightY],
    // 		[mainSideWalls_L],
    // 		scene
    // 	);
    // 	mainSidesWallClipPlanesChange(
    // 		GlobalStore.selectedSidesRight,
    // 		[wallHeightY, groundHeightY],
    // 		[mainSideWalls_R],
    // 		scene
    // 	);

    // 	//set lean wall height constraint
    // 	GlobalStore.setLeanWallHeightConstraint(targetValue - 3);
    // 	GlobalStore.setMainHeight(targetValue)
    // 	if (!init) {
    // 		//recreate reference planes
    // 		recreatePlanes("main", null, "dimensionHelper_M", GlobalStore, ["front", "back", "left", "right"], scene);
    // 		//create new set of gui elements with proper dimensions
    // 		createMeasurementsGUI(GlobalStore, scene, "main", ["height", targetValue]);
    // 	}
    // 	//uptade price
    // 	handlePriceValue(
    // 		targetValue,
    // 		GlobalStore.currentHeightPrice_main.current,
    // 		heightSelectOptions,
    // 		GlobalStore
    // 	);
  };
  const EndCrossBracing = () => {
    if (!IsEndCrossBracing) {
      if (GlobalStore.buildingType.id != 8) {
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type1") &&
            scene.meshes[i].name.includes("END")
          ) {
            {
              scene.meshes[i].isVisible = true;

              // scene.meshes[i].material.diffuseColor  =  new Color3.FromHexString(color.value).toLinearSpace();
              // //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
              // // material.reflectionTexture = envTexture;
              // scene.meshes[i].material.metallic = .7;
              // scene.meshes[i].material.roughness = .7;
            }
          }
        }
      } else {
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type8") &&
            scene.meshes[i].name.includes("END")
          ) {
            {
              scene.meshes[i].isVisible = true;

              // scene.meshes[i].material.diffuseColor  =  new Color3.FromHexString(color.value).toLinearSpace();
              // //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
              // // material.reflectionTexture = envTexture;
              // scene.meshes[i].material.metallic = .7;
              // scene.meshes[i].material.roughness = .7;
            }
          }
        }
      }
    } else {
      if (GlobalStore.buildingType.id != 8) {
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type1") &&
            scene.meshes[i].name.includes("END")
          ) {
            {
              if (scene.meshes[i].name.includes("Door")) {
              }
              scene.meshes[i].isVisible = false;

              // scene.meshes[i].material.diffuseColor  =  new Color3.FromHexString(color.value).toLinearSpace();
              // //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
              // // material.reflectionTexture = envTexture;
              // scene.meshes[i].material.metallic = .7;
              // scene.meshes[i].material.roughness = .7;
            }
          }
        }
      } else {
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type8") &&
            scene.meshes[i].name.includes("END")
          ) {
            {
              scene.meshes[i].isVisible = false;

              // scene.meshes[i].material.diffuseColor  =  new Color3.FromHexString(color.value).toLinearSpace();
              // //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
              // // material.reflectionTexture = envTexture;
              // scene.meshes[i].material.metallic = .7;
              // scene.meshes[i].material.roughness = .7;
            }
          }
        }
      }
    }
    SetIsEndCrossBracing(!IsEndCrossBracing);
    GlobalStore.setIsEndCrossBracing(!GlobalStore.isEndCrossBracing);
  };
  const SideCrossBracing = () => {
    if (!IsSideCrossBracing) {
      if (GlobalStore.buildingType.id != 8) {
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type1") &&
            scene.meshes[i].name.includes("SIDE") &&
            scene.meshes[i].name.includes(pitchMain)
          ) {
            scene.meshes[i].isVisible = true;
            console.error(scene.meshes[i].name);
          }
        }
      } else {
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type8") &&
            scene.meshes[i].name.includes("SIDE") &&
            scene.meshes[i].name.includes(pitchMain)
          ) {
            scene.meshes[i].isVisible = true;
            console.error(scene.meshes[i].name);
          }
        }
      }
    } else {
      if (GlobalStore.buildingType.id != 8) {
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type1") &&
            scene.meshes[i].name.includes("SIDE") &&
            scene.meshes[i].name.includes(pitchMain)
          ) {
            {
              if (scene.meshes[i].name.includes("Door")) {
                console.error("Dooooooooooooooooooooooooooors");
              }
              scene.meshes[i].isVisible = false;

              // scene.meshes[i].material.diffuseColor  =  new Color3.FromHexString(color.value).toLinearSpace();
              // //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
              // // material.reflectionTexture = envTexture;
              // scene.meshes[i].material.metallic = .7;
              // scene.meshes[i].material.roughness = .7;
            }
          }
        }
      } else {
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type8") &&
            scene.meshes[i].name.includes("SIDE") &&
            scene.meshes[i].name.includes(pitchMain)
          ) {
            {
              scene.meshes[i].isVisible = false;
            }
          }
        }
      }
    }
    SetIsSideCrossBracing(!IsSideCrossBracing);
    GlobalStore.setIsSideCrossBracing(!GlobalStore.isSideCrossBracing);

    //IsSideCrossBracing=!IsSideCrossBracing;

    // console.error(IsSideCrossBracing);
  };
  useEffect(() => {
    //your code goes here
    return () => {
      ActiveMeshList = [];
      XScale = [];
      YScale = [];
      ZScale = [];
      XScaleSizes = [];
      YScaleSizes = [];
      ZScaleSizes = [];
      pitchMain = "";
    };
  }, []);
  return (
    <BuiltSizeScrollbarWrapper>
      <BuiltSizeWrapper>
        <SectionTitle title={"Building Name"} />
        <TextField
          id="filled-read-only-input"
          label="Building Name"
          defaultValue="Standard Carports"
          value={buildingTypesData[GlobalStore.buildingType.id - 1].productName}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
        <Separator />
        <SectionTitle title={"Roof"} />
        <InputBoxes onChange={handleRoofPanels} options={roofData} />
        <InputSelect
          onChange={handleRoofPitch}
          label="Roof Pitch"
          options={roofPitchSelectOptions}
          inputName="built-size-roof-pitch"
          selectedOptionIndex={roofPitchIndex.current}
        />
        <Separator />
        <SectionTitle title={"Size"} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputSelect
              onChange={handleSizeWidth}
              label="Width"
              options={widthSelectOptions}
              inputName="built-size-width"
              selectedOptionIndex={widthIndex.current}
            />
          </Grid>
          <Grid item xs={6}>
            <InputSelect
              onChange={handleSizeLength}
              label="Length"
              options={lengthSelectOptions}
              inputName="built-size-length"
              selectedOptionIndex={lengthIndex.current}
            />
          </Grid>
          <Grid item xs={12}>
            <InputSelect
              onChange={handleSizeHeight}
              label="Height"
              options={heightSelectOptions}
              inputName="built-size-height"
              selectedOptionIndex={heightIndex.current}
            />
          </Grid>
        </Grid>
        <Separator />
        {/* <FormControlLabel
					control={
						<Checkbox
							checked={doubleLegBaserail}
							 onChange={handleDoubleLegBasetrailChange}
							name="double-leg-baserail"
							color="primary"
						/>
					}
					style={{ color: "#222" }}
					label="Double Leg Baserail"
				/> */}
        <FormControlLabel
          control={
            <Checkbox
              checked={IsSideCrossBracing}
              onChange={SideCrossBracing}
              name="double-leg-baserail"
              color="primary"
            />
          }
          style={{ color: "#222" }}
          label="Side Cross Bracing"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={IsEndCrossBracing}
              onChange={EndCrossBracing}
              name="double-leg-baserail"
              color="primary"
            />
          }
          style={{ color: "#222" }}
          label="End Cross Bracing"
        />
        <Separator />
        <SectionTitle title={"Gauge & Wind/Snow"} />
        <FormControlLabel
          control={
            <Checkbox
              checked={upgradeGauge}
              onChange={handleUpgradeGaugeChange}
              name="upgrade-gauge"
              color="primary"
            />
          }
          style={{ color: "#222" }}
          label="Upgrade To 12` Gauge"
        />
        <Separator />
        <InputSelect
          label="Wind/Snow"
          options={windSnowSelectOptions}
          onChange={handleWindSnowChange}
          inputName="built-size-wind-snow"
        />
      </BuiltSizeWrapper>
    </BuiltSizeScrollbarWrapper>
  );
};

export default BuiltSize;
