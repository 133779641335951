import styled from 'styled-components/macro';

export const SectionTitleWrapper = styled.div`
    margin-bottom: 10px;
`;

export const SectionTitleText = styled.h5`
    position: relative;
    color: #222;
    font-size: 13px;
    font-weight: bold;
    margin: 0;

    &::after {
        content: '';
        display: block;
        width: 18px;
        height: 3px;
        margin-top: 2px;
        background: #eb7324;
    }
`;