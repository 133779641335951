import styled from 'styled-components/macro';
import Scrollbar from 'react-scrollbars-custom';

export const WallsScrollbarWrapper = styled(Scrollbar)`
width: 350px !important;
height: calc(var(--vh, 1vh)*85 - 250px) !important;

@media (max-width: 991px) {
    width: 100% !important;
    height: inherit !important;
    position: static !important;
}
`

export const WallsWrapper = styled.div`
    padding: 15px;
    
    @media (max-width: 991px) {
        width: 100%;
    }
`;

export const WallsSelectWrapper = styled.div`
    opacity: ${props => props.checked ? 1 : 0.6};
    user-select: ${props => props.checked ? 'auto' : 'none'};
    pointer-events: ${props => props.checked ? 'auto' : 'none'};
    transition: 0.3s ease-in-out;
`;

export const WallsWainscot = styled.div`
    opacity: ${props => props.checked === 'enclosed' ? 1 : 0.6};
    user-select: ${props => props.checked === 'enclosed' ? 'auto' : 'none'};
    pointer-events: ${props => props.checked === 'enclosed' ? 'auto' : 'none'};
    transition: 0.3s ease-in-out;
`;

export const WallsWainscotColorWrapper = styled.div`
    opacity: ${props => props.checked ? 1 : 0.6};
    user-select: ${props => props.checked ? 'auto' : 'none'};
    pointer-events: ${props => props.checked ? 'auto' : 'none'};
    transition: 0.3s ease-in-out;
`;



export const WallsTab = styled.div`

    .flex-row
    {
       // background-color:#fff;
       //display:block;
    }
    .nav-item
    {

        //display:inline-block;
        //background-color:#fff;
        width:auto;
        //display: flex;
        //flex-direction: row;
    }
    .nav-item:after
    {

        // content: "";
        // display: table;
        // clear: both;
    }
    .nav
    {
         //display:inline-block;
        width:100%;

    }
    .nav-link {
        float:left;

		//display: flex;
        //display:inline-block;
		//flex-direction: column;
		// text-align: center;
		// font-size: 11px;
		// color: #222;
		// padding: 15px 5px;
		// align-items: center;
		// text-transform: uppercase;
		// text-decoration: none;
		// border-bottom: 1px solid rgba(35, 35, 35, 0.1);
		// border-right: 1px solid rgba(35, 35, 35, 0.1);
		// transition: all 0.2s ease;
		position: relative;
		//border-radius: 0;
    }

    /////////////////////////////
    .flax-row .nav-item {
        display: flex;
    }
    
    .flax-row .nav-item .nav-link {
        flex-direction: row;
        flex-grow: 1;
        justify-content: center;
        padding: 15px 0 5px;
    }
    
    .flax-row .nav-item .nav-link::before {
        height: 2px;
        width: 100%;
        bottom: -1px;
        left: 0;
    }
    
    .flax-row .nav-item .nav-link.active {
        background: white;
        color: #eb7324;
    }
    
    .flax-row .nav-item .nav-link.active::before {
        background: #eb7324;
    }
    
    .flax-row .nav-item .nav-link.active path {
        fill: #eb7324;
    }
    
    .flax-row .nav-item .nav-link svg {
        margin-right: 5px;
    }
`;
