import styled from 'styled-components/macro'
import Scrollbar from 'react-scrollbars-custom';

export const ColorsScrollbarWrapper = styled(Scrollbar)`
width: 350px !important;
height: calc(var(--vh, 1vh)*90 - 250px) !important;

@media (max-width: 991px) {
    width: 100% !important;
    height: inherit !important;
    position: static !important;
}
`;


export const ColorsWrapper = styled.div`
    padding: 15px;
`;


