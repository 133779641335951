import React, { useContext } from "react";
import { GlobalState, GetCanvas } from "../../App";

import {
  BuildingTypeWrapper,
  BuildingTypeContent,
  BuildingTypeContentTitle,
  BuildingTypeContentText,
  BuildingTypes,
  BuildingTypesItem,
  BuildingTypesItemImage,
  BuildingTypesItemTitle,
  BuildingTypesItemInfo,
  BuildingTypesItemInfoItem,
  BuildingTypesItemInfoItemLabel,
  BuildingTypesItemInfoItemValue,
  BuildingTypesItemButton,
} from "./BuildingType.css";
import { ReactComponent as ArrowRightIcon } from "../../images/icons/right-arrow.svg";

import buildingTypesData from "./BuildingTypes.json";

const BuildingType = () => {
  //console.log("Building Types Refresed")
  const GlobalStore = useContext(GlobalState);

  const handleBuildingTypeButton = (data) => {
    GlobalStore.setDwSummary([]);
    GlobalStore.setBuildingType(data);
    GlobalStore.setCanvasStyle("visible");

    const customizationData = data.customizationData;
    const mainData = customizationData.mainStructure;
    const leftLeanData = customizationData.leftLeanTo;
    const rightLeanData = customizationData.rightLeanTo;

    GlobalStore.setMainWidth(mainData.width);
    GlobalStore.setMainHeight(mainData.height);
    GlobalStore.setMainLength(mainData.length);
    GlobalStore.setMainRoofPitch(mainData.roofPitch);
    //GlobalStore.setLeanWallHeightConstraint(GlobalStore.mainHeight);
    GlobalStore.setLeanLWidth(leftLeanData.width);
    GlobalStore.setLeanLHeight(parseInt(leftLeanData.height));
    GlobalStore.setLeanLLength(leftLeanData.length);
    GlobalStore.setLeanLRoofPitch(leftLeanData.roofPitch);

    GlobalStore.setLeanRWidth(rightLeanData.width);
    GlobalStore.setLeanRHeight(parseInt(rightLeanData.height));
    GlobalStore.setLeanRLength(rightLeanData.length);
    GlobalStore.setLeanRRoofPitch(rightLeanData.roofPitch);

   //set the properties used to modify t
    GlobalStore.setLeftWallStyle(leftLeanData.wallStyle);
    GlobalStore.setRightWallStyle(rightLeanData.wallStyle);
    //for the individual walls
//for left lean
    GlobalStore.setLTFrontWall(leftLeanData.walls.frontStyle);
    GlobalStore.setLTBackWall(leftLeanData.walls.backStyle);
    GlobalStore.setLTRightWall(leftLeanData.walls.rightStyle);
    GlobalStore.setLTLeftWall(leftLeanData.walls.leftStyle);
//for right lean
    GlobalStore.setRTFrontWall(rightLeanData.walls.frontStyle);
    GlobalStore.setRTBackWall(rightLeanData.walls.backStyle);
    GlobalStore.setRTRightWall(rightLeanData.walls.rightStyle);
    GlobalStore.setRTLeftWall(rightLeanData.walls.leftStyle);

//for center walls
GlobalStore.setCenterWalls(mainData.wallStyle);
//GlobalStore.setCenterLeftWall(mainData.walls.leftStyle);
//GlobalStore.setCenterRightWall(mainData.walls.rightStyle);
//GlobalStore.setCenterFrontWall(mainData.walls.frontStyle);
//GlobalStore.setCenterBackWall(mainData.walls.backStyle);

//for storage 
GlobalStore.setIsStorageOn(mainData.isStorageOn);
GlobalStore.setIsStorageLTOn(leftLeanData.isStorageOn);
GlobalStore.setIsStorageRTOn(rightLeanData.isStorageOn);
//console.log("Build size storage data",mainData.isStorageOn, leftLeanData.isStorageOn, rightLeanData.isStorageOn)
    // GlobalStore.setId(customizationData.id);
    GetCanvas(data.id);
  
  };

  return (
    <BuildingTypeWrapper>
      <BuildingTypeContent>
        <BuildingTypeContentTitle>
          Choose a Building Type To Configure
        </BuildingTypeContentTitle>
        <BuildingTypeContentText>
          Pick an option closest to your need. You can customize your option by
          clicking the “Design” button.
        </BuildingTypeContentText>
      </BuildingTypeContent>
      <BuildingTypes>
        {buildingTypesData.map((data) => {
          let {
            id,
            productName,
            productImage,
            productImageDescription,
            width,
            height,
            length,
          } = data;
          return (
            // here we use arrow function inside onClick to prevent handleBuildingTypeButton from invoking on render.
            // we need it to invoke uppon click
            <BuildingTypesItem
              key={id}
              onClick={() => {
                handleBuildingTypeButton(data);
              }}
            >
              <BuildingTypesItemImage>
                <img src={productImage} />
              </BuildingTypesItemImage>
              <BuildingTypesItemTitle>{productName}</BuildingTypesItemTitle>
              <BuildingTypesItemInfo>
                <BuildingTypesItemInfoItem>
                  <BuildingTypesItemInfoItemLabel>
                    Width:
                  </BuildingTypesItemInfoItemLabel>
                  <BuildingTypesItemInfoItemValue>
                    {width}
                  </BuildingTypesItemInfoItemValue>
                </BuildingTypesItemInfoItem>
                <BuildingTypesItemInfoItem>
                  <BuildingTypesItemInfoItemLabel>
                    Length:
                  </BuildingTypesItemInfoItemLabel>
                  <BuildingTypesItemInfoItemValue>
                    {length}
                  </BuildingTypesItemInfoItemValue>
                </BuildingTypesItemInfoItem>
                <BuildingTypesItemInfoItem>
                  <BuildingTypesItemInfoItemLabel>
                    Height:
                  </BuildingTypesItemInfoItemLabel>
                  <BuildingTypesItemInfoItemValue>
                    {height}
                  </BuildingTypesItemInfoItemValue>
                </BuildingTypesItemInfoItem>
              </BuildingTypesItemInfo>
              <BuildingTypesItemButton variant="outlined" color="secondary">
                Design {productName} <ArrowRightIcon />
              </BuildingTypesItemButton>
            </BuildingTypesItem>
          );
        })}
      </BuildingTypes>
    </BuildingTypeWrapper>
  );
};

export default BuildingType;
