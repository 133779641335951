import React from 'react'

import {
    DoorsAndWindowsRightWrapper
} from './DoorsAndWindowsRight.css'

const DoorsAndWindowsRight = () => {
    return (
        <DoorsAndWindowsRightWrapper>
            
        </DoorsAndWindowsRightWrapper>
    )
}

export default DoorsAndWindowsRight
