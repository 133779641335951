import React, {useContext, useState} from "react";

import { TextField, Checkbox, FormControlLabel } from "@material-ui/core";

import Scrollbar from "react-scrollbars-custom";

import Separator from "../../../utils/Separator/Separator";
import SectionTitle from "../../SectionTitle/SectionTitle";

import InputSelect from "../parts/InputSelect/InputSelect";
import InputBoxes from "../parts/InputBoxes/InputBoxes";

import {
	ExtraOptionsScrollbarWrapper,
	ExtraOptionsWrapper,
	ExtraOptionsGroup,
	ExtraOptionsGroupBtnWrap,
	ExtraOptionsAddBtn,
	ExtraOptionsRemoveBtn,
} from "./ExtraOptions.css";

import instalationSurfaceData from "../parts/InputBoxes/instalationSurfaceData.json";
import {GlobalState} from "../../../App";

const ExtraOptions = () => {
	const GlobalStore = useContext(GlobalState);
	const [gaugePanelUpgrade, setGaugePanelUpgrade] = useState(false);
	const [interiorAnchor, setInteriorAnchor] = useState(false);
	const [extraBows, setExtraBows] = useState(false);
	const [concreteAnchor, setConcreteAnchor] = useState(false);
	const [mobileHomeAnchor, setMobileHomeAnchor] = useState(false);
	const [asphaltAnchor, setAsphaltAnchor] = useState(false);
	const [tempRebarAnchor, setTempRebarAnchor] = useState(false);
	const [basePlates, setBasePlates] = useState(false);
	const [rebarAnchor, setRebarAnchor] = useState(false);
	const [lagBolt, setLagBolt] = useState(false);
	const [extraTrusses, setExtraTrusses] = useState(false);

	const [singleBubble, setSingleBubble] = useState(false);
	const [doubleBubble, setDoubleBubble] = useState(false);
	const [fiberGlass2In, setFiberGlass2In] = useState(false);

	const [anchorInputs, setAnchorInputs] = useState([
		{
			anchorName: "",
			numberOfAnchors: "",
		},
	]);

	const [newComponents, setNewComponents] = useState([
		{
			componentName: "",
			numberOfComponent: "",
		},
	]);

	const [jobsiteLevel, setJobsiteLevel] = useState(0);
	const [powerAvailable, setPowerAvailable] = useState(0);

	const jobsiteLevelOptions = [
		{ value: "Yes", label: "Yes" },
		{ value: "No", label: "No" },
	];

	const powerAvailableOptions = [
		{ value: "Yes", label: "Yes" },
		{ value: "No", label: "No" },
	];

	const handleGaugePanel = () => {
		setGaugePanelUpgrade(!gaugePanelUpgrade);
		GlobalStore.setGaugePanelUpgrade(!GlobalStore.setGaugePanelUpgrade)
	};

	const handleInteriorAnchor = () => {
		setInteriorAnchor(!interiorAnchor);
		GlobalStore.setInteriorAnchor(!GlobalStore.interiorAnchor);
	};

	const handleExtraBows = () => {
		setExtraBows(!extraBows);
		GlobalStore.setExtraBows(!GlobalStore.extraBows);
	};

	const handleConcreteAnchor = () => {
		setConcreteAnchor(!concreteAnchor);
		GlobalStore.setConcreteAnchor(!GlobalStore.concreteAnchor);
	};

	const handleMobileHomeAnchor = () => {
		setMobileHomeAnchor(!mobileHomeAnchor);
		GlobalStore.setMobileHomeAnchor(!GlobalStore.mobileHomeAnchor);
	};

	const handleAsphaltAnchor = () => {
if (GlobalStore.asphaltAnchor) {
	GlobalStore.setPrice(GlobalStore.price - 300);
}
else {
	GlobalStore.setPrice(GlobalStore.price + 300);
}

		setAsphaltAnchor(!asphaltAnchor);
		GlobalStore.setAsphaltAnchor(!GlobalStore.asphaltAnchor);
	};

	const handleTempRebarAnchor = () => {
		setTempRebarAnchor(!tempRebarAnchor);
		GlobalStore.setTempRebarAnchor(!GlobalStore.tempRebarAnchor);
	};

	const handleBasePlates = () => {
		setBasePlates(!basePlates);
		GlobalStore.setBasePlates(!GlobalStore.basePlates);
	};

	const handleRebarAnchor = () => {
		setRebarAnchor(!rebarAnchor);
		GlobalStore.setRebarAnchor(!GlobalStore.rebarAnchor);
	};

	const handleLagBolt = () => {
		setLagBolt(!lagBolt);
		GlobalStore.setLagBolt(!GlobalStore.lagBolt);
	};

	const handleExtraTrusses = () => {
		setExtraTrusses(!extraTrusses);
		GlobalStore.setExtraTrusses(!GlobalStore.extraTrusses);
	};

	const addExtraOption = () => {
		setAnchorInputs([
			...anchorInputs,
			{
				anchorName: "",
				numberOfAnchors: "",
			},
		]);
	};

	const removeExtraOption = (i) => () => {
		let inputs = [...anchorInputs];
		inputs.splice(i, 1);
		setAnchorInputs(inputs);
	};

	const addNewComponent = () => {
		setNewComponents([
			...newComponents,
			{
				componentName: "",
				numberOfComponent: "",
			},
		]);
	};

	const removeNewComponent = (i) => () => {
		let components = [...newComponents];
		components.splice(i, 1);
		setNewComponents(components);
	};

	const handleSelectJobsite = (e) => () => {
		setJobsiteLevel(e);
	};

	const handlePowerAvailable = (e) => () => {
		setPowerAvailable(e);
	};


	const handleSingleBubble = () => {
		setSingleBubble(!singleBubble);
		GlobalStore.setSingleBubble(!GlobalStore.singleBubble);
	};

	const handleDoubleBubble = () => {
		setDoubleBubble(!doubleBubble);
		GlobalStore.setDoubleBubble(!GlobalStore.doubleBubble);
	};

	const handleFiberGlass2In = () => {
		setFiberGlass2In(!fiberGlass2In);
		GlobalStore.setFiberGlass2In(!GlobalStore.fiberGlass2In);
	};

	return (
		<ExtraOptionsScrollbarWrapper>
			<ExtraOptionsWrapper>
				<SectionTitle title="Extra Options" />
				<FormControlLabel
					control={
						<Checkbox
							checked={gaugePanelUpgrade}
							onChange={handleGaugePanel}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="26 Gauge Panel Upgrade"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={interiorAnchor}
							onChange={handleInteriorAnchor}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Interior Anchor"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={extraBows}
							onChange={handleExtraBows}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Extra Bows"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={concreteAnchor}
							onChange={handleConcreteAnchor}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Concrete Anchor"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={mobileHomeAnchor}
							onChange={handleMobileHomeAnchor}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Mobile Home Anchor"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={asphaltAnchor}
							onChange={handleAsphaltAnchor}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Asphalt Anchor"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={tempRebarAnchor}
							onChange={handleTempRebarAnchor}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Temp Rebar Anchor"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={basePlates}
							onChange={handleBasePlates}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Base Plates"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={rebarAnchor}
							onChange={handleRebarAnchor}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Rebar Anchor"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={lagBolt}
							onChange={handleLagBolt}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Lag Bolt"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={extraTrusses}
							onChange={handleExtraTrusses}
							name="gauge-panel-upgrade"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Extra Trusses"
				/>
				
				<SectionTitle title="Insulation Options" />
				<FormControlLabel
					control={
						<Checkbox
							checked={singleBubble}
							onChange={handleSingleBubble}
							name="insulation-single-bubble"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Single Bubble"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={doubleBubble}
							onChange={handleDoubleBubble}
							name="insulation-double-bubble"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Double Bubble"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={fiberGlass2In}
							onChange={handleFiberGlass2In}
							name="insulation-double-bubble"
							color="primary"
						/>
					}
					style={{ color: "#222", display: "block" }}
					label="Fiberglass 2 inch"
				/>
				{anchorInputs.map((el, i) => {
					return (
						<ExtraOptionsGroup key={`anchor-input-${i}`}>
							<TextField
								id={`anchor-name-${i}`}
								label="Anchor Name"
								defaultValue=""
								fullWidth
							/>
							<TextField
								id={`no-of-anchors-${i}`}
								label="No. Of Anchors (0-5000)"
								defaultValue=""
								fullWidth
							/>
							<ExtraOptionsGroupBtnWrap>
								{i === anchorInputs.length - 1 && (
									<ExtraOptionsAddBtn
										onClick={addExtraOption}
									>
										+ Add
									</ExtraOptionsAddBtn>
								)}
								{anchorInputs.length > 1 && (
									<ExtraOptionsRemoveBtn
										onClick={removeExtraOption(i)}
									>
										- Remove
									</ExtraOptionsRemoveBtn>
								)}
							</ExtraOptionsGroupBtnWrap>
						</ExtraOptionsGroup>
					);
				})}
				<Separator />
				<SectionTitle title="Add New Component" />
				{newComponents.map((e, i) => {
					return (
						<ExtraOptionsGroup key={`new-component-${i}`}>
							<TextField
								id={`component-name-${i}`}
								label="Component Name"
								defaultValue=""
								fullWidth
							/>
							<TextField
								id={`no-of-component-${i}`}
								label="No. Of Components (0-5000)"
								defaultValue=""
								fullWidth
							/>
							<ExtraOptionsGroupBtnWrap>
								{i === newComponents.length - 1 && (
									<ExtraOptionsAddBtn
										onClick={addNewComponent}
									>
										+ Add
									</ExtraOptionsAddBtn>
								)}
								{newComponents.length > 1 && (
									<ExtraOptionsRemoveBtn
										onClick={removeNewComponent(i)}
									>
										- Remove
									</ExtraOptionsRemoveBtn>
								)}
							</ExtraOptionsGroupBtnWrap>
						</ExtraOptionsGroup>
					);
				})}
				<SectionTitle title="Site Information" />
				<InputSelect
					onChange={handleSelectJobsite}
					label="Jobsite Level"
					options={jobsiteLevelOptions}
					inputName="jobsite-level"
					selectedOptionIndex={0}
				/>
				<Separator marginBottom="15px" />
				<InputSelect
					onChange={handlePowerAvailable}
					label="Power Available"
					options={powerAvailableOptions}
					inputName="jobsite-level"
					selectedOptionIndex={0}
				/>
				<Separator marginBottom="15px" />
				<TextField
					label="Extra Notes"
					variant="outlined"
					inputProps={{ maxLength: 1000 }}
					multiline
					rows={4}
					fullWidth={true}
				/>
				<Separator marginBottom="15px" />
				<SectionTitle title="Installation Surface" />
				<InputBoxes options={instalationSurfaceData} />
			</ExtraOptionsWrapper>
		</ExtraOptionsScrollbarWrapper>
	);
};

export default ExtraOptions;
