import { React, useContext, useEffect } from "react";
import { ColorsScrollbarWrapper, ColorsWrapper } from "./Colors.css";

import ColorPicker from "../parts/ColorPicker/ColorPicker";
import Separator from "../../../utils/Separator/Separator";
import EntryContent from "../../EntryContent/EntryContent";
import SectionTitle from "../../SectionTitle/SectionTitle";

import Scrollbar from "react-scrollbars-custom";
import { GlobalState } from "../../../App";
import { Color3 } from "@babylonjs/core";

import {
  colorsOptionsPremium,
  colorsOptionsWhite,
} from "../parts/ColorPicker/ColorPickerData.json";

import buildingTypesData from "../../BuildingType/BuildingTypes.json";

const Colors = () => {
  //console.log("Colors ref")
  const GlobalStore = useContext(GlobalState);

  const scene = GlobalStore.sceneGlobalRef.current;

  for(let i = 0; i < scene.meshes.length; i++){
    if(scene.meshes[i].name.includes("boundingBox")){
      scene.meshes[i].isVisible = false;
    }
  }

  const indexOfPresetData = buildingTypesData.findIndex(
    (x) => x.id === GlobalStore.buildingType.id
  );
  const presetData =
    buildingTypesData[indexOfPresetData].customizationData.materials;

  const handleRoofColor = (color, index) => {
    //alert(color.name);
    GlobalStore.setRoofColor(color.name);

    const roofMaterials = GlobalStore.outerRoofMaterials.current;
    roofMaterials.map((mat) => {
      //mat.albedoColor = new Color3.FromHexString(color.value).toLinearSpace();
      mat.diffuseColor = new Color3.FromHexString(color.value).toLinearSpace();
      mat.metallic = 0;
      mat.roughness = 1;
      return 0;
    });

    scene.meshes.map((m) => {
      if (m.name.includes("Garage__roof")) {
        //main roof

        m.material.diffuseColor = new Color3.FromHexString(
          color.value
        ).toLinearSpace();
        m.material.metallic = 0;
        m.material.roughness = 1;
        // m.material.bumpTexture = NormalMapWallTexture;
      }
    });
  };

  const handleTrimColor = (color, index) => {
    GlobalStore.setTrimColor(color.name);
    scene.meshes.map((m) => {
      if (
        m.name.includes("profile") ||
        m.name.includes("Ridge") ||
        m.name.includes("Profile")
      ) {
        //main roof

        m.material.diffuseColor = new Color3.FromHexString(
          color.value
        ).toLinearSpace();
        m.material.metallic = 0;
        m.material.roughness = 1;
        // m.material.bumpTexture = NormalMapWallTexture;
      }
    });
  };

  const handleWallColor = (color) => {
    GlobalStore.setWallsColor(color.name);
    scene.meshes.map((m) => {
      if (m.name.includes("walls") && !m.name.includes("Wain")) {
        //main roof
        m.material.diffuseColor = new Color3.FromHexString(
          color.value
        ).toLinearSpace();
        m.material.metallic = 0;
        m.material.roughness = 1;
        // m.material.bumpTexture = NormalMapWallTexture;
      }
      /*if (m.name.includes("_Wainscot")) {
        m.material.diffuseColor = new Color3.FromHexString(
          color.value
        ).toLinearSpace();
        //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
        // material.reflectionTexture = envTexture;
        m.material.metallic = 0.7;
        m.material.roughness = 0.7;
        // GlobalStore.outerWallsMaterials.current.push(material);
      }*/
    });
  };

  const handleWainscotColor = (color) => {
    GlobalStore.setWainscotColor(color.name);
    scene.meshes.map((m) => {
      if (m.name.includes("_Wain")) {
        m.material.diffuseColor = new Color3.FromHexString(
          color.value
        ).toLinearSpace();
        //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
        // material.reflectionTexture = envTexture;
        m.material.metallic = 0.7;
        m.material.roughness = 0.7;
        // GlobalStore.outerWallsMaterials.current.push(material);
      }
    });
  };

  return (
    <ColorsScrollbarWrapper>
      <ColorsWrapper>
        <ColorPicker
          onChange={handleRoofColor}
          title="Roof"
          colorsOptions={colorsOptionsPremium}
          checked={0}
        />
        <Separator marginBotom="25px" />
        <ColorPicker
          onChange={handleTrimColor}
          title="Trim"
          colorsOptions={colorsOptionsPremium}
          checked={0}
        />
        <Separator marginBotom="25px" />
        <ColorPicker
          onChange={handleWallColor}
          title="Wall"
          colorsOptions={colorsOptionsPremium}
          checked={0}
        />
        <Separator marginBotom="25px" />
        <ColorPicker
          onChange={handleWainscotColor}
          title="Wainscot"
          colorsOptions={colorsOptionsPremium}
          checked={0}
        />
        <Separator marginBotom="25px" />
        <ColorPicker
          title="Door"
          colorsOptions={colorsOptionsWhite}
          checked={0}
        />
        <Separator marginBotom="25px" />
        <SectionTitle title="Disclaimer" />
        <EntryContent content="<p>Due to the variations in monitors and browsers, the color samples displayed on this tool may appear different on different monitors and devices.</p><p>Computer monitors are not all calibrated equally and color reproduction on the Internet is not precise. Since it is not possible to guarantee our online colors will look the same on all computers, we do not guarantee that what you see accurately portrays the color of the actual color of the Sheet Metal.</p><p>We do our very best to make sure our samples are as close to the exact product as possible, but cannot guarantee that what you see is an exact sample. If it is important that the sample be exact, it is highly recommended that once you contact us.</p>" />
      </ColorsWrapper>
    </ColorsScrollbarWrapper>
  );
};

export default Colors;
