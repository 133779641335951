import React, { useState, useContext, useRef } from 'react';
import { InputLabel, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';

const InputSelect = ({ options, label, inputName, selectedOptionIndex, onChange }) => {


    const [selectValue, setSelectValue] = useState(selectedOptionIndex ? options[parseInt(selectedOptionIndex)].value : options[0].value);

    const handleChange = (e) => {
        setSelectValue(e.target.value)
        onChange(e.target.value, inputName);
    }

    return (
        <>
            {label && <InputLabel style={{ fontSize: '11px' }} id={`${label}-select-label`}>{label}</InputLabel>}
            <Select
                value={selectValue}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth
            >
                {options.map(({ label, value }, i) => {
                   

                    return (
                        <MenuItem value={value} key={i}>{label}</MenuItem>
                    )
                })}
            </Select>
        </>
    )
}

export default InputSelect
