import styled from "styled-components/macro";

export const SpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* display: none; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  text-align: center;
  z-index: 1999;
`;

export const SpinnerIconWrapper = styled.div``;

export const SpinnerIcon = styled.div`
  svg {
    width: 50px;
    height: 50px;
  }
`;

export const SpinnerContentWrapper = styled.div`
  margin-top: 15px;
  font-size: 22px;
`;
