// importing fundamental modules
import React, { useState, useRef } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
// importing compoents
import { State as StateForm } from "./components/Forms/State/State";
import Header from "./components/Header/Header";
import BuildingType from "./components/BuildingType/BuildingType";
import Build from "./components/Build/Build";
import Canvas from "./components/Canvas/Canvas";
import { OnClickBuildingId } from "./components/Canvas/Canvas";
import DraggableBox from "./components/DraggableBox/DraggableBox";
import { IFrame } from "./components/Canvas/Iframe";
import Spinner from "./components/Spinner/Spinner";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

// some css values
const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

const breakpoints = createBreakpoints({ ...customBreakpointValues });

export const GlobalState = React.createContext();
export const GetCanvas = (id) => {
  OnClickBuildingId(id);
};

const theme = createTheme({
  breakpoints: {
    ...customBreakpointValues,
  },
  palette: {
    primary: {
      main: "#eb7324", // This is an orange looking color
      dark: "#222",
    },
    secondary: {
      main: "#eb7324", //Another orange-ish color
    },
    text: {
      primary: "#222",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiInputLabel: {
      root: {},
      formControl: {
        // Some CSS
        left: "8px",
        fontWeight: "bold",
      },
      shrink: {
        top: "6px",
      },
    },
    MuiInputBase: {
      formControl: {
        padding: "0 8px",
      },
    },
    MuiSelect: {
      root: {
        width: "100%",
      },
      select: {
        fontWeight: "500",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: "white",
        [breakpoints.down("lg")]: {
          color: "#eb7324",
        },
      },
    },
    MuiButton: {
      root: {
        padding: "15px 30px",
        backgroundColor: "#fff",
        border: "2px #fff solid",
        borderRadius: "40px",
        textAlign: "center",
        textTransform: "uppercase",
        fontWeight: "600",
        fontSize: "16px",
        letterSpacing: "normal",
        transition: "all 0.3s ease-in-out",
        cursor: "pointer",
        lineHeight: 1,
        "&:hover": {
          opacity: "0.9",
          boxShadow: "1px 3px 20px rgba(0, 0, 0, 0.3)",
        },
      },
      contained: {},
      outlinedSecondary: {
        backgroundColor: "#222",
        color: "#fff",
        border: "2px solid #222",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  },
});

function App() {
  const [state, setState] = useState(null); //from state form
  const [isLoading, setIsLoading] = useState(true);
  const [upgradeGauge, setUpgradeGauge] = useState(false);
  const [windSnowChange, setWindSnowChange] = useState(false);
  const [buildingType, setBuildingType] = useState(null); //from building type component form
  //state of canvas visibility
  const [canvasStyle, setCanvasStyle] = useState("hidden");
  //states of the camera
  const [cameraRadius, setCameraRadius] = useState(200);
  const cameraStateRadius = useRef(200);
  const [cameraRotating, setCameraRotating] = useState(false);
  const [fullView, setFullView] = useState(false);
  const [backgroundHidden, setBackgroundHidden] = useState(false);
  //scene reference (3D context of the whole scene (objects/materials....))
  const sceneGlobalRef = useRef(null);
  //Starting references for structures (used for calculation of structure position depending on the user dimensions input (WxHxL))
  const main_R_startPosZ = useRef(null);

  const ArcCamera = useRef(null);

  const main_L_startPosZ = useRef(null);
  const main_R_startPosY = useRef(null);
  const main_L_startPosY = useRef(null);
  const lean_R_startPosZ = useRef(null);
  const lean_L_startPosZ = useRef(null);
  const lean_R_startPosY = useRef(null);
  const lean_L_startPosY = useRef(null);
  //offsets (used for calculation of helper elements position depending on the width of the structures)
  const mainWidthOffset = useRef(0);
  const leftWidthOffset = useRef(0);
  const rightWidthOffset = useRef(0);
  //clonedFrames (used for generating frames depending on the length of the structures)
  const clonedFramesMain = useRef([]);
  const clonedFramesLeanR = useRef([]);
  const clonedFramesLeanL = useRef([]);
  //clip distances of the leanTo's (used for calculation of cliping planes for lean tos when width of the main structure is changed)
  const leanClipPlaneDistance = useRef(81);
  //wall meshes references
  const mainWallMeshes = useRef([]);
  const leftLeanWallMeshes = useRef([]);
  const rightLeanWallMeshes = useRef([]);
  const storageMainMeshes = useRef([]);
  const storageLeftMeshes = useRef([]);
  const storageRightMeshes = useRef([]);
  const GarageDoorMesh = useRef([]);
  const WindowMesh = useRef([]);
  const WindowMeshPosFrontWall = useRef([]);
  const DoorMeshPosFrontWall = useRef([]);
  const DoorMeshPosRightWall = useRef([]);
  const DoorMeshPosLeftWall = useRef([]);
  const DoorMeshPosBackWall = useRef([]);

  const DoorMeshPosLeftWallLT = useRef([]);
  const DoorMeshPosRightWallRT = useRef([]);

  const IsWallOpen = useRef([]); //have to check where this is used. Or change it entirely
  const IsWallLTOpen = useRef([]);
  const IsWallRTOpen = useRef([]);

  const doorPosFrontWall = useRef([]);
  const WalkDoorMesh = useRef([]);
  const DoorMesh = useRef([]);
  const FramedMesh = useRef([]);
  const FramedDoorMesh = useRef([]);
  const FramedWindowMesh = useRef([]);

  const wainscotMeshM = useRef([]);
  const wainscotMeshL = useRef([]);
  const wainscotMeshR = useRef([]);

  const MeshPos = useRef([]);
  const MeshScale = useRef([]);
  const MeshStoragePos = useRef([]);
  const MeshStorageScale = useRef([]);

  const MeshStorageLTPos = useRef([]);
  const MeshStorageLTScale = useRef([]);

  const MeshMiddleMainPitch = useRef([]);
  const MeshMiddleLTPitch = useRef([]);
  const MeshMiddleRTPitch = useRef([]);
  const MeshLTScale = useRef([]);
  const MeshLTPos = useRef([]);

  const WalkDoorMeshPosFrontWall = useRef([]);
  //state of walls selections (front back side walls, open/enclosed?)
  //main
  const [selectedEndFront, setSelectedEndFront] = useState("Open");
  const [selectedEndBack, setSelectedEndBack] = useState("Open");
  const [selectedSidesLeft, setSelectedSidesLeft] = useState("Open");
  const [selectedSidesRight, setSelectedSidesRight] = useState("Open");
  //right lean
  const [selectedEndFront_RL, setSelectedEndFront_RL] = useState("Open");
  const [selectedEndBack_RL, setSelectedEndBack_RL] = useState("Open");
  const [selectedSidesRight_RL, setSelectedSidesRight_RL] = useState("Open");
  //left lean
  const [selectedEndFront_LL, setSelectedEndFront_LL] = useState("Open");
  const [selectedEndBack_LL, setSelectedEndBack_LL] = useState("Open");
  const [selectedSidesLeft_LL, setSelectedSidesLeft_LL] = useState("Open");
  //state of price and deposit
  const [price, setPrice] = useState(2800);
  const [deposit, setDeposit] = useState(850);
  //pricing for dimension configuration
  //main structure
  const currentWidthPrice_main = useRef(600);
  const currentHeightPrice_main = useRef(500);
  const currentLengthPrice_main = useRef(400);
  //lean tos
  const currentWidthPrice_rightLean = useRef(200);
  const currentHeightPrice_rightLean = useRef(300);
  const currentLengthPrice_rightLean = useRef(400);
  const currentWidthPrice_leftLean = useRef(200);
  const currentHeightPrice_leftLean = useRef(300);
  const currentLengthPrice_leftLean = useRef(400);
  //pricing for walls
  const currentFrontWallsPrice = useRef(10);
  const currentBackWallsPrice = useRef(10);
  const currentLeftSideWallsPrice = useRef(10);
  const currentRightSideWallsPrice = useRef(10);
  //constrain of lean tos dropdown dimension based on main structure dimensions
  const [leanWallHeightConstraint, setLeanWallHeightConstraint] = useState(20);
  //reference planes used for calculation of auto selecting part of the warehouse
  const cameraDistanceReferencePlanes = useRef([]);
  //check if leans are active or not
  const isLeftLeanActive = useRef([]);
  const isRightLeanActive = useRef([]);

  const MeshMain = useRef([]);
  const MeshLT = useRef([]);
  const MeshRT = useRef([]);

  const StorageMeshMain = useRef([]);
  const StorageMeshLT = useRef([]);
  const StorageMeshRT = useRef([]);

  const isBuiltSizeInit = useState(false);
  const isWallCenterInit = useState(false);
  const isWallRTInit = useState(false);
  const isWallLTInit = useState(false);

  //const isLeftLeanActive = useRef(false);
  // const isRightLeanActive = useRef(false);
  //SCALINGS OF DIMENSIONHELPER (used for positioning support elements (reference planes, clipplanes...))
  const dimensionHelper_M_scalingZ = useRef(null);
  const dimensionHelper_R_scalingZ = useRef(null);
  const dimensionHelper_L_scalingZ = useRef(null);
  //MATERIAL REFS
  const outerWallsMaterials = useRef([]);
  const outerRoofMaterials = useRef([]);
  const outerTrimMaterials = useRef([]);

  //draggable is open?
  const [draggableIsOpen, setDraggableIsOpen] = useState(false);
  //modal is open?
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //45 angle enabled?
  const [haveAngle, setHaveAngle] = useState(false);
  //list of the doors in the scene
  const currentDoors = useRef([]);
  //door options depending on the type of the door
  const [doorOptions, setDoorOptions] = useState(null);
  //wainscot
  const [wainscot, setWainscot] = useState(false);
  const wainscotMeshes = useRef([]);

  //morph data
  const mainMorphLevel = useRef(0);
  //STORAGE
  const normalMapWallTex = useRef(null);
  const normalMapWallTex90 = useRef(null);

  //storage translations and clip plane position
  const mainLengthFromCenter = useRef(0);
  const mainStorageTranslation = useRef(5);
  const leanRLengthFromCenter = useRef(0);
  const leanRStorageTranslation = useRef(5);
  const leanLLengthFromCenter = useRef(0);
  const leanLStorageTranslation = useRef(5);
  //measurement gui
  const currentDimensionElements = useRef([]);
  //is measure on?
  const [isMeasure, setIsMeasure] = useState(false);

  const [mainWidth, setMainWidth] = useState("60");
  const [mainLength, setMainLength] = useState("21");
  const [mainHeight, setMainHeight] = useState("9");
  const [mainRoofPitch, setMainRoofPitch] = useState("2/12");

  const [leanRWidth, setLeanRWidth] = useState("16");
  const [leanRHeight, setLeanRHeight] = useState(6);
  const [leanRLength, setLeanRLength] = useState("21");
  const [leanRRoofPitch, setLeanRRoofPitch] = useState("2/12");

  const [leanLWidth, setLeanLWidth] = useState("16");
  const [leanLHeight, setLeanLHeight] = useState(6);
  const [leanLLength, setLeanLLength] = useState("21");
  const [leanLRoofPitch, setLeanLRoofPitch] = useState("2/12");
  //Door & Windows Options
  const [dwSummary, setDwSummary] = useState([]);

  // Extra options
  const [gaugePanelUpgrade, setGaugePanelUpgrade] = useState(false);
  const [interiorAnchor, setInteriorAnchor] = useState(false);
  const [extraBows, setExtraBows] = useState(false);
  const [concreteAnchor, setConcreteAnchor] = useState(false);
  const [mobileHomeAnchor, setMobileHomeAnchor] = useState(false);
  const [asphaltAnchor, setAsphaltAnchor] = useState(false);
  const [tempRebarAnchor, setTempRebarAnchor] = useState(false);
  const [basePlates, setBasePlates] = useState(false);
  const [rebarAnchor, setRebarAnchor] = useState(false);
  const [lagBolt, setLagBolt] = useState(false);
  const [extraTrusses, setExtraTrusses] = useState(false);

  const [singleBubble, setSingleBubble] = useState(false);
  const [doubleBubble, setDoubleBubble] = useState(false);
  const [fiberGlass2In, setFiberGlass2In] = useState(false);

  // colors
  const [roofColor, setRoofColor] = useState("Barn Red");
  const [trimColor, setTrimColor] = useState("White");
  //there are 4 color pickers which set the wall color, this controls state of all of them
  const [wallsColor, setWallsColor] = useState("Barn Red");
  const [wainscotColor, setWainscotColor] = useState("Barn Red");

  const [isSideCrossBracing, setIsSideCrossBracing] = useState(false);
  const [isEndCrossBracing, setIsEndCrossBracing] = useState(false);

  //we are using this to store individual walls data
  const [leftWallStyle, setLeftWallStyle] = useState("open");
  const [rightWallStyle, setRightWallStyle] = useState("open");
  //for left walls
  const [LTLeftWall, setLTLeftWall] = useState("open");
  const [LTFrontWall, setLTFrontWall] = useState("open");
  const [LTRightWall, setLTRightWall] = useState("open");
  const [LTBackWall, setLTBackWall] = useState("open");

  //for right walls
  const [RTLeftWall, setRTLeftWall] = useState("open");
  const [RTFrontWall, setRTFrontWall] = useState("open");
  const [RTRightWall, setRTRightWall] = useState("open");
  const [RTBackWall, setRTBackWall] = useState("open");

  //for center walls. This won't be same as the right and left lean
  const [centerWalls, setCenterWalls] = useState("enclosed");
  const [centerLeftWall, setCenterLeftWall] = useState("open");
  const [centerRightWall, setCenterRightWall] = useState("open");
  const [centerFrontWall, setCenterFrontWall] = useState("open");
  const [centerBackWall, setCenterBackWall] = useState("open");

  //optimizing the door repositioning using manually added boundingBoxes
  const mainCartportBoundingBox = useRef(null);
  const mainCartportStorageBoundingBox = useRef(null);
  const mainBarnBoundingBox = useRef(null);
  const mainBarnStorageBoundingBox = useRef(null);
  const LTBoundingBox = useRef(null);
  const LTStorageBoundingBox = useRef(null);
  const RTBoundingBox = useRef(null);
  const RTStorageBoundingBox = useRef(null);

  //for storage
  const [isStorageOn, setIsStorageOn] = useState(false);
  const [isStorageLTOn, setIsStorageLTOn] = useState(false);
  const [isStorageRTOn, setIsStorageRTOn] = useState(false);

  //store doors that are added to the various walls in the mesh
  const [frontCenterWallObjects, setFrontCenterWallObjects] = useState([]);
  const [frontCenterStorageWallObjects, setFrontCenterStorageWallObjects] =
    useState([]);
  const [LTFrontWallObjects, setLTFrontWallObjects] = useState([]);
  const [LTFrontStorageWallObjects, setLTFrontStorageWallObjects] = useState(
    []
  );
  const [RTFrontWallObjects, setRTFrontWallObjects] = useState([]);
  const [RTFrontStorageWallObjects, setRTFrontStorageWallObjects] = useState(
    []
  );
  //const [WallObjects, setWallObjects] = useState([]);

  //back
  const [backCenterWallObjects, setBackCenterWallObjects] = useState([]);
  const [LTBackWallObjects, setLTBackWallObjects] = useState([]);
  const [RTBackWallObjects, setRTBackWallObjects] = useState([]);

  //leanLT
  const [leftWallObjects, setLeftWallObjects] = useState([]);
  const [leftStorageWallObjects, setLeftStorageWallObjects] = useState([]);
  const [LTLeftWallObjects, setLTLeftWallObjects] = useState([]);
  const [LTLeftStorageWallObjects, setLTLeftStorageWallObjects] = useState([]);

  //similar for the right lean
  const [rightWallObjects, setRightWallObjects] = useState([]);
  const [rightStorageWallObjects, setRightStorageWallObjects] = useState([]);
  const [RTRightWallObjects, setRTRightWallObjects] = useState([]);
  const [RTRightStorageWallObjects, setRTRightStorageWallObjects] = useState(
    []
  );

/*
  const pointerDragBehaviorLeftPlaneList = useRef([]);
  const pointerDragBehaviorLeftLeanPlaneList=useRef([]);
  const pointerDragBehaviorRightPlaneList=useRef([]);
  const pointerDragBehaviorRightLeanPlaneList=useRef([]);
  const pointerDragBehaviorFrontPlaneList=useRef([]);
  const pointerDragBehaviorBackPlaneList=useRef([]);
*/

const pointerDragBehaviorFrontPlaneList = useRef([])

  //we use this to close it now

  return (
    <ThemeProvider theme={theme}>
      <GlobalState.Provider
        value={{
          // presetDataMain,

          state,
          ArcCamera,
          setState,
          isBuiltSizeInit,
          isWallCenterInit,
          isWallLTInit,
          isWallRTInit,
          buildingType,
          setBuildingType,
          canvasStyle,
          setCanvasStyle,
          cameraRadius,
          setCameraRadius,
          cameraStateRadius,
          cameraRotating,
          setCameraRotating,
          fullView,
          setFullView,
          backgroundHidden,
          setBackgroundHidden,
          sceneGlobalRef,
          main_R_startPosZ,
          main_L_startPosZ,
          main_R_startPosY,
          main_L_startPosY,
          lean_R_startPosZ,
          lean_L_startPosZ,
          lean_R_startPosY,
          lean_L_startPosY,
          clonedFramesMain,
          clonedFramesLeanR,
          clonedFramesLeanL,
          leanClipPlaneDistance,
          mainWallMeshes,
          rightLeanWallMeshes,
          leftLeanWallMeshes,
          storageMainMeshes, //--
          storageLeftMeshes,
          storageRightMeshes,
          FramedMesh,
          FramedWindowMesh,
          FramedDoorMesh,
          WindowMeshPosFrontWall,
          WalkDoorMeshPosFrontWall,
          GarageDoorMesh,
          WindowMesh,
          WalkDoorMesh,
          wainscotMeshL,
          wainscotMeshR,
          wainscotMeshM,
          IsWallOpen,
          IsWallLTOpen,
          IsWallRTOpen,
          DoorMesh,
          DoorMeshPosFrontWall,
          DoorMeshPosRightWall,
          DoorMeshPosLeftWall,
          DoorMeshPosBackWall,
          DoorMeshPosLeftWallLT, //added for garage door to be added on left lean-To wall ,check cardsadd.js - Line-415 ...
          DoorMeshPosRightWallRT, //added for garage door to be added on right lean-To wall
          MeshPos,
          MeshScale,
          MeshLTPos,
          MeshLTScale,
          MeshStorageScale,
          MeshStoragePos,

          MeshMain,
          MeshLT,
          MeshRT,

          normalMapWallTex,
          normalMapWallTex90,

          StorageMeshMain,
          StorageMeshLT,
          StorageMeshRT,

          MeshStorageLTScale,
          MeshStorageLTPos,

          MeshMiddleMainPitch,
          MeshMiddleLTPitch,
          MeshMiddleRTPitch,

          doorPosFrontWall,
          selectedEndFront,
          setSelectedEndFront,
          selectedEndBack,
          setSelectedEndBack,
          selectedSidesLeft,
          setSelectedSidesLeft,
          selectedSidesRight,
          setSelectedSidesRight,
          selectedEndFront_RL,
          setSelectedEndFront_RL,
          selectedEndBack_RL,
          setSelectedEndBack_RL,
          selectedSidesRight_RL,
          setSelectedSidesRight_RL,
          selectedEndFront_LL,
          setSelectedEndFront_LL,
          selectedEndBack_LL,
          setSelectedEndBack_LL,
          selectedSidesLeft_LL,
          setSelectedSidesLeft_LL,
          price,
          setPrice,
          deposit,
          setDeposit,
          currentWidthPrice_main,
          currentHeightPrice_main,
          currentLengthPrice_main,
          currentWidthPrice_rightLean,
          currentHeightPrice_rightLean,
          currentLengthPrice_rightLean,
          currentWidthPrice_leftLean,
          currentHeightPrice_leftLean,
          currentLengthPrice_leftLean,
          currentFrontWallsPrice,
          currentBackWallsPrice,
          currentLeftSideWallsPrice,
          currentRightSideWallsPrice,
          leanWallHeightConstraint,
          setLeanWallHeightConstraint,
          mainWidthOffset,
          leftWidthOffset,
          rightWidthOffset,
          cameraDistanceReferencePlanes,
          isLeftLeanActive,
          isRightLeanActive,
          dimensionHelper_M_scalingZ,
          dimensionHelper_R_scalingZ,
          dimensionHelper_L_scalingZ,
          outerWallsMaterials,
          outerRoofMaterials,
          outerTrimMaterials,
          wallsColor,
          setWallsColor,
          draggableIsOpen,
          setDraggableIsOpen,
          modalIsOpen,
          setModalIsOpen,
          haveAngle,
          setHaveAngle,
          currentDoors,
          dwSummary,
          setDwSummary,
          doorOptions,
          setDoorOptions,

          wainscot,
          setWainscot,
          wainscotMeshes,
          wainscotColor,
          setWainscotColor,
          mainMorphLevel,
          mainLengthFromCenter,
          mainStorageTranslation,
          leanRLengthFromCenter,
          leanRStorageTranslation,
          leanLLengthFromCenter,
          leanLStorageTranslation,
          currentDimensionElements,
          isMeasure,
          setIsMeasure,
          mainWidth,
          setMainWidth,
          mainLength,
          setMainLength,
          mainHeight,
          setMainHeight,
          mainRoofPitch,
          setMainRoofPitch,
          leanRWidth,
          setLeanRWidth,
          leanRHeight,
          setLeanRHeight,
          leanRLength,
          setLeanRLength,
          leanRRoofPitch,
          setLeanRRoofPitch,
          leanLWidth,
          setLeanLWidth,
          leanLHeight,
          setLeanLHeight,
          leanLLength,
          setLeanLLength,
          leanLRoofPitch,
          setLeanLRoofPitch,
          isLoading,
          setIsLoading,
          upgradeGauge,
          setUpgradeGauge,
          windSnowChange,
          setWindSnowChange,
          gaugePanelUpgrade,
          setGaugePanelUpgrade,
          interiorAnchor,
          setInteriorAnchor,
          extraBows,
          setExtraBows,
          concreteAnchor,
          setConcreteAnchor,
          mobileHomeAnchor,
          setMobileHomeAnchor,
          asphaltAnchor,
          setAsphaltAnchor,
          tempRebarAnchor,
          setTempRebarAnchor,
          basePlates,
          setBasePlates,
          rebarAnchor,
          setRebarAnchor,
          lagBolt,
          setLagBolt,
          extraTrusses,
          setExtraTrusses,
          singleBubble,
          setSingleBubble,
          doubleBubble,
          setDoubleBubble,
          fiberGlass2In,
          setFiberGlass2In,
          roofColor,
          setRoofColor,
          trimColor,
          setTrimColor,
          isSideCrossBracing,
          setIsSideCrossBracing,
          isEndCrossBracing,
          setIsEndCrossBracing,
          //we use our data here
          leftWallStyle,
          setLeftWallStyle,
          rightWallStyle,
          setRightWallStyle,
          LTFrontWall,
          setLTFrontWall,
          LTBackWall,
          setLTBackWall,
          LTLeftWall,
          setLTLeftWall,
          LTRightWall,
          setLTRightWall,
          RTLeftWall,
          setRTLeftWall,
          RTRightWall,
          setRTRightWall,
          RTFrontWall,
          setRTFrontWall,
          RTBackWall,
          setRTBackWall,
          //center walls fix
          centerWalls,
          setCenterWalls,
          centerLeftWall,
          setCenterLeftWall,
          centerRightWall,
          setCenterRightWall,
          centerFrontWall,
          setCenterFrontWall,
          centerBackWall,
          setCenterBackWall,
          isStorageOn,
          setIsStorageOn,
          isStorageLTOn,
          setIsStorageLTOn,
          isStorageRTOn,
          setIsStorageRTOn,
          //exporting door and windows arrays
          frontCenterWallObjects,
          setFrontCenterWallObjects,
          frontCenterStorageWallObjects,
          setFrontCenterStorageWallObjects,
          LTFrontWallObjects,
          setLTFrontWallObjects,
          LTFrontStorageWallObjects,
          setLTFrontStorageWallObjects,
          RTFrontWallObjects,
          setRTFrontWallObjects,
          RTFrontStorageWallObjects,
          setRTFrontStorageWallObjects,
          backCenterWallObjects,
          setBackCenterWallObjects,
          LTBackWallObjects,
          setLTBackWallObjects,
          RTBackWallObjects,
          setRTBackWallObjects,
          leftWallObjects,
          setLeftWallObjects,
          leftStorageWallObjects,
          setLeftStorageWallObjects,
          LTLeftWallObjects,
          setLTLeftWallObjects,
          LTLeftStorageWallObjects,
          setLTLeftStorageWallObjects,
          rightWallObjects,
          setRightWallObjects,
          rightStorageWallObjects,
          setRightStorageWallObjects,
          RTRightWallObjects,
          setRTRightWallObjects,
          RTRightStorageWallObjects,
          setRTRightStorageWallObjects,
          //Wall Object functioanlity would be shifted to individual walls(see above)
          //WallObjects,
         //setWallObjects,
          //this handles the door repositions from now. 
          mainCartportBoundingBox,
          mainCartportStorageBoundingBox,
          mainBarnBoundingBox,
          mainBarnStorageBoundingBox,
          LTBoundingBox,
          RTBoundingBox,
          RTStorageBoundingBox,
          LTStorageBoundingBox,


          //drag bheavior references
        /*  pointerDragBehaviorBackPlaneList,
          pointerDragBehaviorFrontPlaneList,
          pointerDragBehaviorLeftPlaneList,
          pointerDragBehaviorRightPlaneList,
          pointerDragBehaviorLeftLeanPlaneList,
          pointerDragBehaviorRightLeanPlaneList*/

          pointerDragBehaviorFrontPlaneList
        }}
      >
        <div className="App">
          <DraggableBox
            draggableIsOpen={draggableIsOpen}
            modalIsOpen={modalIsOpen}
            haveAngle={haveAngle}
          />
          {state && <Canvas canvasStyle={canvasStyle} />}{" "}
          {/* OnClickBuildingId=GetCanvas */}
          {<Header active={buildingType} />}
          {!state && <StateForm />}
          {state && !buildingType && <BuildingType />}
          {state && buildingType && <Build />}
          {state && isLoading && <Spinner content={"Loading..."} />}
        </div>
      </GlobalState.Provider>
    </ThemeProvider>
  );
}

export default App;
