import React, { useContext, useState, useRef } from 'react'
import {
    BuildControllerMenuWrapper,
    BuildControllerMenuItem,
    BuildControllerMenuItemIcon,
    BuildControllerMenuItemText
} from './BuildControllerMenu.css'

import {CubeTexture} from "@babylonjs/core/Materials/Textures/cubeTexture"
import { ReactComponent as IconZoomIn } from '../../images/icons/zoom-in.svg'
import { ReactComponent as IconZoomOut } from '../../images/icons/zoom-out.svg'
import { ReactComponent as IconInside } from '../../images/icons/inside.svg'
import { ReactComponent as IconRotate } from '../../images/icons/rotate.svg'
import { ReactComponent as IconEye } from '../../images/icons/eye.svg'
import { ReactComponent as IconRuler } from '../../images/icons/ruler.svg'
import { ReactComponent as IconFullView } from '../../images/icons/full-view.svg'
import { ReactComponent as IconPanelView } from '../../images/icons/panel-view.svg'
import { GlobalState } from '../../App';


const BuildControllerMenu = () => {
    const GlobalStore = useContext(GlobalState);
    const scene = GlobalStore.sceneGlobalRef.current;
    //boolean for togglin camera inside/out state
    const isCameraInside = useRef(false);
    //handler for zooming in on click
    const handleZoomIn = () => {
        GlobalStore.setCameraRadius(previousRadius => previousRadius - 50)
        GlobalStore.cameraStateRadius.current = GlobalStore.cameraRadius - 50;
    }
    //handler for zooming out on click
    const handleZoomOut = () => {
        GlobalStore.setCameraRadius(previousRadius => previousRadius + 50)
        GlobalStore.cameraStateRadius.current = GlobalStore.cameraRadius + 50;
    }
    //handler for toggling inside/out state of the camera
    const handleCameraInside = () => {
        isCameraInside.current ? GlobalStore.setCameraRadius(GlobalStore.cameraStateRadius.current) : GlobalStore.setCameraRadius(20)
        isCameraInside.current ? isCameraInside.current = false : isCameraInside.current = true;

    }
    //handler for toggling camera auto rotation behavior
    const handleCameraRotate = () => {
        GlobalStore.cameraRotating ? GlobalStore.setCameraRotating(false) : GlobalStore.setCameraRotating(true)
    }
    //handler for full view mode
    const handleFullView = () => {
        GlobalStore.setFullView(!GlobalStore.fullView);
    }

    const handleBackground = () => {
        GlobalStore.setBackgroundHidden(!GlobalStore.backgroundHidden)
        const ground = scene.getMeshByName("Cylinder001");
        const skybox = scene.getMeshByName("skyBox")
        ground.isVisible = GlobalStore.backgroundHidden;
        skybox.isVisible = GlobalStore.backgroundHidden;
        
    }

    const handleMeasure = () => {
        GlobalStore.setIsMeasure(!GlobalStore.isMeasure)


        const measurementGUI = GlobalStore.currentDimensionElements.current;

        if (!GlobalStore.isMeasure) {

            measurementGUI.map(mesh => {
                if (mesh.name.indexOf('main') > -1) {
                    mesh.isVisible = true;
                }
                else if (mesh.name.indexOf("leanR") > -1 && GlobalStore.isRightLeanActive.current) {
                    mesh.isVisible = true;
                }
                else if (mesh.name.indexOf("leanL") > -1 && GlobalStore.isLeftLeanActive.current) {
                    mesh.isVisible = true;
                }
            })
        }
        else {
            //might just need to be disabled
            measurementGUI.map(mesh => mesh.isVisible = false)
        }

    }

    return (
        <BuildControllerMenuWrapper isFullView={GlobalStore.fullView}>
            <BuildControllerMenuItem onClick={handleZoomIn}>
                <BuildControllerMenuItemIcon>
                    <IconZoomIn />
                </BuildControllerMenuItemIcon>
                <BuildControllerMenuItemText>
                    Zoom In
                </BuildControllerMenuItemText>
            </BuildControllerMenuItem>
            <BuildControllerMenuItem onClick={handleZoomOut}>
                <BuildControllerMenuItemIcon>
                    <IconZoomOut />
                </BuildControllerMenuItemIcon>
                <BuildControllerMenuItemText>
                    Zoom Out
                </BuildControllerMenuItemText>
            </BuildControllerMenuItem>
            <BuildControllerMenuItem onClick={handleCameraInside}>
                <BuildControllerMenuItemIcon>
                    <IconInside />
                </BuildControllerMenuItemIcon>
                <BuildControllerMenuItemText>
                    Inside
                </BuildControllerMenuItemText>
            </BuildControllerMenuItem>
            <BuildControllerMenuItem onClick={handleCameraRotate}>
                <BuildControllerMenuItemIcon>
                    <IconRotate />
                </BuildControllerMenuItemIcon>
                <BuildControllerMenuItemText>
                    Rotate
                </BuildControllerMenuItemText>
            </BuildControllerMenuItem>
            <BuildControllerMenuItem onClick={handleBackground}>
                <BuildControllerMenuItemIcon>
                    <IconEye />
                </BuildControllerMenuItemIcon>
                <BuildControllerMenuItemText>
                    Background
                </BuildControllerMenuItemText>
            </BuildControllerMenuItem>
            <BuildControllerMenuItem onClick={handleMeasure}>
                <BuildControllerMenuItemIcon>
                    <IconRuler />
                </BuildControllerMenuItemIcon>
                <BuildControllerMenuItemText>
                    Measure
                </BuildControllerMenuItemText>
            </BuildControllerMenuItem>
            <BuildControllerMenuItem onClick={handleFullView}>
                <BuildControllerMenuItemIcon>
                    {GlobalStore.fullView ? <IconPanelView /> : <IconFullView />}
                </BuildControllerMenuItemIcon>
                <BuildControllerMenuItemText>
                    {GlobalStore.fullView ? 'Panel View' : 'Full View'}
                </BuildControllerMenuItemText>
            </BuildControllerMenuItem>
        </BuildControllerMenuWrapper>
    )
}

export default BuildControllerMenu
