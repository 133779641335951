import React, { useContext, useState, useEffect } from "react";
import { CanvasWrapper } from "./Canvas.css";
//import {createScene} from '../../functions/BabylonViewer/renderScene'
import { Engine, ScatterPanel, Scene } from "react-babylonjs";
import { generateFrames, createReferencePlanes } from "../../utils/functions";

import {
  Vector3,
  Color3,
  AssetsManager,
  Plane,
  MorphTargetManager,
  MorphTarget,
  CubeTexture,
  MeshBuilder,
  StandardMaterial,
  PBRMaterial,
  Texture,
  PointerEventTypes,
  PointerDragBehavior,
  Space,
  VertexBuffer,
  Mesh,
  ThinMaterialHelper,
  AxesViewer,
} from "@babylonjs/core";
import "@babylonjs/loaders/glTF";
import "@babylonjs/loaders/OBJ";
import "pepjs";
import "babylonjs-inspector";
import { GlobalState } from "../../App";
import { ThemeProvider } from "styled-components";
import { IFrame } from "./Iframe";
import buildingTypesData from "../BuildingType/BuildingTypes.json";
//<Iframe src = "http://www.youtube.com/embed/xDMP3i36naA" />

var Id = 0;
export const OnClickBuildingId = (id) => {
  console.warn("id___", id);
  Id = id;
  //  <IFrame src="http://localhost:3000" height="100%" width="100%"/>
};

const Canvas = ({ canvasStyle }) => {
  //console.log("Canvas refresed")
  //import the global state
  const GlobalStore = useContext(GlobalState);
  //create a scene
  const setScene = (sceneRef) => {
    GlobalStore.sceneGlobalRef.current = sceneRef.scene;
    const scene = sceneRef.scene;

    scene.getBoundingBoxRenderer().frontColor.set(0.5, 0.7, 1);
    scene.getBoundingBoxRenderer().backColor.set(0.5, 0.7, 1);

   scene.debugLayer.show({
      embedMode: true,
    });

    var MMesh;

    const assetsManager = new AssetsManager(scene);
    assetsManager.useDefaultLoadingScreen = false;
    const mainStructureTask = assetsManager.addMeshTask(
      "mainStructureTask",
      "",
      "./babylon_assets/meshes/",
      "main_structure 2.glb"
    );

    //load the main mesh(the standard structure that is common to all the buildings, and then seperate the ground)
    //only extracting ground and ignoring the other mesh. Main mesh taken from standard cartport
    mainStructureTask.onSuccess = (task) => {
      const mainStructureMeshes = task.loadedMeshes;
      let mainStructureParent_R;
      let mainStructureParent_L;
      let mainStructureWalls = [];
      const clipPlane_R = new Plane(0, 0, -1, -0.25);
      const clipPlane_L = new Plane(0, 0, 1, -0.25);

      mainStructureMeshes.map((mesh) => {
        mesh.isPickable = false;
        //first set the background from the scene
        if (mesh.name == "Cylinder001") {
          //mesh.name=mesh.name+"_Type0";
          //let groundMaterial= new StandardMaterial("ground",scene);
          //groundMaterial.diffuseTexture= new Texture("C:/Users/Asif computer/Desktop/repos/3DBuilder/public/babylon_assets/textures/ground/grass/grass1.jpg",scene)
          //mesh.material= groundMaterial

          mesh.isVisible = true;
        } else {
          //set all things not the ground as invisible
          mesh.isVisible = false;
          // mesh.dispose();
        }
      });
    };

    const standardCarportTask = assetsManager.addMeshTask(
      "standardCarportTask",
      "",
      "./babylon_assets/meshes/StandardGarage_Full/",
      "Standard Garage 5.obj"
    );
    const standardBarnA = assetsManager.addMeshTask(
      "standardBarnA",
      "",
      "./babylon_assets/meshes/Standard Barn/",
      "Standard Barns 2.obj"
    );
    const standardCarportLeftLeanToTask = assetsManager.addMeshTask(
      "standardCarportLeftLeanToTask",
      "",
      "./babylon_assets/meshes/StandardGarage_Full/",
      "Lean-to (left) 6.obj"
    );
    const standardCarportRightLeanToTask = assetsManager.addMeshTask(
      "standardCarportRightLeanToTask",
      "",
      "./babylon_assets/meshes/StandardGarage_Full/",
      "Lean-to (right) 6.obj"
    );
    // Storage------------
    const standardCarportStorageTask = assetsManager.addMeshTask(
      "standardCarportStorageTask",
      "",
      "./babylon_assets/meshes/StandardGarage_Full/",
      "storageMain 3.obj"
    );
    const standardBarnStorageA = assetsManager.addMeshTask(
      "standardBarnStorageA",
      "",
      "./babylon_assets/meshes/Standard Barn/",
      "Standard Barns Storage 2.obj"
    );
    ////--------- Storage Lean to
    const standardCarportLeftLeanToStorageTask = assetsManager.addMeshTask(
      "standardCarportLeftLeanToStorageTask",
      "",
      "./babylon_assets/meshes/StandardGarage_Full/",
      "storageLeft 3.obj"
    );
    const standardCarportRightLeanToStorageTask = assetsManager.addMeshTask(
      "standardCarportRightLeanToStorageTask",
      "",
      "./babylon_assets/meshes/StandardGarage_Full/",
      "storageRight 3.obj"
    );

    //doors
    //3
    //const StandardGarageDoorsTask = assetsManager.addMeshTask("StandardGarageDoorsTask", "", "./babylon_assets/meshes/StandardGarage_Full/","Garage Door (6x6).obj");
    //4
    const DoorsTask = assetsManager.addMeshTask(
      "TripleWideGarageDoorsTask",
      "",
      "./babylon_assets/meshes/StandardGarage_Full/",
      "Garage Door (6x6) 2.obj"
    );
    const WindowTask = assetsManager.addMeshTask(
      "WindowTask",
      "",
      "./babylon_assets/meshes/Doors and windows/",
      "Window 2.obj"
    );
    const WalkDoorTask = assetsManager.addMeshTask(
      "WalkDoorTask",
      "",
      "./babylon_assets/meshes/Doors and windows/",
      "Walk-in Door (36x80) 2.obj"
    );
    const FramedDoorTask = assetsManager.addMeshTask(
      "FramedDoorTask",
      "",
      "./babylon_assets/meshes/Doors and windows/",
      "Walk-in Door Frame-out (36x80) 2.obj"
    );
    const FramedWindowTask = assetsManager.addMeshTask(
      "FramedWindowTask",
      "",
      "./babylon_assets/meshes/Doors and windows/",
      "Window Frame-out (36x80) 2.obj"
    );
    const FramedOpeningTask = assetsManager.addMeshTask(
      "FramedOpeningTask",
      "",
      "./babylon_assets/meshes/Doors and windows/",
      "Framed Openings (6x6) 2.obj"
    );

    //textures
    const envTexture = new CubeTexture(
      "./babylon_assets/textures/env.env",
      scene,
      1024
    );
    const panelBumpTexture = new Texture(
      "./babylon_assets/textures/wall_normal.jpg",
      scene
    );
    const NormalMapWallTexture90 = new Texture(
      "./babylon_assets/meshes/StandardGarage_Full/Standard Garage (walls)_normals_90.jpg",
      scene
    );
    const NormalMapWallTexture = new Texture(
      "./babylon_assets/meshes/StandardGarage_Full/Standard Garage (walls)_normals.jpg",
      scene
    );

    GlobalStore.normalMapWallTex.current = NormalMapWallTexture;
    GlobalStore.normalMapWallTex90.current = NormalMapWallTexture90;

    //import the cartport. This is used as the main skeletor mesh, upon which we add/remove elements
    standardCarportStorageTask.onSuccess = (task) => {
      //the first one is the parent mesh. It may be empty or a mesh. Depends upon the mesh
      var parentMesh = task.loadedMeshes[0];
      GlobalStore.StorageMeshMain.current[1] = parentMesh;
      /* const axes = new AxesViewer(scene, 10);
      axes.yAxis.parent = parentMesh;
      axes.xAxis.parent = parentMesh;
      axes.zAxis.parent = parentMesh;
*/
      task.loadedMeshes.map((mesh) => {
        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Type1_Storage";
          mesh.isPickable = false;
          /*const axes = new AxesViewer(scene, 400);
          axes.yAxis.parent = mesh;
          axes.xAxis.parent = mesh;
          axes.zAxis.parent = mesh;*/
        } else {
          mesh.isPickable = false;
          mesh.name = mesh.name + "_Type1_Storage";
          /*const axes = new AxesViewer(scene, 200);
          axes.yAxis.parent = mesh;
          axes.xAxis.parent = mesh;
          axes.zAxis.parent = mesh;
          mesh.showBoundingBox = true;
          */
        }

        /*mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;*/

        if (mesh.name.includes("END") || mesh.name.includes("SIDE")) {
          mesh.name = mesh.name + "_Type0_";
        }
        if (mesh.name.includes("roof") || mesh.name.includes("Roof"))
          mesh.name = mesh.name + "_Type0_";
        if(mesh.name.includes("boundingBox")){
          GlobalStore.mainCartportStorageBoundingBox.current = mesh;
        }
        // mesh.position=new Vector3(mesh.position.x-20,mesh.position.y+13,mesh.position.z)
        // mesh.scaling = new Vector3(mesh.scaling.x/10, mesh.scaling.y/7, mesh.scaling.z/7.2);

        /*This part is storing multiple position and scales for the barn. We use anohter array to 
        determine which of the meshes is true at a particular. The 11 parts might also refer to the 
        11 types of buildings used. Each of these corresponds to one building. The one that is 
        true is that one that get's displayed*/
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;
        if (material.name.includes("Garage__roof")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.specularColor = new Color3(1,1,1);
          // material.emissiveColor = new Color3(1, 1, 1);
          // material.ambientColor = new Color3(0.23, 0.98, 0.53);
          // material.ambientColor  = new Color3.FromHexString("#6f2026").toLinearSpace();
          //material.reflectionTexture = envTexture;
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerRoofMaterials.current.push(material);
        } else if (material.name.includes("walls")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.specularColor = new Color3(1,1,1);
          // material.emissiveColor = new Color3(1, 1, 1);
          // material.ambientColor = new Color3(0.23, 0.98, 0.53);
          // material.ambientColor  = new Color3.FromHexString("#6f2026").toLinearSpace();
          //material.reflectionTexture = envTexture;
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else if (material.name.includes("_Wainscot")) {
          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          material.metallic = 0.7;
          material.roughness = 0.7;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else {
          material.diffuseColor = new Color3.FromHexString(
            "#ffffff"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          //material.metallic = 1;
          // material.roughness = 1;
          // material.bumpTexture = NormalMapWallTexture;

          //  GlobalStore.outerTrimMaterials.current.push(material);
        }

        GlobalStore.MeshStoragePos.current[1] = new Vector3(
          mesh.position.x - 68,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStoragePos.current[2] = new Vector3(
          mesh.position.x - 68,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStoragePos.current[3] = new Vector3(
          mesh.position.x - 68,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStoragePos.current[4] = new Vector3(
          mesh.position.x - 68,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStoragePos.current[5] = new Vector3(
          mesh.position.x - 68,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStoragePos.current[6] = new Vector3(
          mesh.position.x - 68,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStoragePos.current[7] = new Vector3(
          mesh.position.x - 68,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStoragePos.current[8] = new Vector3(
          mesh.position.x - 1240 / 7,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStoragePos.current[9] = new Vector3(
          mesh.position.x - 68,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStoragePos.current[10] = new Vector3(
          mesh.position.x - 68,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStoragePos.current[11] = new Vector3(
          mesh.position.x - 68,
          mesh.position.y + 13,
          mesh.position.z
        );

        GlobalStore.MeshStorageScale.current[1] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshStorageScale.current[2] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7

          /*mesh.scaling.x / 5,
          mesh.scaling.y / 5,
          mesh.scaling.z / 5*/
        );
        GlobalStore.MeshStorageScale.current[3] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 10.5
        );
        GlobalStore.MeshStorageScale.current[4] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshStorageScale.current[5] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshStorageScale.current[6] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshStorageScale.current[7] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshStorageScale.current[8] = new Vector3(
          mesh.scaling.x / 17,
          mesh.scaling.y / 8.2,
          mesh.scaling.z / 8.3
        );
        GlobalStore.MeshStorageScale.current[9] = new Vector3(
          mesh.scaling.x / 15,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7.2
        );
        GlobalStore.MeshStorageScale.current[10] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshStorageScale.current[11] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );

        GlobalStore.storageMainMeshes.current[1] = false;
        GlobalStore.storageMainMeshes.current[2] = false;
        GlobalStore.storageMainMeshes.current[3] = false;
        GlobalStore.storageMainMeshes.current[4] = false;
        GlobalStore.storageMainMeshes.current[5] = true;
        GlobalStore.storageMainMeshes.current[6] = false;
        GlobalStore.storageMainMeshes.current[7] = false;
        GlobalStore.storageMainMeshes.current[8] = false;
        GlobalStore.storageMainMeshes.current[9] = false;
        GlobalStore.storageMainMeshes.current[10] = false;
        GlobalStore.storageMainMeshes.current[11] = false;
        // GlobalStore.storageMainMeshes.current[1]
      });
    };

    standardBarnStorageA.onSuccess = (task) => {
      var parentMesh = task.loadedMeshes[0];
      GlobalStore.StorageMeshMain.current[8] = parentMesh;
      task.loadedMeshes.map((mesh) => {
        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Type8_Storage";
        } else {
          mesh.name = mesh.name + "_Type8_Storage";
        }
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;

        if (mesh.name.includes("END") || mesh.name.includes("SIDE")) {
          mesh.name = mesh.name + "_Type0_";
        }
        if (mesh.name.includes("roof") || mesh.name.includes("Roof"))
          mesh.name = mesh.name + "_Type0_";

        // mesh.position=new Vector3(mesh.position.x-20,mesh.position.y+13,mesh.position.z)
        // mesh.scaling = new Vector3(mesh.scaling.x/10, mesh.scaling.y/7, mesh.scaling.z/7.2);

        // GlobalStore.storageMainMeshes.current[1]
      });
    };
    ////////////////////// storage --lean-----------------------------
    standardCarportLeftLeanToStorageTask.onSuccess = (task) => {
      var parentMesh = task.loadedMeshes[0];
      GlobalStore.StorageMeshLT.current[1] = parentMesh;

      task.loadedMeshes.map((mesh) => {
        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Type1_LT_Storage";
          mesh.isPickable = false;
          /*const axes = new AxesViewer(scene, 400);
          axes.yAxis.parent = mesh;
          axes.xAxis.parent = mesh;
          axes.zAxis.parent = mesh;*/
        } else {
          mesh.isPickable = false;
          mesh.name = mesh.name + "_Type1_LT_Storage";
          /*const axes = new AxesViewer(scene, 400);
          axes.yAxis.parent = mesh;
          axes.xAxis.parent = mesh;
          axes.zAxis.parent = mesh;
          */
        }
        if(mesh.name.includes("boundingBox")){
          GlobalStore.LTStorageBoundingBox.current = mesh;
          //alert("Left storage Bounidng Box: "+ GlobalStore.LTStorageBoundingBox.current.name);
        }

        /*  mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;*/
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;
        if (material.name.includes("Garage__roof")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.specularColor = new Color3(1,1,1);
          // material.emissiveColor = new Color3(1, 1, 1);
          // material.ambientColor = new Color3(0.23, 0.98, 0.53);
          // material.ambientColor  = new Color3.FromHexString("#6f2026").toLinearSpace();
          //material.reflectionTexture = envTexture;
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerRoofMaterials.current.push(material);
        } else if (material.name.includes("walls")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.specularColor = new Color3(1,1,1);
          // material.emissiveColor = new Color3(1, 1, 1);
          // material.ambientColor = new Color3(0.23, 0.98, 0.53);
          // material.ambientColor  = new Color3.FromHexString("#6f2026").toLinearSpace();
          //material.reflectionTexture = envTexture;
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else if (material.name.includes("_Wainscot")) {
          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          material.metallic = 0.7;
          material.roughness = 0.7;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else {
          material.diffuseColor = new Color3.FromHexString(
            "#ffffff"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          //material.metallic = 1;
          // material.roughness = 1;
          // material.bumpTexture = NormalMapWallTexture;

          //  GlobalStore.outerTrimMaterials.current.push(material);
        }

        if (mesh.name.includes("END") || mesh.name.includes("SIDE")) {
          mesh.name = "_Type0_";
        }
        if (mesh.name.includes("roof") || mesh.name.includes("Roof"))
          mesh.name = "_Type0_";

        mesh.isVisible = false;
        mesh.isPickable = false;

        // GlobalStore.storageMainMeshes.current[1] =true;
        //  mesh.position=new Vector3(mesh.position.x-20,mesh.position.y+13,mesh.position.z)
        //  mesh.scaling = new Vector3(mesh.scaling.x/11, mesh.scaling.y/7.1, mesh.scaling.z/7);

        GlobalStore.MeshStorageLTPos.current[1] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStorageLTPos.current[2] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStorageLTPos.current[3] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStorageLTPos.current[4] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStorageLTPos.current[5] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStorageLTPos.current[6] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStorageLTPos.current[7] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStorageLTPos.current[8] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStorageLTPos.current[9] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStorageLTPos.current[10] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshStorageLTPos.current[11] = new Vector3(
          mesh.position.x - 471 / 9,
          mesh.position.y + 13,
          mesh.position.z
        );

        GlobalStore.MeshStorageLTScale.current[1] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );
        GlobalStore.MeshStorageLTScale.current[2] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );
        GlobalStore.MeshStorageLTScale.current[3] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );
        GlobalStore.MeshStorageLTScale.current[4] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );
        GlobalStore.MeshStorageLTScale.current[5] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );
        GlobalStore.MeshStorageLTScale.current[6] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );
        GlobalStore.MeshStorageLTScale.current[7] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );
        GlobalStore.MeshStorageLTScale.current[8] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );
        GlobalStore.MeshStorageLTScale.current[9] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );
        GlobalStore.MeshStorageLTScale.current[10] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );
        GlobalStore.MeshStorageLTScale.current[11] = new Vector3(
          mesh.scaling.x,
          mesh.scaling.y,
          mesh.scaling.z
        );

        GlobalStore.storageLeftMeshes.current[1] = false;
        GlobalStore.storageLeftMeshes.current[2] = false;
        GlobalStore.storageLeftMeshes.current[3] = false;
        GlobalStore.storageLeftMeshes.current[4] = false;
        GlobalStore.storageLeftMeshes.current[5] = true;
        GlobalStore.storageLeftMeshes.current[6] = false;
        GlobalStore.storageLeftMeshes.current[7] = false;
        GlobalStore.storageLeftMeshes.current[8] = false;
        GlobalStore.storageLeftMeshes.current[9] = false;
        GlobalStore.storageLeftMeshes.current[10] = false;
        GlobalStore.storageLeftMeshes.current[11] = false;
      });
    };

    standardCarportRightLeanToStorageTask.onSuccess = (task) => {
      var parentMesh = task.loadedMeshes[0];
      GlobalStore.StorageMeshRT.current[1] = parentMesh;
      task.loadedMeshes.map((mesh) => {
        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Type1_RT_Storage";
          mesh.isPickable = false;
          /*const axes = new AxesViewer(scene, 400);
          axes.yAxis.parent = mesh;
          axes.xAxis.parent = mesh;
          axes.zAxis.parent = mesh;*/
        } else {
          mesh.isPickable = false;
          mesh.name = mesh.name + "_Type1_RT_Storage";
        }

        if(mesh.name.includes("boundingBox")){
          GlobalStore.RTStorageBoundingBox.current = mesh;
          //alert("RTStorageBoundingBox",GlobalStore.RTStorageBoundingBox.current.name);
          //alert(GlobalStore.RTStorageBoundingBox.current.name)
        }
        /*mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;*/

        if (mesh.name.includes("END") || mesh.name.includes("SIDE")) {
          mesh.name += "_Type0_";
        }
        if (mesh.name.includes("roof") || mesh.name.includes("Roof"))
          mesh.name += "_Type0_";

        mesh.isVisible = false;
        mesh.isPickable = false;

        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;
        if (material.name.includes("Garage__roof")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.specularColor = new Color3(1,1,1);
          // material.emissiveColor = new Color3(1, 1, 1);
          // material.ambientColor = new Color3(0.23, 0.98, 0.53);
          // material.ambientColor  = new Color3.FromHexString("#6f2026").toLinearSpace();
          //material.reflectionTexture = envTexture;
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerRoofMaterials.current.push(material);
        } else if (material.name.includes("walls")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.specularColor = new Color3(1,1,1);
          // material.emissiveColor = new Color3(1, 1, 1);
          // material.ambientColor = new Color3(0.23, 0.98, 0.53);
          // material.ambientColor  = new Color3.FromHexString("#6f2026").toLinearSpace();
          //material.reflectionTexture = envTexture;
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else if (material.name.includes("_Wainscot")) {
          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          material.metallic = 0.7;
          material.roughness = 0.7;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else {
          material.diffuseColor = new Color3.FromHexString(
            "#ffffff"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          //material.metallic = 1;
          // material.roughness = 1;
          // material.bumpTexture = NormalMapWallTexture;

          //  GlobalStore.outerTrimMaterials.current.push(material);
        }

        // GlobalStore.storageRightMeshes.current[1]=true;
        //    GlobalStore.MeshStorageLTPos.current[1]= new Vector3(mesh.position.x-20,mesh.position.y+13,mesh.position.z);
        //    GlobalStore.MeshStorageLTPos.current[2]= new Vector3(mesh.position.x-30,mesh.position.y+13,mesh.position.z);
        //    GlobalStore.MeshStorageLTPos.current[3]= new Vector3(mesh.position.x-35,mesh.position.y+13,mesh.position.z-13)
        //    GlobalStore.MeshStorageLTPos.current[4]=new Vector3(mesh.position.x-30,mesh.position.y+13,mesh.position.z-5)
        //    GlobalStore.MeshStorageLTPos.current[5]=new Vector3(mesh.position.x-20,mesh.position.y+13,mesh.position.z)
        //    GlobalStore.MeshStorageLTPos.current[6]=new Vector3(mesh.position.x-20,mesh.position.y+13,mesh.position.z)
        //    GlobalStore.MeshStorageLTPos.current[7]=new Vector3(mesh.position.x-35,mesh.position.y+13,mesh.position.z)
        //    GlobalStore.MeshStorageLTPos.current[8]=new Vector3(mesh.position.x-38,mesh.position.y+13,mesh.position.z)
        //    GlobalStore.MeshStorageLTPos.current[9]= new Vector3(mesh.position.x-38,mesh.position.y+13,mesh.position.z)
        //    GlobalStore.MeshStorageLTPos.current[10]=new Vector3(mesh.position.x-38,mesh.position.y+13,mesh.position.z)
        //    GlobalStore.MeshStorageLTPos.current[11]=new Vector3(mesh.position.x-38,mesh.position.y+13,mesh.position.z)

        //    GlobalStore.MeshStorageLTScale.current[1]= new Vector3(mesh.scaling.x/11, mesh.scaling.y/7.1, mesh.scaling.z/7);
        //    GlobalStore.MeshStorageLTScale.current[2]= new Vector3(mesh.scaling.x/10, mesh.scaling.y/7, mesh.scaling.z/7.2);
        //    GlobalStore.MeshStorageLTScale.current[3]=  new Vector3(mesh.scaling.x/17, mesh.scaling.y/7, mesh.scaling.z/7.1);
        //    GlobalStore.MeshStorageLTScale.current[4]=  new Vector3(mesh.scaling.x/17, mesh.scaling.y/7, mesh.scaling.z/10.1);
        //    GlobalStore.MeshStorageLTScale.current[5] =new Vector3(mesh.scaling.x/10, mesh.scaling.y/7, mesh.scaling.z/7.2);
        //    GlobalStore.MeshStorageLTScale.current[6] =new Vector3(mesh.scaling.x/17, mesh.scaling.y/7, mesh.scaling.z/10.1);
        //    GlobalStore.MeshStorageLTScale.current[7]= new Vector3(mesh.scaling.x/17, mesh.scaling.y/7, mesh.scaling.z/7.1);
        //    GlobalStore.MeshStorageLTScale.current[8] = new Vector3(mesh.scaling.x/17, mesh.scaling.y/7, mesh.scaling.z/7.1);
        //    GlobalStore.MeshStorageLTScale.current[9] = new Vector3(mesh.scaling.x/17, mesh.scaling.y/7, mesh.scaling.z/7.1);
        //    GlobalStore.MeshStorageLTScale.current[10]= new Vector3(mesh.scaling.x/17, mesh.scaling.y/7, mesh.scaling.z/7.1);
        //    GlobalStore.MeshStorageLTScale.current[11]= new Vector3(mesh.scaling.x/17, mesh.scaling.y/7, mesh.scaling.z/7.1);

        GlobalStore.storageRightMeshes.current[1] = true;
        GlobalStore.storageRightMeshes.current[2] = false;
        GlobalStore.storageRightMeshes.current[3] = false;
        GlobalStore.storageRightMeshes.current[4] = false;
        GlobalStore.storageRightMeshes.current[5] = true;
        GlobalStore.storageRightMeshes.current[6] = false;
        GlobalStore.storageRightMeshes.current[7] = false;
        GlobalStore.storageRightMeshes.current[8] = false;
        GlobalStore.storageRightMeshes.current[9] = false;
        GlobalStore.storageRightMeshes.current[10] = false;
        GlobalStore.storageRightMeshes.current[11] = false;
        //   mesh.position=new Vector3(mesh.position.x-20,mesh.position.y+13,mesh.position.z)
        // mesh.scaling = new Vector3(mesh.scaling.x/10, mesh.scaling.y/7.1, mesh.scaling.z/7);
      });
    };

    WindowTask.onSuccess = (task) => {
      // console.error( );

      var parentMesh = task.loadedMeshes[0];
      //console.error(mesh.name);

      task.loadedMeshes.map((mesh) => {
        mesh.isVisible = false;
        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Window";
        } else {
          mesh.position = new Vector3(
            mesh.position.x,
            mesh.position.y + 13,
            mesh.position.z
          );
          mesh.scaling = new Vector3(
            mesh.scaling.x,
            mesh.scaling.y / 2,
            mesh.scaling.z * 1.5
          );
          mesh.name = mesh.name + "_Window";
        }
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;
        // console.error(mesh.name);
      });
      GlobalStore.WindowMesh.current[0] = parentMesh;
      GlobalStore.WindowMeshPosFrontWall.current[3] =
        parentMesh.position.x + 68;
      GlobalStore.WindowMeshPosFrontWall.current[4] =
        parentMesh.position.x + 60;
      GlobalStore.WindowMeshPosFrontWall.current[5] =
        parentMesh.position.x + 48;
      GlobalStore.WindowMeshPosFrontWall.current[6] =
        parentMesh.position.x + 68;
      GlobalStore.WindowMeshPosFrontWall.current[7] =
        parentMesh.position.x + 68;
      GlobalStore.WindowMeshPosFrontWall.current[8] =
        parentMesh.position.x + 68;
      GlobalStore.WindowMeshPosFrontWall.current[9] =
        parentMesh.position.x + 68;
      GlobalStore.WindowMeshPosFrontWall.current[10] =
        parentMesh.position.x + 50;
      GlobalStore.WindowMeshPosFrontWall.current[11] =
        parentMesh.position.x + 68;
    };
    WalkDoorTask.onSuccess = (task) => {
      // console.error( );

      var parentMesh = task.loadedMeshes[0];
      //console.error(mesh.name);
      task.loadedMeshes.map((mesh) => {
        mesh.isVisible = false;

        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Window_d";
        } else {
          mesh.position = new Vector3(
            mesh.position.x,
            mesh.position.y + 13,
            mesh.position.z
          );

          /*const axes = new AxesViewer(scene, 10);
          axes.yAxis.parent = parentMesh;
          axes.xAxis.parent = parentMesh;
          axes.zAxis.parent = parentMesh;*/

          //mesh.scaling = new Vector3(mesh.scaling.x/17, mesh.scaling.y/17, mesh.scaling.z/10);
          mesh.name = mesh.name + "_Window_d";
          mesh.isVisible = false;
        }
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;
        // console.error(mesh.name);
      });
      GlobalStore.WalkDoorMesh.current[0] = parentMesh;
      GlobalStore.WalkDoorMeshPosFrontWall.current[3] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[4] =
        parentMesh.position.x + 60;
      GlobalStore.WalkDoorMeshPosFrontWall.current[5] =
        parentMesh.position.x + 48;
      GlobalStore.WalkDoorMeshPosFrontWall.current[6] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[7] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[8] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[9] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[10] =
        parentMesh.position.x + 50;
      GlobalStore.WalkDoorMeshPosFrontWall.current[11] =
        parentMesh.position.x + 68;
    };
    FramedDoorTask.onSuccess = (task) => {
      // console.error( );

      var parentMesh = task.loadedMeshes[0];
      //console.error(mesh.name);
      task.loadedMeshes.map((mesh) => {
        mesh.isVisible = false;

        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Window_d";
        } else {
          mesh.position = new Vector3(
            mesh.position.x,
            mesh.position.y + 13,
            mesh.position.z
          );
          // mesh.scaling = new Vector3(mesh.scaling.x/7, mesh.scaling.y/7, mesh.scaling.z/10);
          mesh.name = mesh.name + "_Window_d";
          mesh.isVisible = false;
        }
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var materialOfMesh = mesh.material;
        materialOfMesh.diffuseColor = new Color3(0, 0, 0);

        // console.error(mesh.name);
      });
      GlobalStore.FramedDoorMesh.current[0] = parentMesh;
      GlobalStore.WalkDoorMeshPosFrontWall.current[3] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[4] =
        parentMesh.position.x + 60;
      GlobalStore.WalkDoorMeshPosFrontWall.current[5] =
        parentMesh.position.x + 48;
      GlobalStore.WalkDoorMeshPosFrontWall.current[6] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[7] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[8] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[9] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[10] =
        parentMesh.position.x + 50;
      GlobalStore.WalkDoorMeshPosFrontWall.current[11] =
        parentMesh.position.x + 68;
    };
    FramedWindowTask.onSuccess = (task) => {
      var parentMesh = task.loadedMeshes[0];
      task.loadedMeshes.map((mesh) => {
        mesh.isVisible = false;

        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Window";
        } else {
          mesh.isVisible = false;

          mesh.position = new Vector3(
            mesh.position.x,
            mesh.position.y + 13,
            mesh.position.z
          );
          // mesh.scaling = new Vector3(mesh.scaling.x/7, mesh.scaling.y/7, mesh.scaling.z/10);
          mesh.name = mesh.name + "_Window";
        }
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);
        var materialOfMesh = mesh.material;
        materialOfMesh.diffuseColor = new Color3(0, 0, 0);
      });
      GlobalStore.FramedWindowMesh.current[0] = parentMesh;
      GlobalStore.WalkDoorMeshPosFrontWall.current[3] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[4] =
        parentMesh.position.x + 60;
      GlobalStore.WalkDoorMeshPosFrontWall.current[5] =
        parentMesh.position.x + 48;
      GlobalStore.WalkDoorMeshPosFrontWall.current[6] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[7] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[8] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[9] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[10] =
        parentMesh.position.x + 50;
      GlobalStore.WalkDoorMeshPosFrontWall.current[11] =
        parentMesh.position.x + 68;
    };
    FramedOpeningTask.onSuccess = (task) => {
      // console.error( );

      var parentMesh = task.loadedMeshes[0];

      task.loadedMeshes.map((mesh) => {
        //  mesh.renderingGroupId = 1;
        mesh.isVisible = false;

        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Window_d";
        } else {
          mesh.position = new Vector3(
            mesh.position.x,
            mesh.position.y,
            mesh.position.z
          );
          mesh.scaling = new Vector3(
            mesh.scaling.x * 2,
            mesh.scaling.y / 1.5,
            mesh.scaling.z
          );
          mesh.name = mesh.name + "_Window_d";
        }
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var materialOfMesh = mesh.material;
        materialOfMesh.diffuseColor = new Color3(0, 0, 0);
        //materialOfMesh.emissiveColor = new Color3(0.1,0.1,0.1);
        //  materialOfMesh.alpha = 0.1;
        //  mesh.material = materialOfMesh;
        //  scene.setRenderingAutoClearDepthStencil(1, false,false,false);
        //  scene.setRenderingAutoClearDepthStencil(2, false,false,false);
        //  Engine.setStencilBuffer(false);
        // console.error(mesh.name);
      });
      GlobalStore.FramedMesh.current[0] = parentMesh;
      GlobalStore.WalkDoorMeshPosFrontWall.current[3] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[4] =
        parentMesh.position.x + 60;
      GlobalStore.WalkDoorMeshPosFrontWall.current[5] =
        parentMesh.position.x + 48;
      GlobalStore.WalkDoorMeshPosFrontWall.current[6] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[7] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[8] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[9] =
        parentMesh.position.x + 68;
      GlobalStore.WalkDoorMeshPosFrontWall.current[10] =
        parentMesh.position.x + 50;
      GlobalStore.WalkDoorMeshPosFrontWall.current[11] =
        parentMesh.position.x + 68;
    };
    DoorsTask.onSuccess = (task) => {
      var parentMesh = task.loadedMeshes[0];

      task.loadedMeshes.map((mesh) => {
        scene.getEngine().restoreStencilState();
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);
        //    if(mesh.material.stencil == null)
        //  mesh.material.stencil = new MaterialStencilState();
        // mesh.material.stencil.enabled = true;
        // mesh.material.stencil.opStencilDepthPass = Engine.REPLACE;
        //  mesh.material.stencil.mask = 0xFF;
        //  mesh.material.stencil.func = Engine.ALWAYS;
        //  mesh.material.stencil.funcRef = 2;

        mesh.isVisible = false;

        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Window_d";
          mesh.isPickable = false;
        } else {
          mesh.isVisible = false;
          mesh.isPickable = true;
          mesh.name = mesh.name + "_Window_d";
          mesh.position = new Vector3(
            mesh.position.x,
            mesh.position.y + 13,
            mesh.position.z
          );
          // mesh.scaling = new Vector3(mesh.scaling.x/8, mesh.scaling.y/7, mesh.scaling.z/12);
        }

        //  mesh.material.disableDepthWrite = true;
        //console.log("mm name " + mesh.material.name);
      });

      GlobalStore.DoorMesh.current[0] = parentMesh;
      //console.log("length" + parentMesh);
      scene.meshes.splice(0, 0, parentMesh);
      GlobalStore.DoorMeshPosLeftWallLT.current[1] =
        parentMesh.position.z + 450;
      GlobalStore.DoorMeshPosLeftWallLT.current[2] =
        parentMesh.position.z + 450;
      GlobalStore.DoorMeshPosLeftWallLT.current[3] =
        parentMesh.position.z + 450; // tested
      GlobalStore.DoorMeshPosLeftWallLT.current[4] =
        parentMesh.position.z + 450;
      GlobalStore.DoorMeshPosLeftWallLT.current[5] =
        parentMesh.position.z + 450;
      GlobalStore.DoorMeshPosLeftWallLT.current[6] =
        parentMesh.position.z + 450;
      GlobalStore.DoorMeshPosLeftWallLT.current[7] =
        parentMesh.position.z + 450;
      GlobalStore.DoorMeshPosLeftWallLT.current[8] =
        parentMesh.position.z + 450;
      GlobalStore.DoorMeshPosLeftWallLT.current[9] =
        parentMesh.position.z + 450;
      GlobalStore.DoorMeshPosLeftWallLT.current[10] =
        parentMesh.position.z + 450;
      GlobalStore.DoorMeshPosLeftWallLT.current[11] =
        parentMesh.position.z + 450;

      GlobalStore.DoorMeshPosRightWallRT.current[1] =
        parentMesh.position.z - 450;
      GlobalStore.DoorMeshPosRightWallRT.current[2] =
        parentMesh.position.z - 450;
      GlobalStore.DoorMeshPosRightWallRT.current[3] =
        parentMesh.position.z - 450; // tested
      GlobalStore.DoorMeshPosRightWallRT.current[4] =
        parentMesh.position.z - 450;
      GlobalStore.DoorMeshPosRightWallRT.current[5] =
        parentMesh.position.z - 450;
      GlobalStore.DoorMeshPosRightWallRT.current[6] =
        parentMesh.position.z - 450;
      GlobalStore.DoorMeshPosRightWallRT.current[7] =
        parentMesh.position.z - 450;
      GlobalStore.DoorMeshPosRightWallRT.current[8] =
        parentMesh.position.z - 450;
      GlobalStore.DoorMeshPosRightWallRT.current[9] =
        parentMesh.position.z - 450;
      GlobalStore.DoorMeshPosRightWallRT.current[10] =
        parentMesh.position.z - 450;
      GlobalStore.DoorMeshPosRightWallRT.current[11] =
        parentMesh.position.z - 450;

      GlobalStore.DoorMeshPosFrontWall.current[1] = parentMesh.position.x + 878; // no need to change
      GlobalStore.DoorMeshPosFrontWall.current[2] =
        parentMesh.position.x + 1150;
      GlobalStore.DoorMeshPosFrontWall.current[3] =
        parentMesh.position.x + 1540;
      GlobalStore.DoorMeshPosFrontWall.current[4] =
        parentMesh.position.x + 1290;
      GlobalStore.DoorMeshPosFrontWall.current[5] = parentMesh.position.x + 890;
      GlobalStore.DoorMeshPosFrontWall.current[6] =
        parentMesh.position.x + 1068;
      GlobalStore.DoorMeshPosFrontWall.current[7] =
        parentMesh.position.x + 1568;
      GlobalStore.DoorMeshPosFrontWall.current[8] =
        parentMesh.position.x + 1268;
      GlobalStore.DoorMeshPosFrontWall.current[9] =
        parentMesh.position.x + 1568;
      GlobalStore.DoorMeshPosFrontWall.current[10] =
        parentMesh.position.x + 1568;
      GlobalStore.DoorMeshPosFrontWall.current[11] =
        parentMesh.position.x + 1568;

      GlobalStore.DoorMeshPosRightWall.current[1] = parentMesh.position.z - 320; ////--------------
      GlobalStore.DoorMeshPosRightWall.current[2] = parentMesh.position.z - 320;
      GlobalStore.DoorMeshPosRightWall.current[3] = parentMesh.position.z - 305;
      GlobalStore.DoorMeshPosRightWall.current[4] = parentMesh.position.z - 330;
      GlobalStore.DoorMeshPosRightWall.current[5] = parentMesh.position.z - 330;
      GlobalStore.DoorMeshPosRightWall.current[6] = parentMesh.position.z - 330;
      GlobalStore.DoorMeshPosRightWall.current[7] = parentMesh.position.z - 330;
      GlobalStore.DoorMeshPosRightWall.current[8] = parentMesh.position.z - 370;
      GlobalStore.DoorMeshPosRightWall.current[9] = parentMesh.position.z - 330;
      GlobalStore.DoorMeshPosRightWall.current[10] =
        parentMesh.position.z - 330;
      GlobalStore.DoorMeshPosRightWall.current[11] =
        parentMesh.position.z - 330;

      GlobalStore.DoorMeshPosLeftWall.current[1] = parentMesh.position.z + 330; ////--------------
      GlobalStore.DoorMeshPosLeftWall.current[2] = parentMesh.position.z + 330;
      GlobalStore.DoorMeshPosLeftWall.current[3] = parentMesh.position.z + 307;
      GlobalStore.DoorMeshPosLeftWall.current[4] = parentMesh.position.z + 330;
      GlobalStore.DoorMeshPosLeftWall.current[5] = parentMesh.position.z + 330;
      GlobalStore.DoorMeshPosLeftWall.current[6] = parentMesh.position.z + 330;
      GlobalStore.DoorMeshPosLeftWall.current[7] = parentMesh.position.z + 330;
      GlobalStore.DoorMeshPosLeftWall.current[8] = parentMesh.position.z + 370;
      GlobalStore.DoorMeshPosLeftWall.current[9] = parentMesh.position.z + 330;
      GlobalStore.DoorMeshPosLeftWall.current[10] = parentMesh.position.z + 390;
      GlobalStore.DoorMeshPosLeftWall.current[11] = parentMesh.position.z + 330;

      GlobalStore.DoorMeshPosBackWall.current[1] = parentMesh.position.x - 460;
      GlobalStore.DoorMeshPosBackWall.current[2] = parentMesh.position.x - 490; // no need to change
      GlobalStore.DoorMeshPosBackWall.current[3] = parentMesh.position.x - 487;
      GlobalStore.DoorMeshPosBackWall.current[4] = parentMesh.position.x - 520;
      GlobalStore.DoorMeshPosBackWall.current[5] = parentMesh.position.x - 508;
      GlobalStore.DoorMeshPosBackWall.current[6] = parentMesh.position.x - 578;
      GlobalStore.DoorMeshPosBackWall.current[7] = parentMesh.position.x - 508;
      GlobalStore.DoorMeshPosBackWall.current[8] = parentMesh.position.x - 1258;
      GlobalStore.DoorMeshPosBackWall.current[9] = parentMesh.position.x - 508;
      GlobalStore.DoorMeshPosBackWall.current[10] = parentMesh.position.x - 508;
      GlobalStore.DoorMeshPosBackWall.current[11] = parentMesh.position.x - 508;
    };
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    standardCarportTask.onSuccess = (task) => {
      GlobalStore.MeshMiddleMainPitch.current[1] = 1; //0 - low, 1- mid, 2 -high
      GlobalStore.MeshMiddleMainPitch.current[2] = 1;
      GlobalStore.MeshMiddleMainPitch.current[3] = 1;
      GlobalStore.MeshMiddleMainPitch.current[4] = 1;
      GlobalStore.MeshMiddleMainPitch.current[5] = 1;
      GlobalStore.MeshMiddleMainPitch.current[6] = 1;
      GlobalStore.MeshMiddleMainPitch.current[7] = 1;
      GlobalStore.MeshMiddleMainPitch.current[8] = 1;
      GlobalStore.MeshMiddleMainPitch.current[9] = 1;
      GlobalStore.MeshMiddleMainPitch.current[10] = 1;
      GlobalStore.MeshMiddleMainPitch.current[11] = 1;

      GlobalStore.IsWallOpen.current[1] = true;
      GlobalStore.IsWallOpen.current[2] = true;
      GlobalStore.IsWallOpen.current[3] = false;
      GlobalStore.IsWallOpen.current[4] = false;
      GlobalStore.IsWallOpen.current[5] = false;
      GlobalStore.IsWallOpen.current[6] = false;
      GlobalStore.IsWallOpen.current[7] = false;
      GlobalStore.IsWallOpen.current[8] = false;
      GlobalStore.IsWallOpen.current[9] = false;
      GlobalStore.IsWallOpen.current[10] = true;
      GlobalStore.IsWallOpen.current[11] = false;

      GlobalStore.IsWallLTOpen.current[1] = false;
      GlobalStore.IsWallLTOpen.current[2] = false;
      GlobalStore.IsWallLTOpen.current[3] = false;
      GlobalStore.IsWallLTOpen.current[4] = false;
      GlobalStore.IsWallLTOpen.current[5] = false;
      GlobalStore.IsWallLTOpen.current[6] = false;
      GlobalStore.IsWallLTOpen.current[7] = false;
      GlobalStore.IsWallLTOpen.current[8] = false;
      GlobalStore.IsWallLTOpen.current[9] = false;
      GlobalStore.IsWallLTOpen.current[10] = false;
      GlobalStore.IsWallLTOpen.current[11] = true;

      GlobalStore.IsWallRTOpen.current[1] = false;
      GlobalStore.IsWallRTOpen.current[2] = false;
      GlobalStore.IsWallRTOpen.current[3] = false;
      GlobalStore.IsWallRTOpen.current[4] = false;
      GlobalStore.IsWallRTOpen.current[5] = false;
      GlobalStore.IsWallRTOpen.current[6] = false;
      GlobalStore.IsWallRTOpen.current[7] = false;
      GlobalStore.IsWallRTOpen.current[8] = false;
      GlobalStore.IsWallRTOpen.current[9] = false;
      GlobalStore.IsWallRTOpen.current[10] = false;
      GlobalStore.IsWallRTOpen.current[11] = false;


      

      // var root = task.loadedMeshes[0];
      // MMesh=root;
      var parentMesh = task.loadedMeshes[0];
      GlobalStore.MeshMain.current[1] = parentMesh;
      //GlobalStore.MeshMain.current[1].showBoundingBox= true;
      task.loadedMeshes.map((mesh) => {
        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Type1_";
          mesh.isPickable = false;
        } else {
          mesh.name = mesh.name + "_Type1_";
          mesh.isPickable = false;
        }


        if(mesh.name.includes("boundingBox")){
          GlobalStore.mainCartportBoundingBox.current = mesh;
          //alert(GlobalStore.mainCartportBoundingBox.current.name)
          GlobalStore.mainCartportBoundingBox.current.isVisible = false;
        }

        mesh.isVisible = false;
        mesh.isPickable = false;

        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;
        if (material.name.includes("Garage__roof")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.specularColor = new Color3(1,1,1);
          // material.emissiveColor = new Color3(1, 1, 1);
          // material.ambientColor = new Color3(0.23, 0.98, 0.53);
          // material.ambientColor  = new Color3.FromHexString("#6f2026").toLinearSpace();
          //material.reflectionTexture = envTexture;
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerRoofMaterials.current.push(material);
        } else if (material.name.includes("walls")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.specularColor = new Color3(1,1,1);
          // material.emissiveColor = new Color3(1, 1, 1);
          // material.ambientColor = new Color3(0.23, 0.98, 0.53);
          // material.ambientColor  = new Color3.FromHexString("#6f2026").toLinearSpace();
          //material.reflectionTexture = envTexture;
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else if (material.name.includes("_Wainscot")) {
          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          material.metallic = 0.7;
          material.roughness = 0.7;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else {
          material.diffuseColor = new Color3.FromHexString(
            "#ffffff"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          //material.metallic = 1;
          // material.roughness = 1;
          // material.bumpTexture = NormalMapWallTexture;

          //  GlobalStore.outerTrimMaterials.current.push(material);
        }

        // else

        //mesh.position=new Vector3(mesh.position.x,mesh.position.y+13,mesh.position.z)
        //mesh.scaling = new Vector3(mesh.scaling.x/7, mesh.scaling.y/7, mesh.scaling.z/7);
        GlobalStore.MeshPos.current[1] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[1] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshPos.current[2] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[2] = new Vector3(
          mesh.scaling.x / 5,
          mesh.scaling.y / 5,
          mesh.scaling.z / 4
        );
        GlobalStore.MeshPos.current[3] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[3] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 10
        );
        GlobalStore.MeshPos.current[4] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[4] = new Vector3(
          mesh.scaling.x / 8,
          mesh.scaling.y / 7,
          mesh.scaling.z / 12
        );
        GlobalStore.MeshPos.current[5] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[5] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshPos.current[6] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[6] = new Vector3(
          mesh.scaling.x / 10,
          mesh.scaling.y / 7,
          mesh.scaling.z / 10
        );
        GlobalStore.MeshPos.current[7] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[7] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshPos.current[8] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[8] = new Vector3(
          mesh.scaling.x / 18,
          mesh.scaling.y / 8,
          mesh.scaling.z / 8.3
        );
        GlobalStore.MeshPos.current[9] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[9] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshPos.current[10] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[10] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshPos.current[11] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshScale.current[11] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
      });
    };
    standardCarportLeftLeanToTask.onSuccess = (task) => {
      GlobalStore.MeshMiddleLTPitch.current[1] = 2; //0 - low, 1- mid, 2 -high
      GlobalStore.MeshMiddleLTPitch.current[2] = 2;
      GlobalStore.MeshMiddleLTPitch.current[3] = 2;
      GlobalStore.MeshMiddleLTPitch.current[4] = 2;
      GlobalStore.MeshMiddleLTPitch.current[5] = 2;
      GlobalStore.MeshMiddleLTPitch.current[6] = 2;
      GlobalStore.MeshMiddleLTPitch.current[7] = 2;
      GlobalStore.MeshMiddleLTPitch.current[8] = 2;
      GlobalStore.MeshMiddleLTPitch.current[9] = 2;
      GlobalStore.MeshMiddleLTPitch.current[10] = 2;
      GlobalStore.MeshMiddleLTPitch.current[11] = 2;

      var parentMesh = task.loadedMeshes[0];

      task.loadedMeshes.map((mesh) => {
        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Type1_LT";
        } else {
          mesh.name = mesh.name + "_Type1_LT";
        }

        if(mesh.name.includes("boundingBox")){
          GlobalStore.LTBoundingBox.current = mesh;
          GlobalStore.LTBoundingBox.current.isVisible = false;
        }

        mesh.isVisible = false;
        mesh.isPickable = false;
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;
        if (material.name.includes("_roof_")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          //  GlobalStore.outerRoofMaterials.current.push(material);
        } else if (material.name.includes("walls")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          //  GlobalStore.outerWallsMaterials.current.push(material);
        } else if (material.name.includes("_Wainscot")) {
          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          material.metallic = 0.7;
          material.roughness = 0.7;
          //material.bumpTexture = panelBumpTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else {
          material.diffuseColor = new Color3.FromHexString(
            "#ffffff"
          ).toLinearSpace();
        }

        if (mesh.name.includes("END") || mesh.name.includes("SIDE")) {
          mesh.name = "_Type0_";
        }
        // else if(mesh.name.includes("walls_"))
        // {
        //     mesh.isVisible = false;
        //     mesh.isPickable = false;
        //     mesh.name=mesh.name+"_Type1_LT";
        // }
        // else
        // {
        // mesh.name=mesh.name+"_Type1_LT";
        // mesh.isVisible = false;
        // mesh.isPickable = false;
        // }

        if (mesh.name.includes("walls")) {
          mesh.isVisible = false;
        }

        GlobalStore.isLeftLeanActive.current[1] = "false";
        GlobalStore.isLeftLeanActive.current[2] = "false";
        GlobalStore.isLeftLeanActive.current[3] = "false";
        GlobalStore.isLeftLeanActive.current[4] = "false";
        GlobalStore.isLeftLeanActive.current[6] = "false";
        GlobalStore.isLeftLeanActive.current[7] = "false";
        GlobalStore.isLeftLeanActive.current[8] = "true";
        GlobalStore.isLeftLeanActive.current[9] = "true";
        GlobalStore.isLeftLeanActive.current[10] = "true";
        GlobalStore.isLeftLeanActive.current[11] = "true";

        // mesh.position=new Vector3(mesh.posi10ion.x,mesh.position.y+13,mesh.position.z)
        //  mesh.scaling = new Vector3(mesh.sca10ing.x/7, mesh.scaling.y/7, mesh.scaling.z/7); 10
        GlobalStore.MeshLTPos.current[1] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[1] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );

        GlobalStore.MeshLTPos.current[2] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[2] = new Vector3(
          mesh.scaling.x / 6,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );

        GlobalStore.MeshLTPos.current[3] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[3] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 10
        );

        GlobalStore.MeshLTPos.current[4] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[4] = new Vector3(
          mesh.scaling.x / 8,
          mesh.scaling.y / 7,
          mesh.scaling.z / 12
        );
        GlobalStore.MeshLTPos.current[5] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[5] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshLTPos.current[6] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[6] = new Vector3(
          mesh.scaling.x / 10,
          mesh.scaling.y / 7,
          mesh.scaling.z / 10
        );
        GlobalStore.MeshLTPos.current[7] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[7] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );

        GlobalStore.MeshLTPos.current[8] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[8] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );

        GlobalStore.MeshLTPos.current[9] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[9] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshLTPos.current[10] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[10] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
        GlobalStore.MeshLTPos.current[11] = new Vector3(
          mesh.position.x,
          mesh.position.y + 13,
          mesh.position.z
        );
        GlobalStore.MeshLTScale.current[11] = new Vector3(
          mesh.scaling.x / 7,
          mesh.scaling.y / 7,
          mesh.scaling.z / 7
        );
      });
      GlobalStore.MeshLT.current[1] = parentMesh;
    };
    standardCarportRightLeanToTask.onSuccess = (task) => {
      GlobalStore.MeshMiddleRTPitch.current[1] = 2; //0 - low, 1- mid, 2 -high
      GlobalStore.MeshMiddleRTPitch.current[2] = 2;
      GlobalStore.MeshMiddleRTPitch.current[3] = 2;
      GlobalStore.MeshMiddleRTPitch.current[4] = 2;
      GlobalStore.MeshMiddleRTPitch.current[5] = 2;
      GlobalStore.MeshMiddleRTPitch.current[6] = 2;
      GlobalStore.MeshMiddleRTPitch.current[7] = 2;
      GlobalStore.MeshMiddleRTPitch.current[8] = 2;
      GlobalStore.MeshMiddleRTPitch.current[9] = 2;
      GlobalStore.MeshMiddleRTPitch.current[10] = 2;
      GlobalStore.MeshMiddleRTPitch.current[11] = 2;

      var parentMesh = task.loadedMeshes[0];
      task.loadedMeshes.map((mesh) => {
        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Type1_RT";
          mesh.isPickable = false;
        } else {
          mesh.name = mesh.name + "_Type1_RT";
          mesh.isPickable = false;
        }

        if(mesh.name.includes("boundingBox")){
          GlobalStore.RTBoundingBox.current = mesh;
          GlobalStore.RTBoundingBox.current.isVisible = false;
        }
        mesh.isVisible = false;
        mesh.isPickable = false;
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);

        var material = mesh.material;
        if (material.name.includes("_roof_")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          // GlobalStore.outerRoofMaterials.current.push(material);
        } else if (material.name.includes("walls")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          material.metallic = 0;
          material.roughness = 1;
          material.bumpTexture = NormalMapWallTexture;
          //  GlobalStore.outerWallsMaterials.current.push(material);
        } else if (material.name.includes("_Wainscot")) {
          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          material.metallic = 0.7;
          material.roughness = 0.7;
          //material.bumpTexture = panelBumpTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else {
          material.diffuseColor = new Color3.FromHexString(
            "#ffffff"
          ).toLinearSpace();
          // GlobalStore.outerTrimMaterials.current.push(material);
        }

        if (mesh.name.includes("END") || mesh.name.includes("SIDE")) {
          mesh.name = "_Type0_";
          mesh.setEnabled = false;
        }

        GlobalStore.isRightLeanActive.current[1] = "false";
        GlobalStore.isRightLeanActive.current[2] = "false";
        GlobalStore.isRightLeanActive.current[3] = "false";
        GlobalStore.isRightLeanActive.current[4] = "false";
        GlobalStore.isRightLeanActive.current[6] = "false";
        GlobalStore.isRightLeanActive.current[7] = "false";
        GlobalStore.isRightLeanActive.current[8] = "true";
        GlobalStore.isRightLeanActive.current[9] = "true";
        GlobalStore.isRightLeanActive.current[10] = "true";
        GlobalStore.isRightLeanActive.current[11] = "false";

        // mesh.position=new Vector3(mesh.position.x,mesh.position.y+13,mesh.position.z)
      });
      GlobalStore.MeshRT.current[1] = parentMesh;
    };

    standardBarnA.onSuccess = (task) => {
      var parentMesh = task.loadedMeshes[0];
      GlobalStore.MeshMain.current[8] = parentMesh;

      task.loadedMeshes.map((mesh) => {
        if (mesh.name != parentMesh.name) {
          mesh.parent = parentMesh;
          mesh.name = mesh.name + "_Type8_";
        } else {
          mesh.name = mesh.name + "_Type8_";
        }
        mesh.isVisible = false;
        mesh.material = new StandardMaterial(mesh.name + "_M", scene);
        var material = mesh.material;
        if (material.name.includes("Garage__roof")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          material.metallic = 0.7;
          material.roughness = 0.7;
          //material.bumpTexture = panelBumpTexture;
          //  GlobalStore.outerRoofMaterials.current.push(material);
        } else if (material.name.includes("walls")) {
          //main roof

          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          material.metallic = 0.7;
          material.roughness = 0.7;
          //material.bumpTexture = panelBumpTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else if (material.name.includes("_Wainscot")) {
          material.diffuseColor = new Color3.FromHexString(
            "#922222"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          material.metallic = 0.7;
          material.roughness = 0.7;
          //material.bumpTexture = panelBumpTexture;
          // GlobalStore.outerWallsMaterials.current.push(material);
        } else {
          material.diffuseColor = new Color3.FromHexString(
            "#ffffff"
          ).toLinearSpace();
          //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
          // material.reflectionTexture = envTexture;
          material.metallic = 1;
          material.roughness = 1;
          //material.bumpTexture = panelBumpTexture;
          // GlobalStore.outerTrimMaterials.current.push(material);
        }
      });
    };
    assetsManager.load();

    const pointerDragBehaviorX = new PointerDragBehavior({
      dragAxis: new Vector3(1, 0, 0),
    });
    const pointerDragBehaviorZ = new PointerDragBehavior({
      dragAxis: new Vector3(0, 0, 1),
    });

    const pointerDragBehaviorYZ = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(1, 0, 0),
    });
    const pointerDragBehaviorXY = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(0, 0, 1),
    });

    // Use drag plane in world space
    pointerDragBehaviorX.useObjectOrientationForDragging = false;
    pointerDragBehaviorZ.useObjectOrientationForDragging = false;
    pointerDragBehaviorYZ.useObjectOrientationForDragging = false;
    pointerDragBehaviorXY.useObjectOrientationForDragging = false;

    scene.onPointerObservable.add((pointerInfo) => {
      let pickedMesh;

      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERDOWN:
          if (pointerInfo.pickInfo.hit) {
            pickedMesh = pointerInfo.pickInfo.pickedMesh.parent;

            /*
                        try {
                            if (pickedMeshParentName.indexOf("front") > -1 || pickedMeshParentName.indexOf("back") > -1) {
                                if (pickedMesh.name.indexOf("window") > -1) {
                                    pickedMesh.removeBehavior(pointerDragBehaviorYZ);
                                    pickedMesh.addBehavior(pointerDragBehaviorYZ);
                                }
                                else {
                                    pickedMesh.removeBehavior(pointerDragBehaviorZ);
                                    pickedMesh.addBehavior(pointerDragBehaviorZ);
                                }
                            }
                            else {
                                if (pickedMesh.name.indexOf("window") > -1) {
                                    pickedMesh.removeBehavior(pointerDragBehaviorXY);
                                    pickedMesh.addBehavior(pointerDragBehaviorXY);
                                }
                                else {
                                    pickedMesh.removeBehavior(pointerDragBehaviorX);
                                    pickedMesh.addBehavior(pointerDragBehaviorX);
                                }
                            }
                        }
                        catch (e) {
                            console.log("pickedMesh error :" + e)
                        } */
          }
          break;
      }
      //  Engine.setDepthFunction(Engine.ALWAYS);
    });
    // scene.setRenderingAutoClearDepthStencil(0, false);

    scene.executeWhenReady(() => {
      scene.setRenderingAutoClearDepthStencil(0, true, true, true);
      scene.setRenderingAutoClearDepthStencil(1, true, true, true);
      scene.setRenderingAutoClearDepthStencil(2, true, true, true);
      scene.setRenderingAutoClearDepthStencil(3, true, true, true);
      // //get necesery meshes
      // const mainParent_R = scene.getMeshByName("Main_Helper_R");
      // const mainParent_L = scene.getMeshByName("Main_Helper_L");
      // const mainParent_R_M = scene.getMeshByName("Main_Helper_R_morph0");
      // const mainParent_L_M = scene.getMeshByName("Main_Helper_L_morph0");
      // const rightLeanParent = scene.getMeshByName("Lean_Right_Helper");
      // const rightLeanParent_M = scene.getMeshByName("Lean_Right_Helper_morph0");
      // const leftLeanParent = scene.getMeshByName("Lean_Left_Helper");
      // const leftLeanParent_M = scene.getMeshByName("Lean_Left_Helper_morph0");
      // const ground = scene.getMeshByName("Cylinder001");
      // const dimensionHelper_M = scene.getMeshByName("dimensionHelper_M");
      // const dimensionHelper_R = scene.getMeshByName("dimensionHelper_R");
      // const dimensionHelper_L = scene.getMeshByName("dimensionHelper_L");

      // ground.computeWorldMatrix(true);
      // const groundMax = ground.getBoundingInfo().boundingBox.maximumWorld.y;
      // const clipPlaneWholeScene = new Plane(0, -1, 0, groundMax);
      // scene.meshes.map(mesh => {
      //     mesh.onBeforeRenderObservable.add(function () {
      //         scene.resetCachedMaterial();
      //         scene.clipPlane3 = clipPlaneWholeScene;
      //     });
      //     mesh.onAfterRenderObservable.add(function () {
      //         scene.clipPlane3 = null;
      //     });
      // })

      // GlobalStore.dimensionHelper_M_scalingZ.current = dimensionHelper_M.scaling.z * 10;
      // dimensionHelper_M.isVisible = false;
      // //setMaterials
      setMaterials(scene);
      // //define morphTargets for meshes
      // //MAIN PARENT RIGHT
      // for (const mainMesh of mainParent_R.getChildMeshes()) {
      //     for (const morphMesh of mainParent_R_M.getChildMeshes()) {
      //         const morphMeshName = morphMesh.name.substring(0, morphMesh.name.length - 7);
      //         if (mainMesh.name.includes(morphMeshName)) {
      //             let morphManager = new MorphTargetManager();
      //             mainMesh.morphTargetManager = morphManager;
      //             let target = MorphTarget.FromMesh(morphMesh, "target", GlobalStore.mainMorphLevel.current);
      //             morphManager.addTarget(target);
      //         }
      //     }
      // }
      // //MAIN PARENT LEFT
      // for (const mainMesh of mainParent_L.getChildMeshes()) {
      //     for (const morphMesh of mainParent_L_M.getChildMeshes()) {
      //         const morphMeshName = morphMesh.name.substring(0, morphMesh.name.length - 7);
      //         if (mainMesh.name.includes(morphMeshName)) {
      //             let morphManager = new MorphTargetManager();
      //             mainMesh.morphTargetManager = morphManager;
      //             let target = MorphTarget.FromMesh(morphMesh, "target", GlobalStore.mainMorphLevel.current);
      //             morphManager.addTarget(target);
      //         }
      //     }
      // }
      // //RIGHT LEAN
      // for (const rightLeanMesh of rightLeanParent.getChildMeshes()) {
      //     for (const morphMesh of rightLeanParent_M.getChildMeshes()) {
      //         const morphMeshName = morphMesh.name.substring(0, morphMesh.name.length - 7);
      //         if (rightLeanMesh.name.includes(morphMeshName)) {
      //             let morphManager = new MorphTargetManager();
      //             rightLeanMesh.morphTargetManager = morphManager;
      //             let target = MorphTarget.FromMesh(morphMesh, "target", GlobalStore.mainMorphLevel.current);
      //             morphManager.addTarget(target);
      //         }
      //     }
      // }
      // //LEFT LEAN
      // for (const leftLeanMesh of leftLeanParent.getChildMeshes()) {
      //     for (const morphMesh of leftLeanParent_M.getChildMeshes()) {
      //         const morphMeshName = morphMesh.name.substring(0, morphMesh.name.length - 7);
      //         if (leftLeanMesh.name.includes(morphMeshName)) {
      //             let morphManager = new MorphTargetManager();
      //             leftLeanMesh.morphTargetManager = morphManager;
      //             let target = MorphTarget.FromMesh(morphMesh, "target", GlobalStore.mainMorphLevel.current);
      //             morphManager.addTarget(target);
      //         }
      //     }
      // }

      //createReference planes (highlighted invisible planes which represents the part of the warehouse that we are looking at)
      const planeMat = new StandardMaterial("planeMat", scene);
      planeMat.alpha = 0;
      planeMat.transparencyMode = 3;
      ////main structure planes
      // createReferencePlanes(dimensionHelper_M, null, "front", ground, planeMat, GlobalStore, scene)
      // createReferencePlanes(dimensionHelper_M, null, "back", ground, planeMat, GlobalStore, scene)
      // createReferencePlanes(dimensionHelper_M, null, "left", ground, planeMat, GlobalStore, scene)
      // createReferencePlanes(dimensionHelper_M, null, "right", ground, planeMat, GlobalStore, scene)
      // //left lean planes
      // createReferencePlanes(dimensionHelper_L, mainParent_L, "front_L", ground, planeMat, GlobalStore, scene)
      // createReferencePlanes(dimensionHelper_L, mainParent_L, "back_L", ground, planeMat, GlobalStore, scene)
      // createReferencePlanes(dimensionHelper_L, null, "left_L", ground, planeMat, GlobalStore, scene)
      // // //right lean planes
      // createReferencePlanes(dimensionHelper_R, mainParent_R, "front_R", ground, planeMat, GlobalStore, scene)
      // createReferencePlanes(dimensionHelper_R, mainParent_R, "back_R", ground, planeMat, GlobalStore, scene)
      // createReferencePlanes(dimensionHelper_R, null, "right_R", ground, planeMat, GlobalStore, scene)

      //push wainscot meshes to the global variable
      scene.meshes.map((mesh) => {
        if (mesh.name.indexOf("wainscot") > -1) {
          mesh.isVisible = false;
          mesh.material = scene.getMaterialByName("wainscotMaterial");
          GlobalStore.wainscotMeshes.current.push(mesh);
        }
      });
      scene.meshes.map((mesh) => {
        if (mesh.material != null) {
          //new StandardMaterial("myMaterial", scene);
        }
        // if (mesh.name.indexOf("wainscot") > -1) {
        //     mesh.isVisible = false;
        //     mesh.material = scene.getMaterialByName("wainscotMaterial");
        //     GlobalStore.wainscotMeshes.current.push(mesh);
        // }
      });

      // (GlobalStore, scene, "global");

      // each frame calculate
      scene.registerBeforeRender(() => {
        try {
          //look for the closest reference plane that we look at
          let distances = [];
          let planes = [];
          GlobalStore.cameraDistanceReferencePlanes.current.map((plane) => {
            if (plane.partOf === "main") {
              distances.push(
                Vector3.Distance(scene.activeCamera.position, plane.position)
              );
              planes.push(plane);
            } else if (
              plane.partOf === "leftLean" &&
              GlobalStore.isLeftLeanActive.current
            ) {
              distances.push(
                Vector3.Distance(scene.activeCamera.position, plane.position)
              );
              planes.push(plane);
            } else if (
              plane.partOf === "rightLean" &&
              GlobalStore.isRightLeanActive.current
            ) {
              distances.push(
                Vector3.Distance(scene.activeCamera.position, plane.position)
              );
              planes.push(plane);
            }
            plane.isActivePlane = false;
            plane.showBoundingBox = false;
          });
          const min = Math.min(...distances);
          const indexOfMin = distances.indexOf(min);
          const closestPlane = planes[indexOfMin];
          closestPlane.isActivePlane = true;
          closestPlane.showBoundingBox = true;
        } catch (e) {
          // console.log("there are no reference frames")
        }
      });

      GlobalStore.setIsLoading(false); // imp
    });
    //  var rendererGlobe = scene.enableDepthRenderer();
    //  var mat = new StandardMaterial("mat01", scene);
    //  mat.emissiveTexture = rendererGlobe.getDepthMap();
    //  scene.disableDepthRender();
  };

  const setCamera = (camera) => {
    GlobalStore.ArcCamera.current = camera;
    console.error(camera);
  };

  const setMaterials = (scene) => {
    const materials = scene.materials;
    const envTexture = new CubeTexture(
      "./babylon_assets/textures/env.env",
      scene,
      1024
    );
    const lineMaterial = new StandardMaterial("lineMaterial", scene);
    const panelBumpTexture = new Texture(
      "./babylon_assets/textures/wall_normal.jpg",
      scene
    );
    lineMaterial.emissiveColor = new Color3.White();

    for (const material of materials) {
      //         if(material.stencil != null)
      //         {
      //         material.stencil.enabled = true;
      //    material.stencil.opStencilDepthPass =Engine.DECR;
      //     material.stencil.func = Engine.EQUAL;
      //     material.stencil.funcRef = 2;
      //         }
      //  material.disableDepthWrite = true;
      //  if (material.name.includes("Ridge")) {
      //     material.albedoColor = new Color3(1, 1, 1).toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0.45;
      //     material.roughness = 0.69;
      //     GlobalStore.outerTrimMaterials.current.push(material);
      // }
      // else if (material.name.includes("__frame_")) {
      //     material.albedoColor = new Color3(1, 1, 1).toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0.45;
      //     material.roughness = 0.69;
      //     GlobalStore.outerTrimMaterials.current.push(material);
      // }
      // else if (material.name.includes("Profile")) {
      //     material.albedoColor = new Color3(1, 1, 1).toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0.45;
      //     material.roughness = 0.69;
      //     GlobalStore.outerTrimMaterials.current.push(material);
      // }
      // else if (material.name.includes("Roof_profile")) {
      //     material.albedoColor = new Color3(1, 1, 1).toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0.45;
      //     material.roughness = 0.69;
      //     GlobalStore.outerTrimMaterials.current.push(material);
      // }
      // else if (material.name.includes("default material")) {   //not changing
      //     material.albedoColor = new Color3(1, 1, 1).toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0.45;
      //     material.roughness = 0.69;
      //     GlobalStore.outerTrimMaterials.current.push(material);
      // }
      // else if (material.name.includes("Garage__roof")) {   //main roof
      //   //  console.warn("~~["+material+"]~~");
      //     material.diffuseColor  =  new Color3.FromHexString("#6f2026").toLinearSpace();
      //     material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
      //    // material.reflectionTexture = envTexture;
      //     material.metallic = 0;
      //     material.roughness = 1;
      //    // material.bumpTexture = panelBumpTexture;
      //     GlobalStore.outerRoofMaterials.current.push(material);
      // }
      // else if (material.name.includes("default material")) {   //main roof
      //     material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0;
      //     material.roughness = 1;
      //    // material.bumpTexture = panelBumpTexture;
      //     GlobalStore.outerRoofMaterials.current.push(material);
      // }
      // else if (material.name.includes("_inner_roof_")) {   //main roof
      //     material.diffuseColor  =  new Color3.FromHexString("#6f2026").toLinearSpace();
      //     material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
      //     //material.reflectionTexture = envTexture;
      //     //material.metallic = 0.7;
      //     material.roughness = 0.7;
      //    // material.bumpTexture = panelBumpTexture;
      //     GlobalStore.outerRoofMaterials.current.push(material);
      // }
      ///
      //outer materials (always white ??so far??)
      // else if (material.name.includes("innerWalls")) {
      //     material.albedoColor = new Color3(0.8, 0.8, 0.8).toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0.1;
      //     material.roughness = 1;
      //     material.zOffset = -0.6;
      // }
      // else if (material.name.includes("innerRoof")) {
      //     material.albedoColor = new Color3(0.8, 0.8, 0.8).toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0.1;
      //     material.roughness = 1;
      // }
      // //innerMaterials
      // else if (material.name.includes("outerWalls")) {
      //     material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0.7;
      //     material.roughness = 0.7;
      //     material.bumpTexture = panelBumpTexture
      //     material.backFaceCulling = false;
      //     GlobalStore.outerWallsMaterials.current.push(material);
      // }
      // else if (material.name.includes("outerRoof")) {
      //     material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0.7;
      //     material.roughness = 0.7;
      //     material.bumpTexture = panelBumpTexture
      //     GlobalStore.outerRoofMaterials.current.push(material);
      // }
      // else if (material.name.includes("frames")) {
      //     material.albedoColor = new Color3(1, 1, 1).toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0.45;
      //     material.roughness = 0.69;
      //     GlobalStore.outerTrimMaterials.current.push(material);
      // }
      // else if (material.name === "doorsMaterial") {
      //     material.albedoColor = new Color3(0.8, 0.8, 0.8).toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0;
      //     material.roughness = 0.8;
      // }
      // else if (material.name === "windowGlassMaterial") {
      //     material.albedoColor = new Color3(0.4, 0.4, 0.4).toLinearSpace();
      //     material.reflectionTexture = envTexture;
      //     material.metallic = 0;
      //     material.roughness = 0.8;
      // }
      // else if (material.name === "framedOpeningsAlphaMaterial") {
      //     material.diffuseColor = new Color3(0, 0, 0).toLinearSpace();
      //     material.backFaceCulling = false;
      // }
    }
    const skybox = MeshBuilder.CreateBox("skyBox", { size: 2000.0 }, scene);
    skybox.position.y += 200;
    const skyboxMaterial = new StandardMaterial("skyBox", scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.reflectionTexture = new CubeTexture(
      "./babylon_assets/textures/sky/sky3/sky3",
      scene
    );
    skyboxMaterial.reflectionTexture.rotationY = 1.89;
    skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
    skyboxMaterial.specularColor = new Color3(0, 0, 0);
    skybox.material = skyboxMaterial;
    skybox.isPickable = false;
    skybox.position.y -= 200;
    const wainscotMaterial = new PBRMaterial("wainscotMaterial", scene);
    wainscotMaterial.albedoColor = new Color3.FromHexString(
      "#6f2026"
    ).toLinearSpace();
    wainscotMaterial.reflectionTexture = envTexture;
    wainscotMaterial.metallic = 0.7;
    wainscotMaterial.roughness = 0.7;
    wainscotMaterial.bumpTexture = panelBumpTexture;
    wainscotMaterial.zOffset = -1;
  };

  return (
    <CanvasWrapper>
      {/* <div dangerouslySetInnerHTML={{ __html: <IFrame src="localhost:3000"/>}} /> */}
      <Engine
        canvasId="renderCanvas"
        canvasStyle={{ visibility: canvasStyle }}
        antialias={true}
        adaptToDeviceRatio={true}
        engineOptions={{ preserveDrawingBuffer: true, stencil: true }}
      >
        <Scene id="renderCanvas" onSceneMount={(scene) => setScene(scene)}>
          <arcRotateCamera
            name="arcRotateCamera"
            alpha={0}
            beta={1.3}
            radius={GlobalStore.cameraRadius}
            target={new Vector3(0, 20, 0)}
            lowerRadiusLimit={1}
            upperRadiusLimit={400}
            lowerBetaLimit={0.001}
            upperBetaLimit={1.5}
            inertia={0.6}
            useAutoRotationBehavior={GlobalStore.cameraRotating}
            wheelPrecision={1.2}
            pinchPrecision={1.2}
            panningSensibility={100}
            onCreated={(camera) => setCamera(camera)}
          ></arcRotateCamera>
          <hemisphericLight
            name="hemisphericLight"
            specular={new Color3.Black()}
            groundColor={new Color3(0.8, 0.8, 0.8)}
            intensity={1}
          ></hemisphericLight>
        </Scene>
      </Engine>
    </CanvasWrapper>
  );
};

export default Canvas;
