import React, {useContext, useEffect, useState} from "react";
import {CardDiv, SummaryWrapper, WallsScrollbarWrapper} from "./Summary.css";
import {GlobalState} from '../../../App';
import buildingTypesData from '../../BuildingType/BuildingTypes.json';
// import ExtraOptions from '../ExtraOptions';

const Summary = () => {

	
	
	// handleInteriorAnchor();
	const GlobalStore = useContext(GlobalState);
	let leftLeanDiv, rightLeanDiv;
	if(GlobalStore.isLeftLeanActive.current[GlobalStore.buildingType.id] == "true"){
		leftLeanDiv = <div><h4>Left Lean-to</h4><br/>
				<CardDiv>
					Size(width x length): {GlobalStore.leanLWidth} x {GlobalStore.leanLLength}
					<br/>Height: {GlobalStore.leanLHeight}
					<br/>Lean to Roof pitch: {GlobalStore.leanLRoofPitch}
					<br/> Wind and Snow: {GlobalStore.windSnowChange ? 'Built to local code' : '140 mph + 30 PSF certified'}
				</CardDiv>
				<br/></div>
	}
	if(GlobalStore.isRightLeanActive.current[GlobalStore.buildingType.id] == "true"){
		rightLeanDiv = <div><h4>Right Lean-to</h4><br/>
				<CardDiv>
					Size(width x length): {GlobalStore.leanRWidth} x {GlobalStore.leanRLength}
					<br/>Height: {GlobalStore.leanRHeight}
					<br/>Lean to Roof pitch: {GlobalStore.leanRRoofPitch}
					<br/> Wind and Snow: {GlobalStore.windSnowChange ? 'Built to local code' : '140 mph + 30 PSF certified'}
				</CardDiv>
				<br/></div>
	}
	return (
		<WallsScrollbarWrapper>
			<SummaryWrapper>Summary</SummaryWrapper>
			<SummaryWrapper><h5>Actual prices may change based on regional permits and certifications upon inspection.</h5>

				<CardDiv>Building Type: {buildingTypesData[GlobalStore.buildingType.id -1].productName}<br/>
				</CardDiv>

				<h4>Building Dimensions:</h4><br/>
				<CardDiv>
					Size(width x length): {GlobalStore.mainWidth} x {GlobalStore.mainLength}
					<br/>Height: {GlobalStore.mainHeight}
					<br/>Roof pitch: {GlobalStore.mainRoofPitch}
					<br/> Gauze: {GlobalStore.upgradeGauge ? '12 Gauge' : '14 Gauge'}
					{GlobalStore.isSideCrossBracing && <span><br/>Side cross bracing: Yes</span>}
					{GlobalStore.isEndCrossBracing && <span><br/>End cross bracing: Yes</span>}
					<br/> Wind and Snow: {GlobalStore.windSnowChange ? 'Built to local code' : '140 mph + 30 PSF certified'}
				</CardDiv>
				<br/>

				{leftLeanDiv}
				{rightLeanDiv}

				<h4>Sides and Ends</h4>
				<CardDiv>
					Left Wall: {GlobalStore.IsWallLTOpen.current[GlobalStore.buildingType.id] ? 'Open' : ' Fully Enclosed'}
					<br/>Center Wall: {GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id] ? 'Open' : 'Fully Enclosed'}
					<br/>Right Wall: {GlobalStore.IsWallRTOpen.current[GlobalStore.buildingType.id] ? 'Open' : 'Fully Enclosed'}
				</CardDiv>

				<h4>Components</h4>
				<CardDiv>
					{/* window, side, dimensions */}
					{GlobalStore.dwSummary.map((data, key)=><span>{key+1}: {data.summary.type}, {data.summary.side}({data.summary.width}x{data.summary.height})<br/></span>)}
				</CardDiv>
				<h4>Colors</h4>
				<CardDiv>
					Roof: {GlobalStore.roofColor}
					<br/>Trim: {GlobalStore.trimColor}
					<br/>Wall: {GlobalStore.wallsColor}
					<br/>Wainscot: {GlobalStore.wainscotColor}
				</CardDiv>

				<h4>Extra Options:</h4>
				<CardDiv>
					{GlobalStore.gaugePanelUpgrade && <span>Gauze panel upgrade: Yes<br/></span>}
					{GlobalStore.interiorAnchor && <span>Interior Anchor: Yes<br/></span>}
					{GlobalStore.extraBows && <span>Extra bows: Yes<br/></span>}
					{GlobalStore.concreteAnchor && <span>concrete anchor: Yes<br/></span>}
					{GlobalStore.mobileHomeAnchor && <span>Mobile home anchor: Yes<br/></span>}
					{GlobalStore.asphaltAnchor && <span>asphalt anchor: Yes<br/></span>}
					{GlobalStore.tempRebarAnchor && <span>Temporary rebar anchor: Yes<br/></span>}
					{GlobalStore.basePlates && <span>Base plates: Yes<br/></span>}
					{GlobalStore.rebarAnchor && <span>Rebar Anchor: Yes<br/></span>}
					{GlobalStore.lagBolt && <span>Lag bolt: Yes<br/></span>}
					{GlobalStore.extraTrusses && <span>Extra trusses: Yes<br/></span>}
					{GlobalStore.singleBubble && <span>Single bubble: Yes<br/></span>}
					{GlobalStore.doubleBubble && <span>Double bubble: Yes<br/></span>}
					{GlobalStore.fiberGlass2In && <span>Fiber glass 2 in: Yes<br/></span>}
				</CardDiv>

			</SummaryWrapper>
		</WallsScrollbarWrapper>
	);
};

export default Summary;
