import React, { useContext, useState, useEffect } from "react";
import {
  WallsScrollbarWrapper,
  WallsWrapper,
  WallsSelectWrapper,
  WallsWainscot,
  WallsWainscotColorWrapper,
} from "../Walls.css";

import InputSelect from "../../parts/InputSelect/InputSelect";
import SectionTitle from "../../../SectionTitle/SectionTitle";
import Separator from "../../../../utils/Separator/Separator";
import ColorPicker from "../../parts/ColorPicker/ColorPicker";
import { colorsOptionsPremium } from "../../parts/ColorPicker/ColorPickerData.json";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Color3, Plane, Axis, Space, Vector3, Scalar} from "@babylonjs/core";
import { GlobalState } from "../../../../App";
import {
  getBoundingBox
} from "../../../../utils/functions";

const storageOptions = [
  { value: "5", label: "5 Feet" },
  { value: "10", label: "10 Feet" },
  { value: "15", label: "15 Feet" },
];

const sidesOptions = [
  { value: "Open", label: "Open" },
  // { value: "1 Panel (3')", label: "1 Panel (3')" },
  // { value: "2 Panel (6')", label: "2 Panel (6')" },
  // { value: "1/4 Closed", label: "1/4 Closed" },
  // { value: "1/2 Closed", label: "1/2 Closed" },
  // { value: "3/4 Closed", label: "3/4 Closed" },
  { value: "Closed", label: "Closed" },
];

const endsOptions = [
  { value: "Open", label: "Open" },
  // { value: "Gable", label: "Gable" },
  // { value: "1/4 Closed", label: "1/4 Closed" },
  // { value: "1/2 Closed", label: "1/2 Closed" },
  // { value: "3/4 Closed", label: "3/4 Closed" },
  { value: "Closed", label: "Closed" },
];

const insulationOptions = [
  { value: "Select", label: "Select" },
  { value: "2-fiber-glass", label: "2'' Fiber Glass" },
];

var j = 0;
var XScaleCenter = [];
var XPosCenter = [];
var ScaleMesh = [];
var pitchMain;

const WallsCenter = () => {
  // var indexB=0,indexR=0,indexL=0;
  const GlobalStore = useContext(GlobalState);
  const scene = GlobalStore.sceneGlobalRef.current;
  const wainscotMaterial = scene.getMaterialByName("wainscotMaterial");

  //const [verticalPanels, setVetricalPanels] = useState(false);
  //console.log("Is Storage Center on "+GlobalStore.isStorageOn)

  // const [leftSidePanel, setLeftSidePanel] = useState(false);
  // const [rightSidePanel, setRightSidePanel] = useState(false);

  const [insulationValue, setInsulationValue] = useState("Select");
  const [insulationCheckboxes, setInsulationCheckboxes] = useState(false);

  const [insulationRadio, setInsulationRadio] = useState("roof-only");

  const [wall, setWall] = useState("open");

  // const [jTrim, setJTrim] = useState(false);

  const [wainscot, setWainscot] = useState(false);

  const presetData = GlobalStore.buildingType.customizationData.mainStructure;
  // for(var i=0;)
  useEffect(() => {
    XScaleCenter = [];
    ScaleMesh = [];
    XPosCenter = [];
    j = 0;

    pitchMain =
      GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleMainPitch.current[
            GlobalStore.buildingType.id
          ] == 1
        ? "Normal"
        : "UP";

    // isWallOpen = GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id];
    // console.log('isWallOpen'+isWallOpen);

    if (!GlobalStore.isWallCenterInit.current) {
      if (GlobalStore.buildingType.id != 8) {
        // GlobalStore.StorageMeshMain.current[1].bakeCurrentTransformIntoVertices();
        //var scalingFactor = GlobalStore.MeshStorageScale.current[GlobalStore.buildingType.id];
        //GlobalStore.StorageMeshMain.current[1].scaling = scalingFactor;
        // GlobalStore.StorageMeshMain.current[1].position.multiplyInPlace(scalingFactor);
        GlobalStore.StorageMeshMain.current[1].parent =
          GlobalStore.MeshMain.current[1];
        // GlobalStore.StorageMeshMain.current[1].computeWorldMatrix(true);
        var C =
          GlobalStore.MeshStorageScale.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshMain.current[1].parent.scaling;
        GlobalStore.StorageMeshMain.current[1].scaling = new Vector3(
          1 / 6,
          1,
          1
        ); //og/pa

        var C = GlobalStore.MeshStoragePos.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshMain.current[1].parent.position;
        GlobalStore.StorageMeshMain.current[1].position = new Vector3(
          (C.x - A.x) * 7,
          (C.y - A.y) * 7,
          (C.z - A.z) * 7
        );

       /* alert(
          `These are the positions ${(C.x - A.x) * 7}, ${(C.y - A.y) * 7}, ${
            (C.z - A.z) * 7
          }`
        );*/
        //GlobalStore.StorageMeshMain.current[1].setAbsolutePosition();
        // GlobalStore.StorageMeshMain.current[1].scaling = Vector3.One().divide(GlobalStore.StorageMeshMain.current[1].parent.absoluteScaling);
        // console.error("A_["+ GlobalStore.StorageMeshMain.current[1].parent.scaling+"]");
        console.error(
          "B_[" + GlobalStore.StorageMeshMain.current[1].scaling + "]"
        );

        GlobalStore.isWallCenterInit.current = true;
        // GlobalStore.StorageMeshMain.current[8].setEnabled(false);
      } else {
        GlobalStore.StorageMeshMain.current[8].parent =
          GlobalStore.MeshMain.current[8];
        var C =
          GlobalStore.MeshStorageScale.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshMain.current[8].parent.scaling;
        GlobalStore.StorageMeshMain.current[8].scaling = new Vector3(
          1 / 6,
          1,
          0.95
        ); //og/pa

        var C = GlobalStore.MeshStoragePos.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshMain.current[8].parent.position;
        GlobalStore.StorageMeshMain.current[8].position = new Vector3(
          (C.x - A.x) * 7,
          (C.y - A.y) * 7,
          (C.z - A.z) * 7
        );
        //GlobalStore.StorageMeshMain.current[8].setAbsolutePosition();
        console.error(
          "B_[" + GlobalStore.StorageMeshMain.current[8].scaling + "]"
        );
        GlobalStore.isWallCenterInit.current = true;
      }
    }

    if (GlobalStore.buildingType.id != 8) {
      ScaleMesh.push(GlobalStore.StorageMeshMain.current[1]);
      //if(Scale.length<=0)

      GlobalStore.StorageMeshMain.current[1].getChildren().forEach((m) => {
        if (m.name.includes("Type1")) {
          if (m.name.includes(pitchMain)) {
            m.isVisible = true;
          } else if (
            m.name.includes("Down") ||
            m.name.includes("Normal") ||
            m.name.includes("UP")
          ) {
            m.isVisible = false;
          } /*else {
              m.isVisible = GlobalStore.isStorageOn;
            }*/
          j++;
        }
      });
    } else {
      ScaleMesh.push(GlobalStore.StorageMeshMain.current[8]);
      //if(Scale.length<=0)
      GlobalStore.StorageMeshMain.current[1].getChildren().forEach((m) => {
        if (m.name.includes("Type8")) {
          if (m.name.includes(pitchMain)) {
            m.isVisible = true;
          } else if (
            m.name.includes("Down") ||
            m.name.includes("Normal") ||
            m.name.includes("UP")
          ) {
            m.isVisible = false;
          }
          j++;
        }
      });
    }

    for (var i = 0; i < storageOptions.length; i = i + 1) {
      XScaleCenter.push(i + 1);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps

    switch (presetData.wallStyle) {
      case "open":
        handleFullyOpen();
       // handleWallChange(null, "open");
        console.log("handling fully open");
        break;
      case "enclosed":
        handleFullyEnclosed();
        //handleWallChange(null, "enclosed");
        console.log("handling fully closed");
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    setWainscot(false);
  }, [wall]);




  useEffect(()=>{
    for (let i = 0; i < scene.meshes.length; i++) {
      let mesh = scene.meshes[i];
  
      if (
        !(
          mesh.name.includes("LT") ||
          mesh.name.includes("RT") ||
          mesh.name.includes("Storage")
        ) &&
        (mesh.name.includes("wall"))
      ) {
        // mesh.isVisible = false;
        if (mesh.name.includes("left")) {
          mesh.isVisible = GlobalStore.centerLeftWall === "open" ? false : true;
        } else if (mesh.name.includes("right")) {
          mesh.isVisible = GlobalStore.centerRightWall === "open" ? false : true;
        } if (mesh.name.includes("front")) {
          mesh.isVisible = GlobalStore.centerFrontWall === "open" ? false : true;
          if(!mesh.name.includes(pitchMain)){
            mesh.isVisible = false;
          }
        } 
        if(mesh.name.includes("back")) {
          mesh.isVisible=GlobalStore.centerBackWall === "open" ? false : true;
          if(!mesh.name.includes(pitchMain)){
            mesh.isVisible = false;
          }
        }
      }
    }
    GlobalStore.mainCartportBoundingBox.current.isVisible = false;
  })
  

  const handleWallChange = (e, i) => {
    i ? setWall(i) : setWall(e.target.value);
    //console.log("handleWallChange i;" + i);
    //console.log("handleWallChange e;" + e.target.value);

    switch (e.target.value) {
      case "open":
        GlobalStore.setCenterWalls("open")

        //code used to delete doors and windows
        let objectsToRemove = [
          ...GlobalStore.frontCenterWallObjects,
          ...GlobalStore.leftWallObjects,
          ...GlobalStore.rightWallObjects,
          ...GlobalStore.backCenterWallObjects,
        ];

        for (let i = 0; i < objectsToRemove.length; i++) {
          objectsToRemove[i].object.dispose();
        }
        GlobalStore.setFrontCenterWallObjects([]);
        GlobalStore.setLeftWallObjects([]);
        GlobalStore.setRightWallObjects([]);
        GlobalStore.setBackCenterWallObjects([]);

        break;
      case "enclosed":
        GlobalStore.setCenterWalls("enclosed")
        break;

      default:
        break;
    }
  };
  // const handleVerticalPanels = () => {
  //     setVetricalPanels(!verticalPanels)
  //     const wallMaterial_main = scene.getMaterialByName("outerWalls_M");
  //     const wallMaterial_leanR = scene.getMaterialByName("outerWalls_RL")
  //     const wallMaterial_leanL = scene.getMaterialByName("outerWalls_LL");
  //     const wainscotMaterial = scene.getMaterialByName("wainscotMaterial");
  //     if (!verticalPanels) {
  //         try {
  //             wallMaterial_main.bumpTexture.wAng = 1.57;
  //         } catch (error) {
  //             console.log("Error setting wallMaterial_main to vertical)")
  //         }
  //         try {
  //             wallMaterial_leanR.bumpTexture.wAng = 1.57;

  //         } catch (error) {
  //             console.log("Error setting wallMaterial_leanR to vertical)")
  //         }
  //         try {
  //             wallMaterial_leanL.bumpTexture.wAng = 1.57;

  //         } catch (error) {
  //             console.log("Error setting wallMaterial_leanL to vertical)")
  //         }
  //         try {
  //             wainscotMaterial.bumpTexture.wAng = 1.57;
  //         } catch (error) {
  //             console.log("Error setting wainscotMaterial to vertical)")
  //         }

  //     }
  //     else {
  //         try {
  //             wallMaterial_main.bumpTexture.wAng = 0;
  //         } catch (error) {
  //             console.log("Error setting wallMaterial_main to horizontal)")
  //         }
  //         try {
  //             wallMaterial_leanR.bumpTexture.wAng = 0;

  //         } catch (error) {
  //             console.log("Error setting wallMaterial_leanR to horizontal)")
  //         }
  //         try {
  //             wallMaterial_leanL.bumpTexture.wAng = 0;

  //         } catch (error) {
  //             console.log("Error setting wallMaterial_leanL to horizontal)")
  //         }
  //         try {
  //             wainscotMaterial.bumpTexture.wAng = 0;
  //         } catch (error) {
  //             console.log("Error setting wainscotMaterial to horizontal)")
  //         }
  //     }
  // }
  // const handleLeftVerticalPanel = () => {
  //     setLeftSidePanel(!leftSidePanel)
  // }
  // const handleRightVerticalPanel = () => {
  //     setRightSidePanel(!rightSidePanel)
  // }
  const handleInsulationChange = (e) => {
    setInsulationValue(e.target.value);
    if (e.target.value === "Select") {
      setInsulationCheckboxes(false);
      setInsulationRadio("roof-only");
    } else {
      setInsulationCheckboxes(true);
    }
  };
  const handleInsulationRadio = (event) => {
    setInsulationRadio(event.target.value);
  };
  // const handleJTrim = () => {
  //     setJTrim(!jTrim)
  // }
  const handleWainscot = () => {
    if (!wainscot) {
      for (var i = 0; i < scene.meshes.length; i++) {
        if (
          scene.meshes[i].name.includes("Type1") &&
          scene.meshes[i].name.includes("Wainscot")
        ) {
          {
            //  scene.meshes[i].isVisible=false;
            scene.meshes[i].material.diffuseColor = new Color3.FromHexString(
              "#ffffff"
            ).toLinearSpace();
            //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
            // material.reflectionTexture = envTexture;
            scene.meshes[i].material.metallic = 0.7;
            scene.meshes[i].material.roughness = 0.7;
          }
        }
      }
    } else {
      var wainscotMat;
      for (var i = 0; i < scene.meshes.length; i++) {
        if (
          scene.meshes[i].name.includes("Type1") &&
          scene.meshes[i].name.includes("walls")
        ) {
          wainscotMat = scene.meshes[i].material.diffuseColor;
          break;
        }
      }
      for (var i = 0; i < scene.meshes.length; i++) {
        if (
          scene.meshes[i].name.includes("Type1") &&
          scene.meshes[i].name.includes("Wainscot")
        ) {
          {
            //  scene.meshes[i].isVisible=false;

            scene.meshes[i].material.diffuseColor = wainscotMat;
            //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
            // material.reflectionTexture = envTexture;
            scene.meshes[i].material.metallic = 0.7;
            scene.meshes[i].material.roughness = 0.7;
          }
        }
      }
    }
    setWainscot(!wainscot);
    // wainscot ? GlobalStore.wainscotMeshes.current.map(mesh => mesh.isVisible = false) :
    //  GlobalStore.wainscotMeshes.current.map(mesh => mesh.isVisible = true)
  };
  const handleWainscotColor = (color) => {
    if (wainscot) {
      for (var i = 0; i < scene.meshes.length; i++) {
        if (
          scene.meshes[i].name.includes("Type1") &&
          scene.meshes[i].name.includes("Wainscot")
        ) {
          {
            //  scene.meshes[i].isVisible=false;

            scene.meshes[i].material.diffuseColor = new Color3.FromHexString(
              color.value
            ).toLinearSpace();
            //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
            // material.reflectionTexture = envTexture;
            scene.meshes[i].material.metallic = 0.7;
            scene.meshes[i].material.roughness = 0.7;
          }
        }
      }
    }

    //wainscotMaterial.albedoColor = new Color3.FromHexString(color.value).toLinearSpace();
  };
  const handleFullyOpen = () => {
    // handleEndsFront("Closed");
    GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id] = true;
    GlobalStore.setCenterLeftWall("open");
    GlobalStore.setCenterRightWall("open");
    GlobalStore.setCenterFrontWall("open");
    GlobalStore.setCenterBackWall("open");
    // isWallOpen = GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id];
    //console.log('isWallOpen handleFullyOpen '+isWallOpen);
    // GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id] = 'Open';
    //endsOptions1 = GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id];
    // console.log('isWallOpen handleFullyOpen '+isWallOpen);

    //handleEndsFront('open');
    // handleEndsBack('open');
    // SetWallOpen("open");
    // setWall('enclosed');
    // setWall('open');
    // SetindexF(indexF=0);
    // handleEndsFront('Open');

    //console.error(indexF);
    // SetWallClose("open");
    // WallOpen="open";
    //  WallClose="enclosed";
    // for(var i=0;i<scene.meshes.length;i++)
    // {

    // if(scene.meshes[i].name.includes("Type1")&&GlobalStore.buildingType.id!=8)
    // {
    //     if(

    //         !(scene.meshes[i].name.includes("_LT")||scene.meshes[i].name.includes("_RT")||scene.meshes[i].name.includes("Storage"))
    //          )
    //     {

    //         if(scene.meshes[i].name.includes("Wainscot")|| scene.meshes[i].name.includes("walls"))
    //         scene.meshes[i].isVisible=false;

    //     }
    // }
    // if(scene.meshes[i].name.includes("Type8")&&GlobalStore.buildingType.id==8)
    // {
    //     if(

    //         !(scene.meshes[i].name.includes("_LT")||scene.meshes[i].name.includes("_RT")||scene.meshes[i].name.includes("Storage"))
    //          )
    //     {

    //         if(scene.meshes[i].name.includes("Wainscot")|| scene.meshes[i].name.includes("walls"))
    //         scene.meshes[i].isVisible=false;

    //     }
    // }
    // if(scene.meshes[i].name.includes("Door"))
    // {

    //     {

    //         scene.meshes[i].isVisible=false;

    //     }
    // }

    // }

    if (GlobalStore.buildingType.id != 8) {
      //GlobalStore.StorageMeshMain.current[1].position=GlobalStore.MeshStoragePos.current[GlobalStore.buildingType.id];
      //  GlobalStore.StorageMeshMain.current[1].scaling=GlobalStore.MeshStorageScale.current[GlobalStore.buildingType.id];
      //ScaleMesh.push(GlobalStore.StorageMeshMain.current[1]);
      //if(Scale.length<=0)

      GlobalStore.MeshMain.current[1].getChildren().forEach((m) => {
        if (
          !(
            m.name.includes("_LT") ||
            m.name.includes("_RT") ||
            m.name.includes("Storage")
          )
        ) {
          if (m.name.includes("Wainscot") || m.name.includes("walls"))
            m.isVisible = false;
        }
      });
    } else {
      GlobalStore.MeshMain.current[8].getChildren().forEach((m) => {
        if (
          !(
            m.name.includes("_LT") ||
            m.name.includes("_RT") ||
            m.name.includes("Storage")
          )
        ) {
          if (m.name.includes("Wainscot") || m.name.includes("walls"))
            m.isVisible = false;
        }
      });
    }

    // GlobalStore.setPrice(GlobalStore.price - 1000);
    // GlobalStore.setSelectedEndFront("Open");
    // GlobalStore.setSelectedEndBack('Open');
    // GlobalStore.setSelectedSidesLeft('Open');
    // GlobalStore.setSelectedSidesRight('Open');
  };
  const handleFullyEnclosed = () => {
    //  handleEndsFront("Open");
    // handleEndsFront("open");
    GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id] = false;
    GlobalStore.setCenterLeftWall("enclosed");
    GlobalStore.setCenterRightWall("enclosed");
    GlobalStore.setCenterFrontWall("enclosed");
    GlobalStore.setCenterBackWall("enclosed");
    //  isWallOpen = GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id];
    // console.log('isWallOpen handleFullyEnclosed '+isWallOpen);
    //handleEndsFront('Closed');
    // handleEndsBack('Closed');
    //  SetWallOpen("enclosed");
    // setWall('open');
    // setWall('enclosed');
    // SetindexF(indexF=1);
    // console.error(indexF);
    // handleEndsFront('Closed');
    // SetWallClose("enclosed");

    // WallOpen="enclosed";
    // WallClose="open";
    var pitchMain =
      GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleMainPitch.current[
            GlobalStore.buildingType.id
          ] == 1
        ? "Normal"
        : "UP";
    if (GlobalStore.buildingType.id != 8) {
      //GlobalStore.StorageMeshMain.current[1].position=GlobalStore.MeshStoragePos.current[GlobalStore.buildingType.id];
      //  GlobalStore.StorageMeshMain.current[1].scaling=GlobalStore.MeshStorageScale.current[GlobalStore.buildingType.id];
      // ScaleMesh.push(GlobalStore.StorageMeshMain.current[1]);
      //if(Scale.length<=0)

      GlobalStore.MeshMain.current[1].getChildren().forEach((m) => {
        if (!(m.name.includes("_LT") || m.name.includes("_RT"))) {
          if (m.name.includes("Wainscot") || m.name.includes("walls"));
          // console.error(scene.meshes[i].name);

          if (m.name.includes(pitchMain)) {
            if (!(m.name.includes("SIDE") || m.name.includes("END")))
              m.isVisible = true;
          } else if (
            !(
              m.name.includes("Down") ||
              m.name.includes("Normal") ||
              m.name.includes("UP")
            )
          ) {
            m.isVisible = true;
          }
          // scene.meshes[i].isVisible=true;
        }
      });
    } else {
      //GlobalStore.StorageMeshMain.current[1].position=GlobalStore.MeshStoragePos.current[GlobalStore.buildingType.id];
      //  GlobalStore.StorageMeshMain.current[1].scaling=GlobalStore.MeshStorageScale.current[GlobalStore.buildingType.id];
      // ScaleMesh.push(GlobalStore.StorageMeshMain.current[1]);
      //if(Scale.length<=0)

      GlobalStore.MeshMain.current[8].getChildren().forEach((m) => {
        if (!(m.name.includes("_LT") || m.name.includes("_RT"))) {
          if (m.name.includes("Wainscot") || m.name.includes("walls"));
          // console.error(scene.meshes[i].name);

          if (m.name.includes(pitchMain)) {
            if (!(m.name.includes("SIDE") || m.name.includes("END")))
              m.isVisible = true;
          } else if (
            !(
              m.name.includes("Down") ||
              m.name.includes("Normal") ||
              m.name.includes("UP")
            )
          ) {
            m.isVisible = true;
          }
          // scene.meshes[i].isVisible=true;
        }
      });
    }

    // const clipPlane = new Plane(0, -1, 0, 0);
    // GlobalStore.mainWallMeshes.current.map((wall) => {
    //     wall.onBeforeRenderObservable.add(function () {
    //         scene.resetCachedMaterial();
    //         scene.clipPlane2 = clipPlane;
    //     });
    //     wall.onAfterRenderObservable.add(function () {
    //         scene.clipPlane2 = null;
    //     });
    //     return true
    // });
    // GlobalStore.setPrice(GlobalStore.price + 1000);
    // GlobalStore.setSelectedEndFront("Closed");
    // GlobalStore.setSelectedEndBack('Closed');
    // GlobalStore.setSelectedSidesLeft('Closed');
    // GlobalStore.setSelectedSidesRight('Closed');
  };

  function frontwall(visible) {
    //visible?GlobalStore.setCenterFrontWall("enclosed"): GlobalStore.setCenterFrontWall("open")
    console.log(visible);
    for (var i = 0; i < scene.meshes.length; i++) {
      if (scene.meshes[i].name.includes("Type1")) {
        if (
          !(
            scene.meshes[i].name.includes("_LT") ||
            scene.meshes[i].name.includes("_RT") ||
            scene.meshes[i].name.includes("Storage")
          ) &&
          (scene.meshes[i].name.includes("front") ||
            scene.meshes[i].name.includes("Wainscot4") ||
            scene.meshes[i].name.includes("Wainscot7"))
        ) {
          // console.error(scene.meshes[i].name);

          if (scene.meshes[i].name.includes(pitchMain)) {
            scene.meshes[i].isVisible = visible;
          } else if (
            !(
              scene.meshes[i].name.includes("Down") ||
              scene.meshes[i].name.includes("Normal") ||
              scene.meshes[i].name.includes("UP")
            )
          ) {
            scene.meshes[i].isVisible = visible;
          }
          // scene.meshes[i].isVisible=true;
        }
      }
    }
  }
  function leftWall(visible) {}
  function rightWall(visible) {}
  function backWall(visible) {
    console.log(visible);
    /*for(var i=0;i<scene.meshes.length;i++)
        {
            if(scene.meshes[i].name.includes("Type1"))
            {
                if(
                    
                    !(scene.meshes[i].name.includes("_LT")||scene.meshes[i].name.includes("_RT")||scene.meshes[i].name.includes("Storage"))
                     && (scene.meshes[i].name.includes("back"))
                     &&(scene.meshes[i].name.includes("walls"))
                     )
                {
                    if(scene.meshes[i].name.includes(pitchMain))  
                    {
                        scene.meshes[i].isVisible=visible;
                    }
                    else if(!(scene.meshes[i].name.includes("Down")||scene.meshes[i].name.includes("Normal")||scene.meshes[i].name.includes("UP"))){ scene.meshes[i].isVisible=visible;}

                }
            }
        }*/
  }

  const handleEndsFront = (targetValue) => {
    console.log("handleEndsFront targetvalue ;" + targetValue);
    console.log(
      "handleEndsFront GlobalStore.buildingType.id ;" +
        GlobalStore.buildingType.id
    );
    //  GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id] = !GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id];
    var pitchMain =
      GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleMainPitch.current[
            GlobalStore.buildingType.id
          ] == 1
        ? "Normal"
        : "UP";
    //GlobalStore.setCenterWalls(GlobalStore.IsWallOpen.current[GlobalStore.buildingType.id]);

    console.log(targetValue);
    switch (targetValue) {
      case "Gable":
        break;
      case "Open":
        GlobalStore.setCenterFrontWall("open");
        // frontwall(false);
        //alert("center front walls have been open, value is: "+ GlobalStore.centerFrontWall)
        //frontwall(false);

        break;
      case "Closed":
        GlobalStore.setCenterFrontWall("enclosed");
        //alert("center front walls have been closed, value is: "+ GlobalStore.centerFrontWall)
        // frontwall(true);

        //rontwall(true);

        break;
    }

    //const groundRef = scene.getMeshByName("Cylinder001");
    // const wallHeightReference = scene.getMeshByName("Profile_004");
    // const mainFrontWalls_R = scene.getTransformNodeByName("frontWalls_R");
    // const mainFrontWalls_L = scene.getTransformNodeByName("frontWalls_L");
    // const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
    // const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
    // GlobalStore.setSelectedEndFront(targetValue)
    // mainEndsWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [mainFrontWalls_R, mainFrontWalls_L], scene)
  };
  const handleEndsBack = (targetValue) => {
    var pitchMain =
      GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleMainPitch.current[
            GlobalStore.buildingType.id
          ] == 1
        ? "Normal"
        : "UP";

          console.log(targetValue)

    switch (targetValue) {
      case "Gable":
        break;
      case "Open":
        //   backWall(true);
        GlobalStore.setCenterBackWall("open");
       //alert("center back walls have been open, value is: "+ GlobalStore.centerBackWall)

        break;
      case "Closed":
        //backWall(true);
        GlobalStore.setCenterBackWall("enclosed");
        //alert("center back walls have been enclosed, value is: "+ GlobalStore.centerBackWall)
        console.log(GlobalStore.centerBackWall)
   
        break;
    }
    // const groundRef = scene.getMeshByName("Cylinder001");
    // const wallHeightReference = scene.getMeshByName("Profile_004");
    // const mainBackWalls_R = scene.getTransformNodeByName("backWalls_R");
    // const mainBackWalls_L = scene.getTransformNodeByName("backWalls_L");
    // const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
    // const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
    // GlobalStore.setSelectedEndBack(targetValue)
    // mainEndsWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [mainBackWalls_R, mainBackWalls_L], scene)
  };
  const handleSidesLeft = (targetValue) => {
    var pitchMain =
      GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleMainPitch.current[
            GlobalStore.buildingType.id
          ] == 1
        ? "Normal"
        : "UP";
    switch (targetValue) {
      case "Gable":
        break;
      case "Open":
        GlobalStore.setCenterLeftWall("open");
        for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("Type1")) {
            if (
              !(
                scene.meshes[i].name.includes("_LT") ||
                scene.meshes[i].name.includes("_RT") ||
                scene.meshes[i].name.includes("Storage")
              ) &&
              scene.meshes[i].name.includes("left") &&
              scene.meshes[i].name.includes("walls")
            ) {
              scene.meshes[i].isVisible = false;
            }
          }
        }

        break;
      case "Closed":
        GlobalStore.setCenterLeftWall("enclosed");
        //alert("center left walls have been closed, value is: "+ GlobalStore.centerLeftWall)
        for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("Type1")) {
            if (
              !(
                scene.meshes[i].name.includes("_LT") ||
                scene.meshes[i].name.includes("_RT") ||
                scene.meshes[i].name.includes("Storage")
              ) &&
              scene.meshes[i].name.includes("left") &&
              scene.meshes[i].name.includes("walls")
            ) {
              if (scene.meshes[i].name.includes(pitchMain)) {
                scene.meshes[i].isVisible = true;
              } else if (
                !(
                  scene.meshes[i].name.includes("Down") ||
                  scene.meshes[i].name.includes("Normal") ||
                  scene.meshes[i].name.includes("UP")
                )
              ) {
                scene.meshes[i].isVisible = true;
              }
            }
          }
        }

        break;
    }
    // const groundRef = scene.getMeshByName("Cylinder001");
    // const wallHeightReference = scene.getMeshByName("Profile_004");
    // const mainSideWalls_L = scene.getTransformNodeByName("sideWalls_L");
    // const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
    // const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
    // GlobalStore.setSelectedSidesLeft(targetValue)
    // mainSidesWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [mainSideWalls_L], scene)
  };
  const handleSidesRight = (targetValue) => {
    var pitchMain =
      GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleMainPitch.current[
            GlobalStore.buildingType.id
          ] == 1
        ? "Normal"
        : "UP";
    switch (targetValue) {
      case "Gable":
        break;
      case "Open":
        GlobalStore.setCenterRightWall("open");
        for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("Type1")) {
            if (
              !(
                scene.meshes[i].name.includes("_LT") ||
                scene.meshes[i].name.includes("_RT") ||
                scene.meshes[i].name.includes("Storage")
              ) &&
              scene.meshes[i].name.includes("right") &&
              scene.meshes[i].name.includes("walls")
            ) {
              scene.meshes[i].isVisible = false;
            }
          }
        }

        break;
      case "Closed":
        GlobalStore.setCenterRightWall("enclosed");
        for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("Type1")) {
            if (
              !(
                scene.meshes[i].name.includes("_LT") ||
                scene.meshes[i].name.includes("_RT") ||
                scene.meshes[i].name.includes("Storage")
              ) &&
              scene.meshes[i].name.includes("right") &&
              scene.meshes[i].name.includes("walls")
            ) {
              if (scene.meshes[i].name.includes(pitchMain)) {
                scene.meshes[i].isVisible = true;
              } else if (
                !(
                  scene.meshes[i].name.includes("Down") ||
                  scene.meshes[i].name.includes("Normal") ||
                  scene.meshes[i].name.includes("UP")
                )
              ) {
                scene.meshes[i].isVisible = true;
              }
            }
          }
        }

        break;
    }
    // const groundRef = scene.getMeshByName("Cylinder001");
    // const wallHeightReference = scene.getMeshByName("Profile_004");
    // const mainSideWalls_R = scene.getTransformNodeByName("sideWalls_R");
    // const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
    // const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
    // GlobalStore.setSelectedSidesRight(targetValue)
    // mainSidesWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [mainSideWalls_R], scene)
  };
  const handleWallColor = (color) => {
    GlobalStore.StorageMeshMain.current[1].getChildren().forEach((m) => {
      if (m.name.includes("Type1")) {
        if (
          !(m.name.includes("_LT") || m.name.includes("_RT")) &&
          (m.name.includes("walls") || m.name.includes("Wainscot")) &&
          m.name.includes("Storage")
        ) {
          m.material.diffuseColor = new Color3.FromHexString(
            color.value
          ).toLinearSpace();
        }
      }
    });
    GlobalStore.MeshMain.current[1].getChildren().forEach((m) => {
      if (m.name.includes("Type1")) {
        if (m.name.includes("walls")) {
          m.material.diffuseColor = new Color3.FromHexString(
            color.value
          ).toLinearSpace();
        } else if (m.name.includes("Wainscot")) {
          m.material.diffuseColor = new Color3.FromHexString(
            color.value
          ).toLinearSpace();
        }
      }
    });

    // for(var i=0;i<scene.meshes.length;i++)
    // {
    //     if(scene.meshes[i].name.includes("Type"+GlobalStore.buildingType.id))
    //     {
    //         if(
    //             !(scene.meshes[i].name.includes("_LT")||scene.meshes[i].name.includes("_RT"))
    //              && ((scene.meshes[i].name.includes("walls")||(scene.meshes[i].name.includes("Wainscot")))&&(scene.meshes[i].name.includes("Storage")))
    //              )
    //         {
    //             scene.meshes[i].material.diffuseColor  =  new Color3.FromHexString(color.value).toLinearSpace();

    //         }
    //     }
    // }
    // const wallMaterials = GlobalStore.outerWallsMaterials.current;
    // wallMaterials.map(mat => mat.albedoColor = new Color3.FromHexString(color.value).toLinearSpace())
  };
  const handleStorage = (e, t, i) => {
    //some where getting true storage

    if(GlobalStore.isStorageOn){
      let sideObjects = [...GlobalStore.leftStorageWallObjects, ...GlobalStore.rightStorageWallObjects, ...GlobalStore.frontCenterStorageWallObjects];

      sideObjects.forEach((obj) => {
        obj.object.dispose();
      })
    }



    GlobalStore.setIsStorageOn(!GlobalStore.isStorageOn);

    var state = true;
    //GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id];
    if (state) {
      pitchMain =
        GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] ==
        0
          ? "Down"
          : GlobalStore.MeshMiddleMainPitch.current[
              GlobalStore.buildingType.id
            ] == 1
          ? "Normal"
          : "UP";

      if (GlobalStore.buildingType.id != 8) {
        GlobalStore.StorageMeshMain.current[1].getChildren().forEach((m) => {
          if (m.name.includes("Type1")) {
            if (
              !(m.name.includes("_LT") || m.name.includes("_RT")) &&
              m.name.includes("Storage")
            ) {
              if (m.name.includes(pitchMain)) {
                //if(GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id])
                // console.error(scene.meshes[i].name);
                m.isVisible = GlobalState.isStorageOn;
              } else if (
                m.name.includes("Down") ||
                m.name.includes("Normal") ||
                m.name.includes("UP")
              ) {
                m.isVisible = false;
              }
              // scene.meshes[i].isVisible=true;
              j++;
            }
          }
        });
      } else {
        GlobalStore.StorageMeshMain.current[8].getChildren().forEach((m) => {
          if (m.name.includes("Type8")) {
            if (
              !(m.name.includes("_LT") || m.name.includes("_RT")) &&
              m.name.includes("Storage")
            ) {
              if (m.name.includes(pitchMain)) {
                //if(GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id])
                // console.error(scene.meshes[i].name);
                m.isVisible = !GlobalStore.isStorageOn;
              } else if (
                m.name.includes("Down") ||
                m.name.includes("Normal") ||
                m.name.includes("UP")
              ) {
                m.isVisible = false;
              }
              // scene.meshes[i].isVisible=true;
              j++;
            }
          }
        });
      }
    } else {
      pitchMain =
        GlobalStore.MeshMiddleMainPitch.current[GlobalStore.buildingType.id] ==
        0
          ? "Down"
          : GlobalStore.MeshMiddleMainPitch.current[
              GlobalStore.buildingType.id
            ] == 1
          ? "Normal"
          : "UP";

      if (GlobalStore.buildingType.id != 8) {
        GlobalStore.StorageMeshMain.current[1].getChildren().forEach((m) => {
          if (m.name.includes("Type1")) {
            if (
              !(m.name.includes("_LT") || m.name.includes("_RT")) &&
              m.name.includes("Storage")
            ) {
              if (m.name.includes(pitchMain)) {
                //if(GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id])
                // console.error(scene.meshes[i].name);
                m.isVisible = GlobalStore.isStorageOn;
              } else if (
                m.name.includes("Down") ||
                m.name.includes("Normal") ||
                m.name.includes("UP")
              ) {
                m.isVisible = false;
              }
              // scene.meshes[i].isVisible=true;
              j++;
            }
          }
        });
      } else {
        GlobalStore.StorageMeshMain.current[8].getChildren().forEach((m) => {
          if (m.name.includes("Type8")) {
            if (
              !(m.name.includes("_LT") || m.name.includes("_RT")) &&
              m.name.includes("Storage")
            ) {
              if (m.name.includes(pitchMain)) {
                //if(GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id])
                // console.error(scene.meshes[i].name);
                m.isVisible = GlobalStore.isStorageOn;
              } else if (
                m.name.includes("Down") ||
                m.name.includes("Normal") ||
                m.name.includes("UP")
              ) {
                m.isVisible = false;
              }
              // scene.meshes[i].isVisible=true;
              j++;
            }
          }
        });
      }
      // for(var i=0;i<scene.meshes.length;i++)
      // {

      //     if(scene.meshes[i].name.includes("Type1"))
      //     {
      //         if(

      //             !(scene.meshes[i].name.includes("_LT")||scene.meshes[i].name.includes("_RT"))
      //             && (scene.meshes[i].name.includes("Storage"))
      //             )
      //         {
      //            // console.error("(f)"+GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id]);

      //             scene.meshes[i].isVisible=false;

      //         }
      //     }
      //  }
    }

    GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id] =
      GlobalStore.isStorageOn;
    GlobalStore.setIsStorageOn(!GlobalStore.isStorageOn);

    // t=GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id];
    // if (!t) {
    //     GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id]=false;
    // }
    // else {
    //     GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id]=true;
    //    // GlobalStore.setStorageMain(!GlobalStore.storageMain)
    // }

    // const mainParent_R = scene.getMeshByName("Main_Helper_R");
    // const mainParent_L = scene.getMeshByName("Main_Helper_L");
    // const storageBackWall_L = scene.getMeshByName("storage_main_backWall_L");
    // const storageBackWall_R = scene.getMeshByName("storage_main_backWall_R");
    // let mainStorage_R;
    // mainParent_R.getChildMeshes().map(mesh => {
    //     if (mesh.name === "storage_main_R") {
    //         mainStorage_R = mesh;
    //     }
    //     return 0
    // })
    // let mainStorage_L;
    // mainParent_L.getChildMeshes().map(mesh => {
    //     if (mesh.name === "storage_main_L") {
    //         mainStorage_L = mesh;
    //     }
    //     return 0
    // })
    // mainParent_R.computeWorldMatrix(true);
    // const storagePlaneDistance = mainParent_R.getBoundingInfo().boundingBox.minimumWorld.x;
    // GlobalStore.mainLengthFromCenter.current = storagePlaneDistance;

    // if (GlobalStore.storageMain) {
    //     mainStorage_R.setAbsolutePosition(GlobalStore.mainLengthFromCenter.current, mainStorage_R.getAbsolutePosition().y, mainStorage_R.getAbsolutePosition().z)
    //     mainStorage_L.setAbsolutePosition(GlobalStore.mainLengthFromCenter.current, mainStorage_L.getAbsolutePosition().y, mainStorage_L.getAbsolutePosition().z)
    //     mainStorage_R.translate(Axis.Y, GlobalStore.mainStorageTranslation.current / 5 * 2.4, Space.LOCAL);
    //     mainStorage_L.translate(Axis.Y, GlobalStore.mainStorageTranslation.current / 5 * 2.4, Space.LOCAL);
    //     storageBackWall_L.isVisible = true;
    //     storageBackWall_R.isVisible = true;
    // }
    // else {
    //     mainStorage_R.setAbsolutePosition(GlobalStore.mainLengthFromCenter.current, mainStorage_R.getAbsolutePosition().y, mainStorage_R.getAbsolutePosition().z)
    //     mainStorage_L.setAbsolutePosition(GlobalStore.mainLengthFromCenter.current, mainStorage_L.getAbsolutePosition().y, mainStorage_L.getAbsolutePosition().z)
    //     storageBackWall_L.isVisible = false;
    //     storageBackWall_R.isVisible = false;
    // }
  };
  const handleAddStorage = (targetValue) => {
    // if(!GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id])

    switch (targetValue) {
      case "5":
        if (GlobalStore.buildingType.id != 8) {
          GlobalStore.StorageMeshMain.current[1]
            .getChildren()
            .forEach((mesh) => {
              mesh.scaling.x = XScaleCenter[0];
            });
        } else if (GlobalStore.buildingType.id === 8) {
          GlobalStore.StorageMeshMain.current[8]
            .getChildren()
            .forEach((mesh) => {
              mesh.scaling.x = XScaleCenter[0];
            });
        }
        break;
      case "10":
        if (GlobalStore.buildingType.id != 8) {
          GlobalStore.StorageMeshMain.current[1]
            .getChildren()
            .forEach((mesh) => {
              mesh.scaling.x = XScaleCenter[1];
            });
        } else if (GlobalStore.buildingType.id === 8) {
          GlobalStore.StorageMeshMain.current[8]
            .getChildren()
            .forEach((mesh) => {
              mesh.scaling.x = XScaleCenter[1];
            });
        }
        break;
      case "15":
        if (GlobalStore.buildingType.id != 8) {
          GlobalStore.StorageMeshMain.current[1]
            .getChildren()
            .forEach((mesh) => {
              mesh.scaling.x = XScaleCenter[2];
            });
        } else if (GlobalStore.buildingType.id === 8) {
          GlobalStore.StorageMeshMain.current[8]
            .getChildren()
            .forEach((mesh) => {
              mesh.scaling.x = XScaleCenter[2];
            });
        }
        break;
      default:
        break;

      }
      setTimeout(() => {
        let bounds= null;
        if(GlobalStore.buildingType.id != 8){
           bounds= getBoundingBox(GlobalStore.mainCartportStorageBoundingBox.current)
        }
        else{
           bounds=getBoundingBox(GlobalStore.mainBarnStorageBoundingBox.current)
        }

        GlobalStore.frontCenterStorageWallObjects.forEach((object) => {
          object.object.position.x = bounds.maxWorld.x;
        })

        let sideObjects = [...GlobalStore.leftStorageWallObjects, ...GlobalStore.rightStorageWallObjects];

        sideObjects.forEach((object) => {
          object.object.position.x = (bounds.maxWorld.x + bounds.minWorld.x) / 2;
          //console.log(object.pointer)
        
          object.pointer.validateDrag = (targetPosition) => {
            targetPosition.x = Scalar.Clamp(
              targetPosition.x,
              bounds.minWorld.x + object.limit,
              bounds.maxWorld.x - object.limit
            )

            targetPosition.y = Scalar.Clamp(
              targetPosition.y,
              13,
              object.heightOffset
            );
            return true;

            
          }
        })

        

      },10);
    // const mainParent_R = scene.getMeshByName("Main_Helper_R");
    // const mainParent_L = scene.getMeshByName("Main_Helper_L");
    // let mainStorage_R;
    // mainParent_R.getChildMeshes().map(mesh => {
    //     if (mesh.name === "storage_main_R") {
    //         mainStorage_R = mesh;
    //     }
    //     return 0
    // })
    // let mainStorage_L;
    // mainParent_L.getChildMeshes().map(mesh => {
    //     if (mesh.name === "storage_main_L") {
    //         mainStorage_L = mesh;
    //     }
    //     return 0
    // })
    // mainParent_R.computeWorldMatrix(true);
    // const storagePlaneDistance = mainParent_R.getBoundingInfo().boundingBox.minimumWorld.x;
    // GlobalStore.mainLengthFromCenter.current = storagePlaneDistance;

    // mainStorage_R.setAbsolutePosition(storagePlaneDistance, mainStorage_R.getAbsolutePosition().y, mainStorage_R.getAbsolutePosition().z)
    // mainStorage_L.setAbsolutePosition(storagePlaneDistance, mainStorage_L.getAbsolutePosition().y, mainStorage_L.getAbsolutePosition().z)
    // mainStorage_R.translate(Axis.Y, targetValue / 5 * 2.4, Space.LOCAL);
    // mainStorage_L.translate(Axis.Y, targetValue / 5 * 2.4, Space.LOCAL);

    //GlobalStore.mainStorageTranslation.current = targetValue;
  };

  useEffect(() => {
    //your code goes here
    return () => {
      XScaleCenter = [];
      ScaleMesh = [];
      XPosCenter = [];
      j = 0;
      GlobalStore.isWallCenterInit.current = false;
    };
  }, []);

  return (
    <WallsScrollbarWrapper>
      <WallsWrapper>
        <RadioGroup
          aria-label="walls"
          name="walls"
          value={wall}
          onChange={handleWallChange}
          row
        >
          <FormControlLabel
            onChange={handleFullyOpen}
            value={"open"}
            control={<Radio />}
            label="Open"
          />
          <FormControlLabel
            onChange={handleFullyEnclosed}
            value={"enclosed"}
            control={<Radio />}
            label="Fully Enclosed"
          />
        </RadioGroup>
        <Separator />
        {/* <FormControlLabel
                    control={
                        <Checkbox
                            checked={verticalPanels}
                            onChange={handleVerticalPanels}
                            name="vertical-panels"
                            color="primary"
                        />
                    }
                    style={{ color: '#222' }}
                    label="Vertical Panels"
                /> */}
        <Separator marginBottom={"5px"} />
        <FormControlLabel
          control={
            <Checkbox
              checked={GlobalStore.isStorageOn}
              onChange={handleStorage}
              name="storage"
              color="primary"
            />
          }
          style={{ color: "#222" }}
          label="Add Storage"
        />
        <WallsSelectWrapper checked={GlobalStore.isStorageOn}>
          <InputSelect
            onChange={handleAddStorage}
            label="Utility Enclosed Area"
            options={storageOptions}
            inputName="add-storage"
          />
        </WallsSelectWrapper>

        <Separator />

        <SectionTitle title={"ENDS"} />

        <InputSelect
          onChange={handleEndsFront}
          label="Front"
          options={endsOptions}
          inputName="ends-front"
          //value={endsOptions[indexF]}
          //selectedOptionIndex={indexF}
        />

        <Separator marginBottom="15px" />

        <InputSelect
          //selectedOptionIndex={indexF}
          onChange={handleEndsBack}
          label="Back"
          options={endsOptions}
          //options={endsOptions}
          inputName="ends-back"
        />

        <Separator marginBottom="15px" />

        <SectionTitle title={"SIDES"} />

        <InputSelect
          onChange={handleSidesLeft}
          label="Left"
          options={sidesOptions}
          inputName="sides-left"
        />

        <Separator marginBottom="15px" />

        {/* <div>
                    {!verticalPanels && wallIsOpen && <FormControlLabel
                        control={
                            <Checkbox
                                checked={leftSidePanel}
                                onChange={handleLeftVerticalPanel}
                                name="sides-left-cut-panel"
                                color="primary"
                            />
                        }
                        style={{
                            color: '#222',
                            padding: '0 8px',
                        }}
                        label="Left Cut Panel"
                    />}
                </div> */}

        <Separator marginBottom="15px" />

        <InputSelect
          onChange={handleSidesRight}
          label="Right"
          options={sidesOptions}
          inputName="sides-right"
        />

        <Separator marginBottom="15px" />

        {/* {!verticalPanels && wallIsOpen && <FormControlLabel
                    control={
                        <Checkbox
                            checked={rightSidePanel}
                            onChange={handleRightVerticalPanel}
                            name="sides-right-cut-panel"
                            color="primary"
                        />
                    }
                    style={{
                        color: '#222',
                        padding: '0 8px',
                    }}
                    label="Right Cut Panel"
                />} */}

        <Separator marginBottom="15px" />

        {/* <FormControlLabel
                    control={
                        <Checkbox
                            checked={jTrim}
                            onChange={handleJTrim}
                            name="vertical-panels"
                            color="primary"
                        />
                    }
                    style={{ color: '#222' }}
                    label="J-Trim"
                /> */}

        <Separator marginBottom="30px" />

        <ColorPicker
          onChange={handleWallColor}
          title="Wall"
          colorsOptions={colorsOptionsPremium}
          checked={GlobalStore.wallsColor}
        />

        <Separator />

        <FormControlLabel
          control={
            <Checkbox checked={true} name="add-insulation" color="primary" />
          }
          style={{
            color: "#222",
          }}
          label="Add Insulation"
        />
        <br />
        <FormControl fullWidth>
          <InputLabel style={{ fontSize: "11px" }} id="insulation-select-label">
            Insulation
          </InputLabel>
          <Select
            value={insulationValue}
            onChange={handleInsulationChange}
            inputProps={{ "aria-label": "Without label" }}
            fullWidth
          >
            {insulationOptions.map(({ label, value }, i) => {
              return (
                <MenuItem value={value} key={i}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {insulationCheckboxes && (
          <>
            <RadioGroup
              aria-label="insulation-radio"
              name="insulation-radio"
              value={insulationRadio}
              onChange={handleInsulationRadio}
              style={{ padding: "0 8px" }}
            >
              <FormControlLabel
                value="roof-only"
                control={<Radio />}
                label="Roof Only"
              />
              <FormControlLabel
                value="full-building"
                control={<Radio />}
                label="Full Building"
              />
            </RadioGroup>
          </>
        )}

        <Separator />
      </WallsWrapper>
    </WallsScrollbarWrapper>
  );
};

export default WallsCenter;
