import styled from 'styled-components/macro';

export const InfoBarWrapper = styled.div`
    display: flex;
    
`;

export const InfoBarItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
    border-left: 1px solid #ccc;
    color: ${props => props.active ? '#222' : '#fff'};

    &:only-child {
        border-right: none;
    }

    svg {
        fill: ${props => props.active ? '#222' : '#fff'};
    }

    #dropdown-basic {
        &:focus {
            outline: none;
            border: 1px solid rgb(235,115,36);
        }
        &:hover {
            cursor: pointer;
        }
    }
`;

export const InfoBarItemLabel = styled.div`
    display: block;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
`;

export const InfoBarItemButton = styled.span`
    color: rgb(235, 115, 36);
    display: flex;
    align-items: flex-start;
    font-weight: bold;

    svg {
        width: 14px;
        height: 14px;
        margin-left: 5px;
    }

    &:hover {
        cursor: pointer;
    }
`;

export const InfoBarItemButtonText = styled.span`
    min-height: 19px;
`

export const InfoBarItemPriceWrap = styled.div`
    display: flex;
    color: #222;
`;

export const InfoBarItemPrice = styled.div`
    font-weight: bold;
    padding: 7px 15px;
    text-align: left;
    font-size: 16px;

    span {
        display: block;
        font-size: 12px;
        font-weight: normal;
    }

    &:last-of-type {
        border-left: 1px solid #222;
    }
`;



export const InfoBarItemDeposit = styled(InfoBarItemPrice)`
    
`;

export const InfoBarDepositDropdown = styled.div`
    position: absolute;
    width: 390px;
    border-radius: 10px;
    top: 10px!important;
    display: initial!important;
    box-shadow: 0 1px 2px rgba(0,0,0,.25);
    font-size: 1rem;
    color: #ccc;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    min-width: 10rem;
    padding: .5rem 0;
    z-index: 2;

    .prices-total-deposit {

        ul {
            display: inherit;
            padding: 0 25px;
            margin: 0;
        }

        li {
            display: flex;
            border: none;
            padding: 10px 0;
            line-height: 16px;
            justify-content: space-between;
            
            

            aside {
                padding: 0;
                border: none;
            }
            aside.deposit-price-value {
                color: #fff;
                font-size: 17px;
                font-weight: bold;
            }
        }

        .deposit-price-label {
            color: #fff;
        }
        .deposit-price-value {
            color: #fff;
            font-size: 17px;
            font-weight: bold;
        }

    }

    .prices-total-deposit ul li.highlight-deposit {
        background: #333;
        margin: 0 -10px;
        padding: 10px;
        border-radius: 4px;
    } 

    .prices-total-deposit ul li.highlight-deposit:last-child {
        margin-bottom: 10px;
    }

    .prices-total-deposit ul li.highlight-deposit aside.deposit-price-label {
        color: #fff;
    }

    .prices-total-deposit ul li.highlight-deposit aside.deposit-price-value {
        color: #fff;
        font-size: 17px;
        font-weight: bold;
    }

    .prices-total-deposit ul li aside.deposit-price-label {
        font-size: 14px;
        color: #222;
    }

    .prices-total-deposit ul li aside.deposit-price-value {
        color: #eb7324;
        font-size: 16px;
        font-weight: bold;
    }
`;