import React, { useContext, useState } from 'react'
import Scrollbar from 'react-scrollbars-custom'
import { Nav, Tab } from 'react-bootstrap'
import Separator from '../../../utils/Separator/Separator'

import DoorsAndWindowsLeft from './DoorsAndWindowsLeft/DoorsAndWindowsLeft';
import DoorsAndWindowsCenter from './DoorsAndWindowsCenter/DoorsAndWindowsCenter';
import DoorsAndWindowsRight from './DoorsAndWindowsRight/DoorsAndWindowsRight';



import {
    DoorsAndWindowsScrollbarWrapper,
    DoorsAndWindowsWrapper
} from './DoorsAndWindows.css'

const DoorsAndWindows = () => {
    return (
        <DoorsAndWindowsScrollbarWrapper>
            <DoorsAndWindowsWrapper>
                <Tab.Container id="walls" defaultActiveKey="doors-and-windows-center">
                    <Nav variant="pills" className="flex-row" defaultActiveKey="doors-and-windows-center">
                        <Nav.Item>
                            {
                            /* <Nav.Link eventKey="doors-and-windows-left">
                                <svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M15.755 2.221A94558.6 94558.6 0 013.515 5.23c-1.772.435-3.288.81-3.369.831L0 6.101V25h4.753V11.316h15.494V25H25V12.498C25 .638 24.994-.004 24.886 0c-.063.002-4.171 1.002-9.13 2.221zm7.943 10.528v10.89h-2.213l-.017-6.896-.016-6.896-8.968-.018-8.968-.018-.016 6.854-.017 6.854-1.074-.01-1.074-.01V7.197l2.734-.665c9.67-2.35 19.297-4.66 19.45-4.666l.179-.007v10.89z" fill="#000" /></svg>
                                Left
                            </Nav.Link>
                            <Nav.Link eventKey="doors-and-windows-center">
                                <svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.18 1.726c-1.987.946-4.858 2.31-6.38 3.031L.033 6.067l-.017 9.467L0 25h4.753V11.315h15.494V25H25V6.085l-.407-.205L18.6 2.834C15.525 1.272 12.962-.004 12.902 0c-.06.004-1.735.78-3.722 1.726zm9.085 2.517l5.4 2.817V23.5l-1.09.016-1.09.016-.017-6.843-.016-6.842-8.968-.018-8.967-.019-.017 6.855-.017 6.854-1.074-.01-1.074-.01V7.063l5.371-2.637a747.109 747.109 0 005.697-2.813c.179-.097.352-.212.39-.215.037-.002 2.502 1.297 5.472 2.846z" fill="#000" /></svg>
                                Center
                            </Nav.Link>
                            <Nav.Link eventKey="doors-and-windows-right">
                                <svg width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.245 2.221l12.24 3.009c1.772.435 3.288.81 3.369.831l.146.04V25h-4.753V11.316H4.753V25H0V12.498C0 .638.006-.004.114 0c.063.002 4.171 1.002 9.13 2.221zM1.302 12.75v10.89h2.213l.017-6.896.016-6.896 8.968-.018 8.968-.018.016 6.854.017 6.854 1.074-.01 1.074-.01V7.197l-2.734-.665c-9.67-2.35-19.298-4.66-19.45-4.666l-.179-.007v10.89z" fill="#000" /></svg>
                                Right
                            </Nav.Link> */
                            }
                        </Nav.Item>
                        <Tab.Content>
                            <Tab.Pane eventKey="doors-and-windows-left">
                                <DoorsAndWindowsLeft />
                            </Tab.Pane>
                            <Tab.Pane eventKey="doors-and-windows-center">
                                <DoorsAndWindowsCenter />
                            </Tab.Pane>
                            <Tab.Pane eventKey="doors-and-windows-right">
                                <DoorsAndWindowsRight />
                            </Tab.Pane>
                        </Tab.Content>
                    </Nav>
                </Tab.Container>
            </DoorsAndWindowsWrapper>
        </DoorsAndWindowsScrollbarWrapper>
    )
}

export default DoorsAndWindows
