const instalationSurfaceData = [
	{
		id: 1,
		text: "Concrete",
		imgUrl: "./img/instalationSurfaces/concrete.png",
		isChecked: true,
	},
	{
		id: 2,
		text: "Asphalt",
		imgUrl: "./img/instalationSurfaces/asphalt.png",
		isChecked: false,
	},
	{
		id: 3,
		text: "Ground",
		imgUrl: "./img/instalationSurfaces/ground.png",
		isChecked: false,
	},
	{
		id: 4,
		text: "Gravel",
		imgUrl: "./img/instalationSurfaces/gravel.png",
		isChecked: false,
	},
];

export default instalationSurfaceData;
