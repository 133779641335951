import React, { useState } from 'react'

import EntryContent from '../../../EntryContent/EntryContent'
import CardsAdd from '../../../CardsAdd/CardsAdd'
import Separator from '../../../../utils/Separator/Separator'

import {
    DoorsAndWindowsCenterWrapper
} from './DoorsAndWindowsCenter.css'

const DoorsAndWindowsCenter = () => {
    const [isActive, setIsActive] = useState(true);

    return (
        <DoorsAndWindowsCenterWrapper>
            <EntryContent content="<p> <strong>ADD</strong> - Add items using the buttons below. Then, move the mouse to place them on the building. </p><p> <strong>EDIT</strong> - Select the Component >> Click on the EDIT icon to update the style, size, or features of selected component. </p>" />
            <Separator marginBotom="25px" />
            <CardsAdd isActive={isActive} />
            <Separator marginBotom="25px" />
            <EntryContent content="<p>Components are added on the HIGHLIGHTED WALL on the building. Rotate to highlight the wall to place a component on it.</p>" />
        </DoorsAndWindowsCenterWrapper>
    )
}

export default DoorsAndWindowsCenter
