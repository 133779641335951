import styled from 'styled-components/macro';
import Scrollbar from 'react-scrollbars-custom';

export const LeanToSideWrapper = styled.div``;

export const LeanToSideTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const LeanToSideTitleLabel = styled.span`
    color: #eb7324;
    margin-left: 10px;
    font-size: 11px;
    white-space: nowrap;
`;

export const LeanToSideFormContent = styled.div`
    opacity: ${props => props.checked ? 1 : 0.6};
    user-select: ${props => props.checked ? 'auto' : 'none'};
    pointer-events: ${props => props.checked ? 'auto' : 'none'};
    transition: 0.3s ease-in-out;
`;
export const BuiltSizeScrollbarWrapper = styled(Scrollbar)`
    width: 350px !important;
    height: calc(var(--vh, 1vh)*90 - 250px) !important;

    @media (max-width: 991px) {
        width: 100% !important;
        height: inherit !important;
        position: static !important;
    }
`