import React from 'react'
import BuildMenu from '../BuildMenu/BuildMenu'
import { BuildWrapper } from './Build.css'
import BuildControllerMenu from '../BuildControllerMenu/BuildControllerMenu'

const Build = () => {


    return (
        <BuildWrapper>
            <BuildMenu />
            <BuildControllerMenu />
        </BuildWrapper>
    )
}

export default Build
