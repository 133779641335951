import React, { useState, useContext, useEffect } from 'react'
import {
    ColorPickerWrapper,
    ColorPickerTitleWrapper,
    ColorPickerTitle,
    ColorPickerValue,
    ColorPickerName,
    ColorPickerOptions,
    ColorPickerOptionsItem,
    ColorPickerOptionsItemText
} from './ColorPicker.css'

import { GlobalState } from "../../../../App"

const ColorPicker = ({ title, onChange, colorsOptions, checked }) => {

    const GlobalStore = useContext(GlobalState);
    const [color, setColor] = useState(colorsOptions[checked]);
    const [activeColor, setActiveColor] = useState(checked)

    useEffect(() => {
        setActiveColor(checked);
        setColor(colorsOptions[checked]);
    }, [checked])

    const handleColorSelection = (color, index) => () => {
        const { value, name, premium } = color;

        setColor({
            name: name,
            value: value,
            premium: premium
        })

        setActiveColor(index)
        if (title === "Wall") {
            GlobalStore.setWallsColor(index);
        }
        if (title === "wainscot") {
            GlobalStore.setWainscotColor(index);
        }

        if (typeof onChange == 'function') {
            onChange(color);
        }
    }

    const renderCheckmarkColor = (colorHex) => {
        switch (colorHex) {
            case '#ffffff':
            case '#d1b9a1':
            case '#ebe1be':
            case '#d0d0d0':
            case '#fae4bb':
            case '#ebe0d3':
                return '#696969';
            default:
                return '#ffffff';
        }
    }

    return (
        <ColorPickerWrapper>
            <ColorPickerTitleWrapper>
                {
                    title && <ColorPickerTitle>
                        {title}
                    </ColorPickerTitle>
                }
                <ColorPickerValue color={color} />
                <ColorPickerName>({color && color.name})</ColorPickerName>
            </ColorPickerTitleWrapper>
            <ColorPickerOptions>
                {colorsOptions.map((color, index) => {
                    return (
                        <ColorPickerOptionsItem
                            color={color.value}
                            key={index}
                            onClick={handleColorSelection(color, index)}
                        >
                            {color.premium && activeColor !== index && <ColorPickerOptionsItemText premium={color.premium}>
                                Premium
                            </ColorPickerOptionsItemText>}
                            {activeColor === index ? <>
                                <svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill={renderCheckmarkColor(color.value)}
                                        d="M12.765 2.893l-1.14-1.14a.776.776 0 00-.57-.235.776.776 0 00-.571.235l-5.502 5.51L2.516 4.79a.776.776 0 00-.57-.235.776.776 0 00-.57.235L.234 5.929A.776.776 0 000 6.5c0 .224.078.414.235.57l3.036 3.036 1.14 1.141a.776.776 0 00.57.235.777.777 0 00.571-.235l1.14-1.14 6.073-6.073a.777.777 0 00.235-.57.777.777 0 00-.235-.57z"
                                    />
                                </svg>
                            </> : null}
                        </ColorPickerOptionsItem>
                    )
                })}
            </ColorPickerOptions>
        </ColorPickerWrapper>
    )
}

export default ColorPicker
