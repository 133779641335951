import React, { useState, useRef, useContext } from 'react'

import Draggable from 'react-draggable'; // The default

import {
    DraggableBoxWrapper,
    DraggableBoxBody,
    DraggableBoxFooter,
    DraggableBoxIconDrag,
    DraggableBoxFooterBtn,
    DraggableBoxFooterBtnPill,
    ModalDraggableBox,
    ModalDraggableBoxTitle,
    ModalDraggableBoxText,
    ModalDraggableBoxNavigation,
    ModalDraggableBoxNavigationButton,
    ModalDraggableBoxNavigationButtonSolid
} from './DraggableBox.css'

import InputSelect from '../Forms/parts/InputSelect/InputSelect';
import SectionTitle from '../SectionTitle/SectionTitle';
import Separator from '../../utils/Separator/Separator';

import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Modal from 'react-modal';

import { Vector3, Quaternion } from '@babylonjs/core';

import { GlobalState } from '../../App';

import { createDnWGUI } from '../../utils/functions'
import { garageDoorOptions, walkInDoorOptions, windowOptions } from '../DraggableBox/DraggableBoxData.json';

const modalStyles = {
    overlay: {
        zIndex: '123',
        padding: '80px 100px'
    },
    content: {
        maxWidth: '660px',
        width: '100%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '80px 100px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#root')

const DraggableBox = (props) => {

    const { draggableIsOpen, modalIsOpen, haveAngle } = props
    const GlobalStore = useContext(GlobalState);
    const scene = GlobalStore.sceneGlobalRef.current;

    const [degreeAngle, setDegreeAngle] = useState();
    const garageDoorInstances = useRef(1)

    const handleDegreeAngleChange = () => {
        setDegreeAngle(!degreeAngle);

        GlobalStore.currentDoors.current.map(mesh => {
            mesh.getChildMeshes().map(child => {
                if (child.name !== ("guiPlane")) {
                    if (child.name.indexOf("_45") > -1) {
                        child.isVisible = !degreeAngle;
                    }
                    else {
                        child.isVisible = degreeAngle;
                    }
                }
            })
        })

    }

    const openModal = () => {
        GlobalStore.setModalIsOpen(true)
    }

    const closeModal = () => {
        GlobalStore.setModalIsOpen(false)
    }

    const closeDraggable = () => {
        GlobalStore.setDraggableIsOpen(false);
    }

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
    }

    const cloneDoors = () => {
        let mesh = GlobalStore.currentDoors.current[1];
        let parentMesh;
        let instanceName;
        let options;
        if (mesh.name.indexOf("garageDoor") > -1) {
            parentMesh = scene.getMeshByName("GarageDoor_Helper");
            instanceName = "garageDoor_instance"
            options = garageDoorOptions;
        }
        else if (mesh.name.indexOf("walkInDoor") > -1) {
            parentMesh = scene.getMeshByName("WalkInDoor_Helper");
            instanceName = "walkInDoor_instance"
            options = walkInDoorOptions
        }
        else if (mesh.name.indexOf("window") > -1) {
            parentMesh = scene.getMeshByName("Window_Helper");
            instanceName = "window_instance"
            options = windowOptions;
        }

        else if (mesh.name.indexOf("framedOpening") > -1) {
            parentMesh = scene.getMeshByName("FramedOpenings_Helper");
            instanceName = "framedOpening_instance"
            options = garageDoorOptions;
        }
        else if (mesh.name.indexOf("framedWalkin") > -1) {
            parentMesh = scene.getMeshByName("FramedWalkIn_Helper");
            instanceName = "framedWalkin_instance"
            options = walkInDoorOptions;
        }
        else if (mesh.name.indexOf("windowFramed") > -1) {
            parentMesh = scene.getMeshByName("WindowFramed_Helper");
            instanceName = "window_framed_instance"
            options = windowOptions;
        }
        const parentInstance = parentMesh.clone(`${instanceName}${garageDoorInstances.current}`);
        parentInstance.getChildMeshes().map(mesh => { mesh.isVisible = true; mesh.isPickable = true; });
        parentInstance.bindedTo = GlobalStore.currentDoors.current[1].bindedTo;
        parentInstance.position = new Vector3(GlobalStore.currentDoors.current[1].position.x, GlobalStore.currentDoors.current[1].position.y, GlobalStore.currentDoors.current[1].position.z)
        createDnWGUI(parentInstance, scene, GlobalStore, options);
        const originalRotationQuaternion = GlobalStore.currentDoors.current[1].rotationQuaternion;
        parentInstance.rotationQuaternion = new Quaternion(originalRotationQuaternion.x, originalRotationQuaternion.y, originalRotationQuaternion.z, originalRotationQuaternion.w)
    }

    const removeDoor = () => {

        closeModal();
        const guiPlane = GlobalStore.currentDoors.current[0]
        const mesh = GlobalStore.currentDoors.current[1];
        guiPlane.dispose();
        mesh.getChildMeshes().map(mesh => mesh.dispose())
        mesh.dispose();

        GlobalStore.currentDoors.current = [];
        closeDraggable();

    }

    const handleDoorSize = (targetValue, inputName) => {
        const currentDoor = GlobalStore.currentDoors.current[1];
        const targetValueSplit = targetValue.split("X");
        let targetValueWidth = parseFloat(targetValueSplit[0]);
        let targetValueHeight = parseFloat(targetValueSplit[1]);

        if (currentDoor.name.indexOf("garageDoor") > -1) {
            currentDoor.scaling.y = targetValueWidth / 6;
            currentDoor.scaling.z = targetValueHeight / 6;
        }
        else if (currentDoor.name.indexOf("walkInDoor") > -1) {
            //currentDoor.scaling.z =
            //currentDoor.scaling.y = 
        }
        else if (currentDoor.name.indexOf("window") > -1) {
            currentDoor.scaling.x = targetValueWidth / 24;
            currentDoor.scaling.y = targetValueHeight / 36;
        }
        else if (currentDoor.name.indexOf("framedOpening") > -1) {
            currentDoor.scaling.x = targetValueWidth / 6;
            currentDoor.scaling.z = targetValueHeight / 6;
        }
        else if (currentDoor.name.indexOf("framedWalkin") > -1) {
            //currentDoor.scaling.x = 
            //currentDoor.scaling.z = 
        }
        else if (currentDoor.name.indexOf("windowFramed") > -1) {
            currentDoor.scaling.x = targetValueWidth / 24;
            currentDoor.scaling.y = targetValueHeight / 36;
        }
    }


    return (<>

        <DraggableBoxWrapper>
            {modalIsOpen && <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Doors Modal"
            >
                <ModalDraggableBox>
                    <ModalDraggableBoxTitle>Alert</ModalDraggableBoxTitle>
                    <ModalDraggableBoxText>Do you really want to delete this component.</ModalDraggableBoxText>
                    <ModalDraggableBoxNavigation>
                        <ModalDraggableBoxNavigationButton onClick={closeModal}>Cancel</ModalDraggableBoxNavigationButton>
                        <ModalDraggableBoxNavigationButtonSolid onClick={removeDoor}>Done</ModalDraggableBoxNavigationButtonSolid>
                    </ModalDraggableBoxNavigation>
                </ModalDraggableBox>
            </Modal>}
            {draggableIsOpen && <Draggable
                handle=".handle"
                defaultPosition={{ x: 0, y: 0 }}
                position={null}
                grid={[25, 25]}
                scale={1}
            >
                <div className="handle">
                    <DraggableBoxIconDrag>
                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 374 375"><path fillRule="evenodd" clipRule="evenodd" d="M184 75.521c-2.76 1.053-40 37.727-40 39.391 0 1.376 11.248 12.479 12.641 12.479.595 0 5.235-4.5 10.31-10s9.637-10 10.138-10c.531 0 .911 14.615.911 35v35H106.867l10.817-10.434 10.816-10.435-6.765-6.815-6.764-6.816-19.986 20.05C75.85 182.139 75 183.157 75 186.891c0 3.733.851 4.753 19.975 23.945l19.976 20.046 6.531-6.473 6.531-6.473-10.74-10.772-10.739-10.773H178v71.466l-10.773-10.739-10.772-10.74-6.473 6.531-6.473 6.531 20.046 19.976c21.395 21.319 22.078 21.768 28.27 18.565 1.499-.775 10.981-9.829 21.071-20.12l18.344-18.711-6.615-6.616-6.616-6.616-10.505 10.469L197 266.855v-70.464h35c19.25 0 35 .231 35 .513 0 .283-4.747 5.086-10.549 10.674l-10.549 10.161 6.27 6.326c3.449 3.479 6.738 6.326 7.309 6.326 1.697 0 38.339-37.275 39.37-40.051 2.084-5.609.603-7.68-19.765-27.639L259.5 143.508l-6.486 6.437-6.487 6.437 10.469 10.505 10.468 10.504H197v-70.464l10.494 10.458 10.495 10.459 6.447-6.389 6.447-6.39-18.062-18.577c-9.934-10.217-18.795-19.077-19.691-19.688-2.603-1.774-6.428-2.31-9.13-1.279z" fill="#fff" /></svg>
                    </DraggableBoxIconDrag>
                    <DraggableBoxBody>
                        <Grid item lg={6} >
                            <InputSelect onChange={handleDoorSize} label="Garage Door" options={GlobalStore.doorOptions} inputName='garage-door' />
                        </Grid>
                        <Separator marginBottom="15px" />
                        <SectionTitle title={'Style & Features'} />
                        {haveAngle && <FormControlLabel
                            control={
                                <Checkbox
                                    checked={degreeAngle}
                                    onChange={handleDegreeAngleChange}
                                    name="degree-angle"
                                    color="primary"
                                    className="checkbox--border"
                                />
                            }
                            style={{ color: '#222' }}
                            label="45 Degree Angle"
                        />}
                        <Separator marginBottom="15px" />
                        <TextField
                            label="Additional Notes"
                            variant="outlined"
                            inputProps={{ maxLength: 500 }}
                            multiline
                            rows={4}
                            fullWidth={true}
                        />
                    </DraggableBoxBody>
                    <DraggableBoxFooter>
                        <DraggableBoxFooterBtn onClick={cloneDoors}>
                            Clone
                        </DraggableBoxFooterBtn>
                        <DraggableBoxFooterBtn onClick={openModal}>
                            Remove
                        </DraggableBoxFooterBtn>
                        <DraggableBoxFooterBtnPill onClick={closeDraggable}>
                            Done
                        </DraggableBoxFooterBtnPill>
                    </DraggableBoxFooter>
                </div>
            </Draggable>}
        </DraggableBoxWrapper>
    </>
    )
}

export default DraggableBox
