import React, { useContext } from 'react'
import { GlobalState } from '../../App';
import {
    InfoBarWrapper,
    InfoBarItem,
    InfoBarItemLabel,
    InfoBarItemButton,
    InfoBarItemButtonText,
    InfoBarItemPriceWrap,
    InfoBarItemPrice,
    InfoBarItemDeposit,
    InfoBarDepositDropdown
} from './InfoBar.css'
import { ReactComponent as EditIcon } from '../../images/icons/pen.svg'
import Dropdown from 'react-bootstrap/Dropdown';

const InfoBar = () => {
    const GlobalStore = useContext(GlobalState);

    const editState = () => {
        window.location.reload();
        GlobalStore.setState('');
        GlobalStore.setBuildingType('');
        GlobalStore.setCanvasStyle("hidden")
    }

    const editBuildingType = () => {
        GlobalStore.setBuildingType('');
        GlobalStore.setCanvasStyle("hidden");
    }

    const renderBuildingTypeInfo = (data) => {
        return (
            <>
                <InfoBarItem active={GlobalStore.buildingType}>
                    <InfoBarItemLabel>Building Type</InfoBarItemLabel>
                    <InfoBarItemButton onClick={editBuildingType} >
                        <InfoBarItemButtonText>{data.productName}</InfoBarItemButtonText> < EditIcon />
                    </InfoBarItemButton>
                </InfoBarItem>
                <InfoBarItem active={GlobalStore.buildingType}>
                    <InfoBarItemLabel>Building Size</InfoBarItemLabel>
                    <InfoBarItemButton>
                        <InfoBarItemButtonText style={{ color: '#222' }}>
                            {
                                // outputs "12 W 21 L x 6 H" for example
                                `${data.width.split('’')[0]}’ W ${data.length.split('’')[0]}’ L x ${data.height.split('’')[0]}’ H`
                            }
                        </InfoBarItemButtonText>
                    </InfoBarItemButton >
                </InfoBarItem>

                <InfoBarItem style={{ zIndex: '1' }}>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{
                            padding: '0',
                            borderRadius: '50px',
                            border: '1px solid #222',
                            backgroundColor: '#fff'
                        }}>
                            <InfoBarItemPriceWrap>
                                <InfoBarItemPrice>
                                    <span>Price</span>
                                    {`${GlobalStore.price} $`}
                                </InfoBarItemPrice>
                                <InfoBarItemDeposit>
                                    <span>Deposit</span>
                                    {`${GlobalStore.deposit} $`}
                                </InfoBarItemDeposit>
                            </InfoBarItemPriceWrap>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <InfoBarDepositDropdown>
                                <div class="prices-total-deposit">
                                    <ul>
                                        <li>
                                            <aside class="deposit-price-label">Building Amount</aside>
                                            <aside class="deposit-price-value">$1605.00</aside>
                                        </li>
                                        <li class="d-none">
                                            <aside class="deposit-price-label">Manufacturer Discount</aside>
                                            <aside class="deposit-price-value">$0</aside>
                                        </li>
                                        <li class="highlight-deposit">
                                            <aside class="deposit-price-label">Sub Total</aside>
                                            <aside class="deposit-price-value">$1605.00</aside>
                                        </li>
                                        <li class=" d-none">
                                            <aside class="deposit-price-label">Sub Total With Gravy</aside>
                                            <aside class="deposit-price-value">$1605.00</aside>
                                        </li>
                                        <li>
                                            <aside class="deposit-price-label">Dealer Deposit</aside>
                                            <aside class="deposit-price-value">$160.50</aside>
                                        </li>
                                        <li class="d-none">
                                            <aside class="deposit-price-label">Dealer Discount</aside>
                                            <aside class="deposit-price-value">$(0)</aside>
                                        </li>
                                        <li class="">
                                            <aside class="deposit-price-label">Card Processing Fee</aside>
                                            <aside class="deposit-price-value">$0.00</aside>
                                        </li>
                                        <li class="d-none">
                                            <aside class="deposit-price-label">Gravy</aside>
                                            <aside class="deposit-price-value">$0.00</aside>
                                        </li>
                                        <li class="highlight-deposit">
                                            <aside class="deposit-price-label">Downpayment</aside>
                                            <aside class="deposit-price-value">$160.50</aside>
                                        </li>
                                        <li>
                                            <aside class="deposit-price-label">Sub Total - Deposit</aside>
                                            <aside class="deposit-price-value">$1444.50</aside>
                                        </li>
                                        <li class="d-none">
                                            <aside class="deposit-price-label">Tax on Sub Total</aside>
                                        </li>
                                        <li class="d-none">
                                            <aside class="deposit-price-label">Additional Charges</aside>
                                            <aside class="deposit-price-value">$0.00</aside>
                                        </li>
                                        <li class="deposit-price-label">
                                            <aside class="deposit-price-label">Due Upon Installation</aside>
                                            <aside class="deposit-price-value">$1444.50</aside>
                                        </li>
                                        <li class="highlight-deposit">
                                            <aside class="deposit-price-label">Grand Total</aside>
                                            <aside class="deposit-price-value">$1605.00</aside>
                                        </li>
                                    </ul>
                                </div>
                            </InfoBarDepositDropdown>
                        </Dropdown.Menu>
                    </Dropdown>
                </InfoBarItem>
            </>
        )
    }

    return (
        <InfoBarWrapper>
            {
                GlobalStore.state && <InfoBarItem active={GlobalStore.buildingType}>
                    <InfoBarItemLabel active={GlobalStore.buildingType}>State</InfoBarItemLabel>
                    <InfoBarItemButton onClick={editState}>
                        <InfoBarItemButtonText>{GlobalStore.state}</InfoBarItemButtonText> <EditIcon />
                    </InfoBarItemButton>
                </InfoBarItem>
            }

            {
                GlobalStore.buildingType && renderBuildingTypeInfo(GlobalStore.buildingType)
            }
        </InfoBarWrapper>
    )
}

export default InfoBar


