import styled from 'styled-components/macro';

export const CanvasWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;

    canvas { 
        width: 100%;
        height: 100%;
        outline: none;
    }

    @media (max-width: 991px) {
        height: 40vh;
        position: static;
    }
`;