export const garageDoorOptions = [
    { value: "6X6'", label: "6X6'", price: 300 },
    { value: "6X8'", label: "6X8'", price: 600 },
    { value: "8X10'", label: "8X10'", price: 900 },
    { value: "9X8'", label: "9X8'", price: 1200 },
    { value: "10X8'", label: "10X8'", price: 1500 },
    { value: "10X10'", label: "10X10'", price: 1800 },
    { value: "10X12'", label: "10X12'", price: 2100 },
    { value: "10X14'", label: "10X14'", price: 2400 },
    { value: "12X8'", label: "12X8'", price: 2700 },
    { value: "12X10'", label: "12X10'", price: 3000 },
    { value: "12X12'", label: "12X12'", price: 3300 },
    { value: "12X14'", label: "12X14'", price: 3600 },
    { value: "14X10'", label: "14X10'", price: 3900 },
    { value: "14X12'", label: "14X12'", price: 4200 },
    { value: "14X14'", label: "14X14'", price: 4500 },
    { value: "14X16'", label: "14X16'", price: 4800 },
    { value: "16X8'", label: "16X8'", price: 5100 },
    { value: "16X10'", label: "16X10'", price: 5400 },
    { value: "16X12'", label: "16X12'", price: 5700 },
    { value: "16X14'", label: "16X14'", price: 6000 },
    { value: "16X16'", label: "16X16'", price: 6300 },
    { value: "16X18'", label: "16X18'", price: 6600 },
    { value: "18X16'", label: "18X16'", price: 6900 },
    { value: "20X16'", label: "20X16'", price: 7200 }
]

export const walkInDoorOptions = [
    { value: "36X80'", label: "36x80'", price: 300 }
]

export const windowOptions = [
    { value: "24X36'", label: "24x36'", price: 300 },
    { value: "30X36'", label: "30x36'", price: 600 }
]
