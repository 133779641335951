/*
INIT: ensure Babel/Eslint/Flow is configured for ES Class Fields & Static Properties
JSX USAGE: <Iframe src='http://web.site' onLoad={myOnloadFunction}/>
*/
// import React, { Component, PropTypes } from 'react'
// import ReactDOM from 'react-dom'

// class Iframe extends Component {

//   static propTypes: Object = {
//     src: PropTypes.string.isRequired,
//     onLoad: PropTypes.func,
//   }

//   // componentDidMount () {
//   //   let iframe = ReactDOM.findDOMNode(this.refs.iframe)
//   //   iframe.addEventListener('load', this.props.onLoad);
//   // }

//   render () {
//     const iframeStyle = {
//       width: '100%',
//       height: '100%',
//       border: '0',
//       position: 'absolute',
//     }

//     return (
//       <iframe
//         ref="iframe"
//         {...this.props}
//         frameBorder={'0'}
//         width={'100%'}
//         height={'100%'}
//         style={iframeStyle}
//       />
//     )
//   }

// }

// export default Iframe
import React, { Component } from 'react'
import { createPortal } from 'react-dom'
export class IFrame extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mountNode: null
    }
    this.setContentRef = (contentRef) => {
      this.setState({
        mountNode: contentRef?.contentWindow?.document?.body
      })
    }
  }

  render() {
    const { children, ...props } = this.props
    const { mountNode } = this.state
    return (
      <iframe
        {...props}
        ref={this.setContentRef}
      >
        {mountNode && createPortal(children, mountNode)}
      </iframe>
    )
  }
}