import React, { useContext, useState, useEffect } from "react";
import {
  WallsScrollbarWrapper,
  WallsWrapper,
  WallsSelectWrapper,
  WallsWainscot,
  WallsWainscotColorWrapper,
} from "../Walls.css";

import InputSelect from "../../parts/InputSelect/InputSelect";
import SectionTitle from "../../../SectionTitle/SectionTitle";
import Separator from "../../../../utils/Separator/Separator";
import ColorPicker from "../../parts/ColorPicker/ColorPicker";
import { colorsOptionsPremium } from "../../parts/ColorPicker/ColorPickerData.json";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Color3, Plane, Axis, Space, Vector3, Scalar } from "@babylonjs/core";
import { GlobalState } from "../../../../App";
import {
  mainEndsWallClipPlanesChange,
  mainSidesWallClipPlanesChange,
  getBoundingBox,
} from "../../../../utils/functions";

const storageOptions = [
  { value: "5", label: "5 Feet" },
  { value: "10", label: "10 Feet" },
  { value: "15", label: "15 Feet" },
  // { value: "20", label: "20 Feet" },
  // { value: "25", label: "25 Feet" },
  // { value: "30", label: "30 Feet" },
  // { value: "35", label: "35 Feet" },
  // { value: "40", label: "40 Feet" },
  // { value: "45", label: "45 Feet" },
  // { value: "50", label: "50 Feet" },
  // { value: "55", label: "55 Feet" },
  // { value: "60", label: "60 Feet" },
  // { value: "65", label: "65 Feet" },
  // { value: "70", label: "70 Feet" },
  // { value: "75", label: "75 Feet" },
  // { value: "80", label: "80 Feet" },
  // { value: "85", label: "85 Feet" },
  // { value: "90", label: "90 Feet" },
  // { value: "95", label: "95 Feet" },
];

const sidesOptions = [
  { value: "Open", label: "Open" },
  // { value: "1 Panel (3')", label: "1 Panel (3')" },
  // { value: "2 Panel (6')", label: "2 Panel (6')" },
  // { value: "1/4 Closed", label: "1/4 Closed" },
  // { value: "1/2 Closed", label: "1/2 Closed" },
  // { value: "3/4 Closed", label: "3/4 Closed" },
  { value: "Closed", label: "Closed" },
];

const endsOptions = [
  { value: "Open", label: "Open" },
  // { value: "Gable", label: "Gable" },
  // { value: "1/4 Closed", label: "1/4 Closed" },
  // { value: "1/2 Closed", label: "1/2 Closed" },
  // { value: "3/4 Closed", label: "3/4 Closed" },
  { value: "Closed", label: "Closed" },
];

const insulationOptions = [
  { value: "Select", label: "Select" },
  { value: "2-fiber-glass", label: "2'' Fiber Glass" },
];
var j = 0;
var XScaleRT = [];
var ScaleMesh = [];
var XPosRT = [];
var pitchRT = "";

const WallsRight = () => {
  //console.log("Walls right triggered")
  var [IsStorage, SetIsStorage] = useState(false);
  const GlobalStore = useContext(GlobalState);
  const scene = GlobalStore.sceneGlobalRef.current;
  const wainscotMaterial = scene.getMaterialByName("wainscotMaterial");

  //const [verticalPanels, setVetricalPanels] = useState(false);
  //const [leftSidePanel, setLeftSidePanel] = useState(false);
  //const [rightSidePanel, setRightSidePanel] = useState(false);

  const [insulationValue, setInsulationValue] = useState("Select");
  const [insulationCheckboxes, setInsulationCheckboxes] = useState(false);

  const [insulationRadio, setInsulationRadio] = useState("roof-only");

  const [wall, setWall] = useState("open");

  //const [jTrim, setJTrim] = useState(false);

  const [wainscot, setWainscot] = useState(false);

  //const [wallIsOpen, setWallIsOpen] = useState(true);
  var pitchRT;
  const presetData = GlobalStore.buildingType.customizationData.rightLeanTo;
  useEffect(() => {
    XScaleRT = [];
    ScaleMesh = [];
    XPosRT = [];

    pitchRT =
      GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] ==
          1
        ? "Normal"
        : "UP";

    if (!GlobalStore.isWallRTInit.current) {
      if (GlobalStore.buildingType.id != 8) {
        // GlobalStore.StorageMeshRT.current[1].scaling = GlobalStore.MeshStorageLTScale.current[GlobalStore.buildingType.id];
        // GlobalStore.StorageMeshRT.current[1].position=GlobalStore.MeshStorageLTPos.current[GlobalStore.buildingType.id];

        GlobalStore.StorageMeshRT.current[1].parent =
          GlobalStore.MeshRT.current[1];
        var C =
          GlobalStore.MeshStorageLTScale.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshRT.current[1].parent.scaling;

        GlobalStore.StorageMeshRT.current[1].scaling = new Vector3(1, 1, 1); //og/pa

        var C =
          GlobalStore.MeshStorageLTPos.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshRT.current[1].parent.position;
        GlobalStore.StorageMeshRT.current[1].position = new Vector3(
          (C.x - A.x) * 9,
          0,
          (C.z - A.z) * 9
        );
        //  console.error("B_["+GlobalStore.StorageMeshRT.current[1].scaling+"]");

        GlobalStore.isWallRTInit.current = true;
      } else {
        GlobalStore.StorageMeshRT.current[1].parent =
          GlobalStore.MeshRT.current[1];
        var C =
          GlobalStore.MeshStorageLTScale.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshRT.current[1].parent.scaling;

        GlobalStore.StorageMeshRT.current[1].scaling = new Vector3(1, 1, 1); //og/pa

        var C =
          GlobalStore.MeshStorageLTPos.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshRT.current[1].parent.position;
        GlobalStore.StorageMeshRT.current[1].position = new Vector3(
          (C.x - A.x) * 9,
          0,
          (C.z - A.z) * 9
        );
        // GlobalStore.StorageMeshMain.current[8].position=GlobalStore.MeshStoragePos.current[GlobalStore.buildingType.id];
        // GlobalStore.StorageMeshMain.current[8].scaling=GlobalStore.MeshStorageScale.current[GlobalStore.buildingType.id];
        GlobalStore.isWallRTInit.current = true;
        //GlobalStore.StorageMeshMain.current[1].setEnabled(false)
      }
    }
    if (GlobalStore.buildingType.id != 8) {
      ScaleMesh.push(GlobalStore.StorageMeshRT.current[1]);
      //if(Scale.length<=0)

      GlobalStore.StorageMeshRT.current[1].getChildren().forEach((m) => {
        if (m.name.includes("Type1_RT_Storage")) {
          {
            if (m.name.includes(pitchRT)) {
              //if(GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id])
              // console.error(scene.meshes[i].name);
              m.isVisible = true;
            } else if (
              m.name.includes("Down") ||
              m.name.includes("Normal") ||
              m.name.includes("UP")
            ) {
              m.isVisible = false;
            }
            // scene.meshes[i].isVisible=true;
            j++;
          }
        }
      });
    } else {
      ScaleMesh.push(GlobalStore.StorageMeshRT.current[1]);
      //if(Scale.length<=0)

      console.log(
        GlobalStore.StorageMeshRT.current[1].getChildren().length,
        " this is the length"
      );
      GlobalStore.StorageMeshRT.current[1].getChildren().forEach((m) => {
        if (m.name.includes("Storage")) {
          {
            if (m.name.includes(pitchRT)) {
              //if(GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id])
              // console.error(scene.meshes[i].name);
              //console.log("We finally did it");
              m.isVisible = true;
            } else if (
              m.name.includes("Down") ||
              m.name.includes("Normal") ||
              m.name.includes("UP")
            ) {
              m.isVisible = false;
            }
            // scene.meshes[i].isVisible=true;
            j++;
          }
        }
      });
    }

    //j=0;

    // for(var i=0;i<scene.meshes.length;i++)
    // {
    //     if(scene.meshes[i].name.includes("Type1_RT_Storage"))
    //     {
    //         if(scene.meshes[i].name.includes(pitchRT))
    //         {
    //             if(GlobalStore.storageRightMeshes.current[GlobalStore.buildingType.id] && GlobalStore.isRightLeanActive.current[GlobalStore.buildingType.id]=="true")
    //             scene.meshes[i].isVisible=true;
    //         }
    //         else if((scene.meshes[i].name.includes("Down")||
    //         scene.meshes[i].name.includes("Normal")||
    //         scene.meshes[i].name.includes("UP"))){ scene.meshes[i].isVisible=false;}
    //        // scene.meshes[i].isVisible=true;
    //        scene.meshes[i].position=GlobalStore.MeshStorageLTPos.current[GlobalStore.buildingType.id];
    //        scene.meshes[i].scaling=GlobalStore.MeshStorageLTScale.current[GlobalStore.buildingType.id];

    //         ScaleMesh.push(scene.meshes[i]);
    //         j++;
    //     }
    // }
    for (var i = 0; i < storageOptions.length; i = i + 1) {
      XScaleRT.push(ScaleMesh[0].scaling.x * (i / 6) + 1 / 6);
    }
    switch (presetData.wallStyle) {
      case "open":
        handleFullyOpen();
        handleWallChange(null, "open");
        break;
      case "enclosed":
        handleFullyEnclosed();
        handleWallChange(null, "enclosed");
        break;

      default:
        break;
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWainscot(false);
  }, [wall]);

  const handleWallChange = (e, i) => {
    i ? setWall(i) : setWall(e.target.value);
    // switch (e.target.value) {
    //     case 'open':
    //         setWallIsOpen(true)
    //         break;
    //     case 'enclosed':
    //         setWallIsOpen(false);
    //         break;

    //     default:
    //         break;
    // }
    //console.log(e.target.value);
  };
  // const handleVerticalPanels = () => {
  //     setVetricalPanels(!verticalPanels)
  // }
  // const handleLeftVerticalPanel = () => {
  //     setLeftSidePanel(!leftSidePanel)
  // }
  // const handleRightVerticalPanel = () => {
  //     setRightSidePanel(!rightSidePanel)
  // }
  const handleInsulationChange = (e) => {
    setInsulationValue(e.target.value);
    if (e.target.value === "Select") {
      setInsulationCheckboxes(false);
      setInsulationRadio("roof-only");
    } else {
      setInsulationCheckboxes(true);
    }
  };
  const handleInsulationRadio = (event) => {
    setInsulationRadio(event.target.value);
  };
  // const handleJTrim = () => {
  //     setJTrim(!jTrim)
  // }
  const handleWainscot = () => {
    if (!wainscot) {
      //later
      for (var i = 0; i < scene.meshes.length; i++) {
        if (
          scene.meshes[i].name.includes("Type" + GlobalStore.buildingType.id) &&
          scene.meshes[i].name.includes("Wainscot") &&
          scene.meshes[i].name.includes("_RT")
        ) {
          {
            //  scene.meshes[i].isVisible=false;

            scene.meshes[i].material.diffuseColor = new Color3.FromHexString(
              "#ffffff"
            ).toLinearSpace();
            //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
            // material.reflectionTexture = envTexture;
            scene.meshes[i].material.metallic = 0.7;
            scene.meshes[i].material.roughness = 0.7;
          }
        }
      }
    } else {
      var wainscotMat;
      for (var i = 0; i < scene.meshes.length; i++) {
        if (
          scene.meshes[i].name.includes("Type" + GlobalStore.buildingType.id) &&
          scene.meshes[i].name.includes("walls") &&
          scene.meshes[i].name.includes("_RT")
        ) {
          wainscotMat = scene.meshes[i].material.diffuseColor;
        }

        if (
          scene.meshes[i].name.includes("Type" + GlobalStore.buildingType.id) &&
          scene.meshes[i].name.includes("Wainscot") &&
          scene.meshes[i].name.includes("_RT")
        ) {
          {
            //  scene.meshes[i].isVisible=false;

            scene.meshes[i].material.diffuseColor = wainscotMat;
            //material.albedoColor = new Color3.FromHexString("#6f2026").toLinearSpace();
            // material.reflectionTexture = envTexture;
            scene.meshes[i].material.metallic = 0.7;
            scene.meshes[i].material.roughness = 0.7;
          }
        }
      }
    }
    setWainscot(!wainscot);
  };
  const handleWainscotColor = (color) => {
    wainscotMaterial.albedoColor = new Color3.FromHexString(
      color.value
    ).toLinearSpace();
  };
  const handleFullyOpen = () => {
    GlobalStore.setRightWallStyle("open");

    GlobalStore.setRTBackWall("open");
    GlobalStore.setRTFrontWall("open");
    GlobalStore.setRTRightWall("open");
    GlobalStore.setRTLeftWall("open");

    GlobalStore.IsWallRTOpen.current[GlobalStore.buildingType.id] = true;

    //code used to remove excess doors and windows
    let objectsToRemove = [
      ...GlobalStore.RTFrontWallObjects,
      ...GlobalStore.RTBackWallObjects,
      ...GlobalStore.RTRightWallObjects,
    ];

    for (let i = 0; i < objectsToRemove.length; i++) {
      objectsToRemove.object.dispose();
    }
    GlobalStore.setRTFrontWallObjects([]);
    GlobalStore.setRTBackWallObjects([]);
    GlobalStore.setRTRightWallObjects([]);

    for (var i = 0; i < scene.meshes.length; i++) {
      if (scene.meshes[i].name.includes("Type1")) {
        if (
          !scene.meshes[i].name.includes("Storage") &&
          scene.meshes[i].name.includes("_RT")
        ) {
          {
            if (
              scene.meshes[i].name.includes("Wainscot") ||
              scene.meshes[i].name.includes("walls") ||
              scene.meshes[i].name.includes("SIDE") ||
              scene.meshes[i].name.includes("END")
            )
              scene.meshes[i].isVisible = false;
          }
        }
      }
    }

    handleAddStorage("5");
    // const clipPlane = new Plane(0, -1, 0, 1000);
    // GlobalStore.rightLeanWallMeshes.current.map((wall) => {
    //     wall.onBeforeRenderObservable.add(function () {
    //         scene.resetCachedMaterial();
    //         scene.clipPlane2 = clipPlane;
    //     });
    //     wall.onAfterRenderObservable.add(function () {
    //         scene.clipPlane2 = null;
    //     });
    //     return true
    // });

    // GlobalStore.setPrice(GlobalStore.price - 300);
    // GlobalStore.setSelectedEndFront_RL("Open");
    // GlobalStore.setSelectedEndBack_RL('Open');
    // GlobalStore.setSelectedSidesRight_RL('Open');
  };
  const handleFullyEnclosed = () => {
    GlobalStore.setRightWallStyle("enclosed");
    GlobalStore.setRTBackWall("enclosed");
    GlobalStore.setRTFrontWall("enclosed");
    GlobalStore.setRTRightWall("enclosed");
    GlobalStore.setRTLeftWall("enclosed");

    GlobalStore.IsWallRTOpen.current[GlobalStore.buildingType.id] = false;

    var pitchRT =
      GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] ==
          1
        ? "Normal"
        : "UP";
    for (var i = 0; i < scene.meshes.length; i++) {
      if (scene.meshes[i].name.includes("Type1")) {
        if (
          !scene.meshes[i].name.includes("Storage") &&
          scene.meshes[i].name.includes("_RT")
        ) {
          if (
            scene.meshes[i].name.includes("Wainscot") ||
            scene.meshes[i].name.includes("walls")
          )
            scene.meshes[i].isVisible = true;
          if (scene.meshes[i].name.includes(pitchRT)) {
            if (
              !(
                scene.meshes[i].name.includes("SIDE") ||
                scene.meshes[i].name.includes("END")
              )
            )
              scene.meshes[i].isVisible = true;
          } else if (
            !(
              scene.meshes[i].name.includes("Down") ||
              scene.meshes[i].name.includes("Normal") ||
              scene.meshes[i].name.includes("UP")
            )
          ) {
            scene.meshes[i].isVisible = true;
          }
        }
      }
    }
    // const clipPlane = new Plane(0, -1, 0, 0);
    // GlobalStore.rightLeanWallMeshes.current.map((wall) => {
    //     wall.onBeforeRenderObservable.add(function () {
    //         scene.resetCachedMaterial();
    //         scene.clipPlane2 = clipPlane;
    //     });
    //     wall.onAfterRenderObservable.add(function () {
    //         scene.clipPlane2 = null;
    //     });
    //     return true
    // });
    // GlobalStore.setPrice(GlobalStore.price + 300);
    // GlobalStore.setSelectedEndFront_RL("Closed");
    // GlobalStore.setSelectedEndBack_RL('Closed');
    // GlobalStore.setSelectedSidesRight_RL('Closed');
  };
  const handleEndsFront = (targetValue) => {
    switch (targetValue) {
      case "Open":
        GlobalStore.setRTFrontWall("open");

        //code used to delete doors and windows
        let objectsToRemove = [...GlobalStore.RTFrontWallObjects];

        for (let i = 0; i < objectsToRemove.length; i++) {
          objectsToRemove.object.dispose();
        }
        GlobalStore.setRTFrontWallObjects([]);

        break;
      case "Closed":
        GlobalStore.setRTFrontWall("enclosed");
        break;
    }

    /*if( GlobalStore.IsWallRTOpen.current[GlobalStore.buildingType.id])return;

        var pitchRT=GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id]==0?"Down":
        (GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id]==1?"Normal":"UP");
        switch (targetValue) {

            case "Gable":
                break;
            case "Open":
                for(var i=0;i<scene.meshes.length;i++)
                {
                    if(!scene.meshes[i].name.includes("Storage")&&scene.meshes[i].name.includes("Type1"))
                    {
                        if(
                            
                            (!scene.meshes[i].name.includes("Storage"))&&(scene.meshes[i].name.includes("_RT"))
                             && (scene.meshes[i].name.includes("front"))
                             &&(scene.meshes[i].name.includes("walls"))
                             )
                        {
                            scene.meshes[i].isVisible=false;
        
                        }
                        else if(!scene.meshes[i].name.includes("Storage")&&(scene.meshes[i].name.includes("END") || scene.meshes[i].name.includes("SIDE")))  // design fault
                        {
                            scene.meshes[i].isVisible=false;
                            
                        }
                    }
                }
        
                break;
            case "Closed":
                for(var i=0;i<scene.meshes.length;i++)
                {
                    if(!scene.meshes[i].name.includes("Storage")&&scene.meshes[i].name.includes("Type1"))
                    {
                        if(
                            
                            (scene.meshes[i].name.includes("_RT"))
                            && (scene.meshes[i].name.includes("front"))
                            &&(scene.meshes[i].name.includes("walls"))
                             )
                        {
                          //  if(scene.meshes[i].name.includes("Wainscot") ||scene.meshes[i].name.includes("walls"))
                          //  console.error(scene.meshes[i].name);

                            if(scene.meshes[i].name.includes(pitchRT))  
                            {
                                scene.meshes[i].isVisible=true;
                            }
                            else if(!scene.meshes[i].name.includes("Storage") && !(scene.meshes[i].name.includes("Down")||
                            scene.meshes[i].name.includes("Normal")||
                            scene.meshes[i].name.includes("UP"))){ scene.meshes[i].isVisible=true;}
        
                        }
                    }
                }
        
                break;

        }
        // const groundRef = scene.getMeshByName("Cylinder001");
        // const wallHeightReference = scene.getMeshByName("dimensionHelper_R");
        // const rightLeanFrontWalls = scene.getTransformNodeByName("frontWall_RL");
        // const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
        // const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        // GlobalStore.setSelectedEndFront_RL(targetValue)
        // mainEndsWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [rightLeanFrontWalls], scene)
    */
  };
  const handleEndsBack = (targetValue) => {
    switch (targetValue) {
      case "Open":
        GlobalStore.setRTBackWall("open");
        //code used to delete doors and windows
        let objectsToRemove = [...GlobalStore.RTBackWallObjects];

        for (let i = 0; i < objectsToRemove.length; i++) {
          objectsToRemove.object.dispose();
        }
        GlobalStore.setRTBackWallObjects([]);
        break;
      case "Closed":
        GlobalStore.setRTBackWall("enclosed");
        break;
    }
    /*if( GlobalStore.IsWallRTOpen.current[GlobalStore.buildingType.id])return;

        var pitchRT=GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id]==0?"Down":
        (GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id]==1?"Normal":"UP");
        switch (targetValue) {

            case "Gable":
                break;
            case "Open":
                for(var i=0;i<scene.meshes.length;i++)
                {
                    if(!scene.meshes[i].name.includes("Storage")&&scene.meshes[i].name.includes("Type1")&&(scene.meshes[i].name.includes("_RT")))
                    {
                        if(
                            
                            (!scene.meshes[i].name.includes("Storage"))
                             && (scene.meshes[i].name.includes("back"))
                             &&(scene.meshes[i].name.includes("walls"))
                             )
                        {
                            scene.meshes[i].isVisible=false;
        
                        }
                    }
                }
        
                break;
            case "Closed":
                for(var i=0;i<scene.meshes.length;i++)
                {
                    if(!scene.meshes[i].name.includes("Storage")&&scene.meshes[i].name.includes("Type1")&&(scene.meshes[i].name.includes("_RT")))
                    {
                        if(
                            
                            
                             (scene.meshes[i].name.includes("back"))
                            &&(scene.meshes[i].name.includes("walls"))
                             )
                        {
                            if(scene.meshes[i].name.includes(pitchRT))  
                            {
                                scene.meshes[i].isVisible=true;
                            }
                            else if(!(scene.meshes[i].name.includes("Down")||
                            scene.meshes[i].name.includes("Normal")||
                            scene.meshes[i].name.includes("UP"))){ scene.meshes[i].isVisible=true;}
        
                        }
                    }
                }
        
                break;

        }
        // const groundRef = scene.getMeshByName("Cylinder001");
        // const wallHeightReference = scene.getMeshByName("dimensionHelper_R");
        // const rightLeanBackWalls = scene.getTransformNodeByName("backWall_RL");
        // const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
        // const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        // GlobalStore.setSelectedEndBack_RL(targetValue)
        // mainEndsWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [rightLeanBackWalls], scene)
    */
  };
  const handleSidesRight = (targetValue) => {
    switch (targetValue) {
      case "Open":
        GlobalStore.setRTRightWall("open");
        //code used to delete doors and windows
        let objectsToRemove = [...GlobalStore.RTRightWallObjects];

        for (let i = 0; i < objectsToRemove.length; i++) {
          objectsToRemove.object.dispose();
        }
        GlobalStore.setRTRightWallObjects([]);
        break;
      case "Closed":
        GlobalStore.setRTRightWall("enclosed");
        break;
    }

    /*if( GlobalStore.IsWallRTOpen.current[GlobalStore.buildingType.id])return;

        var pitchRT=GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id]==0?"Down":
        (GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id]==1?"Normal":"UP");
        switch (targetValue) {

            case "Gable":
                break;
            case "Open":
                for(var i=0;i<scene.meshes.length;i++)
                {
                    if(!scene.meshes[i].name.includes("Storage")&&scene.meshes[i].name.includes("Type1"))
                    {
                        if(
                            
                            (!scene.meshes[i].name.includes("Storage"))&&(scene.meshes[i].name.includes("_RT"))
                             && (scene.meshes[i].name.includes("right_walls")))
                        {
                            scene.meshes[i].isVisible=false;
        
                        }
                    }
                }
        
                break;
            case "Closed":
                for(var i=0;i<scene.meshes.length;i++)
                {
                    if(!scene.meshes[i].name.includes("Storage")&&scene.meshes[i].name.includes("Type1"))
                    {
                        if(
                            
                            scene.meshes[i].name.includes("_RT")
                            && ((scene.meshes[i].name.includes("right_walls"))
                            ||scene.meshes[i].name.includes("right_inner_walls")))
                             
                        {
                            if(scene.meshes[i].name.includes(pitchRT))  
                            {
                                scene.meshes[i].isVisible=true;
                            }
                            else if(!(scene.meshes[i].name.includes("Down")||
                            scene.meshes[i].name.includes("Normal")||
                            scene.meshes[i].name.includes("UP"))){ scene.meshes[i].isVisible=true;}
        
                        }
                    }
                }
        
                break;
            }
        // const groundRef = scene.getMeshByName("Cylinder001");
        // const wallHeightReference = scene.getMeshByName("dimensionHelper_R");
        // const rightLeanSideWalls = scene.getTransformNodeByName("sideWall_RL");
        // const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
        // const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
        // GlobalStore.setSelectedSidesRight_RL(targetValue)
        // mainSidesWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [rightLeanSideWalls], scene)
        */
  };
  const handleWallColor = (color) => {
    GlobalStore.MeshRT.current[1].getChildren().forEach((m) => {
      //         if(m.name.includes("Type1_LT_Storage"))
      //         {
      //             {
      if (m.name.includes("_RT") && m.name.includes("walls")) {
        m.material.diffuseColor = new Color3.FromHexString(
          color.value
        ).toLinearSpace();
      }
    });
    GlobalStore.StorageMeshRT.current[1].getChildren().forEach((m) => {
      //         if(m.name.includes("Type1_LT_Storage"))
      //         {
      //             {
      if (m.name.includes("_RT") && m.name.includes("walls")) {
        m.material.diffuseColor = new Color3.FromHexString(
          color.value
        ).toLinearSpace();
      }
    });

    // for(var i=0;i<scene.meshes.length;i++)
    // {
    //     if(scene.meshes[i].name.includes("1"+GlobalStore.buildingType.id))
    //     {
    //         if(

    //             (scene.meshes[i].name.includes("_RT"))
    //              && (scene.meshes[i].name.includes("walls"))
    //              )
    //         {
    //             scene.meshes[i].material.diffuseColor  =  new Color3.FromHexString(color.value).toLinearSpace();

    //         }
    //     }
    // }
    // const wallMaterials = GlobalStore.outerWallsMaterials.current;
    // wallMaterials.map(mat => mat.albedoColor = new Color3.FromHexString(color.value).toLinearSpace())
  };
  const handleStorage = () => {
    GlobalStore.setIsStorageRTOn(!GlobalStore.isStorageRTOn);
  };
  const handleAddStorage = (targetValue) => {
    switch (targetValue) {
      case "5":
        GlobalStore.StorageMeshRT.current[1].getChildren().forEach((mesh) => {
          mesh.scaling.x = XScaleRT[0];
        });
        break;
      case "10":
        GlobalStore.StorageMeshRT.current[1].getChildren().forEach((mesh) => {
          mesh.scaling.x = XScaleRT[1];
        });
        break;
      case "15":
        GlobalStore.StorageMeshRT.current[1].getChildren().forEach((mesh) => {
          mesh.scaling.x = XScaleRT[2];
        });
        break;
      default:
        break;
    }


    setTimeout(() => {

      let bounds = getBoundingBox(GlobalStore.RTStorageBoundingBox.current)

      GlobalStore.RTFrontStorageWallObjects.forEach((object) => {
        object.object.position.x = bounds.maxWorld.x;
      })

      let sideObjects = [...GlobalStore.RTRightStorageWallObjects];

      sideObjects.forEach((object) => {
        object.object.position.x = (bounds.maxWorld.x + bounds.minWorld.x) / 2;

        object.pointer.validateDrag = (targetPosition) => {
          targetPosition.x = Scalar.Clamp(
            targetPosition.x,
            bounds.minWorld.x+object.limit,
            bounds.maxWorld.x-object.limit
          )

          targetPosition.y = Scalar.Clamp(
            targetPosition.y,
            13,
            object.heightOffset
          );
          return true;
        }
      })

      

    },10);
    // const leanParent_R = scene.getMeshByName("Lean_Right_Helper");
    // let leanStorage_R;
    // leanParent_R.getChildMeshes().map(mesh => {
    //     if (mesh.name === "storage_lean_R") {
    //         leanStorage_R = mesh;
    //     }
    //     return 0;
    // })

    // leanParent_R.computeWorldMatrix(true);
    // const storagePlaneDistance = leanParent_R.getBoundingInfo().boundingBox.minimumWorld.x;
    // GlobalStore.leanRLengthFromCenter.current = storagePlaneDistance;

    // leanStorage_R.setAbsolutePosition(storagePlaneDistance, leanStorage_R.getAbsolutePosition().y, leanStorage_R.getAbsolutePosition().z)
    // leanStorage_R.translate(Axis.Y, targetValue / 5 * 2.4, Space.LOCAL);
    // GlobalStore.leanRStorageTranslation.current = targetValue;
  };

  return (
    <WallsScrollbarWrapper>
      <WallsWrapper>
        <RadioGroup
          aria-label="walls"
          name="walls"
          value={wall}
          onChange={handleWallChange}
          row
        >
          <FormControlLabel
            onChange={handleFullyOpen}
            value="open"
            control={<Radio />}
            label="Open"
          />
          <FormControlLabel
            onChange={handleFullyEnclosed}
            value="enclosed"
            control={<Radio />}
            label="Fully Enclosed"
          />
        </RadioGroup>
        <Separator marginBottom={"15px"} />
        <FormControlLabel
          control={
            <Checkbox
              checked={GlobalStore.isStorageRTOn}
              onChange={handleStorage}
              name="storage"
              color="primary"
            />
          }
          style={{ color: "#222" }}
          label="Add Storage"
        />
        <WallsSelectWrapper checked={true}>
          <InputSelect
            onChange={handleAddStorage}
            label="Utility Enclosed Area"
            options={storageOptions}
            inputName="add-storage"
          />
        </WallsSelectWrapper>

        <Separator />

        <SectionTitle title={"ENDS"} />

        <InputSelect
          onChange={handleEndsFront}
          label="Front"
          options={endsOptions}
          inputName="ends-front"
        />

        <Separator marginBottom="15px" />

        <InputSelect
          onChange={handleEndsBack}
          label="Back"
          options={endsOptions}
          inputName="ends-back"
        />

        <Separator marginBottom="15px" />

        <SectionTitle title={"SIDES"} />

        <InputSelect
          onChange={handleSidesRight}
          label="Right"
          options={sidesOptions}
          inputName="sides-right"
        />

        {/* <Separator marginBottom='15px' />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={jTrim}
                            onChange={handleJTrim}
                            name="vertical-panels"
                            color="primary"
                        />
                    }
                    style={{ color: '#222' }}
                    label="J-Trim"
                /> */}

        <Separator marginBottom="30px" />

        <ColorPicker
          onChange={handleWallColor}
          title="Wall"
          colorsOptions={colorsOptionsPremium}
          checked={GlobalStore.wallsColor}
        />

        <Separator />

        <FormControlLabel
          control={
            <Checkbox checked={true} name="add-insulation" color="primary" />
          }
          style={{
            color: "#222",
          }}
          label="Add Insulation"
        />
        <br />
        <FormControl fullWidth>
          <InputLabel style={{ fontSize: "11px" }} id="insulation-select-label">
            Insulation
          </InputLabel>
          <Select
            value={insulationValue}
            onChange={handleInsulationChange}
            inputProps={{ "aria-label": "Without label" }}
            fullWidth
          >
            {insulationOptions.map(({ label, value }, i) => {
              return (
                <MenuItem value={value} key={i}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {insulationCheckboxes && (
          <>
            <RadioGroup
              aria-label="insulation-radio"
              name="insulation-radio"
              value={insulationRadio}
              onChange={handleInsulationRadio}
              style={{ padding: "0 8px" }}
            >
              <FormControlLabel
                value="roof-only"
                control={<Radio />}
                label="Roof Only"
              />
              <FormControlLabel
                value="full-building"
                control={<Radio />}
                label="Full Building"
              />
            </RadioGroup>
          </>
        )}

        <Separator />
      </WallsWrapper>
    </WallsScrollbarWrapper>
  );
};

export default WallsRight;
