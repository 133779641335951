import React, { useContext, useState, useEffect } from "react";
import {
  WallsScrollbarWrapper,
  WallsWrapper,
  WallsSelectWrapper,
  WallsWainscot,
  WallsWainscotColorWrapper,
} from "../Walls.css";

import InputSelect from "../../parts/InputSelect/InputSelect";
import SectionTitle from "../../../SectionTitle/SectionTitle";
import Separator from "../../../../utils/Separator/Separator";
import ColorPicker from "../../parts/ColorPicker/ColorPicker";
import { colorsOptionsPremium } from "../../parts/ColorPicker/ColorPickerData.json";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Color3, Plane, Axis, Space, Vector3, Scalar } from "@babylonjs/core";
import { GlobalState } from "../../../../App";
import {
  mainEndsWallClipPlanesChange,
  mainSidesWallClipPlanesChange,
} from "../../../../utils/functions";
import { GlowLayer } from "react-babylonjs";
import { getBoundingBox } from "../../../../utils/functions";

const storageOptions = [
  { value: "5", label: "5 Feet" },
  { value: "10", label: "10 Feet" },
  { value: "15", label: "15 Feet" },
  // { value: "20", label: "20 Feet" },
  // { value: "25", label: "25 Feet" },
  // { value: "30", label: "30 Feet" },
  // { value: "35", label: "35 Feet" },
  // { value: "40", label: "40 Feet" },
  // { value: "45", label: "45 Feet" },
  // { value: "50", label: "50 Feet" },
  // { value: "55", label: "55 Feet" },
  // { value: "60", label: "60 Feet" },
  // { value: "65", label: "65 Feet" },
  // { value: "70", label: "70 Feet" },
  // { value: "75", label: "75 Feet" },
  // { value: "80", label: "80 Feet" },
  // { value: "85", label: "85 Feet" },
  // { value: "90", label: "90 Feet" },
  // { value: "95", label: "95 Feet" },
];

const sidesOptions = [
  { value: "Open", label: "Open" },
  // { value: "1 Panel (3')", label: "1 Panel (3')" },
  // { value: "2 Panel (6')", label: "2 Panel (6')" },
  // { value: "1/4 Closed", label: "1/4 Closed" },
  // { value: "1/2 Closed", label: "1/2 Closed" },
  // { value: "3/4 Closed", label: "3/4 Closed" },
  { value: "Closed", label: "Closed" },
];

const endsOptions = [
  { value: "Open", label: "Open" },
  // { value: "Gable", label: "Gable" },
  // { value: "1/4 Closed", label: "1/4 Closed" },
  // { value: "1/2 Closed", label: "1/2 Closed" },
  // { value: "3/4 Closed", label: "3/4 Closed" },
  { value: "Closed", label: "Closed" },
];

const insulationOptions = [
  { value: "Select", label: "Select" },
  { value: "2-fiber-glass", label: "2'' Fiber Glass" },
];

var j = 0;
var XScaleLT = [];
var ScaleMesh = [];
var XPosRT = [];
var pitchLT = "";
const WallsLeft = () => {
  console.log("Walls left triggered");
  var [IsStorage, SetIsStorage] = useState(false);
  const GlobalStore = useContext(GlobalState);
  const scene = GlobalStore.sceneGlobalRef.current;
  const wainscotMaterial = scene.getMaterialByName("wainscotMaterial");
  //  const [verticalPanels, setVetricalPanels] = useState(false);
  // const [storage, setStorage] = useState(false);

  // const [leftSidePanel, setLeftSidePanel] = useState(false);
  // const [rightSidePanel, setRightSidePanel] = useState(false);

  const [insulationValue, setInsulationValue] = useState("Select");
  const [insulationCheckboxes, setInsulationCheckboxes] = useState(false);

  const [insulationRadio, setInsulationRadio] = useState("roof-only");

  const [wall, setWall] = useState("open");

  // const [jTrim, setJTrim] = useState(false);

  const [wainscot, setWainscot] = useState(false);

  // const [wallIsOpen, setWallIsOpen] = useState(true);

  const presetData = GlobalStore.buildingType.customizationData.leftLeanTo;
  useEffect(() => {
    XScaleLT = [];
    ScaleMesh = [];
    XPosRT = [];

    pitchLT =
      GlobalStore.MeshMiddleLTPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleLTPitch.current[GlobalStore.buildingType.id] ==
          1
        ? "Normal"
        : "UP";

    if (!GlobalStore.isWallLTInit.current) {
      if (GlobalStore.buildingType.id != 8) {
        GlobalStore.StorageMeshRT.current[1].scaling =
          GlobalStore.MeshStorageLTScale.current[GlobalStore.buildingType.id];
        GlobalStore.StorageMeshRT.current[1].position =
          GlobalStore.MeshStorageLTPos.current[GlobalStore.buildingType.id];

        GlobalStore.StorageMeshLT.current[1].parent =
          GlobalStore.MeshLT.current[1];
        var C =
          GlobalStore.MeshStorageLTScale.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshLT.current[1].parent.scaling;

        GlobalStore.StorageMeshLT.current[1].scaling = new Vector3(1, 1, 1); //og/pa

        var C =
          GlobalStore.MeshStorageLTPos.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshLT.current[1].parent.position;
        GlobalStore.StorageMeshLT.current[1].position = new Vector3(
          (C.x - A.x) * 9,
          0,
          (C.z - A.z) * 9
        );
        //  console.error("B_["+GlobalStore.StorageMeshRT.current[1].scaling+"]");

        GlobalStore.isWallLTInit.current = true;
      } else {
        GlobalStore.StorageMeshLT.current[1].parent =
          GlobalStore.MeshLT.current[1];
        var C =
          GlobalStore.MeshStorageLTScale.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshLT.current[1].parent.scaling;

        GlobalStore.StorageMeshLT.current[1].scaling = new Vector3(1, 1, 1); //og/pa

        var C =
          GlobalStore.MeshStorageLTPos.current[GlobalStore.buildingType.id];
        var A = GlobalStore.StorageMeshLT.current[1].parent.position;
        GlobalStore.StorageMeshLT.current[1].position = new Vector3(
          (C.x - A.x) * 9,
          0,
          (C.z - A.z) * 9
        );
        // GlobalStore.StorageMeshMain.current[8].position=GlobalStore.MeshStoragePos.current[GlobalStore.buildingType.id];
        // GlobalStore.StorageMeshMain.current[8].scaling=GlobalStore.MeshStorageScale.current[GlobalStore.buildingType.id];
        GlobalStore.isWallLTInit.current = true;
        //GlobalStore.StorageMeshMain.current[1].setEnabled(false);
      }
      if (GlobalStore.buildingType.id != 8) {
        ScaleMesh.push(GlobalStore.StorageMeshLT.current[1]);
        //if(Scale.length<=0)
        console.log(
          GlobalStore.StorageMeshLT.current[1].getChildren().length,
          " this is the length"
        );
        GlobalStore.StorageMeshLT.current[1].getChildren().forEach((m) => {
          if (m.name.includes("Storage")) {
            {
              if (m.name.includes(pitchLT)) {
                //if(GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id])
                // console.error(scene.meshes[i].name);
                //console.log("We finally did it");
                m.isVisible = true;
              } else if (
                m.name.includes("Down") ||
                m.name.includes("Normal") ||
                m.name.includes("UP")
              ) {
                m.isVisible = false;
              }
              // scene.meshes[i].isVisible=true;
              j++;
            }
          }
        });
      } else {
        ScaleMesh.push(GlobalStore.StorageMeshLT.current[1]);
        //if(Scale.length<=0)

        console.log(
          GlobalStore.StorageMeshLT.current[1].getChildren().length,
          " this is the length"
        );
        GlobalStore.StorageMeshLT.current[1].getChildren().forEach((m) => {
          if (m.name.includes("Storage")) {
            {
              if (m.name.includes(pitchLT)) {
                //if(GlobalStore.storageMainMeshes.current[GlobalStore.buildingType.id])
                // console.error(scene.meshes[i].name);
                //console.log("We finally did it");
                m.isVisible = true;
              } else if (
                m.name.includes("Down") ||
                m.name.includes("Normal") ||
                m.name.includes("UP")
              ) {
                m.isVisible = false;
              }
              // scene.meshes[i].isVisible=true;
              j++;
            }
          }
        });
      }

      GlobalStore.LTBoundingBox.current.isVisible=false;
    }

    // j=0;
    // var pitchLT=GlobalStore.MeshMiddleLTPitch.current[GlobalStore.buildingType.id]==0?"Down":
    // (GlobalStore.MeshMiddleLTPitch.current[GlobalStore.buildingType.id]==1?"Normal":"UP");

    // for(var i=0;i<scene.meshes.length;i++)
    // {
    //     if(scene.meshes[i].name.includes("Type1_LT_Storage"))
    //     {
    //             if(scene.meshes[i].name.includes(pitchLT))
    //             {
    //                 if(GlobalStore.storageLeftMeshes.current[GlobalStore.buildingType.id])
    //                 scene.meshes[i].isVisible=true;
    //             }
    //             else if((scene.meshes[i].name.includes("Down")||
    //             scene.meshes[i].name.includes("Normal")||
    //             scene.meshes[i].name.includes("UP"))){ scene.meshes[i].isVisible=false;}
    //            // scene.meshes[i].isVisible=true;
    //            scene.meshes[i].position=GlobalStore.MeshStorageLTPos.current[GlobalStore.buildingType.id];
    //            scene.meshes[i].scaling=GlobalStore.MeshStorageLTScale.current[GlobalStore.buildingType.id];

    //             ScaleMesh.push(scene.meshes[i]);
    //             j++;
    //     }
    // }
    for (var i = 0; i < storageOptions.length; i = i + 1) {
      XScaleLT.push(ScaleMesh[0].scaling.x * (i / 6) + 1 / 6);

      XPosRT.push(ScaleMesh[0].position.x);
    }

    switch (presetData.wallStyle) {
      case "open":
        handleFullyOpen();
        handleWallChange(null, "open");
        console.log("handling fully open");
        break;
      case "enclosed":
        handleFullyEnclosed();
        handleWallChange(null, "enclosed");
        console.log("handling fully closed");
        break;

      default:
        break;
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWainscot(false);
  }, [wall]);

  const handleWallChange = (e, i) => {
    i ? setWall(i) : setWall(e.target.value);
    // switch (e.target.value) {
    //     case 'open':
    //         setWallIsOpen(true)
    //         break;
    //     case 'enclosed':
    //         setWallIsOpen(false);
    //         break;

    //     default:
    //         break;
    // }
  };
  // const handleVerticalPanels = () => {
  //     setVetricalPanels(!verticalPanels)
  // }

  // const handleLeftVerticalPanel = () => {
  //     setLeftSidePanel(!leftSidePanel)
  // }

  // const handleRightVerticalPanel = () => {
  //     setRightSidePanel(!rightSidePanel)
  // }
  const handleInsulationChange = (e) => {
    setInsulationValue(e.target.value);

    if (e.target.value === "Select") {
      setInsulationCheckboxes(false);
      setInsulationRadio("roof-only");
    } else {
      setInsulationCheckboxes(true);
    }
  };
  const handleInsulationRadio = (event) => {
    setInsulationRadio(event.target.value);
  };
  // const handleJTrim = () => {
  //     setJTrim(!jTrim)
  // }
  const handleWainscot = () => {
    setWainscot(!wainscot);
  };
  const handleWainscotColor = (color) => {
    wainscotMaterial.albedoColor = new Color3.FromHexString(
      color.value
    ).toLinearSpace();
  };
  const handleFullyOpen = () => {
    GlobalStore.setLeftWallStyle("open");

    GlobalStore.setLTBackWall("open");
    GlobalStore.setLTFrontWall("open");
    GlobalStore.setLTRightWall("open");
    GlobalStore.setLTLeftWall("open");

    GlobalStore.IsWallLTOpen.current[GlobalStore.buildingType.id] = true;

    //code used to remove excess doors and windows
    let objectsToRemove = [
      ...GlobalStore.LTFrontWallObjects,
      ...GlobalStore.LTBackWallObjects,
      ...GlobalStore.LTLeftWallObjects,
    ];

    for (let i = 0; i < objectsToRemove.length; i++) {
      objectsToRemove.object.dispose();
    }
    GlobalStore.setLTFrontWallObjects([]);
    GlobalStore.setLTBackWallObjects([]);
    GlobalStore.setLTLeftWallObjects([]);

    for (var i = 0; i < scene.meshes.length; i++) {
      if (scene.meshes[i].name.includes("Type1")) {
        if (
          !scene.meshes[i].name.includes("Storage") &&
          scene.meshes[i].name.includes("_LT")
        ) {
          if (
            scene.meshes[i].name.includes("Wainscot") ||
            scene.meshes[i].name.includes("walls") ||
            scene.meshes[i].name.includes("SIDE") ||
            scene.meshes[i].name.includes("END")
          )
            scene.meshes[i].isVisible = false;
        }
      }
    }

    handleAddStorage("5");
    // const clipPlane = new Plane(0, -1, 0, 1000);
    // GlobalStore.leftLeanWallMeshes.current.map((wall) => {
    //     wall.onBeforeRenderObservable.add(function () {
    //         scene.resetCachedMaterial();
    //         scene.clipPlane2 = clipPlane;
    //     });
    //     wall.onAfterRenderObservable.add(function () {
    //         scene.clipPlane2 = null;
    //     });
    //     return true
    // });

    // GlobalStore.setPrice(GlobalStore.price - 300);
    // GlobalStore.setSelectedEndFront_LL("Open");
    // GlobalStore.setSelectedEndBack_LL('Open');
    // GlobalStore.setSelectedSidesLeft_LL('Open');
  };
  const handleFullyEnclosed = () => {
    console.log("closing all walls");
    GlobalStore.setLeftWallStyle("enclosed");

    GlobalStore.setLTBackWall("enclosed");
    GlobalStore.setLTFrontWall("enclosed");
    GlobalStore.setLTRightWall("enclosed");
    GlobalStore.setLTLeftWall("enclosed");

    GlobalStore.IsWallLTOpen.current[GlobalStore.buildingType.id] = false;

    var pitchLT =
      GlobalStore.MeshMiddleLTPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleLTPitch.current[GlobalStore.buildingType.id] ==
          1
        ? "Normal"
        : "UP";
    for (var i = 0; i < scene.meshes.length; i++) {
      if (scene.meshes[i].name.includes("Type1")) {
        if (
          !scene.meshes[i].name.includes("Storage") &&
          scene.meshes[i].name.includes("_LT")
        ) {
          if (
            scene.meshes[i].name.includes("Wainscot") ||
            scene.meshes[i].name.includes("walls")
          )
            scene.meshes[i].isVisible = true;
          if (scene.meshes[i].name.includes(pitchLT)) {
            if (
              !(
                scene.meshes[i].name.includes("SIDE") ||
                scene.meshes[i].name.includes("END")
              )
            )
              scene.meshes[i].isVisible = true;
          } else if (
            !(
              scene.meshes[i].name.includes("Down") ||
              scene.meshes[i].name.includes("Normal") ||
              scene.meshes[i].name.includes("UP")
            )
          ) {
            scene.meshes[i].isVisible = true;
          }
        }
      }
    }
  };
  const handleEndsFront = (targetValue) => {
    switch (targetValue) {
      case "Open":
        GlobalStore.setLTFrontWall("open");

        //code used to delete doors and windows
        let objectsToRemove = [...GlobalStore.LTFrontWallObjects];

        for (let i = 0; i < objectsToRemove.length; i++) {
          objectsToRemove.object.dispose();
        }
        GlobalStore.setLTFrontWallObjects([]);
        break;
      case "Closed":
        GlobalStore.setLTFrontWall("enclosed");
        break;
    }

    /*if (GlobalStore.IsWallLTOpen.current[GlobalStore.buildingType.id]) return;

    var pitchLT =
      GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] ==
          1
        ? "Normal"
        : "UP";
    switch (targetValue) {
      case "Gable":
        break;
      case "Open":
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type1")
          ) {
            if (
              !scene.meshes[i].name.includes("Storage") &&
              scene.meshes[i].name.includes("_LT") &&
              scene.meshes[i].name.includes("front") &&
              scene.meshes[i].name.includes("walls")
            ) {
              scene.meshes[i].isVisible = false;
            } else if (
              !scene.meshes[i].name.includes("Storage") &&
              (scene.meshes[i].name.includes("END") ||
                scene.meshes[i].name.includes("SIDE"))
            ) {
              // design fault
              scene.meshes[i].isVisible = false;
            }
          }
        }

        break;
      case "Closed":
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type1")
          ) {
            if (
              scene.meshes[i].name.includes("_LT") &&
              scene.meshes[i].name.includes("front") &&
              scene.meshes[i].name.includes("walls")
            ) {
              if (scene.meshes[i].name.includes(pitchLT)) {
                scene.meshes[i].isVisible = true;
              } else if (
                !scene.meshes[i].name.includes("Storage") &&
                !(
                  scene.meshes[i].name.includes("Down") ||
                  scene.meshes[i].name.includes("Normal") ||
                  scene.meshes[i].name.includes("UP")
                )
              ) {
                scene.meshes[i].isVisible = true;
              }
            }
          }
        }

        break;
    }
    */
  };
  const handleEndsBack = (targetValue) => {
    switch (targetValue) {
      case "Open":
        GlobalStore.setLTBackWall("open");

        //code used to delete doors and windows
        let objectsToRemove = [...GlobalStore.LTBackWallObjects];

        for (let i = 0; i < objectsToRemove.length; i++) {
          objectsToRemove.object.dispose();
        }
        GlobalStore.setLTBackWallObjects([]);

        break;
      case "Closed":
        GlobalStore.setLTBackWall("enclosed");
        break;
    }
    /*if (GlobalStore.IsWallLTOpen.current[GlobalStore.buildingType.id]) return;

    var pitchLT =
      GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] ==
          1
        ? "Normal"
        : "UP";
    switch (targetValue) {
      case "Gable":
        break;
      case "Open":
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type1") &&
            scene.meshes[i].name.includes("_LT")
          ) {
            if (
              !scene.meshes[i].name.includes("Storage") &&
              scene.meshes[i].name.includes("back") &&
              scene.meshes[i].name.includes("walls")
            ) {
              scene.meshes[i].isVisible = false;
            }
          }
        }

        break;
      case "Closed":
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type1") &&
            scene.meshes[i].name.includes("_LT")
          ) {
            if (
              scene.meshes[i].name.includes("_LT") &&
              scene.meshes[i].name.includes("back") &&
              scene.meshes[i].name.includes("walls")
            ) {
              if (scene.meshes[i].name.includes(pitchLT)) {
                scene.meshes[i].isVisible = true;
              } else if (
                !(
                  scene.meshes[i].name.includes("Down") ||
                  scene.meshes[i].name.includes("Normal") ||
                  scene.meshes[i].name.includes("UP")
                )
              ) {
                scene.meshes[i].isVisible = true;
              }
            }
          }
        }

        break;
    }
    // const groundRef = scene.getMeshByName("Cylinder001");
    // const wallHeightReference = scene.getMeshByName("dimensionHelper_L");
    // const leftLeanBackWalls = scene.getTransformNodeByName("backWall_LL");
    // const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
    // const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
    // GlobalStore.setSelectedEndBack_LL(targetValue)
    // mainEndsWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [leftLeanBackWalls], scene)
  */
  };
  const handleSidesLeft = (targetValue) => {
    switch (targetValue) {
      case "Open":
        GlobalStore.setLTLeftWall("open");

        //code used to delete doors and windows
        let objectsToRemove = [...GlobalStore.LTLeftWallObjects];

        for (let i = 0; i < objectsToRemove.length; i++) {
          objectsToRemove.object.dispose();
        }
        GlobalStore.setLTLeftWallObjects([]);

        break;
      case "Closed":
        GlobalStore.setLTLeftWall("enclosed");
        break;
    }
    /*if (GlobalStore.IsWallLTOpen.current[GlobalStore.buildingType.id]) return;

    var pitchLT =
      GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] == 0
        ? "Down"
        : GlobalStore.MeshMiddleRTPitch.current[GlobalStore.buildingType.id] ==
          1
        ? "Normal"
        : "UP";
    switch (targetValue) {
      case "Gable":
        break;
      case "Open":
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type1")
          ) {
            if (
              !scene.meshes[i].name.includes("Storage") &&
              scene.meshes[i].name.includes("_LT") &&
              scene.meshes[i].name.includes("left_walls")
            ) {
              scene.meshes[i].isVisible = false;
            }
          }
        }

        break;
      case "Closed":
        for (var i = 0; i < scene.meshes.length; i++) {
          if (
            !scene.meshes[i].name.includes("Storage") &&
            scene.meshes[i].name.includes("Type1")
          ) {
            if (
              !scene.meshes[i].name.includes("Storage") &&
              scene.meshes[i].name.includes("_LT") &&
              (scene.meshes[i].name.includes("left_walls") ||
                scene.meshes[i].name.includes("left_inner_walls"))
            ) {
              if (scene.meshes[i].name.includes(pitchLT)) {
                scene.meshes[i].isVisible = true;
              } else if (
                !(
                  scene.meshes[i].name.includes("Down") ||
                  scene.meshes[i].name.includes("Normal") ||
                  scene.meshes[i].name.includes("UP")
                )
              ) {
                scene.meshes[i].isVisible = true;
              }
            }
          }
        }

        break;
    }
    // const groundRef = scene.getMeshByName("Cylinder001");
    // const wallHeightReference = scene.getMeshByName("dimensionHelper_L");
    // const leftLeanSideWalls = scene.getTransformNodeByName("sideWall_LL");
    // const wallHeightReferenceMaxY = wallHeightReference.getBoundingInfo().boundingBox.maximumWorld.y;
    // const groundRefMaxY = groundRef.getBoundingInfo().boundingBox.maximumWorld.y
    // GlobalStore.setSelectedSidesLeft_LL(targetValue)
    // mainSidesWallClipPlanesChange(targetValue, [wallHeightReferenceMaxY, groundRefMaxY], [leftLeanSideWalls], scene)
  */
  };
  const handleWallColor = (color) => {
    GlobalStore.MeshLT.current[1].getChildren().forEach((m) => {
      //         if(m.name.includes("Type1_LT_Storage"))
      //         {
      //             {
      if (m.name.includes("_LT") && m.name.includes("walls")) {
        m.material.diffuseColor = new Color3.FromHexString(
          color.value
        ).toLinearSpace();
      }
    });
    GlobalStore.StorageMeshLT.current[1].getChildren().forEach((m) => {
      if (m.name.includes("_LT") && m.name.includes("walls")) {
        m.material.diffuseColor = new Color3.FromHexString(
          color.value
        ).toLinearSpace();
      }
    });

    // for(var i=0;i<scene.meshes.length;i++)
    // {
    //     // if(scene.meshes[i].name.includes("Type"+GlobalStore.buildingType.id))
    //     // {
    //     //     if(
    //     //         (scene.meshes[i].name.includes("_LT"))
    //     //          && (scene.meshes[i].name.includes("walls"))
    //     //          )
    //     //     {
    //     //         scene.meshes[i].material.diffuseColor  =  new Color3.FromHexString(color.value).toLinearSpace();
    //     //     }
    //     // }
    // }

    // const wallMaterials = GlobalStore.outerWallsMaterials.current;
    // wallMaterials.map(mat => mat.albedoColor = new Color3.FromHexString(color.value).toLinearSpace())
  };
  const handleStorage = () => {
    console.log(
      "handling storage for open/closing. Current State ",
      GlobalStore.isStorageLTOn
    );
    if (
      GlobalStore.isLeftLeanActive.current[GlobalStore.buildingType.id] !=
      "true"
    )
      return;
    //do a check if lean is avialable or not
    //change the state now

    if(GlobalStore.isStorageLTOn){
      let objects = [...GlobalStore.LTFrontStorageWallObjects, ...GlobalStore.LTLeftStorageWallObjects]

      objects.forEach(object => {
        object.object.dispose()
      })
    }



    GlobalStore.setIsStorageLTOn(!GlobalStore.isStorageLTOn);
  };
  const handleAddStorage = (targetValue) => {
    switch (targetValue) {
      case "5":
        /*for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("LT_Storage")) {
            {
              scene.meshes[i].scaling.x = XScaleLT[0];
              //scene.meshes[i].position.x = XPosRT[0];

              // scene.meshes[i].position.x=-22*2
            }
          }
        }*/

        GlobalStore.StorageMeshLT.current[1].getChildren().forEach((mesh) => {
          mesh.scaling.x = XScaleLT[0];
        });

        break;
      case "10":
        /*for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("Type1_LT_Storage")) {
            {
              scene.meshes[i].scaling.x = XScaleLT[1];
              //scene.meshes[i].position.x = XPosRT[1];

              // scene.meshes[i].position.x=-22*1.9
            }
          }
        }*/
        GlobalStore.StorageMeshLT.current[1].getChildren().forEach((mesh) => {
          mesh.scaling.x = XScaleLT[1];
        });

        break;
      case "15":
        /*for (var i = 0; i < scene.meshes.length; i++) {
          if (scene.meshes[i].name.includes("Type1_LT_Storage")) {
            {
              scene.meshes[i].scaling.x = XScaleLT[2];
              //scene.meshes[i].position.x = XPosRT[2];

              // scene.meshes[i].position.x=scene.meshes[i].position.x
            }
          }
        }*/
        GlobalStore.StorageMeshLT.current[1].getChildren().forEach((mesh) => {
          mesh.scaling.x = XScaleLT[2];
        });
        break;
      default:
        break;
    }

    setTimeout(() => {
 
      
        let  bounds=getBoundingBox(GlobalStore.LTStorageBoundingBox.current)
      

      GlobalStore.LTFrontStorageWallObjects.forEach((object) => {
        object.object.position.x = bounds.maxWorld.x;
      })


      let sideObjects = [...GlobalStore.LTLeftStorageWallObjects];

      sideObjects.forEach((object) => {
        object.object.position.x = (bounds.maxWorld.x + bounds.minWorld.x) / 2;

        object.pointer.validateDrag = (targetPosition) => {
          targetPosition.x = Scalar.Clamp(
            targetPosition.x,
            bounds.minWorld.x+object.limit,
            bounds.maxWorld.x-object.limit
          )

          targetPosition.y = Scalar.Clamp(
            targetPosition.y,
            13,
            object.heightOffset
          );
          return true;
        }
      })

      

    },10);
    // const leanParent_L = scene.getMeshByName("Lean_Left_Helper");
    // let leanStorage_L;
    // leanParent_L.getChildMeshes().map(mesh => {
    //     if (mesh.name === "storage_lean_L") {
    //         leanStorage_L = mesh;
    //     }
    //     return 0
    // })

    // leanParent_L.computeWorldMatrix(true);
    // const storagePlaneDistance = leanParent_L.getBoundingInfo().boundingBox.minimumWorld.x;
    // GlobalStore.leanLLengthFromCenter.current = storagePlaneDistance;

    // leanStorage_L.setAbsolutePosition(storagePlaneDistance, leanStorage_L.getAbsolutePosition().y, leanStorage_L.getAbsolutePosition().z)
    // leanStorage_L.translate(Axis.Y, targetValue / 5 * 2.4, Space.LOCAL);
    // GlobalStore.leanLStorageTranslation.current = targetValue;
  };
  return (
    <WallsScrollbarWrapper>
      <WallsWrapper>
        <RadioGroup
          aria-label="walls"
          name="walls"
          value={wall}
          onChange={handleWallChange}
          row
        >
          <FormControlLabel
            onChange={handleFullyOpen}
            value="open"
            control={<Radio />}
            label="Open"
          />
          <FormControlLabel
            onChange={handleFullyEnclosed}
            value="enclosed"
            control={<Radio />}
            label="Fully Enclosed"
          />
        </RadioGroup>
        <Separator marginBottom={"15px"} />
        <FormControlLabel
          control={
            <Checkbox
              checked={GlobalStore.isStorageLTOn}
              onChange={handleStorage}
              name="storage"
              color="primary"
            />
          }
          style={{ color: "#222" }}
          label="Add Storage"
        />
        <WallsSelectWrapper checked={true}>
          <InputSelect
            onChange={handleAddStorage}
            label="Utility Enclosed Area"
            options={storageOptions}
            inputName="add-storage"
          />
        </WallsSelectWrapper>

        <Separator />

        <SectionTitle title={"ENDS"} />

        <InputSelect
          onChange={handleEndsFront}
          label="Front"
          options={endsOptions}
          inputName="ends-front"
        />

        <Separator marginBottom="15px" />

        <InputSelect
          onChange={handleEndsBack}
          label="Back"
          options={endsOptions}
          inputName="ends-back"
        />

        <Separator marginBottom="15px" />

        <SectionTitle title={"SIDES"} />

        <InputSelect
          onChange={handleSidesLeft}
          label="Left"
          options={sidesOptions}
          inputName="sides-left"
        />
        {/* <Separator marginBottom='15px' />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={jTrim}
                            onChange={handleJTrim}
                            name="vertical-panels"
                            color="primary"
                        />
                    }
                    style={{ color: '#222' }}
                    label="J-Trim"
                /> */}

        <Separator marginBottom="30px" />

        <ColorPicker
          onChange={handleWallColor}
          title="Wall"
          colorsOptions={colorsOptionsPremium}
          checked={GlobalStore.wallsColor}
        />

        <Separator />

        <FormControlLabel
          control={
            <Checkbox checked={true} name="add-insulation" color="primary" />
          }
          style={{
            color: "#222",
          }}
          label="Add Insulation"
        />
        <br />
        <FormControl fullWidth>
          <InputLabel style={{ fontSize: "11px" }} id="insulation-select-label">
            Insulation
          </InputLabel>
          <Select
            value={insulationValue}
            onChange={handleInsulationChange}
            inputProps={{ "aria-label": "Without label" }}
            fullWidth
          >
            {insulationOptions.map(({ label, value }, i) => {
              return (
                <MenuItem value={value} key={i}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {insulationCheckboxes && (
          <>
            <RadioGroup
              aria-label="insulation-radio"
              name="insulation-radio"
              value={insulationRadio}
              onChange={handleInsulationRadio}
              style={{ padding: "0 8px" }}
            >
              <FormControlLabel
                value="roof-only"
                control={<Radio />}
                label="Roof Only"
              />
              <FormControlLabel
                value="full-building"
                control={<Radio />}
                label="Full Building"
              />
            </RadioGroup>
          </>
        )}

        <Separator />
      </WallsWrapper>
    </WallsScrollbarWrapper>
  );
};

export default WallsLeft;
