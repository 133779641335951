import { React, useContext, useRef, useEffect, useState } from "react";
import { Engine, Scene } from "react-babylonjs";

import Grid from "@material-ui/core/Grid";
import { GlobalState } from "../../App";
import {
  Vector3,
  Space,
  Plane,
  Ray,
  RayHelper,
  Color3,
  Vector4,
  PointerDragBehavior,
  MeshBuilder,
  Matrix,
  Scalar,
  Mesh,
  Layer,
  C,
  StandardMaterial,
} from "@babylonjs/core";
import { AdvancedDynamicTexture, Button, Control } from "@babylonjs/gui/2D";
import {
  CardsAddWrapper,
  CardsAddItem,
  CardsAddItemIcon,
  CardsAddItemText,
  CardsAddItemAddBtn,
} from "./CardsAdd.css";
import LeanTo from "../Forms/LeanTo/LeanTo";

var currWall = "";
const CardsAdd = ({ isActive }) => {
  const GlobalStore = useContext(GlobalState);
  const scene = GlobalStore.sceneGlobalRef.current;
  useRef(1);
  var garageDoor;
  var pickUpMesh;

  var WallMesh;
  var pickedWallMesh;

  var counter = 1;
  var leftWallMesh;
  var leftLeanMesh;
  var rightWallMesh;
  var rightLeanMesh;
  var frontWallMesh;
  var backWallMesh;

  var leftWallPlane = null;
  var rightWallPlane = null;
  var frontWallPlane = null;
  var backWallPlane = null;

  var leftBoundingBox = null;
  var leftLeanBoundingBox = null;
  var rightBoundingBox = null;
  var rightLeanBoundingBox = null;
  var frontBoundingBox = null;
  var backBoundingBox = null;

  var meshToDelete = null;

  //let frontCenterWallObjects=GlobalStore.frontCenterWallObjects;
  let frontCenterStorageWallObjects = [];
  let LTFrontWallObjects = [];
  let LTFrontWallStorageObjects = [];
  let RTFrontWallObjects = [];
  let RTFrontWallStorageObjects = [];
  //stores backWall objects
  let backCenterWallObjecs = [];
  let LTBackWallObjects = [];
  let RTBackWallObjects = [];

  //for left sided doors
  let leftWallObjects = [];
  let leftWallStorageObjects = [];
  let LTLeftWallObjects = [];
  let LTLeftStorageWallObjects = [];

  //similar for the right lean
  let rightWallObjects = [];
  let rightWallStorageObjects = [];
  let RTRightWallObjects = [];
  let RTRightStorageWallObjects = [];

  let pointerDragBehaviorFrontPlane;
  let pointerDragBehaviorBackPlane;
  let pointerDragBehaviorLeftLeanPlane;
  let pointerDragBehaviorLeftPlane;
  let pointerDragBehaviorRightLeanPlane;
  let pointerDragBehaviorRightPlane;

  let newWallObject;

  const limit = 4;

  useEffect(() => {
    garageDoor = null;
    for (var i = 0; i < scene.meshes.length; i++) {
      if (scene.meshes[i].name.includes("Door"));
    }
  }, []);
  var canPickObj = false;

  scene.pointerMovePredicate = (mesh) => {
    // console.error("etai hbe" +mesh.name);
    return true;
  };
  scene.pointerUpPredicate = (mesh) => {
    return true;
  };

  scene.onPointerUp = function (evt, pickResult) {
    // if the click hits the ground object, we change the impact position
    if (pickUpMesh && pickUpMesh.isPickable && pickResult.hit) {
      //console.error(pickObj);
      canPickObj = !canPickObj;
    }
  };

  let vector = { x: "", y: "", z: "" };
  scene.onPointerDown = function (event, pickResult) {
    //right mouse click
    if (event.button == 2) {
      //vector.x = pickResult.pickedPoint.x;
      //vector.y = pickResult.pickedPoint.y;
      //vector.z = pickResult.pickedPoint.z;
      //console.log('right mouse click: ' + vector.x + ',' + vector.y + ',' + vector.z);
      //console.log(pickResult);
    }
    //Wheel button or middle button on mouse click
    if (event.button == 1) {
      vector["x"] = pickResult.pickedPoint["x"];
      vector["y"] = pickResult.pickedPoint["y"];
      vector["z"] = pickResult.pickedPoint["z"];
      console.log(
        "middle mouse click: " + vector.x + "," + vector.y + "," + vector.z
      );
    }
    if (event.button == 0) {
      raycastTofindWhichWall();
    }
  };

  function castRay() {
    var pickResult = scene.pick(scene.pointerX, scene.pointerY);

    var origin = pickResult.pickedPoint; //pickResult.ray.origin;
    var forward = pickResult.ray.direction;
  }

  scene.registerBeforeRender(function () {
    castRay();
  });

  scene.onPointerMove = function (evt, pickResult) {
    //mousemovef();
  };

  function predicate(mesh) {
    if (
      //door cannot be attached to inner wall or lean wall
      mesh.name.toLowerCase().includes("inner") ||
      mesh.name.toLowerCase().includes("inner") ||
      mesh.name.toLowerCase().includes("up") ||
      mesh.name.toLowerCase().includes("down") ||
      mesh.name.toLowerCase().includes("wainscot") ||
      !mesh.isEnabled() ||
      !mesh.isVisible
    ) {
      return false;
    }
    if (
      (mesh.name.includes("Type1") || mesh.name.includes("Type8")) &&
      mesh.isVisible &&
      mesh.isEnabled() &&
      (mesh.name.includes("wall") || mesh.name.includes("Wall"))
      //mesh can only be attached to walls that are of type 1 or type 8. The walls must be visible
    ) {
      return true;
    }
  }

  /*for (let i = 0; i < scene.meshes.length; i++) {
    scene.meshes[i].isPickable = true;
  }*/

  var debugMaterial = new StandardMaterial("debug", scene);
  debugMaterial.diffuseColor = new Color3(0, 0, 0);

  function raycastTofindWhichWall() {
    WallMesh = null;
    var ray = scene.createPickingRay(
      /*window.innerWidth / 2,
      window.innerHeight / 2,*/
      scene.pointerX,
      scene.pointerY,
      Matrix.Identity(),
      GlobalStore.ArcCamera.current
    );

    var hit = scene.pickWithRay(ray, predicate);
    if (hit.pickedMesh) {
      //something is picked
      WallMesh = hit.pickedMesh.name;
      pickedWallMesh = hit.pickedMesh;
      //console.log("WallMesh\n"+WallMesh +"\nIsVisible: " +pickedWallMesh.isVisible+"\nIsEnabled:"+pickedWallMesh.isEnabled())
      //pickedWallMesh.showBoundingBox=true;
      //pickedWallMesh.isVisible=true;
      //pickedWallMesh.material= debugMaterial;
    }
    //setTimeout(raycastTofindWhichWall, 1000);
  }

  //raycastTofindWhichWall();

  function addDoorAndWindow(mesh) {}

  function DoorAndWindowSet(PrimaryMesh) {
    /*
    We initialize the planes in the start. At the end, we will only
    push on of these to the lists
    */

    pointerDragBehaviorLeftPlane = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(0, 0, 1), //y axis
    });
    pointerDragBehaviorLeftLeanPlane = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(1, 0, 0), //x axis
    });
    pointerDragBehaviorRightPlane = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(0, 0, 1), //y axis(towards the right)
    });
    pointerDragBehaviorRightLeanPlane = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(1, 0, 0), //x axis
    });
    pointerDragBehaviorFrontPlane = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(1, 0, 0), //x axis
    });
    pointerDragBehaviorBackPlane = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(1, 0, 0), //x asix
    });
    /*var pointerDragBehaviorCenterStorage = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(1, 0, 0),
    });
    var pointerDragBehaviorRightLeanStoragePlane = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(1, 0, 0),
    });
    var pointerDragBehaviorLeftLeanStoragePlane = new PointerDragBehavior({
      dragPlaneNormal: new Vector3(1, 0, 0),
    });*/

    pointerDragBehaviorLeftPlane.useObjectOrientationForDragging = false;
    pointerDragBehaviorRightPlane.useObjectOrientationForDragging = false;
    pointerDragBehaviorFrontPlane.useObjectOrientationForDragging = true;
    pointerDragBehaviorBackPlane.useObjectOrientationForDragging = false;

    if (!WallMesh) return;
    //cannot add doors to the first 2 types of open

    //we first find which wall and which lean to
    var wall = "";
    var leanTo = "";
    var storage = ""; //we can use it to see if this is attached to the storage
    var pickObj;
    var bgObj;

    console.log("WallMesh is" + WallMesh);

    //need to change this logic to include storage. First experiment with the lean to metal structures
    if (WallMesh && WallMesh.includes("front")) {
      wall = "front";
      if (WallMesh.includes("LT")) {
        leanTo = "LT";
        if (GlobalStore.LTFrontWall == "open" && WallMesh.includes("Storage")) {
          storage = "true";
        } else {
          storage = "false";
        }
      } else if (WallMesh.includes("RT")) {
        leanTo = "RT";
        if (WallMesh.RTFrontWall === "open" && WallMesh.includes("Storage")) {
          storage = "true";
        } else {
          storage = "false";
        }
      } else {
        leanTo = "";
        if (WallMesh.includes("Storage")) {
          storage = "true";
        } else {
          storage = "false";
        }
      }
    } else if (WallMesh && WallMesh.includes("back")) {
      wall = "back";
      if (WallMesh.includes("LT")) {
        leanTo = "LT";
      } else if (WallMesh.includes("RT")) {
        leanTo = "RT";
      } else {
        leanTo = "";
      }
    } else if (WallMesh && WallMesh.includes("right")) {
      wall = "right";
      if (GlobalStore.RTRightWall != "open") {
        leanTo = "RT";
        if (WallMesh.includes("Storage")) {
          storage = "true";
        }
      }
      //if it is false, then see if the storage works
    } else if (WallMesh && WallMesh.includes("left")) {
      wall = "left";
      if (GlobalStore.LTLeftWall != "open") {
        leanTo = "LT";
        if (WallMesh.includes("Storage")) {
          storage = "true";
        }
      }
      //see if the storage works
    }

    console.log("most suitable wall: " + wall);
    console.log("most suitable leanTo: " + leanTo);
    //for the storage
    console.log("this is a suitable storage" + storage);
    pickObj = PrimaryMesh.clone(
      PrimaryMesh.name + "_Type" + GlobalStore.buildingType.id + "_" + counter++
    ); //works pretty good till here

    var windowWidth = 10;
    var realWindowHeight = 8;
    var heightOffset;
    var leanAdjustment = 11.5;
    var type;
    var name = PrimaryMesh.name.toString();

    //here we specify width and height parameters, based on hardcoded shapes
    if (
      PrimaryMesh.name.toString().includes("Garage_Door__6x6__001_Window_d")
    ) {
      type = "Door";
      windowWidth = 18;
      realWindowHeight = 26;
      heightOffset = 13;
    } else if (PrimaryMesh.name.toString().includes("Walk_in_Door__36x80")) {
      type = "Door";
      console.log("walk in door 2");
      PrimaryMesh.scaling = new Vector3(0.3, 0.5, 0.5);
      windowWidth = 10;
      realWindowHeight = 15;
      heightOffset = 13;
    } else if (
      PrimaryMesh.name.toString().includes("Window__36x80__001_Window")
    ) {
      type = "Window";
      windowWidth = 10;
      realWindowHeight = 7;
      heightOffset = 57 - realWindowHeight;
      if (leanTo.includes("LT") || leanTo.includes("RT")) {
        heightOffset = heightOffset - leanAdjustment;
      }
    } else if (
      PrimaryMesh.name.toString().includes("Framed_Openings__6x6__001_Window_d")
    ) {
      type = "Window";
      windowWidth = 18;
      realWindowHeight = 17;
      // heightOffset = 57 - realWindowHeight;
      heightOffset = 13;
      /* if (leanTo.includes("LT") || leanTo.includes("RT")) {
        heightOffset = heightOffset - leanAdjustment;
      }*/
    } else if (
      PrimaryMesh.name
        .toString()
        .includes("Walk_in_Door_Frame_out__36x80__001_Window_d")
    ) {
      type = "Window";
      windowWidth = 10;
      realWindowHeight = 35;
      heightOffset = 13;
    } else if (
      PrimaryMesh.name
        .toString()
        .includes("Window_Frame_out__36x80__001_Window")
    ) {
      type = "Window";
      windowWidth = 7;
      realWindowHeight = 17;
      heightOffset = 57 - realWindowHeight;
      if (leanTo.includes("LT") || leanTo.includes("RT")) {
        heightOffset = heightOffset - leanAdjustment;
      }
    }
    pickObj.summary = {
      type: type,
      side: wall,
      width: windowWidth,
      height: realWindowHeight,
    };
    if (GlobalStore.buildingType.id != 8) {
      var foundMatchingWall = false; //found matching wall checks if the lean to's are active

      for (var i = 0; i < scene.meshes.length; i++) {
        var meshName = scene.meshes[i].name.toLowerCase();
        scene.meshes[i].isPickable = true; //we are making all meshes pickable by ray
        if (
          scene.meshes[i].name.includes(wall) &&
          scene.meshes[i].name.includes("Type1") &&
          !meshName.includes("inner") &&
          !meshName.includes("up") &&
          !meshName.includes("down") &&
          !meshName.includes("wainscot") &&
          //!meshName.includes("storage") &&
          scene.meshes[i].isVisible &&
          !foundMatchingWall &&
          scene.meshes[i].name === WallMesh //the found matching wall is not functioning correctly. Maybe it is being resetted
        ) {
          //console.log(scene.meshes[i].name);
          bgObj = scene.meshes[i];

          newWallObject = {
            object: pickObj,
            wall: bgObj,
            pointer: null,
            price: 0,
            heightOffset: heightOffset,
            limit: 13,
          };

          console.log(newWallObject);

          //bgObj.showBoundingBox=true;

          if (wall.includes("left")) {
            if (scene.meshes[i].name.includes("Lean_to_")) {
              // handle left lean

              windowWidth = windowWidth * 1.5;
              leftLeanMesh = scene.meshes[i];
              leftLeanBoundingBox = bgObj.getBoundingInfo();

              var wallWidth = Math.round(
                parseInt(
                  leftLeanMesh.getBoundingInfo().boundingBox.maximumWorld.x
                ) -
                  parseInt(
                    leftLeanMesh.getBoundingInfo().boundingBox.minimumWorld.x
                  )
              );

              var wallHeight = Math.round(
                parseInt(
                  leftLeanMesh.getBoundingInfo().boundingBox.maximumWorld.y
                ) -
                  parseInt(
                    leftLeanMesh.getBoundingInfo().boundingBox.minimumWorld.y
                  )
              );
              var windowHeight = Math.round(
                parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.y) -
                  parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.y)
              );

              var leftLeanPlane = MeshBuilder.CreatePlane(
                "leftLeanBoundsPlane",
                {
                  width: wallWidth - windowWidth,
                  height: wallHeight - windowHeight,
                },
                scene
              );

              leftLeanPlane.isPickable = false;
              leftLeanPlane.position = leftLeanMesh.position.clone();
              leftLeanPlane.position.x =
                (leftLeanMesh.getBoundingInfo().boundingBox.maximumWorld.x +
                  leftLeanMesh.getBoundingInfo().boundingBox.minimumWorld.x) /
                2;

              leftLeanPlane.isVisible = false; //should be set to false when not testing
              //this could be used to drag the door against

              pointerDragBehaviorLeftLeanPlane.validateDrag = (
                targetPosition
              ) => {
                const bounds = leftLeanPlane.getBoundingInfo().boundingBox;
                targetPosition.x = Scalar.Clamp(
                  targetPosition.x,
                  bounds.minimumWorld.x,
                  bounds.maximumWorld.x
                );
                targetPosition.y = Scalar.Clamp(
                  targetPosition.y,
                  13,
                  heightOffset
                );
                return true;
              };
              foundMatchingWall = true;
            } else if (!scene.meshes[i].name.toLowerCase().includes("Lean")) {
              //alert("this is not the lean to");
              windowWidth = windowWidth * 1.5;
              leftWallMesh = scene.meshes[i];
              leftBoundingBox = bgObj.getBoundingInfo();

              var wallWidth = Math.round(
                parseInt(
                  leftWallMesh.getBoundingInfo().boundingBox.maximumWorld.x
                ) -
                  parseInt(
                    leftWallMesh.getBoundingInfo().boundingBox.minimumWorld.x
                  )
              );
              //var windowWidth = Math.round(parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.x));

              var wallHeight = Math.round(
                parseInt(
                  leftWallMesh.getBoundingInfo().boundingBox.maximumWorld.y
                ) -
                  parseInt(
                    leftWallMesh.getBoundingInfo().boundingBox.minimumWorld.y
                  )
              );
              var windowHeight = Math.round(
                parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.y) -
                  parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.y)
              );

              var leftWallPlane = MeshBuilder.CreatePlane(
                "leftBoundsPlane",
                {
                  width: wallWidth - windowWidth,
                  height: wallHeight - windowHeight,
                },
                scene
              );
              leftWallPlane.isPickable = false;
              leftWallPlane.position = leftWallMesh.position.clone();
              leftWallPlane.position.x =
                (leftWallMesh.getBoundingInfo().boundingBox.maximumWorld.x +
                  leftWallMesh.getBoundingInfo().boundingBox.minimumWorld.x) /
                2;
              leftWallPlane.position.z =
                (-1 *
                  Math.round(
                    parseInt(
                      leftWallMesh.getBoundingInfo().boundingBox.maximumWorld.z
                    ) -
                      parseInt(
                        leftWallMesh.getBoundingInfo().boundingBox.minimumWorld
                          .z
                      )
                  )) /
                2;
              leftWallPlane.isVisible = false;

              console.log("leftWallPlane");
              console.log(leftWallPlane);
              pointerDragBehaviorLeftPlane.validateDrag = (targetPosition) => {
                console.log("leftWallMesh----We did enter this portion");
                console.log(leftWallMesh);
                console.log(leftWallMesh.getBoundingInfo().boundingBox);

                const bounds = leftWallPlane.getBoundingInfo().boundingBox;
                console.log("bounds");
                console.log(bounds);
                targetPosition.x = Scalar.Clamp(
                  targetPosition.x,
                  bounds.minimumWorld.x,
                  bounds.maximumWorld.x
                );
                targetPosition.y = Scalar.Clamp(
                  targetPosition.y,
                  13,
                  heightOffset
                );
                return true;
              };
              foundMatchingWall = false;
            }
            if (meshName.includes("lean")) {
              if (meshName.includes("storage")) {
                GlobalStore.setLTLeftStorageWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              } else {
                GlobalStore.setLTLeftWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              }
            } else {
              //(meshName)
              if (meshName.includes("storage")) {
                GlobalStore.setLeftStorageWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              } else {
                GlobalStore.setLeftWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              }
            }
          } else if (wall.includes("right")) {
            if (scene.meshes[i].name.includes("Lean_to_")) {
              // right lean
              windowWidth = windowWidth * 1.5;
              rightLeanMesh = scene.meshes[i];

              rightBoundingBox = bgObj.getBoundingInfo();

              var wallWidth = Math.round(
                parseInt(
                  rightLeanMesh.getBoundingInfo().boundingBox.maximumWorld.x
                ) -
                  parseInt(
                    rightLeanMesh.getBoundingInfo().boundingBox.minimumWorld.x
                  )
              );
              //var windowWidth = Math.round(parseInt(PrimaryMesh.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(PrimaryMesh.getBoundingInfo().boundingBox.minimumWorld.x));

              var wallHeight = Math.round(
                parseInt(
                  rightLeanMesh.getBoundingInfo().boundingBox.maximumWorld.y
                ) -
                  parseInt(
                    rightLeanMesh.getBoundingInfo().boundingBox.minimumWorld.y
                  )
              );
              var windowHeight = 0; //Math.round(parseInt(PrimaryMesh.getBoundingInfo().boundingBox.maximumWorld.y) - parseInt(PrimaryMesh.getBoundingInfo().boundingBox.minimumWorld.y));

              var rightLeanPlane = MeshBuilder.CreatePlane(
                "rightLeanBoundsPlane",
                {
                  width: wallWidth - windowWidth,
                  height: wallHeight - windowHeight,
                },
                scene
              );
              //rightLeanMesh.showBoundingBox= true;
              rightLeanPlane.isPickable = false;
              rightLeanPlane.position = rightLeanMesh.position.clone();
              rightLeanPlane.position.x =
                (rightLeanMesh.getBoundingInfo().boundingBox.maximumWorld.x +
                  rightLeanMesh.getBoundingInfo().boundingBox.minimumWorld.x) /
                2;
              rightLeanPlane.isVisible = false;
              //rightLeanPlane.showBoundingBox= true;

              pointerDragBehaviorRightLeanPlane.validateDrag = (
                targetPosition
              ) => {
                const bounds = rightLeanPlane.getBoundingInfo().boundingBox;
                targetPosition.x = Scalar.Clamp(
                  targetPosition.x,
                  bounds.minimumWorld.x,
                  bounds.maximumWorld.x
                );
                targetPosition.y = Scalar.Clamp(
                  targetPosition.y,
                  13,
                  heightOffset
                );
                return true;
              };
              foundMatchingWall = true;
            } else if (!scene.meshes[i].name.toLowerCase().includes("Lean")) {
              windowWidth = windowWidth * 1.5;
              rightWallMesh = scene.meshes[i];

              rightBoundingBox = bgObj.getBoundingInfo();

              var wallWidth = Math.round(
                parseInt(
                  rightWallMesh.getBoundingInfo().boundingBox.maximumWorld.x
                ) -
                  parseInt(
                    rightWallMesh.getBoundingInfo().boundingBox.minimumWorld.x
                  )
              );
              //var windowWidth = Math.round(parseInt(PrimaryMesh.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(PrimaryMesh.getBoundingInfo().boundingBox.minimumWorld.x));

              var wallHeight = Math.round(
                parseInt(
                  rightWallMesh.getBoundingInfo().boundingBox.maximumWorld.y
                ) -
                  parseInt(
                    rightWallMesh.getBoundingInfo().boundingBox.minimumWorld.y
                  )
              );
              var windowHeight = 0; //Math.round(parseInt(PrimaryMesh.getBoundingInfo().boundingBox.maximumWorld.y) - parseInt(PrimaryMesh.getBoundingInfo().boundingBox.minimumWorld.y));

              var rightWallPlane = MeshBuilder.CreatePlane(
                "rightBoundsPlane",
                {
                  width: wallWidth - windowWidth,
                  height: wallHeight - windowHeight,
                },
                scene
              );
              rightWallPlane.isPickable = false;
              rightWallPlane.position = bgObj.position.clone();

              rightWallPlane.position.x =
                (rightWallMesh.getBoundingInfo().boundingBox.maximumWorld.x +
                  rightWallMesh.getBoundingInfo().boundingBox.minimumWorld.x) /
                2;

              rightWallPlane.isVisible = false;

              pointerDragBehaviorRightPlane.validateDrag = (targetPosition) => {
                const bounds = rightWallPlane.getBoundingInfo().boundingBox;
                targetPosition.x = Scalar.Clamp(
                  targetPosition.x,
                  bounds.minimumWorld.x,
                  bounds.maximumWorld.x
                );
                targetPosition.y = Scalar.Clamp(
                  targetPosition.y,
                  13,
                  heightOffset
                );
                return true;
              };
              foundMatchingWall = true;
            }
            if (meshName.includes("lean")) {
              if (meshName.includes("storage")) {
                GlobalStore.setRTRightStorageWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              } else {
                GlobalStore.setRTRightWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              }
            } else {
              if (meshName.includes("storage")) {
                GlobalStore.setRightStorageWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              } else {
                GlobalStore.setRightWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              }
            }
          } else if (wall.includes("front")) {
            frontWallMesh = scene.meshes[i];

            frontBoundingBox = bgObj.getBoundingInfo();

            var wallWidth = Math.round(
              parseInt(
                frontWallMesh.getBoundingInfo().boundingBox.maximumWorld.z
              ) -
                parseInt(
                  frontWallMesh.getBoundingInfo().boundingBox.minimumWorld.z
                )
            );
            //var windowWidth = Math.round(parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.x));

            var wallHeight = Math.round(
              parseInt(
                frontWallMesh.getBoundingInfo().boundingBox.maximumWorld.y
              ) -
                parseInt(
                  frontWallMesh.getBoundingInfo().boundingBox.minimumWorld.y
                )
            );
            var windowHeight = 0; //Math.round(parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.y) - parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.y));

            var frontWallPlane = MeshBuilder.CreatePlane(
              "frontBoundsPlane",
              {
                width: wallWidth - windowWidth,
                height: wallHeight - windowHeight,
              },
              scene
            );
            frontWallPlane.isPickable = false;
            frontWallPlane.position = frontWallMesh.position.clone();
            let bounds = frontWallMesh.getBoundingInfo().boundingBox;
            frontWallPlane.position.z =
              bounds.minimumWorld.z +
              (bounds.maximumWorld.z - bounds.minimumWorld.z) / 2;
            frontWallPlane.rotation.y = (-90 * Math.PI) / 180;
            //frontWallPlane.position.z = -1 * Math.round(parseInt(frontWallMesh.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(frontWallMesh.getBoundingInfo().boundingBox.minimumWorld.x))/2;
            frontWallPlane.isVisible = false;

            pointerDragBehaviorFrontPlane.validateDrag = (targetPosition) => {
              const bounds = frontWallPlane.getBoundingInfo().boundingBox;
              targetPosition.z = Scalar.Clamp(
                targetPosition.z,
                bounds.minimumWorld.z + limit,
                bounds.maximumWorld.z - limit
              );
              targetPosition.y = Scalar.Clamp(
                targetPosition.y,
                13,
                heightOffset
              );
              return true;
            };
            if (leanTo === "LT") {
              if (meshName.includes("storage")) {
                GlobalStore.setLTFrontStorageWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              } else {
                GlobalStore.setLTFrontWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              }
            } else if (leanTo == "RT") {
              if (meshName.includes("storage")) {
                GlobalStore.setRTFrontStorageWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              } else {
                GlobalStore.setRTFrontWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              }
            } else {
              if (meshName.includes("storage")) {
                GlobalStore.setFrontCenterStorageWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              } else {
                //frontCenterWallObjects.push(pickObj)
                GlobalStore.setFrontCenterWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
                //console.log("frontCenterWallsObject \n " + GlobalStore.frontCenterWallObjects.length);
              }
            }
          } else if (wall.includes("back")) {
            backWallMesh = scene.meshes[i];
            backBoundingBox = bgObj.getBoundingInfo();

            var wallWidth = Math.round(
              parseInt(
                backWallMesh.getBoundingInfo().boundingBox.maximumWorld.z
              ) -
                parseInt(
                  backWallMesh.getBoundingInfo().boundingBox.minimumWorld.z
                )
            );
            //var windowWidth = Math.round(parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.x));

            var wallHeight = Math.round(
              parseInt(
                backWallMesh.getBoundingInfo().boundingBox.maximumWorld.y
              ) -
                parseInt(
                  backWallMesh.getBoundingInfo().boundingBox.minimumWorld.y
                )
            );
            var windowHeight = Math.round(
              parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.y) -
                parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.y)
            );

            var backWallPlane = MeshBuilder.CreatePlane(
              "BackBoundsPlane",
              {
                width: wallWidth - windowWidth,
                height: wallHeight - windowHeight,
              },
              scene
            );
            backWallPlane.isPickable = false;
            backWallPlane.position = backWallMesh.position.clone();
            //backWallPlane.rotation.y = -90 * Math.PI / 180;
            backWallPlane.rotation.y = (90 * Math.PI) / 180;
            let bounds = backWallMesh.getBoundingInfo().boundingBox;
            backWallPlane.position.z =
              bounds.minimumWorld.z +
              (bounds.maximumWorld.z - bounds.minimumWorld.z) / 2;
            backWallPlane.isVisible = false;

            pointerDragBehaviorBackPlane.validateDrag = (targetPosition) => {
              const bounds = backWallPlane.getBoundingInfo().boundingBox;
              targetPosition.z = Scalar.Clamp(
                targetPosition.z,
                bounds.minimumWorld.z + limit,
                bounds.maximumWorld.z - limit
              );
              targetPosition.y = Scalar.Clamp(
                targetPosition.y,
                13,
                heightOffset
              );
              return true;
            };

            /*depends on the requirements
          if(leanTo === "LT"){
                if(meshName.includes("Storage")){
                  LTFrontWallStorageObjects.push(pickObj)
                }
                else{
                  LTFrontWallObjects.push(pickObj)
                }
            }
            else if(leanTo=="RT"){
              if(meshName.includes("Storage")){
                RTFrontWallStorageObjects.push(pickObj)
              }
              else{
                RTFrontWallObjects.push(pickObj)
              }
            }
            else{
              if(meshName.includes("Storage")){
                frontCenterStorageWallObjects.push(pickObj)
              }
              else{
                GlobalStore.frontCenterWallObjects.push(pickObj)
              }
            }
          */

            if (leanTo === "LT") {
              GlobalStore.setLTBackWallObjects((prev) => {
                return [...prev, newWallObject];
              });
            } else if (leanTo == "RT") {
              GlobalStore.setRTBackWallObjects((prev) => {
                return [...prev, newWallObject];
              });
            } else {
              GlobalStore.setBackCenterWallObjects((prev) => {
                return [...prev, newWallObject];
              });
            }
          }
        }
      }
    } else {
      //for the barn. First do it all for the things other than the barn
      var foundMatchingWall = false;
      let count = 0;
      for (var i = 0; i < scene.meshes.length; i++) {
        var meshName = scene.meshes[i].name.toLowerCase();
        if (
          scene.meshes[i].name.includes(wall) &&
          !meshName.includes("inner") &&
          !meshName.includes("up") &&
          !meshName.includes("down") &&
          !meshName.includes("wainscot") &&
          //!meshName.includes("storage") &&
          scene.meshes[i].isVisible &&
          !foundMatchingWall &&
          scene.meshes[i].name === WallMesh //the found matching wall is not functioning correctly. Maybe it is being resetted
        ) {
          console.log(scene.meshes[i].name);
          bgObj = scene.meshes[i];
          
          newWallObject = {
            object: pickObj,
            wall: bgObj,
            pointer: null,
            price: 0,
            heightOffset: heightOffset,
            limit: 13,
          };

          count++;
          console.log(`count: ${count} frontWallMesh=${bgObj.name}`);
          // bgObj.showBoundingBox=true;

          if (wall.includes("left")) {
            if (scene.meshes[i].name.includes("Lean_to_")) {
              // handle left lean

              windowWidth = windowWidth * 1.5;
              leftLeanMesh = scene.meshes[i];
              leftLeanBoundingBox = bgObj.getBoundingInfo();

              var wallWidth = Math.round(
                parseInt(
                  leftLeanMesh.getBoundingInfo().boundingBox.maximumWorld.x
                ) -
                  parseInt(
                    leftLeanMesh.getBoundingInfo().boundingBox.minimumWorld.x
                  )
              );

              var wallHeight = Math.round(
                parseInt(
                  leftLeanMesh.getBoundingInfo().boundingBox.maximumWorld.y
                ) -
                  parseInt(
                    leftLeanMesh.getBoundingInfo().boundingBox.minimumWorld.y
                  )
              );
              var windowHeight = Math.round(
                parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.y) -
                  parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.y)
              );

              var leftLeanPlane = MeshBuilder.CreatePlane(
                "leftLeanBoundsPlane",
                {
                  width: wallWidth - windowWidth,
                  height: wallHeight - windowHeight,
                },
                scene
              );
              leftLeanPlane.isPickable = false;
              leftLeanPlane.position = leftLeanMesh.position;
              leftLeanPlane.position.z =
                (-1 *
                  Math.round(
                    parseInt(
                      leftLeanMesh.getBoundingInfo().boundingBox.maximumWorld.z
                    ) -
                      parseInt(
                        leftLeanMesh.getBoundingInfo().boundingBox.minimumWorld
                          .z
                      )
                  )) /
                2;
              leftLeanPlane.isVisible = false; //should be set to false when not testing
              //this could be used to drag the door against

              pointerDragBehaviorLeftLeanPlane.validateDrag = (
                targetPosition
              ) => {
                const bounds = leftLeanPlane.getBoundingInfo().boundingBox;
                targetPosition.x = Scalar.Clamp(
                  targetPosition.x,
                  bounds.minimum.x,
                  bounds.maximum.x
                );
                targetPosition.y = Scalar.Clamp(
                  targetPosition.y,
                  13,
                  heightOffset
                );
                return true;
              };
              foundMatchingWall = true;
            } else if (!scene.meshes[i].name.toLowerCase().includes("Lean")) {
              windowWidth = windowWidth * 1.5;
              leftWallMesh = scene.meshes[i];
              leftBoundingBox = bgObj.getBoundingInfo();

              var wallWidth = Math.round(
                parseInt(
                  leftWallMesh.getBoundingInfo().boundingBox.maximumWorld.x
                ) -
                  parseInt(
                    leftWallMesh.getBoundingInfo().boundingBox.minimumWorld.x
                  )
              );
              //var windowWidth = Math.round(parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.x));

              var wallHeight = Math.round(
                parseInt(
                  leftWallMesh.getBoundingInfo().boundingBox.maximumWorld.y
                ) -
                  parseInt(
                    leftWallMesh.getBoundingInfo().boundingBox.minimumWorld.y
                  )
              );
              var windowHeight = Math.round(
                parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.y) -
                  parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.y)
              );

              var leftWallPlane = MeshBuilder.CreatePlane(
                "leftBoundsPlane",
                {
                  width: wallWidth - windowWidth,
                  height: wallHeight - windowHeight,
                },
                scene
              );
              leftWallPlane.isPickable = false;
              leftWallPlane.position = leftWallMesh.position;
              leftWallPlane.position.z =
                (-1 *
                  Math.round(
                    parseInt(
                      leftWallMesh.getBoundingInfo().boundingBox.maximumWorld.z
                    ) -
                      parseInt(
                        leftWallMesh.getBoundingInfo().boundingBox.minimumWorld
                          .z
                      )
                  )) /
                2;
              leftWallPlane.isVisible = false;

              console.log("leftWallPlane");
              console.log(leftWallPlane);
              pointerDragBehaviorLeftPlane.validateDrag = (targetPosition) => {
                console.log("leftWallMesh");
                console.log(leftWallMesh);
                console.log(leftWallMesh.getBoundingInfo().boundingBox);

                const bounds = leftWallPlane.getBoundingInfo().boundingBox;
                console.log("bounds");
                console.log(bounds);
                targetPosition.x = Scalar.Clamp(
                  targetPosition.x,
                  bounds.minimum.x,
                  bounds.maximum.x
                );
                targetPosition.y = Scalar.Clamp(
                  targetPosition.y,
                  13,
                  heightOffset
                );
                return true;
              };
              foundMatchingWall = false;
            }
          } else if (wall.includes("right")) {
            if (scene.meshes[i].name.includes("Lean_to_")) {
              // right lean
              windowWidth = windowWidth * 1.5;
              rightLeanMesh = scene.meshes[i];

              rightBoundingBox = bgObj.getBoundingInfo();

              var wallWidth = Math.round(
                parseInt(
                  rightLeanMesh.getBoundingInfo().boundingBox.maximumWorld.x
                ) -
                  parseInt(
                    rightLeanMesh.getBoundingInfo().boundingBox.minimumWorld.x
                  )
              );
              //var windowWidth = Math.round(parseInt(PrimaryMesh.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(PrimaryMesh.getBoundingInfo().boundingBox.minimumWorld.x));

              var wallHeight = Math.round(
                parseInt(
                  rightLeanMesh.getBoundingInfo().boundingBox.maximumWorld.y
                ) -
                  parseInt(
                    rightLeanMesh.getBoundingInfo().boundingBox.minimumWorld.y
                  )
              );
              var windowHeight = 0; //Math.round(parseInt(PrimaryMesh.getBoundingInfo().boundingBox.maximumWorld.y) - parseInt(PrimaryMesh.getBoundingInfo().boundingBox.minimumWorld.y));

              var rightLeanPlane = MeshBuilder.CreatePlane(
                "rightLeanBoundsPlane",
                {
                  width: 10 * (wallWidth - windowWidth),
                  height: wallHeight - windowHeight,
                },
                scene
              );
              rightLeanPlane.isPickable = false;
              rightLeanPlane.position = rightLeanMesh.position;
              rightLeanPlane.position.z =
                (-1 *
                  Math.round(
                    parseInt(
                      rightLeanMesh.getBoundingInfo().boundingBox.maximumWorld.z
                    ) -
                      parseInt(
                        rightLeanMesh.getBoundingInfo().boundingBox.minimumWorld
                          .z
                      )
                  )) /
                2;
              rightLeanPlane.isVisible = false;

              pointerDragBehaviorRightLeanPlane.validateDrag = (
                targetPosition
              ) => {
                const bounds = rightLeanPlane.getBoundingInfo().boundingBox;
                targetPosition.x = Scalar.Clamp(
                  targetPosition.x,
                  bounds.minimum.x,
                  bounds.maximum.x
                );
                targetPosition.y = Scalar.Clamp(
                  targetPosition.y,
                  13,
                  heightOffset
                );
                return true;
              };
              foundMatchingWall = true;
            } else if (!scene.meshes[i].name.toLowerCase().includes("Lean")) {
              windowWidth = windowWidth * 1.5;
              rightWallMesh = scene.meshes[i];

              rightBoundingBox = bgObj.getBoundingInfo();

              var wallWidth = Math.round(
                parseInt(
                  rightWallMesh.getBoundingInfo().boundingBox.maximumWorld.x
                ) -
                  parseInt(
                    rightWallMesh.getBoundingInfo().boundingBox.minimumWorld.x
                  )
              );
              //var windowWidth = Math.round(parseInt(PrimaryMesh.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(PrimaryMesh.getBoundingInfo().boundingBox.minimumWorld.x));

              var wallHeight = Math.round(
                parseInt(
                  rightWallMesh.getBoundingInfo().boundingBox.maximumWorld.y
                ) -
                  parseInt(
                    rightWallMesh.getBoundingInfo().boundingBox.minimumWorld.y
                  )
              );
              var windowHeight = 0; //Math.round(parseInt(PrimaryMesh.getBoundingInfo().boundingBox.maximumWorld.y) - parseInt(PrimaryMesh.getBoundingInfo().boundingBox.minimumWorld.y));

              var rightWallPlane = MeshBuilder.CreatePlane(
                "rightBoundsPlane",
                {
                  width: wallWidth - windowWidth,
                  height: wallHeight - windowHeight,
                },
                scene
              );
              rightWallPlane.isPickable = false;
              rightWallPlane.position = rightWallMesh.position;
              //rightWallPlane.rotation = rightWallMesh.rotation;
              rightWallPlane.position.z =
                (-1 *
                  Math.round(
                    parseInt(
                      rightWallMesh.getBoundingInfo().boundingBox.maximumWorld.z
                    ) -
                      parseInt(
                        rightWallMesh.getBoundingInfo().boundingBox.minimumWorld
                          .z
                      )
                  )) /
                2;
              rightWallPlane.isVisible = false;

              pointerDragBehaviorRightPlane.validateDrag = (targetPosition) => {
                const bounds = rightWallPlane.getBoundingInfo().boundingBox;
                targetPosition.x = Scalar.Clamp(
                  targetPosition.x,
                  bounds.minimum.x,
                  bounds.maximum.x
                );
                targetPosition.y = Scalar.Clamp(
                  targetPosition.y,
                  13,
                  heightOffset
                );
                return true;
              };
              foundMatchingWall = true;
            }
          } else if (wall.includes("front")) {
            frontWallMesh = scene.meshes[i];

            frontBoundingBox = bgObj.getBoundingInfo();

            var wallWidth = Math.round(
              parseInt(
                frontWallMesh.getBoundingInfo().boundingBox.maximumWorld.z
              ) -
                parseInt(
                  frontWallMesh.getBoundingInfo().boundingBox.minimumWorld.z
                )
            );
            //var windowWidth = Math.round(parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.x));

            var wallHeight = Math.round(
              parseInt(
                frontWallMesh.getBoundingInfo().boundingBox.maximumWorld.y
              ) -
                parseInt(
                  frontWallMesh.getBoundingInfo().boundingBox.minimumWorld.y
                )
            );
            var windowHeight = 0; //Math.round(parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.y) - parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.y));

            var frontWallPlane = MeshBuilder.CreatePlane(
              "frontBoundsPlane",
              {
                width: 0.9 * (wallWidth - windowWidth),
                height: wallHeight - windowHeight,
              },
              scene
            );
            frontWallPlane.isPickable = false;

            frontWallPlane.rotation.y = (-90 * Math.PI) / 180;
            //frontWallPlane.position.z = -1 * Math.round(parseInt(frontWallMesh.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(frontWallMesh.getBoundingInfo().boundingBox.minimumWorld.x))/2;
            frontWallPlane.position = frontWallMesh.position.clone();
            let bounds = frontWallMesh.getBoundingInfo().boundingBox;
            frontWallPlane.position.z =
              bounds.minimumWorld.z +
              (bounds.maximumWorld.z - bounds.minimumWorld.z) / 2;
            //frontWallPlane.isVisible = false;

            pointerDragBehaviorFrontPlane.validateDrag = (targetPosition) => {
              const bounds = frontWallPlane.getBoundingInfo().boundingBox;
              targetPosition.z = Scalar.Clamp(
                targetPosition.z,
                bounds.minimumWorld.z,
                bounds.maximumWorld.z
              );
              targetPosition.y = Scalar.Clamp(
                targetPosition.y,
                13,
                heightOffset
              );
              return true;
            };
            if (leanTo === "LT") {
              if (meshName.includes("storage")) {
                GlobalStore.setLTFrontStorageWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              } else {
                GlobalStore.setLTFrontWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              }
            } else if (leanTo == "RT") {
              if (meshName.includes("storage")) {
                GlobalStore.setRTFrontStorageWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              } else {
                GlobalStore.setRTFrontWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              }
            } else {
              if (meshName.includes("storage")) {
                GlobalStore.setFrontCenterStorageWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
              } else {
                //frontCenterWallObjects.push(pickObj)
                GlobalStore.setFrontCenterWallObjects((prev) => {
                  return [...prev, newWallObject];
                });
                //console.log("frontCenterWallsObject \n " + GlobalStore.frontCenterWallObjects.length);
              }
            }
          } else if (wall.includes("back")) {
            backWallMesh = scene.meshes[i];
            backBoundingBox = bgObj.getBoundingInfo();

            var wallWidth = Math.round(
              parseInt(
                backWallMesh.getBoundingInfo().boundingBox.maximumWorld.z
              ) -
                parseInt(
                  backWallMesh.getBoundingInfo().boundingBox.minimumWorld.z
                )
            );
            //var windowWidth = Math.round(parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.x) - parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.x));

            var wallHeight = Math.round(
              parseInt(
                backWallMesh.getBoundingInfo().boundingBox.maximumWorld.y
              ) -
                parseInt(
                  backWallMesh.getBoundingInfo().boundingBox.minimumWorld.y
                )
            );
            var windowHeight = Math.round(
              parseInt(pickObj.getBoundingInfo().boundingBox.maximumWorld.y) -
                parseInt(pickObj.getBoundingInfo().boundingBox.minimumWorld.y)
            );

            var backWallPlane = MeshBuilder.CreatePlane(
              "BackBoundsPlane",
              {
                width: 0.9 * (wallWidth - windowWidth),
                height: wallHeight - windowHeight,
              },
              scene
            );
            backWallPlane.isPickable = false;
            backWallPlane.position = backWallMesh.position.clone();
            //backWallPlane.rotation.y = -90 * Math.PI / 180;
            backWallPlane.rotation.y = (90 * Math.PI) / 180;
            let bounds = backWallMesh.getBoundingInfo().boundingBox;
            backWallPlane.position.z =
              bounds.minimumWorld.z +
              (bounds.maximumWorld.z - bounds.minimumWorld.z) / 2;
            //backWallPlane.isVisible = false;

            pointerDragBehaviorBackPlane.validateDrag = (targetPosition) => {
              const bounds = backWallPlane.getBoundingInfo().boundingBox;
              targetPosition.z = Scalar.Clamp(
                targetPosition.z,
                bounds.minimumWorld.z + limit,
                bounds.maximumWorld.z - limit
              );
              targetPosition.y = Scalar.Clamp(
                targetPosition.y,
                13,
                heightOffset
              );
              return true;
            };
          }
        }
      }
    }

    console.log("bgObj");
    console.log(bgObj.name);
    GlobalStore.setPrice(GlobalStore.price + 100);
    //pickObj.parent = bgObj;

    /*GlobalStore.setWallObjects((prev) => {
      console.log(GlobalStore.WallObjects);
      return [...prev, newWallObject];
    });*/

    var plane = Mesh.CreatePlane("plane", 200, scene);
    plane.parent = pickObj;
    plane.rotation.y = (90 * Math.PI) / 180;
    plane.position.x += 4;

    var guiCanvas = AdvancedDynamicTexture.CreateForMesh(plane); //, 20, 20);

    let guiButton = Button.CreateImageOnlyButton(
      "guiButton",
      "./img/icon/Cross.png"
    );
    guiButton.width = 0.2;
    guiButton.height = 0.2;

    //guiButton.fontSize = 1;
    guiButton.color = "black";
    guiButton.onPointerUpObservable.add(function () {
      guiCanvas.dispose();
      pickObj.dispose();
      console.log("close button");

      GlobalStore.setPrice(GlobalStore.price - 100);
    });
    guiButton.top -= pickObj.getBoundingInfo().boundingBox.maximumWorld.y;
    guiButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    guiButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    guiCanvas.addControl(guiButton);
    console.log(guiButton);

    if (wall.includes("left")) {
      if (
        /*GlobalStore.isLeftLeanActive.current[GlobalStore.buildingType.id] ==
        "true"*/
        WallMesh.includes("Lean")
      ) {
        pointerDragBehaviorLeftLeanPlane.attach(pickObj);
        newWallObject.pointer = pointerDragBehaviorLeftLeanPlane;
        //alert("we have entered the lean area");
      } else {
        pointerDragBehaviorLeftPlane.attach(pickObj);
        newWallObject.pointer = pointerDragBehaviorLeftPlane;
      }
    } else if (wall.includes("right")) {
      if (
        /*GlobalStore.isRightLeanActive.current[GlobalStore.buildingType.id] ==
        "true"*/
        WallMesh.includes("Lean")
      ) {
        pointerDragBehaviorRightLeanPlane.attach(pickObj);
        newWallObject.pointer = pointerDragBehaviorRightLeanPlane;
      } else {
        pointerDragBehaviorRightPlane.attach(pickObj);
        newWallObject.pointer = pointerDragBehaviorRightPlane;
      }
    } else if (wall.includes("front")) {
      pointerDragBehaviorFrontPlane.attach(pickObj);
      newWallObject.pointer = pointerDragBehaviorFrontPlane;
    } else if (wall.includes("back")) {
      pointerDragBehaviorBackPlane.attach(pickObj);
      newWallObject.pointer = pointerDragBehaviorBackPlane;
    }

    console.log(newWallObject);
    //pickObj.parent = bgObj;

    if (WallMesh.includes("front_")) {
      //pickObj.position.x = GlobalStore.DoorMeshPosFrontWall.current[GlobalStore.buildingType.id];

      pickObj.position.x = bgObj.getBoundingInfo().boundingBox.maximumWorld.x;
      pickObj.position.y += 13;
      pickObj.scaling = new Vector3(1 / 7, 1 / 7, 1 / 7);
      pickObj.position.z =
        (bgObj.getBoundingInfo().boundingBox.maximumWorld.z +
          bgObj.getBoundingInfo().boundingBox.minimumWorld.z) /
        2;

      pickObj.isVisible = true;
      if (WallMesh.includes("LT")) {
        //pickObj.position.z = pickObj.position.z+400;
      } else if (WallMesh.includes("RT")) {
        //pickObj.position.z = pickObj.position.z-400;
      } else {
        pickObj.position.z = pickObj.position.z;
      }
    } else if (WallMesh.includes("back_")) {
      //pickObj.position.x = GlobalStore.DoorMeshPosBackWall.current[GlobalStore.buildingType.id];
      pickObj.position.x = bgObj.getBoundingInfo().boundingBox.maximumWorld.x;
      pickObj.position.y += 13;
      pickObj.scaling = new Vector3(1 / 7, 1 / 7, 1 / 7);
      pickObj.position.z =
        (bgObj.getBoundingInfo().boundingBox.maximumWorld.z +
          bgObj.getBoundingInfo().boundingBox.minimumWorld.z) /
        2;
      pickObj.rotation.y = (180 * Math.PI) / 180;

      pickObj.isVisible = true;
      if (WallMesh.includes("LT")) {
        //pickObj.position.z = pickObj.position.z+20;
      } else if (WallMesh.includes("RT")) {
        //pickObj.position.z = pickObj.position.z-20;
      } else {
        pickObj.position.z = pickObj.position.z;
      }
    } else if (WallMesh.includes("right_")) {
      pickObj.position.z = bgObj.getBoundingInfo().boundingBox.maximumWorld.z;
      pickObj.position.x =
        (bgObj.getBoundingInfo().boundingBox.maximumWorld.x +
          bgObj.getBoundingInfo().boundingBox.minimumWorld.x) /
        2;
      pickObj.position.y += 13;
      pickObj.scaling = new Vector3(1 / 7, 1 / 7, 1 / 7);
      pickObj.rotation.y = (90 * Math.PI) / 180;
      pickObj.isVisible = true;
      pickObj.position.x = pickObj.position.x + 5;
    } else if (WallMesh.includes("left_")) {
      pickObj.position.z = bgObj.getBoundingInfo().boundingBox.maximumWorld.z;
      pickObj.position.x =
        (bgObj.getBoundingInfo().boundingBox.maximumWorld.x +
          bgObj.getBoundingInfo().boundingBox.minimumWorld.x) /
        2;
      pickObj.position.y += 13;
      pickObj.scaling = new Vector3(1 / 7, 1 / 7, 1 / 7);
      pickObj.rotation.y = (-90 * Math.PI) / 180;
      pickObj.position.x = pickObj.position.x;
      pickObj.isVisible = true;
    }

    console.log("pickObj.position");
    console.log(pickObj.position);
    pickObj.getChildMeshes().map((mesh) => {
      mesh.isVisible = true;
      mesh.isPickable = true;
    });

    scene.getEngine().setStencilBuffer(true);
    for (var i = 0; i < scene.meshes.length; i++) {
      if (scene.meshes[i].name.includes(PrimaryMesh.name)) {
        var mesh = scene.meshes[i];
        scene.meshes.splice(i, 1);
        //console.log("in loop");
        mesh.onBeforeRenderObservable.add(function () {
          if (mesh.material.stencil != null) {
            console.log("For Stencil " + mesh.material.name);
            mesh.material.stencil.enabled = true;
            mesh.material.stencil.opStencilDepthPass = Engine.REPLACE;
            mesh.material.stencil.mask = 0xff;
            mesh.material.stencil.func = Engine.ALWAYS;
            mesh.material.stencil.funcRef = 2;
          }
        });

        scene.meshes.splice(0, 0, mesh);
      }
    }
    GlobalStore.setDwSummary([...GlobalStore.dwSummary, pickObj]);
    console.log(newWallObject);
    return pickObj;
  }

  const addGarageDoor = (active) => {
    var pickObj = DoorAndWindowSet(GlobalStore.DoorMesh.current[0]);
    // GlobalStore.setDoorSummary([...GlobalStore.doorSummary,GlobalStore.DoorMesh.current[0]])
    console.log(GlobalStore.DoorMesh.current[0]);
    if (typeof pickObj != "undefined") {
      pickObj.position.y -= 13;
    }
  };
  const addWalkInDoor = (active) => {
    console.log("adding walk-in door");
    var pickObj = DoorAndWindowSet(GlobalStore.WalkDoorMesh.current[0]);
    if (typeof pickObj != "undefined") {
      pickObj.position.y -= 13;
    }
  };
  const addWindow = (active) => {
    DoorAndWindowSet(GlobalStore.WindowMesh.current[0]);
  };
  const addFramedOpening = (active) => {
    DoorAndWindowSet(GlobalStore.FramedMesh.current[0]);
    console.log("this is frame door opening which is to be stenciled");
  };
  const addFramedWalkInDoor = (active) => {
    var pickObj = DoorAndWindowSet(GlobalStore.FramedDoorMesh.current[0]);
    if (typeof pickObj != "undefined") {
      pickObj.position.y -= 13;
    }
  };
  const addFramedWindow = (active) => {
    DoorAndWindowSet(GlobalStore.FramedWindowMesh.current[0]);
  };
  scene.setRenderingAutoClearDepthStencil(0, true);
  useEffect(() => {
    //your code goes here
    return () => {
      // console.error("clean doors");
    };
  }, []);

  /*
  const frontPlace, backPlane, rightPlace, LeftPlane;
  const LT_FrontPlane, LTBackPlane, LT_LeftPlane;
  const RT_FrontPlane, RTBackPlane, RT_RightPlane;
  const S_FrontPlane, S_BackPlane, S_RightPlane, S_LeftPlane;
  const LTS_FrontPlane, LTS_LeftPlane;
  const RTS_FrontPlane, RTS_RightPlane;
  

  function createPlanes(){
    for(i=0, i<scene.meshes.length, i++){
      if(scene.meshes[i]===)
    }
  }
  */
  return (
    <CardsAddWrapper isActive={isActive}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CardsAddItem>
            <CardsAddItemIcon>
              <svg
                width="42"
                height="42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.009.186C.802.288.499.573.336.82l-.296.448-.033 19.444C-.01 31.407.004 40.32.04 40.517c.086.482.56 1.025 1.115 1.277.434.197 1.27.206 19.837.206 18.608 0 19.401-.008 19.832-.207.518-.24.887-.607 1.056-1.056.084-.219.12-6.264.12-19.776V1.501l-.252-.5a1.74 1.74 0 00-.7-.75L40.599 0H20.992C2.993.002 1.353.018 1.009.187zm39.394 1.318c.201.077.207 38.71.006 38.91-.179.179-38.419.197-38.752.019-.213-.114-.217-.45-.217-19.496 0-19.079.003-19.38.217-19.46.237-.088 38.517-.061 38.746.027zM9.507 20.384v12.771H7.602v1.456h26.779v-1.456H32.476V7.614H9.507v12.77zM21.412 9.061l9.608.01v23.86H10.964V21.01c0-11.265.01-11.925.196-11.963.108-.022.297-.03.42-.018.123.013 4.548.028 9.832.033zm-7.087 4.77v.728h14.352l-.033-.7-.033-.7-7.143-.029-7.143-.029v.73zm0 4.817v.728h6.994c3.846 0 7.073-.03 7.17-.068.134-.051.178-.23.178-.728v-.66H14.326v.728zm.023 4.732l.033.757h14.23v-1.456l-7.148-.029-7.148-.029.033.757zm-.023 4.79v.73l7.143-.029 7.143-.029.033-.7.033-.7H14.325v.729z"
                  fill="#000"
                />
              </svg>
            </CardsAddItemIcon>
            <CardsAddItemText>Garage Door</CardsAddItemText>
            <CardsAddItemAddBtn onClick={addGarageDoor}>
              <svg
                width="9"
                height="9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z"
                  fill="#fff"
                />
              </svg>
              ADD
            </CardsAddItemAddBtn>
          </CardsAddItem>
        </Grid>
        <Grid item xs={6}>
          <CardsAddItem>
            <CardsAddItemIcon>
              <svg
                width="42"
                height="42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.997.111C1.826.365.707 1.324.243 2.471L0 3.07v17.816c0 14.325.027 17.917.137 18.332.295 1.106 1.278 2.16 2.411 2.584.567.212.702.213 18.643.186l18.072-.028.583-.285c.743-.365 1.503-1.123 1.868-1.866l.286-.582V2.755l-.257-.526c-.367-.752-1.327-1.658-2.077-1.96l-.613-.247L21.316.003C7.859-.01 3.439.016 2.997.111zM39 1.544c.499.139 1.114.665 1.398 1.195l.234.436v35.632l-.228.42c-.278.514-.828 1.053-1.227 1.203-.204.077-5.811.111-18.135.111-14.783 0-17.897-.024-18.18-.142-.405-.17-.954-.695-1.207-1.156-.17-.309-.183-1.517-.209-18.026-.018-11.519.01-17.83.078-18.084.139-.517.734-1.195 1.29-1.47l.45-.222h17.683c11.15 0 17.82.039 18.053.103zm-26.421 19.08v11.824H14.158V10.27h13.789v22.178h1.474V8.798H12.58v11.825z"
                  fill="#000"
                />
              </svg>
            </CardsAddItemIcon>
            <CardsAddItemText>Walk-in Door</CardsAddItemText>
            <CardsAddItemAddBtn onClick={addWalkInDoor}>
              <svg
                width="9"
                height="9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z"
                  fill="#fff"
                />
              </svg>
              ADD
            </CardsAddItemAddBtn>
          </CardsAddItem>
        </Grid>
        <Grid item xs={6}>
          <CardsAddItem>
            <CardsAddItemIcon>
              <svg
                width="42"
                height="42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.644.175C1.57.547.862 1.214.324 2.36l-.288.614-.03 17.497C-.013 32.336.014 38.2.089 38.687c.134.872.399 1.414 1.007 2.064a3.688 3.688 0 002.149 1.155c.488.084 5.713.108 18.247.087l17.564-.03.613-.248c.742-.299 1.688-1.194 2.07-1.96L42 39.23V2.765l-.273-.578c-.356-.754-.981-1.382-1.801-1.81l-.66-.345L21.228.01C4.087-.013 3.164-.005 2.644.175zm36.54 1.44c.635.239 1.25.971 1.392 1.657.078.377.104 5.905.085 18.04l-.028 17.497-.227.42c-.29.541-.604.829-1.193 1.096-.47.212-.585.214-18.078.216-13.57.002-17.703-.027-18.037-.126-.601-.178-1.353-.947-1.52-1.553-.1-.368-.123-4.376-.1-18.128l.03-17.654.292-.497c.348-.59 1.062-1.05 1.757-1.13.262-.03 8.285-.044 17.83-.03 15.602.02 17.398.04 17.798.191zm-25.69 11.317c-.028.072-.038 3.63-.023 7.908l.027 7.776 7.599.027 7.599.027V12.8H21.12c-5.964 0-7.587.028-7.626.132zm13.73 4.23v2.891l-6.18-.027-6.179-.027-.028-2.73c-.015-1.502-.005-2.791.023-2.864.04-.106 1.337-.133 6.207-.133h6.156v2.89zm0 7.2V27.2l-6.18-.027-6.179-.027-.028-2.73c-.015-1.503-.01-2.78.013-2.838.027-.071 2.102-.098 6.207-.079l6.166.028v2.837z"
                  fill="#000"
                />
              </svg>
            </CardsAddItemIcon>
            <CardsAddItemText>Window</CardsAddItemText>
            <CardsAddItemAddBtn onClick={addWindow}>
              <svg
                width="9"
                height="9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z"
                  fill="#fff"
                />
              </svg>
              ADD
            </CardsAddItemAddBtn>
          </CardsAddItem>
        </Grid>
        <Grid item xs={6}>
          <CardsAddItem>
            <CardsAddItemIcon>
              <svg
                width="42"
                height="42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.015.213C.519.475.223.897.086 1.537c-.073.344-.1 6.788-.08 19.78C.032 39.015.05 40.61.208 40.9c.237.428.594.745 1.059.94.346.144 2.514.162 19.8.16 18.646-.002 19.426-.01 19.83-.196.486-.225.832-.57.99-.99.079-.206.113-6.266.113-19.89V1.332l-.252-.43a2.11 2.11 0 00-.671-.666L40.658 0 21.03.005C1.815.009 1.392.014 1.015.213zM40.503 1.66c.172.216.195 38.347.023 38.668-.107.2-.436.203-19.465.203-14.915 0-19.385-.029-19.482-.126-.198-.197-.182-38.654.015-38.818.104-.085 5.339-.125 19.25-.144 10.51-.014 19.202-.009 19.318.013.115.022.269.114.341.204zM8.875 8.787c-.038.038-.07 5.376-.07 11.864v11.796h1.471V10.29H31.705l.026 11.052.027 11.052.762.031.762.03-.027-11.843-.026-11.844-12.143-.025c-6.678-.014-12.174.006-12.211.044z"
                  fill="#000"
                />
              </svg>
            </CardsAddItemIcon>
            <CardsAddItemText>Framed Openings</CardsAddItemText>
            <CardsAddItemAddBtn onClick={addFramedOpening}>
              <svg
                width="9"
                height="9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z"
                  fill="#fff"
                />
              </svg>
              ADD
            </CardsAddItemAddBtn>
          </CardsAddItem>
        </Grid>
        <Grid item xs={6}>
          <CardsAddItem>
            <CardsAddItemIcon>
              <svg
                width="42"
                height="42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.644.175C1.239.662.322 1.785.088 3.308c-.075.486-.101 6.35-.082 18.162l.03 17.445.244.604c.297.734 1.083 1.62 1.771 1.994.28.152.817.329 1.193.393.487.083 5.727.108 18.247.087l17.564-.03.613-.248c.742-.299 1.688-1.194 2.07-1.96L42 39.23V2.765l-.272-.579C41.383 1.45 40.64.706 39.87.33l-.603-.297L21.228.01C4.087-.013 3.164-.005 2.644.175zm36.54 1.44c.635.238 1.25.97 1.392 1.657.078.377.104 5.905.085 18.04l-.028 17.498-.227.42c-.29.54-.604.828-1.193 1.095-.47.213-.585.214-18.078.216-13.57.002-17.703-.027-18.037-.126-.602-.178-1.353-.947-1.52-1.553-.101-.368-.123-4.376-.1-18.127l.03-17.655.292-.497c.332-.564.972-.993 1.652-1.11.243-.04 8.281-.064 17.864-.05 15.653.022 17.469.041 17.869.192zM13.472 20.97l.027 7.645h15.145l.027-7.645.027-7.645H13.444l.027 7.645zm13.752.027V27.2l-6.179-.027-6.179-.027-.027-6.174-.027-6.174h12.412v6.201z"
                  fill="#000"
                />
              </svg>
            </CardsAddItemIcon>
            <CardsAddItemText>
              Walk-in Door <br /> Frame-out
            </CardsAddItemText>
            <CardsAddItemAddBtn onClick={addFramedWalkInDoor}>
              <svg
                width="9"
                height="9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z"
                  fill="#fff"
                />
              </svg>
              ADD
            </CardsAddItemAddBtn>
          </CardsAddItem>
        </Grid>
        <Grid item xs={6}>
          <CardsAddItem>
            <CardsAddItemIcon>
              <svg
                width="42"
                height="42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.644.175C1.239.662.322 1.785.088 3.308c-.075.486-.101 6.35-.082 18.162l.03 17.445.244.604c.297.734 1.083 1.62 1.771 1.994.28.152.817.329 1.193.393.487.083 5.727.108 18.247.087l17.564-.03.613-.248c.742-.299 1.688-1.194 2.07-1.96L42 39.23V2.765l-.272-.579C41.383 1.45 40.64.706 39.87.33l-.603-.297L21.228.01C4.087-.013 3.164-.005 2.644.175zm36.54 1.44c.635.238 1.25.97 1.392 1.657.078.377.104 5.905.085 18.04l-.028 17.498-.227.42c-.29.54-.604.828-1.193 1.095-.47.213-.585.214-18.078.216-13.57.002-17.703-.027-18.037-.126-.602-.178-1.353-.947-1.52-1.553-.101-.368-.123-4.376-.1-18.127l.03-17.655.292-.497c.332-.564.972-.993 1.652-1.11.243-.04 8.281-.064 17.864-.05 15.653.022 17.469.041 17.869.192zM13.472 20.97l.027 7.645h15.145l.027-7.645.027-7.645H13.444l.027 7.645zm13.752.027V27.2l-6.179-.027-6.179-.027-.027-6.174-.027-6.174h12.412v6.201z"
                  fill="#000"
                />
              </svg>
            </CardsAddItemIcon>
            <CardsAddItemText>
              Window <br /> Frame-out
            </CardsAddItemText>
            <CardsAddItemAddBtn onClick={addFramedWindow}>
              <svg
                width="9"
                height="9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.375 1.687v1.688H0l.006 1.12.006 1.118 1.681.006 1.682.006.006 1.682.006 1.681 1.119.006L5.626 9V5.625H9v-2.25L7.318 3.37l-1.682-.005-.005-1.682L5.625 0h-2.25v1.687z"
                  fill="#fff"
                />
              </svg>
              ADD
            </CardsAddItemAddBtn>
          </CardsAddItem>
        </Grid>
      </Grid>
    </CardsAddWrapper>
  );
};

export default CardsAdd;
